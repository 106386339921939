import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object({
  name: yup.string().required(),
  mobileNumber: yup
    .string()
    .max(11)
    .min(11)
    .typeError("Please enter a number only")
    .required()
    .matches(phoneRegExp, "Phone number is not valid"),
  address: yup.string(),
  code: yup.string().required(),
  percent: yup.number().typeError("Please enter a number only"),
  email: yup
    .string()
    .required()
    .matches(/@[^.]*\./, "Email is not valid"),
});

const DistributorForm = ({ onSubmit, distributor }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: distributor,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (distributor)
      reset({ ...distributor, email: distributor?.email.join(",") });
  }, [distributor, reset]);

  const randomCode =
    Math.random().toString(36).substring(2, 7).toUpperCase() +
    Math.random().toString(36).substring(2, 7).toUpperCase();

  return (
    <>
      <div className="flex flex-col justify-center   py-6 ">
        <Link
          to={"/distributors"}
          className=" flex w-auto max-w-[600px] items-center gap-2 text-xl font-bold text-primary"
        >
          <ArrowLeftIcon className="h-5 w-5 text-primary" />
          <span className="text-primary">Back</span>
        </Link>
        <div className="mt-2 max-w-lg sm:mx-auto sm:w-full md:mt-8">
          <div className="bg-white px-4  sm:rounded-2xl sm:px-10">
            <form className=" space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="distributor"
                  className="block text-sm font-medium text-[#3B5CAD]"
                >
                  Disributor name<span className="text-red-600"> *</span>
                </label>
                <div className="mt-1">
                  <input
                    {...register("name")}
                    id="distributor"
                    name="name"
                    type="text"
                    autoComplete="distributor"
                    className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                  />
                  {errors.distributor && (
                    <span className="text-xs text-red-500">
                      {errors.distributor?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex gap-4">
                <div>
                  <label
                    htmlFor="code"
                    className="block text-sm font-medium text-[#3B5CAD]"
                  >
                    Disributor code <span className="text-red-600"> *</span>
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("code")}
                      id="code"
                      name="code"
                      type="text"
                      autoComplete="code"
                      value={randomCode}
                      className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                    />
                    {errors.code && (
                      <span className="text-xs text-red-500">
                        {errors.code?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="percent"
                    className="block text-sm font-medium text-[#3B5CAD]"
                  >
                    Disributor percent
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("percent")}
                      id="percent"
                      name="percent"
                      type="text"
                      autoComplete="percent"
                      className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                    />
                    {errors.percent && (
                      <span className="text-xs text-red-500">
                        {errors.percent?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium text-[#3B5CAD]"
                >
                  Disributor mobile number
                  <span className="text-red-600"> *</span>
                </label>
                <div className="mt-1">
                  <input
                    {...register("mobileNumber")}
                    id="mobile"
                    name="mobileNumber"
                    type="text"
                    autoComplete="mobile"
                    className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                  />
                  {errors.mobileNumber && (
                    <span className="text-xs text-red-500">
                      {errors.mobileNumber?.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-[#3B5CAD]"
                >
                  Disributor address
                </label>
                <div className="mt-1">
                  <input
                    {...register("address")}
                    id="address"
                    name="address"
                    type="text"
                    autoComplete="address"
                    className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                  />
                  {errors.address && (
                    <span className="text-xs text-red-500">
                      {errors.address?.message}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <div className="mt-2">
                  <label
                    htmlFor="Email"
                    className="block text-sm font-medium text-[#3B5CAD]"
                  >
                    Disributor Email<span className="text-red-600"> *</span>
                  </label>
                  <div className=" mt-1 flex flex-col gap-2">
                    <textarea
                      {...register("email")}
                      rows={2}
                      id="Email"
                      name="email"
                      type="text"
                      autoComplete="Email"
                      className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                      placeholder="test1@gmail.com,test2@gmail.com"
                    />
                    <span className="text-xs text-[#00C18B]">
                      Seprate names using comma {"< , >"}
                    </span>
                    {errors.email && (
                      <span className="text-xs text-red-500">
                        {errors.email?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="mt-4 flex w-full justify-center rounded-xl border border-transparent bg-[#3B5CAD] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#3B5CAD]/90 focus:outline-none focus:ring-2 focus:ring-[#3B5DAD] focus:ring-offset-2"
                >
                  Add Disributor
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DistributorForm;
