import { configureStore } from "@reduxjs/toolkit";
import { authUserApi } from "./authUserApi";
import { checkPointApi } from "./checkPointApi";
import { distributorsApi } from "./distributorsApi";
import { moviesApi } from "./moviesApi";
import { reportsApi } from "./reportsApi";
import reportsReducer from "./reportsSlice";
import { reservationApi } from "./reservation/reservationApi";
import reservationReducer from "./reservation/reservationSlice";
import { screensApi } from "./screensApi";
import { seatsApi } from "./seatsApi";
import { showMovieApi } from "./showMovieApi";
import { showTimesApi } from "./showTimesApi";
import socketReducer from "./socketSlice";
import uiReducer from "./uiSlice";
import { usersApi } from "./usersApi";

const store = configureStore({
  reducer: {
    [authUserApi.reducerPath]: authUserApi.reducer,
    [seatsApi.reducerPath]: seatsApi.reducer,
    [showTimesApi.reducerPath]: showTimesApi.reducer,
    [showMovieApi.reducerPath]: showMovieApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [moviesApi.reducerPath]: moviesApi.reducer,
    [screensApi.reducerPath]: screensApi.reducer,
    [distributorsApi.reducerPath]: distributorsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [reservationApi.reducerPath]: reservationApi.reducer,
    [checkPointApi.reducerPath]: checkPointApi.reducer,
    reservation: reservationReducer,
    socket: socketReducer,
    reports: reportsReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["socket.socket"],
      },
    })
      .concat(authUserApi.middleware)
      .concat(usersApi.middleware)
      .concat(seatsApi.middleware)
      .concat(checkPointApi.middleware)
      .concat(showMovieApi.middleware)
      .concat(screensApi.middleware)
      .concat(reservationApi.middleware)
      .concat(reportsApi.middleware)
      .concat(distributorsApi.middleware)
      .concat(moviesApi.middleware)
      .concat(showTimesApi.middleware),
});

export default store;
