import React from 'react';
import ExchangeForm from './ExchangeForm';
import { useSelector, useDispatch } from 'react-redux';
import { useReservationsQuery } from '../../store/reservation/reservationApi';
import { socketActions } from '../../store/socketSlice';

function Exchange({ setIsOpen, newReservation, onExchange }) {
  const dispatch = useDispatch();
  const exchangesRes = useSelector(
    (state) => state.socket.exchangeData.oldSeats
  );
  const oldReservationsIds = exchangesRes?.map((i) => i.reservationStatus);
  const { data: oldReservations, isLoading } =
    useReservationsQuery(oldReservationsIds);

  const oldPrice = oldReservations?.reduce(
    (a, b) => ({ price: Number(a.price) + Number(b.price) }),
    {
      price: 0,
    }
  ).price;
  const newPrice = newReservation?.reduce(
    (a, b) => ({ price: Number(a.price) + Number(b.price) }),
    {
      price: 0,
    }
  ).price;

  return (
    <div className="flex h-full w-full flex-col items-center justify-start py-8 px-[52px]">
      <div className="mb-8 w-full">
        <p className=" w-full text-center text-base font-medium text-[#607D8B]">
          Are you sure you want to Exchange this reservation ?
        </p>
      </div>
      <p className="font-semibold text-red-600">Old</p>
      {oldReservations && !isLoading && (
        <ExchangeForm reservations={oldReservations} />
      )}
      <div className="my-5 w-full text-start text-[#00C18B]">
        <i className="fa-solid fa-arrow-down ml-[30px]"></i>
      </div>
      <p className="font-semibold text-green-600">New</p>
      <ExchangeForm reservations={newReservation} />
      <div className="mt-4 flex w-full flex-wrap items-center justify-evenly">
        <button
          onClick={() => {
            if (oldPrice !== newPrice)
              return alert('You have To Choose Seats With the Same Price');
            setIsOpen();
            onExchange('Exchange');
          }}
          className="mt-3  rounded-2xl bg-primary px-14 py-4 text-white"
        >
          Exchange
        </button>
        <button
          onClick={() => {
            setIsOpen(false);
          }}
          className="mt-3  rounded-2xl  px-14 py-4 text-red-500"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            dispatch(socketActions.resetExchangeData());
            setIsOpen();
          }}
          className="mt-3  rounded-2xl  px-14 py-4 text-red-500"
        >
          Reset Exchange
        </button>
      </div>
    </div>
  );
}

export default Exchange;
