import React, { useState } from "react";
import { useMoviesQuery } from "../../store/moviesApi";
import { DatePicker } from "@mantine/dates";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReportMovieModal from "../ui-assets/ReportMovieModal";

function TotalRevenuesByDayChooses({ setChoices }) {
  const { path } = useParams();
  const [filterField, setfilterField] = useState("Active Movies");

  const [searchValue, setSearchValue] = useState("");

  const { data: movies, refetch } = useMoviesQuery(
    `${
      filterField === "Active Movies" ? "" : "locked=true"
    }&search=${searchValue}`
  );

  const [data, setData] = useState({
    movieType: undefined,
  });

  const [dataChoices, setDataChoices] = useState({
    seatsType: ["Standard", "VIP","IMAX"],
    movieType: undefined,
    group: "Day",
  });


  const [value, setValue] = useState(new Date());
  const [valueOne, setValueOne] = useState(
    new Date(
      new Date().getHours() < 7
        ? new Date(new Date().setDate(new Date().getDate() - 1))
        : new Date()
    )
  );
  const [valueTwo, setValueTwo] = useState(
    new Date(
      new Date().getHours() < 7
        ? new Date(new Date().setDate(new Date().getDate() - 1))
        : new Date()
    )
  );

  useEffect(() => {
    setValue([
      new Date(new Date(valueOne).setHours(7, 0, 0, 0)),

      new Date(
        new Date(
          new Date(valueTwo).setDate(new Date(valueTwo).getDate() + 1)
        ).setHours(7, 0, 0, 0)
      ),
    ]);
  }, [valueOne, valueTwo]);

  useEffect(() => {
    setData({ ...data, ...dataChoices });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChoices]);
  
  useEffect(() => {
    setChoices(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    [
      "Total Revenues By Day",
      "Detailed revenues",
      "Detailed revenues 2",
      "online",
      "online for bank",
      "online paymob for bank",
    ].includes(path) && setData({ ...data, date: value, ...dataChoices });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [popUpsState, setPopUpsState] = useState({
    movies: false,
    screens: false,
    disrupter: false,
    timeFrom: false,
    timeTo: false,
  });
  useEffect(() => {
    refetch();
  }, [filterField, refetch, searchValue]);

  return (
    <>
      <div className="flex w-full flex-col items-start">
        <div className="mt-3 flex h-auto w-full flex-row gap-10 self-center overflow-scroll rounded-xl ">
          <div className=" flex h-full w-full flex-wrap  justify-around   gap-5  overflow-scroll px-4">
            <div className="flex min-h-[40px] w-[48%] flex-col">
              <div className="flex w-full items-center justify-between">
                <h1 className="  font-semibold  text-primary">Movie</h1>
                {data.movies && (
                  <i
                    onClick={() => setData({ ...data, movies: undefined })}
                    className="fa-solid fa-xmark mr-3 cursor-pointer text-gray-500"
                  ></i>
                )}
              </div>
              <div className="mt-3 w-full pb-3">
                <div className="min-h-11 relative z-10 flex w-full items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                  <p className="p-4">
                    {movies
                      ?.filter(
                        (i) => data.movies && data.movies.includes(i._id)
                      )
                      .map((i) => i.name)
                      .join(" , ")}
                  </p>

                  <button
                    onClick={() =>
                      setPopUpsState({
                        ...popUpsState,
                        movies: true,
                      })
                    }
                    className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                  >
                    <i className="fa-solid fa-bars"></i>
                  </button>
                </div>
              </div>
              {!data?.movies &&
                [
                  "Total Revenues By Day",
                  "Detailed revenues",
                  "Detailed revenues 2",
                ].includes(path) && (
                  <span className="mt-4 text-xs text-red-500">
                    movie is required
                  </span>
                )}
            </div>

            <>
              <div>
                <h1 className="  font-semibold  text-primary">Start date</h1>
                <DatePicker
                  value={valueOne}
                  onChange={setValueOne}
                  withAsterisk
                  onClear={() => setValueOne(new Date())}
                  inputFormat="DD MMM, YYYY"
                />
              </div>
              <div>
                <h1 className="  font-semibold  text-primary">End date</h1>
                <DatePicker
                  value={valueTwo}
                  onChange={setValueTwo}
                  withAsterisk
                  // clearable={false}
                  onClear={() => setValueTwo(new Date())}
                  inputFormat="DD MMM, YYYY"
                />
              </div>
            </>
            <>
              {/* Seats Type */}
              <div className="flex min-h-[40px] w-[48%] flex-col items-center gap-3">
                <h1 className="  font-semibold  text-primary">Seats Type</h1>
                <div className="flex w-40 flex-row items-center justify-around text-primary ">
                  <div
                    onClick={() =>
                      setDataChoices({
                        ...dataChoices,
                        seatsType: dataChoices.seatsType.includes("IMAX")
                          ? [...dataChoices.seatsType.filter((i) => i !== "IMAX")]
                          : [...dataChoices.seatsType, "IMAX"],
                      })
                    }
                    className={`${
                      dataChoices.seatsType?.includes("IMAX") && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    IMAX
                  </div>
                  <div
                    onClick={() =>
                      setDataChoices({
                        ...dataChoices,
                        seatsType: dataChoices.seatsType.includes("VIP")
                          ? [...dataChoices.seatsType.filter((i) => i !== "VIP")]
                          : [...dataChoices.seatsType, "VIP"],
                      })
                    }
                    className={`${
                      dataChoices.seatsType?.includes("VIP") && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    VIP
                  </div>
                  <div
                    onClick={() =>
                      setDataChoices({
                        ...dataChoices,
                        seatsType: dataChoices.seatsType.includes("Standard")
                          ? [...dataChoices.seatsType.filter((i) => i !== "Standard")]
                          : [...dataChoices.seatsType, "Standard"],
                      })
                    }
                    className={`${
                      dataChoices.seatsType?.includes("Standard") &&
                      "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    Standard
                  </div>
                </div>
              </div>
              {/* Movies Type */}
              <div className="flex min-h-[40px] w-[48%] flex-col items-center gap-3">
                <h1 className="  font-semibold  text-primary">Movies Type</h1>
                <div className="flex flex-wrap items-center justify-between  gap-2  text-primary ">
                  <div
                    onClick={() =>
                      setDataChoices({
                        ...dataChoices,
                        movieType: "Egyptian",
                      })
                    }
                    className={`${
                      dataChoices.movieType === "Egyptian" && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    Egyptian
                  </div>{" "}
                  :{" "}
                  <div
                    onClick={() =>
                      setDataChoices({
                        ...dataChoices,
                        movieType: "Foreign",
                      })
                    }
                    className={`${
                      dataChoices.movieType === "Foreign" && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    Foreign
                  </div>{" "}
                  :{" "}
                  <div
                    onClick={() =>
                      setDataChoices({
                        ...dataChoices,
                        movieType: undefined,
                      })
                    }
                    className={`${
                      dataChoices.movieType === undefined && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    All
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      {popUpsState.movies && (
        <ReportMovieModal
          data={movies}
          setSelected={(i) =>
            setData({
              ...data,
              movies: [i._id],
            })
          }
          type="Movies"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, movies: false })
          }
          selected={data?.movies}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          setfilterField={setfilterField}
        />
      )}
    </>
  );
}

export default TotalRevenuesByDayChooses;
