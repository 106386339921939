import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useDispatch, useSelector } from "react-redux";
import { socketActions } from "../../store/socketSlice";
import { useAuthUserQuery } from "../../store/authUserApi";
import { uiActions } from "../../store/uiSlice";
import { useSearchParams } from "react-router-dom";

export default function PopUp({ text, type }) {
  const [open, setOpen] = useState(true);
  const reservationDate = useSelector(
    (state) => state.reservation.ReservationDate
  );
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams.entries()]);
  const { data: authUser } = useAuthUserQuery();

  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const cancelHandler = () => {
    dispatch(
      socketActions.cancelReservation({
        type: "Cancel",
        date: `${reservationDate}, ${queryParams.startTime}`,
        data: {
          agent: authUser._id,
          showMovie: queryParams.showmovie,
          showTime: queryParams.showtime,
          screen: queryParams.screen,
        },
      })
    );
    dispatch(uiActions.setExChangePopup(false));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="mt-6 font-bold text-primary"
                    >
                      {type}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{text}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-2xl border-2 border-red-600 bg-white px-6 py-2 text-red-600 hover:bg-red-600 hover:text-white"
                    onClick={() => {
                      setOpen(false);
                      dispatch(socketActions.cancelAdminToken());
                      dispatch(uiActions.setExChangePopup(false));
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-2xl border-2 border-green-500 bg-white px-6 py-2 text-green-500 hover:bg-green-500 hover:text-white"
                    onClick={() => {
                      setOpen(false);
                      cancelHandler();
                      dispatch(socketActions.cancelAdminToken());
                    }}
                  >
                    Yes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
