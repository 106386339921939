import React from "react";
function Choices({ item, Choices }) {
  return (
    <div
      className={`flex  cursor-pointer flex-row items-center justify-between rounded-md border-b-2 hover:bg-zinc-200`}
    >
      <div className="flex flex-row items-center">
        <p
          className={`${
            Choices?.includes(item._id) ? "text-green-600" : ""
          }   truncate py-2 px-6`}
        >
          {item.name}
        </p>
      </div>
      {Choices?.includes(item._id) && (
        <>
          <i className=" fa-solid fa-circle-check mr-6 text-green-600"></i>
        </>
      )}
    </div>
  );
}

export default Choices;
