import React from "react";
import avatar from "../../Assets/profileaAvatar.svg";

const AgentAccordionHeader = ({ agent }) => {
  return (
    <div className="flex w-full border p-4">
      <div className="mt-4 flex h-full w-32 flex-col items-center gap-1">
        <img
          src={
            agent?.avatar === "/uploads/Default-avatar.jpg"
              ? avatar
              : agent.avatar || avatar
          }
          className="aspect- h-16 w-16 rounded-full"
        />
        <p className="w-full break-words">{agent.name}</p>
      </div>
      <div className="flex min-h-full w-full items-end justify-center text-center sm:items-center sm:p-0">
        <div className="relative w-full  transform rounded-2xl  bg-white text-left transition-all ">
          <div className="mt-4 flex w-full items-start gap-4 px-8">
            <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Sold Tickets</span>
              <p className="text-base font-semibold">{agent?.count || 0}</p>
            </div>
            <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Cash Tickets</span>
              <p className="text-base font-semibold">
                {agent?.cashTickets || 0}
              </p>
            </div>
            <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Credit Tickets</span>
              <p className="text-base font-semibold">
                {agent?.bankTickets || 0}
              </p>
            </div>
          </div>
          <div className="mt-4 flex w-full items-start gap-4  border-b-2 border-slate-200 px-8 pb-6">
            <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Sold Glasses</span>
              <p className="text-base font-semibold">
                {agent?.glassesCount || 0}
              </p>
            </div>
            <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Cash Glasses</span>
              <p className="text-base font-semibold">
                {agent?.cashGlasses || 0}
              </p>
            </div>
            <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Credit Glasses</span>
              <p className="text-base font-semibold">
                {agent?.bankGlasses || 0}
              </p>
            </div>
          </div>
          <div className="mt-4 flex w-full items-center justify-between  gap-4 px-8 pb-6">
            <div className="flex basis-1/3  flex-col"></div>
            <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Total sales</span>
              <p className="text-base font-semibold">
                {agent?.cashTickets + agent?.bankTickets} EGP
              </p>
            </div>
            {/* <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Total Cash</span>
              <p className="text-base font-semibold">0 EGP</p>
            </div>
            <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Total Credit</span>
              <p className="text-base font-semibold">0 EGP</p>
            </div> */}
            <div className="flex basis-1/3  flex-col">
              <span className=" text-xs text-[#607D8B]">Total Glasses</span>
              <p className="text-base font-semibold">
                {agent?.cashGlasses + agent?.bankGlasses} EGP
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentAccordionHeader;
