import React from "react";
import { useState } from "react";
import Modal from "../ui-assets/Modal";

function LockModal({ showtime, onToggleLock }) {
   const [ShowLockModal, setShowLockModal] = useState(false);

   return !showtime.locked ? (
      <>
         <Modal
            onClick={() => {
               onToggleLock(showtime.showTimeStatus);
               setShowLockModal(false);
            }}
            isOpen={ShowLockModal}
            // item={item}
            setIsOpen={setShowLockModal}
            ok="OK"
            action=" change "
            name={"Showtime Status"}
         />
         <i
            onClick={() => setShowLockModal(true)}
            class="fa-solid fa-lock-open cursor-pointer text-green-500"
         ></i>
      </>
   ) : (
      <>
         <Modal
            onClick={() => {
               setShowLockModal(false);
               onToggleLock(showtime.showTimeStatus);
            }}
            isOpen={ShowLockModal}
            // item={item}
            setIsOpen={setShowLockModal}
            action=" change "
            ok="OK"
            name={"Showtime Status"}
         />
         <i
            onClick={() => setShowLockModal(true)}
            class="fa-solid fa-lock cursor-pointer text-red-500"
         ></i>
      </>
   );
}

export default LockModal;
