import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { socketActions } from '../../store/socketSlice';
import { uiActions } from '../../store/uiSlice';
import { useSearchParams } from 'react-router-dom';
import EmergencyModal from './emergencyModal';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useAuthUserQuery } from '../../store/authUserApi';
import ReserveModal from '../reservation/ReserveModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const schema = yup
  .object({
    email: yup.string().email().required('email is required'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Your password is too short.'),
  })
  .required();

function Signinform({ onof, screen, showTime, showMovie }) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const [IsOpen, setIsOpen] = useState(false);
  const ContextMenu = useSelector((state) => state.ui.contextMenu);
  const oldContextMenu = useSelector((state) => state.ui.oldContextMenu);

  const seat = useSelector((state) => state.ui.contextMenuSet);
  const reservationDate = useSelector(
    (state) => state.reservation.ReservationDate
  );
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams.entries()]);
  const { data: userAgent } = useAuthUserQuery();

  const Reprint = () => {
    setTimeout(() => {
      dispatch(socketActions.cancelAdminToken());
    }, 1000);
    dispatch(socketActions.reprintReservation(seat));
  };

  const EmergencyReservation = () => {
    //  alert('OKI');
    if ([ContextMenu.seat] === 0)
      return toast.warning('Please Select a Seat!', {
        autoClose: 1500,
        toastId: '54sd654fdddsf',
      });
    const fullDate = new Date(`${reservationDate}, ${showTime?.startTime}`);

    if (userAgent?.role !== 'Super Admin') {
      const newDate =
        fullDate.getHours() < 7
          ? new Date(fullDate.setDate(fullDate.getDate() + 1))
          : fullDate;

      if (
        new Date(newDate.setMinutes(newDate.getMinutes() + 30)).getTime() <=
        Date.now()
      )
        return toast.warning(
          "You Can't Reserve after Show Started By +30 min",
          {
            autoClose: 1500,
            toastId: '54sd654fdsf',
          }
        );
    }

    if (
      new Date(fullDate).getTime() >
      new Date().setDate(new Date().getDate() + 7)
    )
      return toast.warning("You Can't Reserve more than One Week from now", {
        autoClose: 1500,
        toastId: '54sd654fdsf',
      });
    setIsOpen(true);
  };

  const submitHandler = (data) => {
    //  alert('hi');
    dispatch(socketActions.getAdminToken(data));

    ContextMenu === 'Delete'
      ? dispatch(uiActions.setExChangePopup(true))
      : ContextMenu === 'Reprint'
        ? Reprint()
        : ContextMenu?.type === 'EmergencyReservation' ||
          (oldContextMenu?.type === 'EmergencyReservation' &&
            ContextMenu === 'invitationTrue')
          ? EmergencyReservation()
          : ContextMenu === 'Exchange'
            ? dispatch(
              socketActions.setExchangeData({
                date: `${reservationDate}, ${queryParams.startTime}`,
              })
            )
            : ContextMenu === 'invitation'
              ? dispatch(uiActions.setContextMenu('invitationTrue'))
              : ContextMenu === 'refundGlasses' &&
              dispatch(uiActions.setRefundModal(true));
    dispatch(uiActions.setLoginPopup(false));
  };

  //   alert(onof);
  return (
    <>
      {onof && !IsOpen && (
        <div
          className={classNames(
            ' fixed top-0 right-0 z-50 flex h-[100vh] w-full items-center justify-center bg-[#00000048] '
          )}
        >
          <div className=" relative flex h-[400px] w-[476px] rotate-180 flex-col items-center rounded-xl bg-white ">
            <div
              onClick={() => dispatch(uiActions.setLoginPopup(false))}
              className="absolute right-8 top-5 cursor-pointer text-rose-700"
            >
              <i className="fa-solid fa-circle-xmark"></i>
            </div>
            <div className="w-90 mt-8 ">
              <img alt="logo" className=" w-36" src="/logo.png"></img>
            </div>
            <form
              onSubmit={handleSubmit(submitHandler)}
              className="w-full p-[32px]"
            >
              <div className="w-full p-[32px]">
                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-[#3B5CAD]"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        {...register('email')}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                      />
                      {errors.email && (
                        <span className="text-red-500">
                          {errors.email?.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-[#3B5CAD]"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          {...register('password')}
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                        />
                      </div>
                      {errors.password && (
                        <span className="text-red-500 ">
                          {errors.password?.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="mx-auto flex w-[190px] justify-center rounded-xl border border-transparent bg-[#3B5CAD] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#3B5CAD]/90 focus:outline-none focus:ring-2 focus:ring-[#3B5DAD] focus:ring-offset-2"
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {(ContextMenu?.type === 'EmergencyReservation' ||
        (ContextMenu === 'invitationTrue' &&
          oldContextMenu?.type === 'EmergencyReservation')) && (
          <ReserveModal
            className=""
            screen={screen}
            showTime={showTime}
            showMovie={showMovie}
            setIsOpenProp={setIsOpen}
            isOpenProp={IsOpen}
            chosenSeats={
              ContextMenu.seat ? [ContextMenu.seat] : [oldContextMenu.seat]
            }
            isEmergency={true}
            showButton={false}
          />
        )}
    </>
  );
}

export default Signinform;
