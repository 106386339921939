import { Link, useLocation, useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { sortShowTime } from "../../utiles/sortTime";
import ScrollContainer from "react-indiana-drag-scroll";
import MovieCard from "./MovieCard";

import { DatePicker } from "@mantine/dates";
import { useShowMovieReservationQuery } from "../../store/showMovieApi";
import { useDispatch, useSelector } from "react-redux";
import { reservationActions } from "../../store/reservation/reservationSlice";
import { useEffect, useState } from "react";
import Modal from "../ui-assets/Modal";

const MoviesReservation = ({ showTimes }) => {
  const reservationDate = useSelector(
    (state) => state.reservation.ReservationDate
  );

  const { id } = useParams();
  const { data: showMovie } = useShowMovieReservationQuery(
    { id, date: reservationDate },
    { skip: !id }
  );
  const dispatch = useDispatch();

  const location = useLocation().pathname;
  const [isOpen, setIsOpen] = useState(false);

  const handleAvailableShowTime = (ts) => {
    if (!location.includes("/selfServices")) return true;
    
    let date = new Date(reservationDate);
    let timeString = ts.startTime;
    let timeParts = timeString.split(":");
    let hours = parseInt(timeParts[0]); 
    let minutes = parseInt(timeParts[1].split(" ")[0]);
    let isPM = timeParts[1].split(" ")[1] === "PM";

    if (isPM && hours !== 12) {
      hours += 12;
    } else if (!isPM && hours === 12) {
      hours = 0;
    }
    if (!isPM && (hours >= 12 || hours < 6)) {
      date.setDate(date.getDate() + 1); 
    }
    date.setHours(hours, minutes);
    let now = new Date();
    date.setTime(date.getTime() + 30 * 60 * 1000);
    if (date.getTime() > now.getTime()) {
      return true;
    } else {
      return false;
    }
  };

  // const handleAvailableShowTime = (ts) => {
  //   if (!location.includes("/selfServices")) return true;
  //   const timeString = ts.startTime;
  //   const now = new Date();
  //   const dateString = `${now.getFullYear()}-${now.getMonth() + 1}-${
  //     ts.startTime.includes("AM") &&
  //     (parseInt(ts.startTime.split(":")[0]) >= 12 ||
  //       parseInt(ts.startTime.split(":")[0]) < 6)
  //       ? now.getDate() + 1
  //       : now.getDate()
  //   }`;
  //   const dateTimeString = `${dateString} ${timeString}`;
  //   const dateObject = new Date(dateTimeString).getTime();
  //   return dateObject > now.getTime();
  // };

  useEffect(() => {
    sessionStorage.removeItem("changeDate");
  }, []);

  // const allShowTimes = showMovie?.screens
  //   ?.map(({ showTimes }) => showTimes)
  //   .flat();

  // const availableShowTimes = showTimes?.filter((show) =>
  //   allShowTimes.some((i) => i._id === show._id)
  // );
  // const availableScreens = showMovie?.screens.filter((i) =>
  //   i.showTimes.some((i) => i._id === selectedShowtime)
  // );

  return (
    <>
      <div className=" flex w-full flex-row justify-between pb-4 ">
        <Link
          to={
            location.includes("/selfServices")
              ? "/selfServices"
              : "/reservation"
          }
          className=" flex w-auto max-w-[600px] items-center gap-2 text-xl font-bold text-primary"
        >
          <ArrowLeftIcon className="h-5 w-5 text-primary" />
          {showMovie?.movie?.name}
        </Link>
        {!location.includes("/selfServices") ? (
          <div>
            <DatePicker
              value={new Date(reservationDate)}
              onChange={(e) =>
                dispatch(
                  reservationActions.setReservationDate(
                    new Date(e).toLocaleDateString()
                  )
                )
              }
              withAsterisk
            />
          </div>
        ) : (
          <>
            <button
              onClick={() => setIsOpen(true)}
              className={`font-base flex cursor-pointer items-center
                  justify-center rounded-2xl border border-primary bg-primary px-4 py-2 text-center text-white `}
            >
              Change Date
            </button>
            <Modal
              action="Confirm"
              name="Showtime"
              // onClick={onDelete}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              datepicker={true}
              isSelfServices={location.includes("/selfServices")}
              changeDate={true}
            />
          </>
        )}
      </div>

      <div className="grid h-auto  w-full auto-rows-auto grid-cols-12 gap-x-5 gap-y-2  rounded-2xl px-4">
        <div
          className={`col-start-1 ${
            location.includes("/selfServices") ? "col-end-4" : "col-end-3"
          } row-start-2  h-full w-full`}
        >
          {showMovie && (
            <MovieCard
              text="hidden"
              height="w-full h-full"
              opacity=""
              showMovie={showMovie}
              movieSelf={location.includes("/selfServices") ? true : false}
            />
          )}
        </div>
        <div className=" col-start-3 col-end-10  flex flex-row items-center justify-end">
          <h1 className=" truncate text-base font-bold">
            Release Date:{" "}
            {new Date(showMovie?.releaseDate).toLocaleDateString() || "NA"}
          </h1>
          <div className=" flex w-20  justify-around  "></div>
        </div>
        <div
          className={`${
            location.includes("/selfServices") ? "col-start-4" : "col-start-3"
          } col-end-13 row-start-2 mt-2 mb-4 flex w-full flex-row justify-start`}
        >
          {showMovie?.screens.length === 0 &&
            location.includes("/selfServices") && (
              <div className="col-start-3 col-end-13 row-start-2 mt-6 mb-4 flex w-full flex-col items-center">
                <p className="mb-6 text-lg text-red-500">
                  Can't find showmovies please change the date
                </p>
              </div>
            )}
          {showMovie && (
            <ScrollContainer
              horizontal={location.includes("/selfServices") ? true : false}
              hideScrollbars={location.includes("/selfServices") ? false : true}
              className={`${
                location.includes("/selfServices") ? "my-scroll-container" : ""
              }`}
            >
              <div className="col-start-3 col-end-13 row-start-2 mt-2 mb-4 flex w-full flex-row justify-start">
                {[...showMovie?.screens]
                  ?.sort(
                    (a, b) =>
                      +a.screen.name.split(" ")[1] -
                      +b.screen.name.split(" ")[1]
                  )
                  ?.map(({ screen, showTimes }, i) => (
                    <>
                      <div key={i} className="col-span-1 mr-2 flex flex-col">
                        <span
                          className={`text-red-500 ${
                            location.includes("/selfServices") ? "text-xl" : ""
                          }`}
                        >
                          {screen.type}
                        </span>
                        <span
                          className={`font-base mb-4 flex items-center justify-center rounded-2xl  bg-white  fill-primary px-2 py-2 text-center ${
                            location.includes("/selfServices")
                              ? "text-xl"
                              : "text-sm"
                          } text-primary`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            className="svgStyle mx-2 h-6 w-6"
                          >
                            <path d="M16.75 44v-3h4.3v-5h-14q-1.2 0-2.1-.9-.9-.9-.9-2.1V9q0-1.2.9-2.1.9-.9 2.1-.9h34q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9h-14v5h4.3v3Zm-9.7-11h34V9h-34v24Zm0 0V9v24Z" />
                          </svg>
                          {screen.name}
                        </span>

                        <div className="relative flex justify-center">
                          <div
                            className={`relative flex w-full flex-col justify-between gap-2 ${
                              location.includes("/selfServices")
                                ? ""
                                : "max-h-[240px] overflow-scroll scroll-smooth"
                            }`}
                          >
                            {sortShowTime(showTimes)?.map((showtime, i) => (
                              <Link
                                key={i}
                                onClick={(event) =>
                                  handleAvailableShowTime(showtime)
                                    ? undefined
                                    : event.preventDefault()
                                }
                                to={`${
                                  location.includes("/selfServices")
                                    ? "/selfReserve"
                                    : "/reserve"
                                }?showmovie=${showMovie._id}&showtime=${
                                  showtime._id
                                }&screen=${screen._id}&startTime=${
                                  showtime.startTime
                                }`}
                                className={`${
                                  handleAvailableShowTime(showtime)
                                    ? "cursor-pointer border-primary text-primary hover:bg-primary hover:text-white"
                                    : "cursor-auto border-[#aaa] text-[#aaa] line-through"
                                } font-base flex items-center justify-center rounded-2xl border px-2 py-1 text-center ${
                                  location.includes("/selfServices")
                                    ? "text-base"
                                    : "text-xs"
                                } `}
                              >
                                {showtime.startTime}
                              </Link>
                            ))}
                          </div>{" "}
                          {!location.includes("/selfServices") &&
                            showTimes.length > 5 && (
                              <span className="absolute -bottom-3 mx-16 h-4 w-4 animate-bounce text-primary">
                                <i className="fa-solid fa-circle-arrow-down"></i>
                              </span>
                            )}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </ScrollContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default MoviesReservation;
