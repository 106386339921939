import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DropDownList from "./DropDownList";

function DropDown({ type, data, selected, setSelected, isSelfServices }) {
  const [dropdownState, setDropdownState] = useState(false);
  const location = useLocation().pathname;
  return (
    <div className=" relative h-16 w-full  rounded-t-2xl bg-[#f1f4fb]">
      <div className="flex h-full rotate-180 flex-col justify-end ">
        <div className="mb-3 flex items-center pl-3">
          <h1 className="h-1/2 text-[12px] "> {type}</h1>
        </div>
        <div className=" z-[1000] flex h-1/2 w-full items-center  justify-between rounded-b-2xl bg-white px-2 pl-3 shadow-lg ">
          <p className="text-xs ">
            {type === "Movies"
              ? selected?.movie?.name
              : type === "Screens"
              ? selected?.name
              : type === "Time Show"
              ? selected?.startTime
              : type === "ShowMovie"
              ? selected
              : selected.name}
          </p>{" "}
          {type !== "Movies" ? (
            <button
              onClick={() =>
                type !== "Movies" && setDropdownState((prev) => !prev)
              }
              className="rounded-lg   p-[4px] text-sm text-gray-600 hover:text-gray-900"
            >
              <i className="fa-solid fa-bars"></i>
            </button>
          ) : (
            <Link to={location.includes("/selfReserve") ? "/selfServices": "/reservation"}>
              <button className="rounded-lg   p-[4px] text-sm text-gray-600 hover:text-gray-900">
                <i className="fa-solid fa-bars"></i>
              </button>
            </Link>
          )}
        </div>

        {dropdownState && (
          <DropDownList
            selected={selected}
            data={data}
            setSelected={setSelected}
            type={type}
            setDropdownState={() => setDropdownState((prev) => !prev)}
            isSelfServices={isSelfServices}
          />
        )}
      </div>
    </div>
  );
}

export default DropDown;
