import { RadioGroup } from "@headlessui/react";
import React, { useState } from "react";
import Toggle from "../ui-assets/Toggle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { ToastContainer, toast } from "react-toastify";
import { sortShowTime } from "../../utiles/sortTime";

const schema = yup
  .object({
    hour: yup
      .number()
      .max(12)
      .typeError("hour must be a Number")
      .required("hour is required"),
    minutes: yup
      .number()
      .max(59)
      .typeError("minutes must be a Number")
      .required("minutes is required"),
    price: yup.number().typeError("price must be a Number").required(),
  })
  .required();

const ShowtimeForm = ({ onSubmit, showTime }) => {
  const [time, setTime] = useState();
  const [enabled, setEnabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: showTime,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (showTime) {
      reset(showTime);
      setTime(showTime?.startTime.split(" ")[1]);
      setEnabled(showTime?.status === "Available");
    }
  }, [showTime, reset]);

  const notify = (i, ii) =>
    ii === "error" ? toast.error(i) : toast.success(i);

  return (
    <>
      <Link
        to={"/showtimes"}
        className=" flex w-auto max-w-[600px] items-center gap-2 text-xl font-bold text-primary"
      >
        <ArrowLeftIcon className="h-5 w-5 text-primary" />
        <span className="text-primary">Back</span>
      </Link>
      <div className="flex h-full w-full items-center justify-center py-24">
        <form
          className="w-[412px] text-center"
          onSubmit={
            time
              ? handleSubmit(
                  onSubmit.bind(null, {
                    status: enabled ? "Available" : "Not Available",
                    _id: showTime?._id,
                    time,
                  })
                )
              : (e) => {
                  e.preventDefault();
                  notify("Please select PM or AM", "error");
                }
          }
        >
          <div className="mb-4 flex justify-between">
            <h3 className="text-2xl font-bold">New Showtime</h3>
            <Toggle enabled={enabled} setEnabled={setEnabled} />
          </div>
          <p className="text-left text-sm font-semibold text-[#3B5CAD]">
            showtime starting
          </p>
          <div className="mt-2 flex justify-between gap-2">
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="border-r border-[#CFD8DC] pr-2 text-xs text-gray-500">
                  Hour
                </span>
              </div>
              <input
                {...register("hour")}
                type="text"
                name="hour"
                id="hour"
                defaultValue={showTime?.startTime.split(":")[0]}
                maxLength={2}
                className=" h-10 w-[129px] rounded-xl border-gray-300 pl-16  focus:border-primary focus:ring-primary sm:text-sm"
                placeholder="00"
              />
            </div>
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="border-r border-[#CFD8DC] pr-2 text-xs text-gray-500">
                  Minutes
                </span>
              </div>
              <input
                {...register("minutes")}
                type="text"
                name="minutes"
                id="minutes"
                maxLength={2}
                defaultValue={showTime?.startTime.split(":")[1].split(" ")[0]}
                className=" h-10  w-[129px] rounded-xl border-gray-300 pl-20  focus:border-primary focus:ring-primary sm:text-sm"
                placeholder="00"
              />
            </div>

            <div>
              <RadioGroup value={time} onChange={setTime}>
                <div className="mt-1 flex h-10 space-x-2">
                  <RadioGroup.Option
                    value="AM"
                    className={({ active, checked }) =>
                      `${active ? "" : ""}
                    ${checked ? "bg-primary  text-white" : "bg-white"}
                      relative flex cursor-pointer rounded-xl px-5 py-4 shadow-md focus:outline-none`
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-center">
                            <div className="text-sm">
                              <RadioGroup.Label
                                as="p"
                                className={`font-medium  ${
                                  checked ? "text-white" : "text-gray-900"
                                }`}
                              >
                                AM
                              </RadioGroup.Label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option
                    value="PM"
                    className={({ active, checked }) =>
                      `${active ? "" : ""}
                    ${checked ? "bg-primary  text-white" : "bg-white"}
                      relative flex cursor-pointer rounded-xl px-5 py-4 shadow-md focus:outline-none`
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-center">
                            <div className="text-sm">
                              <RadioGroup.Label
                                as="p"
                                className={`font-medium  ${
                                  checked ? "text-white" : "text-gray-900"
                                }`}
                              >
                                PM
                              </RadioGroup.Label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                </div>
              </RadioGroup>
            </div>
          </div>
          <p className="mt-4 text-left text-sm font-semibold text-[#3B5CAD]">
            Showtime price
          </p>
          <div className="relative mt-2  ">
            <input
              {...register("price")}
              type="text"
              name="price"
              id="price"
              className="block h-10 w-full rounded-xl border-gray-300 pr-12 focus:border-primary focus:ring-primary sm:text-sm"
              placeholder="Enter your showtime price here"
              aria-describedby="price-currency"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span
                className="border-l pl-4 text-gray-500 sm:text-sm"
                id="price-currency"
              >
                EGP
              </span>
            </div>
          </div>
          {errors && (
            <div className="mt-4 flex w-full flex-col items-start justify-start text-left">
              {errors.hour && (
                <span className="text-xs text-red-500">
                  {errors.hour?.message}
                </span>
              )}
              {errors.minutes && (
                <span className="text-xs text-red-500">
                  {errors.minutes?.message}
                </span>
              )}
              {errors.price && (
                <span className="text-xs text-red-500">
                  {errors.price?.message}
                </span>
              )}
            </div>
          )}
          <button className="mt-20 rounded-2xl bg-primary px-14 py-4 text-white">
            Add Showtime
          </button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default ShowtimeForm;
