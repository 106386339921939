import React from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

function DetailedSalesTable({ data, movieReportData }) {
  const location = useLocation();

  const distributors = movieReportData?.map(
    (movieReportData) => movieReportData.العامل
  );
  const usersUnique = [...new Set(distributors)];

  // get total of الصافى from movieReportData to evry distributor And not to repeat it

  const total = usersUnique?.map((distributor) => {
    const total = movieReportData?.reduce(
      (acc, curr) => {
        if (curr.العامل === distributor) {
          return {
            number: acc.number + curr['العدد'],
            total: acc.total + curr['المبلغ'],
            cash: acc.cash + curr['نقدي'],
            totalCash: acc.totalCash + curr['اجمالي النقدي'],
            card: acc.card + curr['بطاقات مصرفية'],
            totalCard: acc.totalCard + curr['اجمالي البطاقات المصرفية'],
          };
        }
        return acc;
      },
      {
        number: 0,
        total: 0,
        cash: 0,
        totalCash: 0,
        card: 0,
        totalCard: 0,
      }
    );
    return total;
  });

  const total3 = usersUnique?.map((distributor) => {
    const total = movieReportData?.reduce(
      (acc, curr) => {
        if (curr.العامل === distributor) {
          return {
            total: acc.total + curr['السعر'],
          };
        }
        return acc;
      },
      {
        total: 0,
      }
    );
    return total;
  });

  return (
    <table className=" w-full text-center">
      <tr className="w-full border border-black  ">
        {data.map((item, index) => (
          <th className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1">
            {item}
          </th>
        ))}
      </tr>
      <br />
      {usersUnique.map((distributor, index) => (
        <>
          <div className="  mb-2 w-full text-center">
            <p className="border-b-2 border-b-black">{distributor}</p>
          </div>
          <>
            {movieReportData.map((item) =>
              item.العامل === distributor ? (
                <tr className="w-full border  border-black  ">
                  {data.map((item1, index) => (
                    <td className="h-[50px] w-24 max-w-[100px] items-center justify-center overflow-hidden text-ellipsis border border-black px-1">
                      {item1 === 'العدد' || item1 === 'رقم'
                        ? item[item1]
                        : typeof item[item1] === 'number'
                        ? item[item1]
                        : item1 === 'تاريخ الحفله' ||
                          item1 === 'توقيت الحذف' ||
                          item1 === 'توقيت البيع' ||
                          item1 === 'وقت اعادة الطباعه' ||
                          item1 === 'التوقيت' ||
                          item1 === 'التاريخ'
                        ? dayjs(item[item1]).format('HH:mm:ss')
                        : item1 === 'المشرف'
                        ? item[item1].name
                        : item[item1]}
                    </td>
                  ))}
                </tr>
              ) : null
            )}
            {location.pathname === '/viewReport/Detailed%20sales' && (
              <tr className="w-full border  border-black  ">
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total[index].total}
                </td>{' '}
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total[index].totalCard}
                </td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total[index].card}
                </td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total[index].totalCash}
                </td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total[index].cash}
                </td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total[index].number}
                </td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <th className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  الاجمالي
                </th>
              </tr>
            )}
            {location.pathname === '/viewReport/Detailed%20sales%203' && (
              <tr className="w-full border  border-black  ">
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>{' '}
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total3[index].total}
                </td>{' '}
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <th className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  الاجمالي
                </th>
              </tr>
            )}
            {(location.pathname === '/viewReport/Total%20sales%20inadvance2' ||
              location.pathname ===
                '/viewReport/Total%20sales%20inprevious2') && (
              <tr className="w-full border  border-black  ">
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>{' '}
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total3[index].total}
                </td>{' '}
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <th className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  الاجمالي
                </th>
              </tr>
            )}
            <br />
          </>
        </>
      ))}
    </table>
  );
}

// {movieReportData?.map((item, index) => {
//     return (
//       <tr className="w-full border  border-black  ">
//         {data.map((item1, index) => (
//           <td className=" h-[50px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
//             {typeof item[item1] === "number"
//               ? item[item1].toFixed(2)
//               : item1 === "تاريخ الحفله" ||
//                 item1 === "توقيت الحذف" ||
//                 item1 === "توقيت البيع" ||
//                 item1 === "وقت اعادة الطباعه" ||
//                 item1 === "التوقيت"
//               ? moment(item[item1]).format("YYYY-MM-DD HH:mm:ss")
//               : item1 === "المشرف"
//               ? item[item1].name
//               : item[item1]}
//           </td>
//         ))}
//       </tr>
//     );
//   })}
export default DetailedSalesTable;
