import React, { useState } from "react";
import { useGetpriceSettingsQuery } from "../../store/reservation/reservationApi";
import Filters from "../ui-assets/Filters";
import Glasses from "./tabs/Glasses";

function Setting() {
  const [filterField, setFilterField] = useState("glasses");

  const { data: priceSettings } = useGetpriceSettingsQuery();
  const filterData = [
    {
      id: 1,
      name: "Glasses",
      component: <Glasses priceSettings={priceSettings} />,
      value: "glasses",
    },
  ];

  return (
    <>
      <div className="flex w-full justify-center">
        <Filters setfilterData={setFilterField} filterData={filterData} />
      </div>

      <>
        {filterData.map((item, i) => (
          <React.Fragment key={i}>
            {item.value === filterField && item.component}
          </React.Fragment>
        ))}
      </>
    </>
  );
}

export default Setting;
