import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Roles from "./Roles";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { RadioGroup } from "@headlessui/react";
import { useDistributorsQuery } from "../../store/distributorsApi";

function classNames(...classes) {
   return classes.filter(Boolean).join(" ");
}

const roles = [
   {
      name: "Super Admin",
   },
   {
      name: "Admin",
   },
   {
      name: "Supervisor",
   },
   {
      name: "Reservation",
   },
   {
      name: "Finance",
   },
   {
      name: "Distributor",
   },
   {
      name: "Taxes",
   },
   {
      name: "Scanner",
   },
   {
      name: "Kiosk",
   },
];

const UserForm = ({ user, onSubmit, imageloading }) => {
   const [password, setpassword] = useState("");
   const [selected, setSelected] = useState(user?.role);
   const [distributor, setDistributor] = useState();
   const { data: distributors, isLoading } = useDistributorsQuery();
   const schema = yup.object({
      name: yup.string().required("name is required"),
      email: yup.string().email().required("email is required"),
      distributor:
         selected === "Distributor"
            ? yup.string().required("Distributor is required")
            : yup.string(),
      password: user
         ? password.length === 0
            ? yup.string()
            : yup.string().min(7, "Your password is too short")
         : yup
              .string()
              .required("Please enter your password.")
              .min(8, "Your password is too short."),
   });

   const avatarInput = useRef();
   const [imgPreview, setImgPreview] = useState();

   const {
      register,
      handleSubmit,
      watch,
      reset,
      formState: { errors },
   } = useForm({
      defaultValues: user,
      resolver: yupResolver(schema),
      mode: "onChange",
   });

   useEffect(() => {
      if (user) {
         setSelected(user.role);
         reset(user);
      }
   }, [user, reset]);

   useEffect(() => {
      if (selected === "Distributor") {
         reset({ ...watch(), distributor: distributor });
      }
   }, [selected, distributor]);

   return (
      <>
         <Link
            to={"/users"}
            className=" flex w-auto max-w-[600px] items-center gap-2 text-xl font-bold text-primary"
         >
            <ArrowLeftIcon className="h-5 w-5 text-primary" />
            <span className="text-primary">Back</span>
         </Link>
         <div className="container mx-auto max-w-[412px] pt-8">
            <form
               className=" space-y-6"
               onSubmit={handleSubmit(
                  onSubmit.bind(null, {
                     file: imgPreview,
                     role: selected || user?.role,
                  })
               )}
            >
               <div className="flex justify-center">
                  <div className="relative mt-1 flex items-center">
                     <input
                        className=" ml-5 hidden rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        type="file"
                        ref={avatarInput}
                        onChange={(e) => setImgPreview(e.target.files[0])}
                     />
                     <button
                        type="button"
                        className=" relative  h-20 w-20  text-lg font-medium text-[#3B5CAD]"
                        onClick={() => avatarInput.current.click()}
                     >
                        <span className=" overflow-hidden rounded-full bg-gray-100">
                           <img
                              className="h-full rounded-full object-cover"
                              src={
                                 imgPreview
                                    ? URL.createObjectURL(imgPreview)
                                    : user
                                    ? user?.avatar ===
                                      "/uploads/Default-avatar.jpg"
                                       ? "/profileaAvatar.svg"
                                       : user?.avatar
                                    : "/profileaAvatar.svg"
                              }
                              alt="as"
                           />
                        </span>
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           className="absolute top-0 left-1/2 h-6  w-6 translate-x-1/2  rounded-full bg-white bg-opacity-90"
                           fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor"
                           strokeWidth={2}
                        >
                           <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                           />
                        </svg>
                     </button>
                  </div>
               </div>

               <div>
                  <label
                     htmlFor="email"
                     className="block text-sm font-medium text-[#3B5CAD]"
                  >
                     Name
                  </label>
                  <div className="mt-1">
                     <input
                        {...register("name")}
                        id="name"
                        name="name"
                        type="text"
                        defaultValue={user?.name}
                        autoComplete="name"
                        className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                     />
                     {errors.name && (
                        <span className="text-xs text-red-500">
                           {errors.name?.message}
                        </span>
                     )}
                  </div>
               </div>

               <div>
                  <label
                     htmlFor="email"
                     className="block text-sm font-medium text-[#3B5CAD]"
                  >
                     Email address
                  </label>
                  <div className="mt-1">
                     <input
                        {...register("email")}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        defaultValue={user?.email}
                        className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                     />
                     {errors.email && (
                        <span className="text-xs text-red-500">
                           {errors.email?.message}
                        </span>
                     )}
                  </div>
               </div>

               <div>
                  <label
                     htmlFor="password"
                     className="block text-sm font-medium text-[#3B5CAD]"
                  >
                     Password
                  </label>
                  <div className="mt-1">
                     <input
                        {...register("password")}
                        id="password"
                        name="password"
                        type="password"
                        onChange={(e) => setpassword(e.target.value)}
                        defaultValue={user?.password}
                        autoComplete="current-password"
                        className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                     />
                     {errors.password && (
                        <span className="text-xs text-red-500">
                           {errors.password?.message}
                        </span>
                     )}
                  </div>
               </div>

               <div>
                  <label
                     htmlFor="password"
                     className="block text-sm font-medium text-[#3B5CAD]"
                  >
                     Roles
                  </label>
                  <div className="mt-1">
                     <Roles
                        selected={selected}
                        setSelected={setSelected}
                        settings={roles}
                     />
                     {!selected && (
                        <p className="text-xs text-red-500">Role is required</p>
                     )}
                  </div>
               </div>

               {distributors && selected === "Distributor" && (
                  <>
                     <label
                        htmlFor="password"
                        className="block text-sm font-medium text-[#3B5CAD]"
                     >
                        Distributors
                     </label>
                     <div className="space-y-2">
                        <RadioGroup
                           value={distributor}
                           onChange={setDistributor}
                        >
                           <div className="mt-1 flex flex-wrap gap-2 -space-y-px rounded-md bg-white text-center shadow-sm">
                              {distributors?.map((setting, settingIdx) => (
                                 <RadioGroup.Option
                                    key={setting.name}
                                    value={setting._id}
                                    className={({ checked }) =>
                                       classNames(
                                          checked
                                             ? "z-10 border-sky-200 bg-[#3B5DAD] "
                                             : "border-[#3B5DAD]",
                                          "text-xm  relative flex  cursor-pointer  items-center justify-center rounded-2xl border py-3  px-2 font-medium shadow-sm focus:outline-none  "
                                       )
                                    }
                                 >
                                    {({ active, checked }) => (
                                       <>
                                          <span className="">
                                             <RadioGroup.Label
                                                as="span"
                                                className={classNames(
                                                   checked
                                                      ? "text-white"
                                                      : "text-[#3B5DAD]",
                                                   "block text-center text-xs font-medium "
                                                )}
                                             >
                                                {setting.name}
                                             </RadioGroup.Label>
                                          </span>
                                       </>
                                    )}
                                 </RadioGroup.Option>
                              ))}
                           </div>
                        </RadioGroup>
                     </div>
                     {errors.distributor && (
                        <span className="text-xs text-red-500">
                           {errors.distributor?.message}
                        </span>
                     )}
                  </>
               )}

               <div>
                  <button
                     disabled={imageloading.loading}
                     type="submit"
                     className="mt-14 flex w-full justify-center rounded-xl border border-transparent bg-[#3B5CAD] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#3B5CAD]/90 focus:outline-none focus:ring-2 focus:ring-[#3B5DAD] focus:ring-offset-2"
                  >
                     {imageloading.loading
                        ? `loading ${imageloading.progress}%`
                        : user
                        ? "Edit"
                        : "Add user"}
                  </button>
               </div>
            </form>
         </div>
      </>
   );
};

export default UserForm;

// {
//   loading: false,
//   progress: 0,
// }
