import React from "react";

import moment from "moment";

function DistributorsRevenuesTable({ data, movieReportData }) {
  // filter array of object to get only the الموزع from movieReportData And not to repeat it

  const distributors = movieReportData?.map(
    (movieReportData) => movieReportData.الموزع
  );
  const distributorsUnique = [...new Set(distributors)];

  // get total of الصافى from movieReportData to evry distributor And not to repeat it
  const total = distributorsUnique?.map((distributor) => {
    const total = movieReportData?.reduce(
      (acc, curr) => {
        if (curr.الموزع === distributor) {
          return {
            net: acc.net + curr["الصافى"],
            numberOfTickets: acc.numberOfTickets + curr["عدد التذاكر"],
            total: acc.total + curr["الاجمالى"],
            Tax: acc.Tax + curr["الضريبة"],
            ValueAdded: acc.ValueAdded + curr["القيمه المضافة"],
            online: acc.online + curr["اونلاين"],
          };
        }
        return acc;
      },
      {
        net: 0,
        numberOfTickets: 0,
        total: 0,
        Tax: 0,
        ValueAdded: 0,
        online: 0,
      }
    );
    return total;
  });

  return (
    <table className=" w-full text-center">
      <tr className="w-full border border-black  ">
        {data.map((item, index) => (
          <th className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1">
            {item}
          </th>
        ))}
      </tr>
      <br />
      {distributorsUnique.map((distributor, index) => (
        <>
          <div className="  mb-2 w-full text-center">
            <p className="border-b-2 border-b-black">{distributor}</p>
          </div>
          <>
            {movieReportData.map((item) =>
              item.الموزع === distributor ? (
                <tr className="w-full border  border-black  ">
                  {data.map((item1, index) => (
                    <td className=" h-[50px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                      {item1 === "عدد التذاكر"
                        ? item[item1]
                        : item1 === "اونلاين"
                        ? item[item1]
                        : typeof item[item1] === "number"
                        ? item[item1].toFixed(2)
                        : item1 === "تاريخ الحفله" ||
                          item1 === "توقيت الحذف" ||
                          item1 === "توقيت البيع" ||
                          item1 === "وقت اعادة الطباعه" ||
                          item1 === "التوقيت"
                        ? moment(item[item1]).format("DD-MM-YYYY HH:mm:ss")
                        : item1 === "المشرف"
                        ? item[item1].name
                        : item[item1]}
                    </td>
                  ))}
                </tr>
              ) : null
            )}
            <tr className="w-full border  border-black  ">
              <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                {total[index]?.net.toFixed(2)}
              </td>
              <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                {total[index]?.ValueAdded.toFixed(2)}
              </td>
              <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                {total[index]?.Tax.toFixed(2)}
              </td>
              <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                {total[index]?.total.toFixed(2)}
              </td>
              <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                {total[index]?.numberOfTickets}
              </td>
              <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                {total[index]?.online}
              </td>
              <th className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                الاجمالي
              </th>
            </tr>
            <br />
          </>
        </>
      ))}
    </table>
  );
}

// {movieReportData?.map((item, index) => {
//     return (
//       <tr className="w-full border  border-black  ">
//         {data.map((item1, index) => (
//           <td className=" h-[50px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
//             {typeof item[item1] === "number"
//               ? item[item1].toFixed(2)
//               : item1 === "تاريخ الحفله" ||
//                 item1 === "توقيت الحذف" ||
//                 item1 === "توقيت البيع" ||
//                 item1 === "وقت اعادة الطباعه" ||
//                 item1 === "التوقيت"
//               ? moment(item[item1]).format("DD-MM-YYYY HH:mm:ss")
//               : item1 === "المشرف"
//               ? item[item1].name
//               : item[item1]}
//           </td>
//         ))}
//       </tr>
//     );
//   })}
export default DistributorsRevenuesTable;
