import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../config/dataService/dataService";

export const moviesApi = createApi({
   reducerPath: "moviesApi",
   baseQuery: fetchBaseQuery({
      baseUrl: BASE_URL,
      credentials: "include",
   }),
   tagTypes: ["Movies"],
   endpoints: (builder) => ({
      movies: builder.query({
         query: (locked) => `movies?${locked}`,
         providesTags: ["Movies"],
      }),
      movie: builder.query({
         query: (id) => `movies/${id}`,
         providesTags: ["Movies"],
      }),
      createMovie: builder.mutation({
         query: (data) => ({
            url: "movies",
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["Movies"],
      }),
      updateMovie: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `movies/${id}`,
            method: "PATCH",
            body: data,
         }),
         invalidatesTags: ["Movies"],
      }),
      deleteMovie: builder.mutation({
         query: (id) => ({
            url: `movies/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["Movies"],
      }),
      SyncMovie: builder.mutation({
         query: (id) => ({
            url: `movies/sync`,
            method: "GET",
         }),
         invalidatesTags: ["Movies"],
      }),
   }),
});

export const {
   useMoviesQuery,
   useMovieQuery,
   useCreateMovieMutation,
   useUpdateMovieMutation,
   useDeleteMovieMutation,
   useSyncMovieMutation,
} = moviesApi;
