/* eslint-disable no-unreachable */
import { ArrowLeftIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DropDownList from "../ui-assets/DropDownList";
import Input from "../ui-assets/Input";
import { useFreeShowTimesQuery } from "../../store/showTimesApi";
import { toast } from "react-toastify";
import {
  useDeleteShowtimeStatusMutation,
  useEditShowTimeDateMutation,
  useOverwriteShowTimeMutation,
} from "../../store/showMovieApi";
import DeleteShowtime from "../ui-assets/DeleteShowtime";
import { useDistributorQuery } from "../../store/distributorsApi";
import { sortShowTime } from "../../utiles/sortTime";
import { DatePicker } from "@mantine/dates";
import { CalendarIcon } from "@heroicons/react/solid";
import ModalDate from "../ui-assets/ModalDate";

const ShowMovieForm = ({ movies, screens, onSubmit, showMovie }) => {
  const [isDateOpen, setIsDateOpen] = useState({ isOpen: false, id: "" });

  const { state } = useLocation();
  const location = useLocation().pathname;
  const [removeShowTime, { isSuccess, isError, error }] =
    useDeleteShowtimeStatusMutation();

  const [
    editShowTimeDate,
    {
      isSuccess: editDateIsSuccess,
      isError: editDateIsError,
      error: editDateError,
      data: editDateData,
    },
  ] = useEditShowTimeDateMutation();

  //  const [overwriteShowTime] = useOverwriteShowTimeMutation();
  const [Overwrote, setOverwrote] = useState([]);

  const [date, setDate] = useState(
    state
      ? new Date(state.releaseDate)
      : new Date(new Date().setHours(new Date().getHours() + 2))
  );

  // -----------------------------------------------------------------------------------------------------------------
  const [selectedScreen, setSelectedScreen] = useState({
    screen: screens?.[0],
    showTimes: [],
  });

  const [data, setdata] = useState({
    movie: showMovie ? showMovie?.movie : movies?.[0],
    screens: showMovie ? showMovie?.screens : [],
  });

  const [newData, setNewData] = useState({
    screens: [],
  });

  const [popUpsState, setPopUpsState] = useState({
    movie: false,
    screen: false,
    showTimes: false,
  });

  const [newScreen, setNewScreen] = useState(
    screens?.filter((screen) =>
      selectedScreen?.screen?._id === screen?._id ? screen : null
    )
  );
  const [endDate, setEndDate] = useState(new Date());
  const [expDate, setExpDate] = useState(new Date());
  const [startShowtimeDate, setStartShowtimeDate] = useState(new Date());

  const { data: ShowTimes, refetch } = useFreeShowTimesQuery(
    {
      screen: selectedScreen?.screen?._id,
      date: new Date(date).toLocaleDateString(),
      endDate: new Date(endDate).toLocaleDateString()
    },
    { skip: !!!newScreen || !!!date }
  );


  useEffect(() => {
    setEndDate(
      new Date(
        new Date(
          new Date(endDate).getHours() < 7
            ? new Date(
                new Date(endDate).setDate(new Date(endDate).getDate() - 1)
              )
            : new Date(endDate)
        )
      )
    );
  }, []);

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const isEndOfMonth = selectedDate.getDate() === 31;

    if (isEndOfMonth) {
      selectedDate.setHours(7, 0, 0, 0); // Set the time to 07:00 AM
      selectedDate.setDate(1); // Set the date to the 1st of the next month
      selectedDate.setMonth(selectedDate.getMonth() + 1); // Set the month to the next month
    }

    const formattedDate = selectedDate.toISOString().substring(0, 10);
    setEndDate(formattedDate);
  };

  const overWriteHandler = (showtime) => {
    setOverwrote([
      ...Overwrote,
      {
        showTimeStatus: showtime.showTimeStatus._id,
        date: new Date(new Date(endDate).setHours(7, 0, 0, 0)).toISOString(),
      },
    ]);
    setSelectedScreen({
      ...selectedScreen,
      showTimes: selectedScreen.showTimes.includes(showtime)
        ? selectedScreen.showTimes.filter((j) => j !== showtime)
        : [...selectedScreen.showTimes, showtime],
    });
  };

  useEffect(() => {
    if (showMovie) {
      setdata({
        movie: showMovie?.movie,
        screens: showMovie?.screens,
      });
    }
  }, []);

  useEffect(() => {
    !showMovie && setdata((prev) => ({ ...prev, movie: movies?.[0] }));
    setSelectedScreen((prev) => ({ ...prev, screen: screens?.[0] }));

    setOverwrote([]);
  }, [screens, movies, showMovie]);

  useEffect(() => {
    refetch();
  }, [date, selectedScreen.screen, refetch]);

  useEffect(() => {
    setSelectedScreen((prev) => ({ ...prev, showTimes: [] }));
  }, [selectedScreen.screen]);

  // -----------------------------------------------------------------------------------------------------------------

  const notify = (i, x) => (x === "error" ? toast.error(i) : toast.success(i));

  const [ShowtimetoDelete, setShowtimetoDelete] = useState({});

  useEffect(() => {
    if (isSuccess) {
      const screen = showMovie?.screens
        ?.filter((i) => i?.screen?._id === ShowtimetoDelete?.screen)[0]
        ?.showTimes?.filter((i) => i?._id === ShowtimetoDelete?.showTime)?.[0];

      const showTimes = [
        ...data?.screens
          ?.filter((i) => i?.screen?._id === ShowtimetoDelete?.screen)[0]
          ?.showTimes?.filter((i) => i?._id !== ShowtimetoDelete?.showTime),
      ];

      screen &&
        isSuccess &&
        setdata({
          ...data,
          screens: [
            ...data?.screens?.filter(
              (i) => i.screen?._id !== ShowtimetoDelete?.screen
            ),
            {
              screen: data?.screens.filter(
                (i) => i.screen._id === ShowtimetoDelete?.screen
              )[0].screen,
              showTimes: [...showTimes],
            },
          ].filter((i) => i.showTimes.length > 0),
        });
    }
    if (isError) {
      notify(error?.data?.message, "error");
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (editDateIsSuccess) {
      const updatedScreens = data.screens.map((screenObj) => {
        if (screenObj.screen._id === editDateData.screen) {
          const updatedShowTimes = screenObj.showTimes.map((showTime) => {
            if (showTime.showTimeStatus === editDateData._id) {
              // Update the expDate of the selected showTime
              return {
                ...showTime,
                expDate: expDate,
                date: startShowtimeDate,
              };
            }
            return showTime;
          });

          // Update only the showTimes array for the selected screen
          return {
            ...screenObj,
            showTimes: updatedShowTimes,
          };
        }
        return screenObj;
      });

      setdata((prevData) => ({
        ...prevData,
        screens: updatedScreens,
      }));

      console.log(data);
    }
    if (editDateIsError) {
      notify(editDateError?.data?.message, "error");
    }
  }, [editDateIsSuccess, editDateData, editDateIsError, editDateError]);

  const RemoveShowTimehandler = (showtime) => {
    removeShowTime(showtime);

    // isSuccess &&
    //   setdata({
    //     ...data,
    //     screens: [
    //       ...data.screens.filter((i) => i.screen._id !== showtime?.screen),
    //       {
    //         screen: data.screens.filter(
    //           (i) => i.screen._id === showtime?.screen
    //         )[0].screen,
    //         showTimes: [...showTimes],
    //       },
    //     ].filter((i) => i.showTimes.length > 0),
    //   });
  };

  const screensHandler = data && [...data.screens];

  const { data: distributor } = useDistributorQuery(
    showMovie ? data.movie?.distributor : data.movie?.distributor?._id
  );
  
  return (
    <>
      <Link
        to={"/showmovies"}
        className=" flex  max-w-[100px] items-center gap-2 text-xl font-bold text-primary"
      >
        <ArrowLeftIcon className="h-5 w-5 text-primary" />
        <span className="text-primary">Back</span>
      </Link>
      <div className="flex flex-col">
        <div className="mt-6 flex items-start justify-between">
          <div className="flex basis-1/2 flex-col gap-2">
            <div className="flex gap-2">
              <div className="w-48 rounded-lg">
                <img
                  className="w-40 rounded-lg object-cover"
                  src={data.movie?.poster}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-start gap-4 ">
                <p className="font-semibold">
                  Name: <span className="font-medium">{data.movie?.name}</span>
                </p>
                <p className="font-semibold">
                  Release Date:{" "}
                  <span className=" font-medium">
                    {new Date(date).toLocaleDateString()}
                  </span>
                </p>
                <p className="font-semibold">
                  duration:{" "}
                  <span className=" font-medium">
                    {data.movie?.duration} Min
                  </span>
                </p>
                <p className="font-semibold">
                  Experience:{" "}
                  <span className=" font-medium">{data.movie?.experience}</span>
                </p>
                <p className="font-semibold">
                  Distributor:{" "}
                  <span className=" font-medium">{distributor?.name}</span>
                </p>
              </div>
            </div>
            <div>
              <h3 className="  font-semibold text-primary">Screens</h3>
              <div className=" mt-2 flex w-[350px] flex-wrap justify-around gap-3">
                {screensHandler
                  ?.sort(
                    (a, b) =>
                      +a.screen.name.split(" ")[1] -
                      +b.screen.name.split(" ")[1]
                  )
                  .map(({ screen, showTimes }) => {
                    return (
                      <div key={`${data?.movie?._id}${Math.random()}`}>
                        <div className="flex flex-row items-center gap-4">
                          <span className="font-semibold">{screen?.name}</span>
                        </div>
                        <div className=" mt-2 text-center text-white">
                          {showTimes.map((i, n) => {
                            const de =
                              showMovie &&
                              showMovie?.screens
                                ?.filter((i) => i.screen._id === screen._id)[0]
                                ?.showTimes?.filter(
                                  (a) => a._id === i._id
                                )?.[0];
                            return (
                              <div
                                key={n}
                                className="flex flex-row items-center gap-1"
                              >
                                <div
                                  key={`${i._id}${Math.random()}`}
                                  className="my-1 w-[100px] rounded-xl bg-[#00C18B]"
                                >
                                  {i.startTime}
                                </div>
                                {showMovie && de && (
                                  <>
                                    <DeleteShowtime
                                      onDelete={(value) => {
                                        RemoveShowTimehandler({
                                          showTime: i?._id,
                                          screen: screen?._id,
                                          showMovie: showMovie?._id,
                                          date: value || null,
                                        });
                                        setShowtimetoDelete({
                                          showTime: i?._id,
                                          screen: screen?._id,
                                          showMovie: showMovie?._id,
                                          date: value || null,
                                        });
                                      }}
                                      item=""
                                      datepicker="datepicker"
                                    />
                                    <button
                                      onClick={() => {
                                        setIsDateOpen({
                                          isOpen: !isDateOpen.isOpen,
                                          id: i?.showTimeStatus,
                                        });
                                      }}
                                      type="button"
                                      className="flex  items-center justify-center   text-[#777]  hover:text-[#777] focus:outline-none "
                                    >
                                      <span className="sr-only">Date</span>
                                      <CalendarIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </button>
                                    {i?.showTimeStatus === isDateOpen.id && (
                                      <ModalDate
                                        action="Confirm"
                                        name="Showtime"
                                        isOpen={isDateOpen.isOpen}
                                        releaseDate={date}
                                        startDate={i?.date || date}
                                        expDate={i?.expDate}
                                        setIsOpen={(isOpen) =>
                                          setIsDateOpen({
                                            isOpen: isOpen,
                                            id: "",
                                          })
                                        }
                                        onClick={({ valueStart, valueEnd }) => {
                                          editShowTimeDate({
                                            id: i?.showTimeStatus,
                                            data: {
                                              startDate: valueStart,
                                              endDate: valueEnd,
                                            },
                                          });
                                          setStartShowtimeDate(valueStart);
                                          setExpDate(valueEnd);
                                        }}
                                        datepicker={true}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="flex basis-1/2 flex-col items-center justify-center">
            <div className="flex w-[450px] flex-col ">
              {!showMovie && (
                <>
                  <h1 className="  font-semibold text-primary">Movie</h1>
                  <div className="mt-3 w-full">
                    <div className="relative z-10 flex h-11 w-full items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                      <p className="p-4">{data?.movie?.name}</p>
                      <button
                        onClick={() =>
                          setPopUpsState({
                            ...popUpsState,
                            movie: true,
                          })
                        }
                        className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                      >
                        <i className="fa-solid fa-bars"></i>
                      </button>
                    </div>
                  </div>
                </>
              )}
              {/* {showMovie && (
                <DatePicker
                  placeholder="Pick date"
                  label="End date"
                  withAsterisk
                  // defaultValue={new Date()}
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                  minDate={
                    new Date(
                      new Date(
                        new Date().getHours() < 7
                          ? new Date(
                              new Date().setDate(new Date().getDate() - 1)
                            )
                          : new Date()
                      )
                    )
                  }
                />
              )} */}
              <>
                <div className="mt-3 w-full">
                  <Input
                    defaultValue={
                      showMovie
                        ? new Date(state.releaseDate)
                            .toISOString()
                            .substring(0, 10)
                        : new Date(
                            new Date(
                              new Date(date).getHours() < 7
                                ? new Date(
                                    new Date(date).setDate(
                                      new Date(date).getDate() - 1
                                    )
                                  )
                                : new Date(date)
                            )
                          )
                            .toISOString()
                            .substring(0, 10)
                    }
                    label={!showMovie ? "Release Date" : "Start Date"}
                    type="date"
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
                <div className="mt-3 w-full">
                  <Input
                    defaultValue={
                      showMovie
                        ? new Date(state.releaseDate)
                            .toISOString()
                            .substring(0, 10)
                        : new Date(
                            new Date(
                              new Date(date).getHours() < 7
                                ? new Date(
                                    new Date(date).setDate(
                                      new Date(date).getDate() - 1
                                    )
                                  )
                                : new Date(date)
                            )
                          )
                            .toISOString()
                            .substring(0, 10)
                    }
                    label="End Date"
                    type="date"
                    onChange={handleEndDateChange}
                  />
                </div>
              </>
              <>
                <h1 className=" mt-4  font-semibold text-primary">Screens</h1>
                <div className="mt-3 w-full">
                  <div className="relative z-10 flex h-11 w-full items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                    <p className="p-4">{selectedScreen?.screen?.name}</p>

                    <button
                      onClick={() =>
                        setPopUpsState({
                          ...popUpsState,
                          screen: true,
                        })
                      }
                      className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                    >
                      <i className="fa-solid fa-bars"></i>
                    </button>
                  </div>
                </div>
              </>
              <>
                <div className="flex flex-row justify-between">
                  <h1 className=" mt-4  font-semibold text-primary">
                    ShowTimes
                  </h1>
                  <button
                    onClick={() => {
                      new Date(date) <= new Date().setHours(0, 0, 0, 0) &&
                      !showMovie
                        ? notify("You can't add showtimes in the past", "error")
                        : setPopUpsState({
                            ...popUpsState,
                            showTimes: true,
                          });
                      typeof newScreen === "undefined" &&
                        setNewScreen(
                          screens?.filter((screen) =>
                            selectedScreen?.screen._id === screen?._id
                              ? screen
                              : null
                          )
                        );
                    }}
                    className="self-end  rounded-lg p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                  >
                    <i className="fa-solid fa-bars"></i>
                  </button>
                </div>
                <div className="mt-3 w-full">
                  {selectedScreen.showTimes.length !== 0 && (
                    <div className="relative z-10 flex h-auto w-full flex-wrap items-center justify-around gap-2 rounded-xl border-0 p-2 text-sm font-medium shadow-lg">
                      {sortShowTime(selectedScreen?.showTimes)?.map(
                        ({ startTime, _id }) => (
                          <div
                            key={`${_id}${Math.random()}`}
                            className="font-base flex w-auto items-center justify-center rounded-2xl border border-primary px-2 py-2 text-sm text-primary outline-none "
                          >
                            {startTime}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </>

              <button
                onClick={() => {
                  const showTimes = showMovie?.screens.filter(
                    (i) => i.screen._id === selectedScreen.screen._id
                  )[0];
                  selectedScreen.showTimes.length !== 0
                    ? setdata({
                        ...data,

                        screens: showMovie
                          ? [
                              ...data.screens.filter(
                                (i) =>
                                  i.screen._id !== selectedScreen.screen._id
                              ),
                              {
                                ...selectedScreen,
                                showTimes: showTimes
                                  ? [
                                      ...showTimes.showTimes,
                                      ...selectedScreen.showTimes,
                                    ]
                                  : selectedScreen.showTimes,
                              },
                            ]
                          : [
                              ...data.screens.filter(
                                (i) =>
                                  i.screen._id !== selectedScreen.screen._id
                              ),
                              selectedScreen,
                            ],
                      })
                    : notify("Please select ShowTimes", "error");
                  selectedScreen.showTimes.length !== 0 &&
                    setNewData({
                      screens: [
                        ...newData?.screens?.filter(
                          (i) => i.screen._id !== selectedScreen.screen._id
                        ),
                        selectedScreen,
                      ],
                    });
                }}
                className=" mt-8 place-self-center rounded-2xl border-2 border-primary bg-white px-14 py-4 text-primary"
              >
                Add screen
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            location.includes("edit-showmovie")
              ? data.screens.length !== 0
                ? onSubmit({
                    overwrote: Overwrote.map((item, i) => {
                      return {
                        showTimeStatus: item.showTimeStatus,
                        date: new Date(
                          new Date(date).setHours(9, 0, 0, 0)
                        ).toISOString(),
                      };
                    }),
                    endDate: new Date(
                      new Date(endDate).setHours(9, 0, 0, 0)
                    ).toISOString(),
                    new: {
                      screens: newData.screens.map((i) => ({
                        screen: i.screen._id,
                        showTimes: i.showTimes.map((x) => x._id),
                      })),
                    },
                    screens: data.screens.map((i) => ({
                      screen: i.screen._id,
                      showTimes: i.showTimes.map((x) => x._id),
                    })),
                    movie: data.movie._id,
                    releaseDate: new Date(new Date(date).setHours(5, 0, 0, 0)),
                  })
                : notify("Please add a screen", "error")
              : data.screens.length !== 0
              ? Math.floor(Date.parse(date) / 1000) >
                Math.floor(
                  new Date(new Date().setDate(new Date().getDate() - 1)) / 1000
                )
                ? onSubmit({
                    screens: data.screens.map((i) => ({
                      screen: i.screen._id,
                      showTimes: i.showTimes.map((x) => x._id),
                    })),
                    movie: data.movie._id,
                    releaseDate: date,
                    endDate: endDate,
                  })
                : notify("Please select valid date", "error")
              : notify("Please add a screen", "error");
          }}
          className=" mt-3  place-self-center rounded-2xl bg-primary px-14 py-4 text-white"
        >
          Submit ShowMovie
        </button>
      </div>
      {popUpsState.movie && (
        <DropDownList
          data={movies}
          setSelected={(i) => setdata({ ...data, movie: i })}
          type="Movies"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, movie: false })
          }
          selected={data?.movie}
        />
      )}
      {popUpsState.screen && (
        <DropDownList
          data={screens}
          setSelected={(i) =>
            setSelectedScreen({ ...selectedScreen, screen: i })
          }
          type="Screens"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, screen: false })
          }
          selected={selectedScreen?.screen}
        />
      )}
      {popUpsState.showTimes && (
        <DropDownList
          data={sortShowTime(ShowTimes)}
          setSelected={(i) =>
            setSelectedScreen({
              ...selectedScreen,
              showTimes: selectedScreen.showTimes.includes(i)
                ? selectedScreen.showTimes.filter((j) => j !== i)
                : [...selectedScreen.showTimes, i],
            })
          }
          type="Time Show"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, showTimes: false })
          }
          selected={
            selectedScreen?.showTimes && sortShowTime(selectedScreen?.showTimes)
          }
          newMovie={data?.movie}
          overWriteHandler={(i) => {
            overWriteHandler(i);
          }}
        />
      )}
    </>
  );
};

export default ShowMovieForm;
