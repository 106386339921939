import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../config/dataService/dataService";
export const checkPointApi = createApi({
  reducerPath: "checkPointApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/check-point`,
    credentials: "include",
  }),
  tagTypes: ["CheckPoint"],
  endpoints: ({ query, mutation }) => ({
    checkPoint: query({
      query: (agent) => `?agent=${agent}`,
    }),
    agentCheckPoints: query({
      query: (id) => `/${id}`,
      providesTags: ["CheckPoint"],
    }),
    createCheckPoint: mutation({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["CheckPoint"],
    }),
  }),
});

export const {
  useCheckPointQuery,
  useAgentCheckPointsQuery,
  useCreateCheckPointMutation,
} = checkPointApi;
