import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    email: yup.string().email().required("email is required"),
    password: yup
      .string()
      .required("Please enter your password.")
      .min(8, "Your password is too short."),
  })
  .required();

const SigninForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  return (
    <>
      <div className="flex min-h-screen flex-col justify-center bg-[#F1F4FA] py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4  sm:rounded-2xl sm:px-10">
            <div className=" mb-2 flex justify-center">
              <img src="/logo.png" alt="logo" />
            </div>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-[#3B5CAD]"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    {...register("email")}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                  />
                  {errors.email && (
                    <span className="text-red-500">
                      {errors.email?.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-[#3B5CAD]"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    {...register("password")}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                  />
                  {errors.password && (
                    <span className="text-red-500 ">
                      {errors.password?.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-xl border border-transparent bg-[#3B5CAD] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#3B5CAD]/90 focus:outline-none focus:ring-2 focus:ring-[#3B5DAD] focus:ring-offset-2"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SigninForm;
