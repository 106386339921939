import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ReservationDate:
    new Date().getHours() < 7
      ? new Date(
          new Date().setDate(new Date().getDate() - 1)
        ).toLocaleDateString()
      : new Date().toLocaleDateString(),
  exchanges: JSON.parse(localStorage.getItem("ExchangesRes")),
};

const reservationSlice = createSlice({
  name: "reservationSlice",
  initialState,
  reducers: {
    setReservationDate(state, action) {
      state.ReservationDate = action.payload;
    },
    setExchangesRes(state, action) {
      localStorage.setItem("ExchangesRes", JSON.stringify(action.payload));
      state.exchanges = action.payload;
    },
    resetExchangesRes(state, action) {
      localStorage.removeItem("ExchangesRes", JSON.stringify(action.payload));
      state.exchanges = [];
    },
  },
});

export const reservationActions = reservationSlice.actions;
export default reservationSlice.reducer;
