import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../components/ui-assets/SearchBar";
import Filters from "../../components/ui-assets/Filters";
import CustomToast from "../../utiles/CustomToast";
import avatar from "../../Assets/profileaAvatar.svg";
import { Helmet } from "react-helmet";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import AgentAccordionHeader from "../../components/agents/agentAccordionHeader";
import AgentTicketsDetailsTable from "../../components/agents/AgentTicketsDetailsTable";
import { useAgentReportQuery } from "../../store/reportsApi";

const AgentsPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const date = new Date(new Date().setHours(7, 0, 0));
  const { data, isLoading, isSuccess, isError, error } = useAgentReportQuery(
    {
      startDate: date.toString(),
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <>
      <Helmet>
        <title>Agents List</title>
      </Helmet>
      {isError && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/agents"
        />
      )}
      <div className="container py-10">
        <div className="flex flex-col items-center justify-between gap-4 lg:flex-row">
          <SearchBar onChange={setSearchValue} value={searchValue} />
        </div>
        <div className="py-4">
          {!isLoading && (
            <Accordion allowMultiple>
              {data?.map((agent) => (
                <AccordionItem
                  header={<AgentAccordionHeader agent={agent} />}
                  buttonProps={{ className: "w-full" }}
                  className="mb-3 border"
                  key={agent._id}
                >
                  <div className="px-4">
                    <AgentTicketsDetailsTable data={{ _id: agent._id }} />
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </div>
      </div>
    </>
  );
};

export default AgentsPage;
