import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const reportsSlice = createSlice({
  name: "reportsSlice",
  initialState,
  reducers: {
    setReportsData(state, action) {
      state.reportsData = action.payload;
    },
  },
});

export const reportsActions = reportsSlice.actions;
export default reportsSlice.reducer;
