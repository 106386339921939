import { useParams } from "react-router-dom";
import ShowtimeForm from "../../components/showtimes/ShowtimeForm";
import {
  useCreateShowTimeMutation,
  useShowTimeQuery,
  useUpdateShowTimeMutation,
} from "../../store/showTimesApi";
import CustomToast from "../../utiles/CustomToast";
import { Helmet } from "react-helmet";

const ShowtimePage = () => {
  const { id } = useParams();
  const [createShowTime, { isSuccess, isError, error }] =
    useCreateShowTimeMutation();
  const [
    updateShowTime,
    { isSuccess: updateIsSuccess, updateIsError, updateError },
  ] = useUpdateShowTimeMutation();
  const { data: showTime } = useShowTimeQuery(id, { skip: !!!id });
  const submitHandler = ({ status, time }, { hour, minutes, price }) => {
    const showTimeData = {
      startTime: `${hour.toString().length === 1 ? "0" + hour : hour}:${
        minutes.toString().length === 1 ? "0" + minutes : minutes
      } ${time}`,
      status,
      price,
    };
    createShowTime(showTimeData);
  };

  const updateHandler = ({ status, time, _id }, { hour, minutes, price }) => {
    const showTimeData = {
      id: _id,
      startTime: `${hour.toString().length === 1 ? "0" + hour : hour}:${
        minutes.toString().length === 1 ? "0" + minutes : minutes
      } ${time}`,
      status,
      price,
    };
    updateShowTime(showTimeData);
  };

  return (
    <>
      <Helmet>
        <title>Showtime Page</title>
      </Helmet>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/showtimes"
        />
      )}
      {(updateIsError || updateIsSuccess) && (
        <CustomToast
          IsError={updateIsError}
          error={updateError}
          isSuccess={updateIsSuccess}
          path="/showtimes"
        />
      )}
      <ShowtimeForm
        onSubmit={id && showTime ? updateHandler : submitHandler}
        showTime={id && showTime}
      />
    </>
  );
};

export default ShowtimePage;
