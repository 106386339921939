import React, { useState } from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { Outlet } from "react-router-dom";
import moment from "moment";
import Profile from "../ui-assets/Profile";
import { useAuthUserQuery } from "../../store/authUserApi";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { reservationActions } from "../../store/reservation/reservationSlice";
import Modal from "../ui-assets/Modal";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function CustomLayout() {
  const location = useLocation().pathname;
  const [isOpen, setIsOpen] = useState(false);

  const { data: authUser, isLoading } = useAuthUserQuery();
  const dispatch = useDispatch();
  const reservationDate = useSelector(
    (state) => state.reservation.ReservationDate
  );
  const [calendrstate, setcalendrstate] = useState(false);
  const calenderHandler = (e) => {
    dispatch(
      reservationActions.setReservationDate(new Date(e).toLocaleDateString())
    );
  };

  return (
    // rotate - 180
    <>
      <div
        className={classNames(
          location.includes("/scan") ? "max-w-full" : "max-w-full rotate-180",
          "mx-auto h-screen max-h-screen "
        )}
      >
        <div className="flex h-full flex-1 flex-col bg-[#F1F4FA] px-2 py-2 ">
          <div className="z-10 flex  h-auto w-full flex-shrink-0  flex-col rounded-t-3xl bg-white px-6">
            <div
              className={`${location.includes("/scan") ? "" : "rotate-180"}`}
            >
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3B5CAD] md:hidden"
                // onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              <div className=" flex flex-1  items-center justify-end px-4 lg:justify-between">
                <div className="flex items-center ">
                  <img alt="logo" src="/logo.png" className="m-3 w-[90px]" />
                  <div className="hidden  lg:flex ">
                    <p>{`${moment().format(
                      "h:mm A, dddd "
                    )} , ${moment().format("DD MMM, YYYY")}`}</p>
                  </div>
                </div>
                {!location.includes("/scan") && (
                  <>
                    <div className="relative z-50 flex h-11 w-[421px] items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                      {" "}
                      <div className="truncate pl-3 pr-6 ">
                        {moment(new Date(reservationDate)).format(
                          "ddd MMM,DD YYYY"
                        )}
                      </div>
                      {calendrstate && (
                        <div
                          onClick={() => {
                            setcalendrstate((prev) => !prev);
                          }}
                          className="absolute bottom-0 left-[-600px] h-[100vh] w-[1300px] "
                        />
                      )}
                      <div
                        className={`${
                          calendrstate ? "block" : "hidden"
                        } absolute bottom-[50px] right-[-100px]  overflow-hidden rounded-2xl `}
                      >
                        <Calendar
                          onChange={calenderHandler}
                          defaultValue={
                            new Date().getHours() < 7
                              ? new Date(
                                  new Date().setDate(new Date().getDate() - 1)
                                )
                              : new Date()
                          }
                          onClickDay={() => setcalendrstate(!calendrstate)}
                        />
                      </div>
                      {!location.includes("/selfReserve") ? (
                        <div
                          onClick={() => setcalendrstate(!calendrstate)}
                          className=" cursor-pointer pr-3"
                        >
                          <i className="fa-solid fa-calendar-days"></i>
                        </div>
                      ) : (
                        <div
                          onClick={() => setIsOpen(true)}
                          className="h-full cursor-pointer"
                        >
                          <button
                            className={`font-base flex h-full cursor-pointer items-center justify-center
                  rounded-2xl border border-primary bg-primary px-4 py-2 text-center text-lg text-white `}
                          >
                            Change Date
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {!location.includes("/selfReserve") && (
                  <div className="ml-4 flex items-center md:ml-6">
                    {!isLoading && authUser && <Profile user={authUser} />}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end of header */}
          <main className="h-full flex-1  rounded-b-2xl ">
            <div className="min-h-full rounded-b-2xl bg-white">
              <div className="mx-auto max-w-[1900px] px-4 sm:px-6 md:px-8 ">
                <Outlet />
              </div>
            </div>
            <Modal
              action="Confirm"
              name="Showtime"
              // onClick={onDelete}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              datepicker={true}
              isSelfServices={true}
            />
          </main>
        </div>
      </div>
    </>
  );
}

export default CustomLayout;
