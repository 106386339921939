import { SearchIcon } from "@heroicons/react/solid";

export default function SearchBar({ onChange, value, placeHolder }) {
  return (
    <div>
      <div className=" relative w-full rounded-md shadow-sm md:w-64">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="search"
          name="search"
          onChange={(e) => onChange(e.target.value)}
          value={value}
          id="search-bar"
          className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 pl-10 placeholder-gray-400  focus:border-[#3B5CAD] focus:outline-none  sm:text-sm"
          placeholder={placeHolder || "Search"}
        />
      </div>
    </div>
  );
}
