import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Spinner from "../components/ui-assets/Spinner";
import { useAuthUserQuery } from "../store/authUserApi";

const ProtectedRoutes = () => {
  const { data: user, isLoading } = useAuthUserQuery();
  const location = useLocation();
  return isLoading ? (
    <Spinner />
  ) : user ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
