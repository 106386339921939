import React from "react";
import { useParams } from "react-router-dom";
import ScreenForm from "../../components/screens/ScreenForm";
import {
  useCreateScreenMutation,
  useScreenQuery,
  useUpdateScreenMutation,
} from "../../store/screensApi";
import CustomToast from "../../utiles/CustomToast";
import { Helmet } from "react-helmet";

const ScreenPage = () => {
  const { id } = useParams();
  const { data: screen } = useScreenQuery(id, { skip: !!!id });
  const [createScreen, { isSuccess, isError, error }] =
    useCreateScreenMutation();
  const [
    updateScreen,
    { isSuccess: updateIsSuccess, updateIsError, updateError },
  ] = useUpdateScreenMutation();
  const submitHandler = (type, data) => {
    data.type = type;
    createScreen(data);
  };
  const updateHandler = (type, data) => {
    const newData = {
      id: data._id,
      name: data.name,
      price: data.price,
      type: type || data.type,
    };
    updateScreen(newData);
  };
  return (
    <>
      <Helmet>
        <title>Screen Page</title>
      </Helmet>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/screens"
        />
      )}
      {(updateIsError || updateIsSuccess) && (
        <CustomToast
          IsError={updateIsError}
          error={updateError}
          isSuccess={updateIsSuccess}
          path="/screens"
        />
      )}
      <div className="flex  items-center justify-center py-8 lg:py-14">
        <ScreenForm
          onSubmit={id && screen ? updateHandler : submitHandler}
          screen={id && screen}
        />
      </div>
    </>
  );
};

export default ScreenPage;
