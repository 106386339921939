import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../../config/dataService/dataService";

export const reservationApi = createApi({
  reducerPath: "reservationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/reservations/`,
    credentials: "include",
  }),
  tagTypes: ["Reservation"],
  endpoints: (builder) => ({
    ticket: builder.mutation({
      query: (id) => ({
        url: `checkup/${id}`,
        method: "PATCH",
      }),
    }),
    reservations: builder.query({
      query: (data) => ({
        url: "get-selected",
        method: "POST",
        body: data,
      }),
      providesTags: ["Reservation"],
    }),

    buyGlasses: builder.mutation({
      query: (data) => ({
        url: "buy-glasses",
        method: "POST",
        body: data,
      }),
    }),

    refundGlasses: builder.mutation({
      query: (data) => ({
        url: "refund-glasses",
        method: "POST",
        body: data,
      }),
    }),

    updatepriceSettings: builder.mutation({
      query: (data) => ({
        url: "price-settings",
        method: "PATCH",
        body: data,
      }),
    }),
    getpriceSettings: builder.query({
      query: () => "price-settings",
    }),
    getResDetails: builder.query({
      query: (token) => `reservations-by-token/${token}`,
    }),
  }),
});

export const {
  useReservationsQuery,
  useTicketMutation,
  useBuyGlassesMutation,
  useRefundGlassesMutation,
  useUpdatepriceSettingsMutation,
  useGetpriceSettingsQuery,
  useGetResDetailsQuery,
} = reservationApi;
