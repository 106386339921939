import React from 'react';

function TotalRevenuesTable({ data, movieReportData, daysBetween }) {
  console.log('data', daysBetween);
  return (
    <table className=" w-full text-center">
      <tr>
        <th className=" border border-black " rowSpan="2" colSpan="2">
          الجمله العمومية
        </th>
        <th className="w-[161.8px] border border-black " colSpan="10">
          رسوم اضافية
        </th>
        <th className="w-[161.8px] border border-black " colSpan="10">
          بيان التذاكر المنصرفة بمعرفة المستغل و ضريبتها{' '}
        </th>
      </tr>
      <tr>
        <th className=" w-[64.72px] border border-black " colSpan="2"></th>
        <th className=" w-[64.72px] border border-black " colSpan="2"></th>
        <th className=" w-[64.72px] border border-black " colSpan="2"></th>
        <th className=" border border-black " colSpan="2">
          رسم الشرطة
        </th>
        <th className="  border border-black " colSpan="2">
          رسم الاعمال الخيرية
        </th>
        <th className=" border border-black " colSpan="2">
          جمله الضريبة
        </th>
        <th className=" border border-black " colSpan="2">
          ضريبةالتذكرة
        </th>
        <th className=" w-[75px] border border-black " colSpan="2">
          مقابل الدخور (وعاء الضريبه)
        </th>
        <th className=" w-[64.72px] border border-black " colSpan="2">
          ارقامها
        </th>
        <th className=" w-[64.72px] border border-black " colSpan="2">
          عدد التذاكر المنصرفه
        </th>
      </tr>
      <tr>
        <td className=" border border-black " colSpan="1">
          ق
        </td>
        <td className=" border border-black " colSpan="1">
          ج
        </td>
        <td className=" border border-black " colSpan="1">
          ق
        </td>
        <td className=" border border-black " colSpan="1">
          ج
        </td>
        <td className=" border border-black " colSpan="1">
          ق
        </td>
        <td className=" border border-black " colSpan="1">
          ج
        </td>
        <td className=" border border-black " colSpan="1">
          ق
        </td>
        <td className=" border border-black " colSpan="1">
          ج
        </td>
        <td className=" border border-black " colSpan="1">
          ق
        </td>
        <td className=" border border-black " colSpan="1">
          ج
        </td>
        <td className=" border border-black " colSpan="1">
          ق
        </td>
        <td className=" border border-black " colSpan="1">
          ج
        </td>
        <td className=" border border-black " colSpan="1">
          ق
        </td>
        <td className=" border border-black " colSpan="1">
          ج
        </td>
        <td className=" border border-black " colSpan="1">
          ق
        </td>
        <td className=" border border-black " colSpan="1">
          ج
        </td>
        <td className=" border border-black " colSpan="1">
          ق
        </td>
        <td className=" border border-black " colSpan="1">
          ج
        </td>
        <td className=" border border-black " colSpan="1">
          الي
        </td>
        <td className=" border border-black " colSpan="1">
          من
        </td>
        <td className=" border border-black " colSpan="2"></td>
      </tr>
      {data.map((item, index) => (
        <tr className="h-[50px]">
          <td className="h-[50px] border border-black" colSpan="2">
            {item === 'الاجمالى' || item === 'دمغة'
              ? (
                  +movieReportData?.[0][item] + +(daysBetween * 0.1).toFixed(2)
                ).toFixed(2)
              : item === 'عدد التذاكر'
              ? movieReportData?.[0][item]
              : movieReportData?.[0][item].toFixed(2)}
          </td>
          <td className=" border border-black " colSpan="4">
            {item}
          </td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="1"></td>
          <td className=" border border-black " colSpan="2"></td>
        </tr>
      ))}
      <tr>
        <td className=" h-7 border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="1"></td>
        <td className=" border border-black " colSpan="2"></td>
      </tr>
    </table>
  );
}

export default TotalRevenuesTable;
