import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Filters({ filterData, setfilterData }) {
  return (
    <Tab.Group defaultIndex={0}>
      <Tab.List className="flex  p-1 focus:outline-none">
        {filterData.map((filter) => (
          <Tab
            key={filter.id}
            onClick={() => setfilterData(filter.value)}
            className={({ selected }) =>
              classNames(
                "  border-b-4 border-gray-300 py-2 px-4 text-xs  text-gray-800   outline-hidden transition-all duration-500 ease-in-out focus:outline-0  active:outline-0",
                selected
                  ? "!border-[#00C18B] !text-[#00C18B]  focus:outline-hidden focus:outline-0  active:outline-hidden"
                  : "text-gray-800  hover:!text-[#00C18B]  focus:outline-hidden focus:outline-0 active:outline-hidden"
              )
            }
          >
            {filter.name}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
}
