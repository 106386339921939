import React from "react";
import Table from "./Table";

function ScreenRevenue({ movieReportData, data, startDate, endDate, Choices }) {
  const ScreenRevenueCol = [
    "total",
    "stamp",
    "numberOfTickets",
    "online",
    "helpDarn",
    "cinemaAid",
    "helpIt",
    "otherServices",
    "conditionalDrawing",
    "Tax",
  ];
  const total = movieReportData?.reduce(
    (acc, curr) => {
      return {
        total: acc.total + curr["الاجمالى"],
        stamp: acc.stamp + curr["دمغة"],
        numberOfTickets: acc.numberOfTickets + curr["عدد التذاكر"],
        helpDarn: acc.helpDarn + curr["اعانه درن"],
        cinemaAid: acc.cinemaAid + curr["اعانه سينيما"],
        helpIt: acc.helpIt + curr["اعانه بر"],
        otherServices: acc.otherServices + curr["خدمات واخرى"],
        conditionalDrawing: +acc.conditionalDrawing + +curr["رسم شرطه"],
        Tax: acc.Tax + curr["الضريبة"],
        online: acc.online + curr["اونلاين"],
      };
    },
    {
      total: 0,
      stamp: 0,
      numberOfTickets: 0,
      helpDarn: 0,
      cinemaAid: 0,
      helpIt: 0,
      otherServices: 0,
      conditionalDrawing: 0,
      Tax: 0,
      online: 0,
    }
  );

  return (
    <div className="flex flex-col items-center p-2 ">
      <div className="  flex w-full flex-row justify-between ">
        <div className="mt-3">
          <img alt="logo" className=" w-36" src="/logo.png"></img>
        </div>
        <div className="mt-3 text-xl">
          <p>مديرية الضرائب العقارية</p>
          <p>ادارة صرائب الملاهي</p>
        </div>
      </div>
      <div className="text-center">
        <p>حافظه توريد تفصيلي بالقاعات</p>
        <p>
          من مستغلي الحفلات التي قيمت ب تاريخ {startDate} الي {endDate}
        </p>
        <p>بيان اعداد تذاكر الدخول المباعه الكترونيا</p>
      </div>
      <div className="w-full text-end ">
        <p className="text-lg">اسم المستغل و عنوانه</p>
        <p className="text-lg">اسم المحل و عنوانه </p>
        <p>
          عدد الحفلات ووقت اقامتها : عدد الحفلات 6 حفلات يوميا 10.3 - 1.00 -
          3.30 - 6.30 - 9.30 - 12.30
        </p>
        <p className="text-start my-2">
          {`${
            Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
          } ${Choices?.movieType || "ALL"}`}
        </p>
      </div>
      <div className=" flex w-full flex-col">
        {movieReportData?.length === 0 ? (
          <h1 className="text-xl font-extrabold text-red-600">
            لا يوجد بيانات للعرض
          </h1>
        ) : (
          <Table
            movieReportData={
              movieReportData
              // &&
              // [...movieReportData]?.sort(
              //   (a, b) =>
              //     +a["القاعه"].split(" ")[1] - +b["القاعه"].split(" ")[1]
              // )
            }
            data={data}
            total={total}
            type="ScreenRevenue"
            ScreenRevenueCol={ScreenRevenueCol}
            Choices={[startDate, endDate]}
          />
        )}
      </div>
      <div className="flex w-full flex-col text-lg">
        <div className=" mt-5 flex w-full flex-row-reverse justify-between px-10">
          <p>اقر بأن البيانات مطابقة للحقيقة</p>
          <p>
            {" "}
            ................................................ : امضاء المستغل
          </p>
        </div>
        <div className=" mt-5 flex w-1/2 flex-row-reverse justify-between self-end px-10">
          <p>
            تحرير في ............. سنه <span className="mr-5">20</span>
            {"  "}{" "}
          </p>
          <p>
            {" "}
            سنه <span className="mr-5">20</span>
          </p>
        </div>
        <div className=" mt-5 flex w-full flex-row-reverse justify-between px-10">
          <p>
            تسلمت المبلغ و توريد باليوميه صفحة ............... وحرر عنه ايصال
            رقم ............ في تاريخ
          </p>
        </div>
        <div className=" mt-5 flex w-full flex-row-reverse justify-between  px-10">
          <p>
            تحرير في ............. سنه <span className="mr-5">20</span>
          </p>
          <p>
            {" "}
            سنه <span className="mr-5">20</span>
          </p>
          <p>................................................ : المحصل</p>
        </div>
        <div className=" mt-5 flex w-full flex-row-reverse justify-between px-10">
          <p>
            ................ : روجعت البيانات المدرجة في بهذا الحافظة و قيدت
            بالجريدة تحت رقم
          </p>
        </div>
        <div className=" mt-5 flex w-full flex-row-reverse justify-between  px-10">
          <p>
            تحرير في ............. سنه <span className="mr-5">20</span>
            {"  "}{" "}
          </p>
          <p>
            {" "}
            سنه <span className="mr-5">20</span>
          </p>
          <p>................................................ : المراجع</p>
        </div>
      </div>
    </div>
  );
}

export default ScreenRevenue;
