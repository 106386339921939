import React from "react";

const MovieCard = ({ text, height, opacity, showMovie, isSelfServices, MoviesSelf, movieSelf }) => {
  const { movie } = showMovie;
  return (
    <div
      className={`flex  ${MoviesSelf ? "max-h-[400px] max-w-[250px]" : movieSelf ? "max-h-[562px] max-w-[404px]" : "max-h-[362px] max-w-[204px]"}  flex-col items-center justify-center ${opacity}`}
    >
      <div
        className={` ${height} ${MoviesSelf ? "max-h-[400px] max-w-[250px]" : movieSelf ? "max-h-[562px] max-w-[404px]" : "max-h-[362px] max-w-[204px]"}  overflow-hidden rounded-lg ${isSelfServices && " border border-[#bbb] shadow-lg"}`}
      >
        <img src={movie?.poster} alt="movie" className={`w-full h-full`} />
      </div>
      <div className={`${isSelfServices ? "my-4 w-full" : "max-w-[204px]"} `}>
        <h3 className={`${text}  rounded-2xl text-center  capitalize`}>
          {movie?.name}
        </h3>
      </div>
    </div>
  );
};

export default MovieCard;
