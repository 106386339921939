import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RadioGroup } from "@headlessui/react";

const schema = yup
  .object({
    name: yup.string().required(),
    price: yup.string().required(),
  })
  .required();

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ExperienceOptions = ["Standard", "Deluxe", "VIP", "IMAX"];

const ScreenForm = ({ onSubmit, screen }) => {
  const [type, setType] = useState();

  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm({
    defaultValues: screen,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (screen) {
      setType(screen?.type);
      reset(screen);
    }
  }, [screen, reset]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit.bind(null, type))}
      className="flex w-full max-w-[412px] flex-col gap-4"
    >
      <div>
        <label
          htmlFor="name"
          className="text-left text-sm font-semibold text-[#3B5CAD]"
        >
          Screen name
        </label>
        <div className="relative mt-1  ">
          <input
            {...register("name")}
            defaultValue={screen?.name}
            type="text"
            name="name"
            id="name"
            className=" h-10 w-full  rounded-xl border-gray-300 pr-12 focus:border-primary focus:ring-primary sm:text-sm"
            placeholder="Name"
            aria-describedby="name-currency"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="price"
          className="text-left text-sm font-semibold text-[#3B5CAD]"
        >
          Screen price
        </label>
        <div className="relative mt-1  ">
          <input
            {...register("price")}
            defaultValue={screen?.price}
            type="text"
            name="price"
            id="price"
            className=" h-10 w-full  rounded-xl border-gray-300 pr-12 focus:border-primary focus:ring-primary sm:text-sm"
            placeholder="0.00"
            aria-describedby="price-currency"
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span
              className="border-l pl-4 text-gray-500 sm:text-sm"
              id="price-currency"
            >
              EGP
            </span>
          </div>
        </div>
      </div>

      {/* screen type */}
      <RadioGroup value={type} onChange={setType} className="mt-2 ">
        <RadioGroup.Label className="text-left text-sm font-semibold text-[#3B5CAD]">
          Choose Screen type
        </RadioGroup.Label>
        <div className="mt-1 flex flex-row flex-wrap gap-4">
          {ExperienceOptions.map((option) => (
            <RadioGroup.Option
              key={option}
              value={option}
              className={({ active, checked }) =>
                classNames(
                  "cursor-pointer focus:outline-none",
                  active ? "bg-primary text-white" : "text-primary",
                  option === type || checked
                    ? "border-transparent bg-primary !text-white hover:bg-primary"
                    : "border-gray-200 bg-white   ",
                  "flex items-center justify-center rounded-2xl border border-primary px-2 py-2 text-sm font-medium   sm:flex-1"
                )
              }
            >
              <RadioGroup.Label as="span">{option}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      <div className="text-center">
        <button className="mt-10 rounded-2xl bg-primary px-14 py-4 text-white">
          {!screen ? "Add Screen" : "Save Edits"}
        </button>
      </div>
    </form>
  );
};

export default ScreenForm;
