import React from 'react';
import Table from './Table';
import moment from 'moment';
import { cinemaNameInReport } from '../../../config/dataService/dataService';

function MoviesRevenuesTable({ movieReportData, data, singleDate }) {
  const total = movieReportData?.reduce(
    (acc, curr) => {
      return {
        TotalRevenueOfTicketsSold:
          acc.TotalRevenueOfTicketsSold + curr['جمله ايراد التذاكر المباعه'],
        NetBeforeValueAdded: acc.NetBeforeValueAdded + curr['صافى الايراد'],
        ValueAddedTax: acc.ValueAddedTax + curr['ضريبه قيمه مضافه'],
        TotalAmusementTaxOwed:
          acc.TotalAmusementTaxOwed + curr['جملة ضريبه الملاهى المستحقة'],
        OtherServices: acc.OtherServices + curr['خدمات اخرى'],
        CinemaSupportFee: acc.CinemaSupportFee + curr['رسم دعم السينيما'],
        wildDrawing: acc.wildDrawing + curr['رسم البر'],
        TuberculosisDrawing: +acc.TuberculosisDrawing + +curr['رسم الدرن'],
        conditionalDrawing: acc.conditionalDrawing + curr['رسم شرطه'],
        Tickets: acc.Tickets + curr['عدد التذاكر'],
      };
    },
    {
      TotalRevenueOfTicketsSold: 0,
      NetBeforeValueAdded: 0,
      ValueAddedTax: 0,
      TotalAmusementTaxOwed: 0,
      OtherServices: 0,
      CinemaSupportFee: 0,
      wildDrawing: 0,
      TuberculosisDrawing: 0,
      conditionalDrawing: 0,
      Tickets: 0,
    }
  );

  // const moviesUnique = movieReportData?.map((movieReportData) => {
  //   return {
  //     movie: movieReportData["الفيلم"],
  //     screen: movieReportData["القاعه"],
  //     distributor: movieReportData["الموزع"],
  //   };
  // });

  // const dataUnique = moviesUnique?.filter(
  //   (moviesUnique, index, self) =>
  //     index ===
  //     self.findIndex(
  //       (t) =>
  //         t.screen === moviesUnique.screen && t.movie === moviesUnique.movie
  //     )
  // );

  return (
    <div className=" flex w-full flex-col items-center p-5 ">
      <div className="mb-2 flex w-full flex-col items-center ">
        <div className="flex w-full justify-between ">
          <div className="flex w-[200px] flex-col  text-end">
            <div className="flex w-full flex-row-reverse text-center ">
              <p className="w-1/2 border border-black ">قاعة</p>
              <p className="w-1/2 border border-black">
                {movieReportData[0]?.['القاعه']}
              </p>
            </div>
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className=" w-1/2 border border-black">توزيع</p>
              <p className="w-1/2 border border-black">
                {movieReportData[0]?.['الموزع']}
              </p>
            </div>
          </div>
          <div>
            <img alt="logo" className=" w-36" src="/logo.png"></img>
          </div>
          <div className="flex w-[200px]  flex-col text-end">
            <h1 className="border border-black text-center">
              {cinemaNameInReport}
            </h1>
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">التاريخ</p>
              <p className="w-1/2 border border-black">
                {moment(singleDate).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">اليوم</p>
              <p className="w-1/2 border border-black">
                {moment(singleDate).format('dddd')}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[50%] ">
          <div className="flex w-full flex-row-reverse text-center  ">
            <p className="w-[20%] border border-black">الفيلم</p>
            <p className="w-[80%] border border-black">
              {movieReportData[0]?.['الفيلم']}
            </p>
          </div>
        </div>
      </div>
      {movieReportData?.length === 0 ? (
        <h1 className="text-xl font-extrabold text-red-600">
          لا يوجد بيانات للعرض
        </h1>
      ) : (
        <>
          <Table
            screen={movieReportData[0].screen}
            movie={movieReportData[0].movie}
            movieReportData={movieReportData}
            data={data}
            totalTickets={total.Tickets}
          />
        </>
      )}

      <div className="mt-10 flex w-full flex-col items-center ">
        <div className="flex h-auto w-full justify-between ">
          <div className="flex w-[200px] flex-col  text-end">
            <div className="flex w-full flex-row-reverse text-center ">
              <p className="w-1/2 border border-black ">المجموع</p>
              <p className="w-1/2 border border-black">
                {total?.NetBeforeValueAdded.toFixed(2)}
              </p>
            </div>
          </div>
          <div>
            <h1 className=" font-bold"> مدير السينما</h1>
          </div>
          <div className=" flex h-[234px] w-[300px]  flex-col text-end">
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">جملة ايراد الحفلات</p>
              <p className="w-1/2 border border-black">
                {total?.TotalRevenueOfTicketsSold}
              </p>
            </div>
            {/* <div className="flex w-full flex-row-reverse text-center  ">
                <p className="w-1/2 border border-black">
                  صافي قبل القيمةالمضافة
                </p>
                <p className="w-1/2 border border-black">
                  {total?.NetBeforeValueAdded + total?.ValueAddedTax}
                </p>
              </div> */}
            <div className="flex w-full flex-row-reverse bg-slate-300 text-center  ">
              <p className="w-1/2 border border-black">ض القيمةالمضافة</p>
              <p className="w-1/2 border border-black">
                {total?.ValueAddedTax.toFixed(2)}
              </p>
            </div>
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">الضريبة</p>
              <p className="w-1/2 border border-black">
                {total?.TotalAmusementTaxOwed.toFixed(2)}
              </p>
            </div>
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">خدمات</p>
              <p className="w-1/2 border border-black">
                {total?.OtherServices.toFixed(2)}
              </p>
            </div>
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">رسم دعم سينما</p>
              <p className="w-1/2 border border-black">
                {total?.CinemaSupportFee.toFixed(2)}
              </p>
            </div>
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">رسم بر</p>
              <p className="w-1/2 border border-black">
                {total?.wildDrawing.toFixed(2)}
              </p>
            </div>
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">رسم درن</p>
              <p className="w-1/2 border border-black">
                {total?.TuberculosisDrawing}
              </p>
            </div>
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">رسم شرطه</p>
              <p className="w-1/2 border border-black">
                {total?.conditionalDrawing}
              </p>
            </div>
            {/* <div className="flex w-full flex-row-reverse text-center  ">
                <p className="w-1/2 border border-black">دمغة</p>
                <p className="w-1/2 border border-black">0</p>
              </div> */}
            <div className="flex w-full flex-row-reverse text-center  ">
              <p className="w-1/2 border border-black">المجموع</p>
              <p className="w-1/2 border border-black">
                {(
                  total?.OtherServices +
                  total?.conditionalDrawing +
                  total?.TuberculosisDrawing +
                  total?.wildDrawing +
                  total?.CinemaSupportFee +
                  total?.TotalAmusementTaxOwed
                ).toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoviesRevenuesTable;
