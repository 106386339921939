import { TrashIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import Modal from "./Modal";

const DeleteShowtime = ({ onDelete, item, datepicker }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        className="flex  items-center justify-center   text-[#F44336]  hover:text-[#F44336] focus:outline-none "
      >
        <span className="sr-only">Delete</span>
        <TrashIcon className="h-4 w-4" aria-hidden="true" />
      </button>
      <Modal
        action="Delete"
        name="Showtime"
        onClick={onDelete}
        isOpen={isOpen}
        item={item}
        setIsOpen={setIsOpen}
        datepicker={datepicker === "datepicker" ? true : false}
      />
    </>
  );
};

export default DeleteShowtime;
