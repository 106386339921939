import React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

function Table({
  data,
  movieReportData,
  screen,
  movie,
  totalTickets,
  type,
  total,
  Choices,
  ScreenRevenueCol,
  tax,
}) {
  const location = useLocation().pathname;

  console.log(movieReportData)

  const ticketsTotal = (data) => {
    let sum = 0
    data?.forEach((ele) => {
      sum += 1 * ele["الصف"].length
    })
    return sum
  }

  const daysBetween = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const [day, month, year] = date1.split('/');
    const firstDate = new Date(`${year}-${month}-${day}`);
    const [day2, month2, year2] = date2.split('/');
    const secondDate = new Date(`${year2}-${month2}-${day2}`);
    return Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
  };
  function concatArrays(arr1, arr2) {
    if (arr1.length === arr2.length && location.includes("online")) {
      let result = '';
      for (let i = 0; i < arr1.length; i++) {
        result += `${arr2[i]}${arr1[i]}, `;
      }
      return result.slice(0, -2);  // remove the last comma and space
    } else {
      return `${arr2}${arr1}`;
    }
  }

  return (
    <table className=" w-full text-center">
      <tr className={`w-full ${tax || 'border  border-black'}`}>
        {data.map((item, index) => (
          <th
            className={` ${
              type ? 'h-[50px]' : 'h-[100px]'
            } max-w-[150px] items-center justify-center  border border-black px-1`}
          >
            {item}
          </th>
        ))}
      </tr>
      {location.includes('Movies%20revenues') ? (
        <>
          {movieReportData?.map((item, index) => (
            <>
              <tr className="w-full border  border-black  ">
                {location.includes('/Reprinted%20tickets') ? (
                  <>
                    {data.map((item1, index) => (
                      <td className=" h-[50px]  max-w-[150px] items-center justify-center border border-black px-1">
                        {typeof item[item1] === 'number'
                          ? item[item1].toFixed(2)
                          : item1 === 'تاريخ الحفله' ||
                            item1 === 'توقيت الحذف' ||
                            item1 === 'توقيت البيع' ||
                            item1 === 'وقت اعادة الطباعه' ||
                            item1 === 'التوقيت'
                          ? moment(item[item1]).format('DD-MM-YYYY HH:mm:ss')
                          : item1 === 'المشرف'
                          ? item[item1].name
                          : item1 === 'رقم التذكرة'
                          ? item['الصف'] + ' - ' + item['رقم']
                          : item[item1]}
                      </td>
                    ))}
                  </>
                ) : (
                  <>
                    {data.map((item1, index) => (
                      <td className=" h-[50px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                        {item1 === 'اونلاين'
                          ? item[item1]
                          : item1 === 'عدد التذاكر'
                          ? item[item1]
                          : typeof item[item1] === 'number'
                          ? item[item1].toFixed(2)
                          : item1 === 'تاريخ الحفله' ||
                            item1 === 'توقيت الحذف' ||
                            item1 === 'توقيت البيع' ||
                            item1 === 'وقت اعادة الطباعه' ||
                            item1 === 'التوقيت'
                          ? moment(item[item1]).format('DD-MM-YYYY HH:mm:ss')
                          : item1 === 'المشرف'
                          ? item[item1].name
                          : item[item1]}
                      </td>
                    ))}
                  </>
                )}
              </tr>
            </>
          ))}
          <tr>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
              {totalTickets}
            </td>
            <td className="h-[30px] max-w-[150px] items-center justify-center   px-1"></td>
            <td className="h-[30px] max-w-[150px] items-center justify-center   px-1"></td>
          </tr>
        </>
      ) : (
        <>
          {!tax &&
            movieReportData?.map((item, index) => {
              return (
                <>
                  <tr className={`w-full ${tax || 'border  border-black'}`}>
                    {location.includes('/Reprinted%20tickets') ? (
                      <>
                        {data.map((item1, index) => (
                          <td className=" h-[50px]  max-w-[150px] items-center justify-center border border-black px-1">
                            {typeof item[item1] === 'number' &&
                            item[item1] !== 0
                              ? item[item1].toFixed(2)
                              : item1 === 'تاريخ الحفله' ||
                                item1 === 'توقيت الحذف' ||
                                item1 === 'توقيت البيع' ||
                                item1 === 'وقت اعادة الطباعه' ||
                                item1 === 'التوقيت'
                              ? moment(item[item1]).format('HH:mm:ss A')
                              : item1 === 'المشرف'
                              ? item[item1].name
                              : item1 === 'رقم التذكرة'
                              ? item['الصف'] + ' - ' + item['رقم']
                              : item[item1]}
                          </td>
                        ))}
                      </>
                    ) : (
                      <>
                        {data.map((item1, index) => (
                          <td className=" h-[40px] items-center justify-center border border-black px-1 text-sm">
                            {item1 === 'عدد التذاكر' ||
                            item1 === 'العدد' ||
                            item1 === 'اونلاين' ||
                            item1 === 'الكمية' ||
                            item1 === 'نقدي' ||
                            item1 === 'بطاقة مصرفية' ||
                            item1 === 'اجمالي البطاقات' ||
                            item1 === 'اجمالي النقدي' ||
                            item1 === 'اجمالي الاونلاين' ||
                            item1 === 'بطاقات مصرفية' ||
                            item1 === 'جمله التذاكر المباعه' ||
                            item1 === 'اجمالي البطاقات المصرفية' ||
                            item1 === 'رقم' ||
                            item1 === 'عادي' ||
                            item1 === 'ديلوكس' ||
                            item1 === 'بريميوم'
                              ? item[item1]
                              : item1 === 'مقعد'
                              ? concatArrays(item['رقم'], item['الصف'])
                              : typeof item[item1] === 'number'
                              ? (location.includes('/Detailed%20revenues') &&
                                  item1 === 'الاجمالى') ||
                                (location.includes('glasses-report') &&
                                  item1 === 'السعر') ||
                                (location.includes('online') &&
                                  item1 === 'السعر')
                                ? item[item1]
                                : item[item1].toFixed(2)
                              : item1 === 'تاريخ الحفله' ||
                                item1 === 'توقيت الحذف' ||
                                item1 === 'توقيت البيع' ||
                                item1 === 'وقت اعادة الطباعه' ||
                                item1 === 'التوقيت'
                              ? moment(item[item1]).format('hh:mm:ss A')
                              : item1 === 'المشرف'
                              ? item[item1]?.name
                              : item[item1]}
                          </td>
                        ))}
                      </>
                    )}
                  </tr>
                </>
              );
            })}
          {tax && (
            <>
              {movieReportData?.data?.map((item, index) => {
                return (
                  <>
                    <tr
                      className={`w-full ${tax || 'border  border-black'}`}
                      key={index}
                    >
                      {data.map((item1, index) => (
                        <td
                          className=" h-[40px] items-center justify-center border border-black px-1 text-sm"
                          key={index}
                        >
                          {item1 === 'اسم الفيلم'
                            ? item['الفيلم']
                            : typeof item[item1] === 'number'
                            ? item[item1].toFixed(2)
                            : item1 === 'الموزع'
                            ? item[item1] || 'NA'
                            : item1 === 'الاجمالي'
                            ? item['السعر'].toFixed(2)
                            : item1 === 'التاريخ والوقت'
                            ? moment(item['التاريخ']).format(
                                'DD-MM-YYYY HH:mm:ss'
                              )
                            : item[item1]}
                        </td>
                      ))}
                    </tr>
                  </>
                );
              })}
              <tr className="w-full">
                {data.map((item1, index) => (
                  <td
                    className=" h-[40px] items-center justify-center border border-black px-1 text-sm"
                    key={index}
                  >
                    {item1 === 'الاجمالي'
                      ? movieReportData.length !== 0 &&
                        movieReportData['السعر'].toFixed(2)
                      : item1 === 'الصافي' ||
                        item1 === 'العدد' ||
                        item1 === 'المبلغ'
                      ? movieReportData.length !== 0 &&
                        movieReportData[item1].toFixed(2)
                      : item1 === 'القيمه المضافة'
                      ? movieReportData.length !== 0 &&
                        movieReportData['اجمالي القيمه المضافة'].toFixed(2)
                      : ''}
                  </td>
                ))}
                <td className=" h-[40px] items-center justify-center border border-black px-1 font-bold">
                  الاجمالي
                </td>
              </tr>
            </>
          )}{' '}
          {location.includes('/Total%20sales') && (
            <tr>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {total?.TotalTickets}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {total?.totalCard}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {total?.card}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {total?.totalCash}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {total?.cash}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {total?.Total}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                الاجمالي
              </td>
            </tr>
          )}
          {location.includes('/refund-glasses-report') && (
            <tr>
              {' '}
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {total?.total}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {total?.quantity}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                الاجمالي
              </td>
            </tr>
          )}
          {location.includes('/online') && (
            <tr>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {ticketsTotal(movieReportData)}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                {total?.total}
              </td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>{' '}
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>
              <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                الاجمالي
              </td>
            </tr>
          )}
          {location.includes('/glasses-report') && (
            <>
              <tr>
                <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1"></td>{' '}
                <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                  {total?.totalCard}
                </td>{' '}
                <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                  {total?.card}
                </td>{' '}
                <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                  {total?.totalCash}
                </td>{' '}
                <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                  {total?.cash}
                </td>{' '}
                <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                  {total?.total}
                </td>{' '}
                <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                  {' '}
                  {total?.count}
                </td>{' '}
                <td className="h-[30px] max-w-[150px] items-center justify-center  border border-black px-1">
                  الاجمالي
                </td>{' '}
              </tr>
            </>
          )}
          {location.includes('/Detailed%20revenues') &&
            !location.includes('/Detailed%20revenues%202') && (
              <tr className="w-full border border-black">
                <td colSpan="1" className="  border border-black">
                  {total?.net.toFixed(2)}
                </td>

                <td colSpan="1" className="  border border-black">
                  {total?.ValueAdded.toFixed(2)}
                </td>
                <td colSpan="1" className="  border border-black">
                  {total?.tax.toFixed(2)}
                </td>
                <td colSpan="1" className="  border border-black">
                  {total?.total}
                </td>

                <td colSpan="1" className="  border border-black">
                  {total?.totalTickets}
                </td>
                <td colSpan="1" className="  border border-black"></td>
                <td colSpan="1" className="  border border-black">
                  الاجمالى
                </td>
              </tr>
            )}
          {location.includes('/Detailed%20revenues%202') && (
            <tr className="w-full border border-black">
              <td colSpan="1" className="  border border-black">
                {total?.net.toFixed(2)}
              </td>

              <td colSpan="1" className="  border border-black">
                {total?.ValueAdded.toFixed(2)}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.tax.toFixed(2)}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.standard}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.deluxe}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.premium}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.totalOnline}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.online}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.totalCard}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.card}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.totalCash}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.cash}
              </td>
              <td colSpan="1" className="  border border-black">
                {total?.totalTickets}
              </td>
              <td colSpan="1" className="  border border-black"></td>
              <td colSpan="1" className="  border border-black">
                الاجمالى
              </td>
            </tr>
          )}
          {location.includes('/Screens%20revenues') && (
            <>
              <tr className="w-full border border-black">
                {ScreenRevenueCol.map((item, index) => {
                  return (
                    <td
                      colSpan="1"
                      className=" w-24 border border-black"
                      key={index}
                    >
                      {item === 'total'
                        ? (
                            +total?.['total'].toFixed(2) +
                            +(
                              daysBetween(Choices[0], Choices[1]) * 0.1
                            ).toFixed(2)
                          ).toFixed(2)
                        : item === 'stamp'
                        ? (
                            +total?.[item].toFixed(2) +
                            +(
                              daysBetween(Choices[0], Choices[1]) * 0.1
                            ).toFixed(2)
                          ).toFixed(2)
                        : item === 'numberOfTickets' || item === 'online'
                        ? total?.[item]
                        : total?.[item].toFixed(2)}
                    </td>
                  );
                })}
                <td colSpan="1" className=" w-24 border border-black">
                  الاجمالي
                </td>
              </tr>

              <tr className="w-full border border-black">
                <td colSpan="9" className=" border border-black">
                  {(
                    +total?.['total'].toFixed(2) +
                    +(daysBetween(Choices[0], Choices[1]) * 0.1).toFixed(2)
                  ).toFixed(2)}
                </td>
                <td colSpan="2" className=" w-44   border border-black">
                  الجملة العمومية وا قدرها
                </td>
              </tr>
            </>
          )}
        </>
      )}
    </table>
  );
}

export default Table;
