import { Button, Tooltip } from "@mantine/core";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { FaFileExcel } from 'react-icons/fa';

const ExportExcel = ({ inputData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const xlsxExtension = ".xlsx";

  const exportToXLSX = () => {
    const ws = XLSX.utils.json_to_sheet(inputData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + xlsxExtension);
  };

  return (
    <>
      <button
        onClick={(e) => exportToXLSX(fileName)}
        className="mt-7 rounded-lg border-2 border-[#3B5DAD] bg-white  p-5 text-[#3B5DAD] shadow-2xl  hover:bg-[#3B5DAD] hover:text-white "
      >
        <FaFileExcel className="scale-150" />
      </button>
    </>
  );
};

export default ExportExcel;
