import React from "react";
import { useEffect } from "react";
import { useRef } from "react";

const ScanForm = ({ onSubmit, inputRef }) => {
  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);
  window.addEventListener("click", () => inputRef.current.focus());
  return (
    <form className="w-full" onSubmit={onSubmit}>
      <div className="w-full">
        <label className="w-full" htmlFor=""></label>
        <input
          placeholder="Scan Bar Code"
          className="mt-1 h-10 w-full rounded-xl  border-gray-300 opacity-0 shadow-lg focus:border-primary focus:ring-primary sm:text-sm"
          name="inputCode"
          ref={inputRef}
          autoComplete="off"
          type="text"
        />
      </div>
      <button type="submit" className="hidden" />
    </form>
  );
};

export default ScanForm;
