import React, { useEffect, useState } from "react";
import Filters from "../../components/ui-assets/Filters";
import SearchBar from "../../components/ui-assets/SearchBar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  useDistributorsQuery,
  useSyncDistributorsMutation,
} from "../../store/distributorsApi";
import TableRow from "../../components/ui-assets/table/TableRow";
import CustomToast from "../../utiles/CustomToast";

const columns = [
  {
    title: "Name",
    value: (item) => item.name,
    classes: "text-xs text-gray-600 md:basis-2/6",
  },
  {
    title: "Code",
    value: (item) => item.code,
    classes: "flex basis-3/6 flex-col md:basis-2/6",
  },
  {
    title: "Mobile",
    value: (item) => item.mobileNumber,
    classes: "flex basis-3/6 flex-col md:basis-1/6",
  },
  {
    title: "address",
    value: (item) => item.address,
    classes: "flex basis-2/6  flex-col",
  },
];

const DistributorsList = () => {
  const [searchValue, setSearchValue] = useState("");
  const {
    data: distributors,
    isLoading,
    refetch,
  } = useDistributorsQuery(searchValue);

  const [SyncDistributors, { isError, isSuccess, error }] =
    useSyncDistributorsMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  return (
    <>
      <Helmet>
        <title>Distributors List</title>
      </Helmet>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/distributors"
        />
      )}
      <div className="container relative py-10">
        <div className="sticky top-0 flex flex-col flex-wrap items-center justify-between gap-4 bg-white py-4 lg:flex-row">
          <SearchBar onChange={setSearchValue} value={searchValue} />
          <div>
            {/* <Filters setfilterData={setfilterField} filterData={filterData} /> */}
          </div>
          <div className="w-100 flex flex-1 flex-wrap justify-center gap-2 text-[14px] md:flex-nowrap">
            <button
              className="w-[240px] whitespace-nowrap rounded-2xl bg-primary px-4 py-2 text-white md:w-fit"
              onClick={() => {
                SyncDistributors();
              }}
            >
              Sync Distributors
            </button>
            <button
              className="w-[240px] whitespace-nowrap rounded-2xl bg-primary px-4 py-2 text-white md:w-fit"
              onClick={() => {
                SyncDistributors();
              }}
            >
              Update Existing Distributors
            </button>
          </div>
        </div>
        <div className="py-4">
          {!isLoading &&
            distributors &&
            distributors.map((dist) => (
              <TableRow
                data={dist}
                column={columns}
                key={dist._id}
                // onDelete={deleteDistributor}
                // editUrl="/edit-dist"
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default DistributorsList;
