import React from "react";
import QRCode from "react-qr-code";
import dayjs from "dayjs";
import { urlInPrint } from "../config/dataService/dataService";
// import { useGetpriceSettingsQuery } from "../store/reservation/reservationApi";

export const ComponentToPrint = React.forwardRef(({ reservations }, ref) => {
  // const { data: priceSettings } = useGetpriceSettingsQuery();
  return (
    <>
      <div ref={ref} className="flex flex-col ">
        {reservations &&
          reservations.map((reservation, i) => {
            return (
              <div
                key={i}
                className="ticket_printer mx-[25px] mt-[5px] flex w-[7.2cm]"
              >
                <img src="/cfc-logo.webp" alt="logo" className="mx-auto" />
                <p className="text-[14px]">Scene Cinema District 5</p>
                {/* <p className="text-[14px]">Company name</p> */}
                <p className="text-[14px]">
                  District 5, Marakez, Ain Sokhna Road
                </p>
                <p className="text-[14px]">Tax Reg No.: 100-057-667</p>
                <div
                  key={reservation._id}
                  //${reservation.invitation ? "h-[3.5cm]" : "h-[3.5cm]"}
                  className={`flex-row justify-between`}
                >
                  <div className="flex  flex-col">
                    <div className="m-auto my-4 flex w-[50%] justify-between">
                      <div
                        style={{
                          height: "auto",
                          margin: "auto",
                          // maxWidth: 64,
                          width: "100%",
                          paddingRight: "15px",
                        }}
                        className="flex justify-between"
                      >
                        <QRCode
                          size={128}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                            margin: "auto",
                          }}
                          value={reservation.serial}
                          // viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row gap-10">
                      <div className="flex flex-col items-start text-[19px]">
                        <p className="mx-0 w-24 truncate text-[14px] font-bold capitalize">
                          {reservation?.movie?.name}
                        </p>
                        <p className="text-sm font-bold">
                          {new Date(reservation?.date).toLocaleDateString()}
                        </p>
                        <p className="text-default my-0 font-bold capitalize">
                          {reservation?.showTime?.startTime}
                        </p>
                      </div>
                      <div className="flex flex-col items-end text-[19px]">
                        <p className="w-[85px] text-end text-sm font-bold capitalize">
                          {reservation?.screen?.name}
                        </p>
                        <p className=" font-bold capitalize">
                          {reservation?.seat?.title?.split("-")[1]}
                        </p>
                        <p className="truncate text-[12px] capitalize">
                          {`${reservation?.agent?.name}`}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      {reservation.invitation ? (
                        <h1 className="mt-8 font-extrabold">invitation</h1>
                      ) : reservation.invitation ? (
                        <h1 className="mt-8 font-extrabold">invitation</h1>
                      ) : (
                        <>
                          {reservation?.rePrinted && (
                            <p className="font-bold">Reprinted</p>
                          )}
                          <p className="text-sm">{`${
                            Number(reservation?.price)
                            // +
                            // Number(
                            //   reservations.length === reservations[0].glasses
                            //     ? Math.floor(
                            //         reservations.length /
                            //           (reservations[0].glasses || 0)
                            //       ) * (priceSettings?.glassesPrice || 20)
                            //     : i + 1 > reservations[0].glasses
                            //     ? 0
                            //     : Math.floor(
                            //         reservations.length /
                            //           (reservations[0].glasses || 0)
                            //       ) * (priceSettings?.glassesPrice || 20)
                            // )
                          } EGP ${reservation?.payment}`}</p>
                          <p className="text-xs">{`Net=${
                            reservation?.enterPrice
                          } Ent=${
                            reservation?.amusementTax
                          } Add=2.04 Val=${+reservation?.valueTax.toFixed(
                            2
                          )}`}</p>
                          <p className="text-[10px]">
                            SN:{" "}
                            {reservation && (
                              <span className="text-[10px] font-bold">
                                {reservation.serial}
                              </span>
                            )}{" "}
                            {dayjs(reservation?.createdAt).format(
                              "DD/MM/YYYY, HH:mm"
                            )}
                          </p>
                          <p className="text-xs">{urlInPrint}</p>
                          <div className="mx-auto mt-2 mb-3 w-full">
                            <QRCode
                              size={24}
                              style={{
                                height: "50px",
                                maxWidth: "100%",
                                width: "50px",
                                margin: "auto",
                              }}
                              value={urlInPrint}
                              // viewBox={`0 0 256 256`}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
});