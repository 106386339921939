import React from "react";
import { useAgentReportDetailsQuery } from "../../store/reportsApi";
import dayjs from "dayjs";

const TableHeader = () => {
  return (
    <thead>
      <tr className=" h-[50px] w-full items-center justify-center  border border-black px-1">
        <th className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1">
          اسم الفيلم
        </th>
        <th className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1">
          الحفله
        </th>
        <th className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1">
          الكرسي
        </th>
        <th className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1">
          تاريخ الحفله
        </th>
        <th className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1">
          تاريخ الحجز
        </th>
        <th className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1">
          السعر
        </th>
      </tr>
    </thead>
  );
};

const AgentTicketsDetailsTable = ({ data }) => {
  const {
    data: details,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useAgentReportDetailsQuery({
    startDate: new Date(new Date().setHours(7, 0, 0)).toString(),
    data: {
      users: [data?._id],
    },
  });

  return (
    <table className="my-3 w-full">
      <TableHeader />
      <tbody>
        {details?.map((row) => (
          <tr className="w-full border  border-black " key={row._id}>
            <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center">
              {row["الفيلم"]}
            </td>
            <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center">
              {row["الحفله"]}
            </td>
            <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center">
              {row["الصف"] + row["رقم"]}
            </td>
            <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center">
              {dayjs(row["تاريخ الحفلة"]).format("DD/MM/YYYY")}
            </td>
            <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center">
              {dayjs(row["التاريخ"]).format("DD/MM/YYYY HH:mm")}
            </td>
            <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center">
              {row["السعر"]}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AgentTicketsDetailsTable;
