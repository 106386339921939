export const buttonColors = (status) => {
  switch (status) {
    case "Available":
      return "bg-[#FFFFFF]";
    case "Booked":
      return "!bg-[#90CAF9]";
    case "Holding":
      return "!bg-[#9FA8DA]";
    case "Invitation":
      return "!bg-[#A5D6A7]";
    case "Waiting":
      return "!bg-[#CE93D8]";
    case "Repair":
      return "!bg-[#FFCC80]";
    case "Emergency":
      return "!bg-[#0000FF]";
    case "Online reservation":
      return "!bg-[#FFFFFF]";
    case "Reserved":
      return "!bg-[#0000FF]";
    default:
      return "bg-[#fff]";
  }
};
