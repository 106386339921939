import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../config/dataService/dataService";

export const showTimesApi = createApi({
  reducerPath: "showTimesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: "include",
  }),
  tagTypes: ["showTimes"],
  endpoints: (builder) => ({
    showTimes: builder.query({
      query: () => "show-times",
      providesTags: ["showTimes"],
    }),
    freeShowTimes: builder.query({
      query: ({ screen, date, endDate }) =>
        `show-times/free-show-times?screen=${screen}&date=${date}&endDate=${endDate}`,
      providesTags: ["showTimes"],
    }),
    showTime: builder.query({
      query: (id) => `show-times/${id}`,
      providesTags: ["showTimes"],
    }),
    createShowTime: builder.mutation({
      query: (data) => ({
        url: `show-times/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["showTimes"],
    }),
    updateShowTime: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `show-times/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["showTimes"],
    }),
    deleteShowTime: builder.mutation({
      query: (id) => ({
        url: `show-times/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["showTimes"],
    }),
  }),
});

export const {
  useShowTimesQuery,
  useShowTimeQuery,
  useFreeShowTimesQuery,
  useCreateShowTimeMutation,
  useDeleteShowTimeMutation,
  useUpdateShowTimeMutation,
} = showTimesApi;
