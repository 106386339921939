import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import MovieCard from "../reservation/MovieCard";
import { useLocation } from "react-router-dom";
import SearchBar from "./SearchBar";
import Filters from "./Filters";
import Spinner from "./Spinner";

const filterData = [
  {
    id: 2,
    name: "Active Movies",
    value: "Active Movies",
  },
  {
    id: 3,
    name: "Locked Movies",
    value: "Locked Movies",
  },
];

export default function ReportMovieModal({
  data,
  setSelected,
  type,
  setDropdownState,
  selected,
  setSearchValue,
  searchValue,
  setfilterField,
  isLoading,
}) {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    !open && setDropdownState(false);
  }, [open, setDropdownState]);

  const location = useLocation().pathname;

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-full ">
              <div className="flex min-h-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0  translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100  translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative w-4/5 transform  rounded-lg  bg-white p-4 text-left shadow-xl transition-all ">
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="mt-6 flex  flex-row items-center justify-between px-5 font-bold text-primary"
                      >
                        <p>{type}</p>
                        <div
                          onClick={() => setOpen((prev) => !prev)}
                          className=" cursor-pointer text-red-500"
                        >
                          <i className="fa-solid fa-circle-xmark"></i>
                        </div>
                      </Dialog.Title>

                      <div className="mt-2">
                        <p className="px-3 text-sm text-gray-500">
                          {Array.isArray(selected) &&
                            data
                              ?.filter((i) => selected.includes(i._id))
                              .map((i) => i.name)
                              .join(" , ")}
                          {type === "Movies"
                            ? location.includes("add-showmovie")
                              ? selected?.name
                              : selected?.movie?.name
                            : type === "Screens"
                            ? selected?.name
                            : selected?.startTime}
                        </p>
                      </div>

                      <div className="my-4 flex w-full justify-center ">
                        {" "}
                        <SearchBar
                          onChange={setSearchValue}
                          value={searchValue}
                        />
                      </div>
                      <div className="flex w-full  justify-center">
                        <Filters
                          setfilterData={setfilterField}
                          filterData={filterData}
                        />
                      </div>
                    </div>
                    <div className="  h-[50vh] overflow-scroll px-4 py-2">
                      <div className="   flex w-full  flex-wrap items-start justify-start  gap-4   ">
                        {data &&
                          data.map((showmovie) => (
                            // Date.now() >=
                            //   new Date(showmovie.releaseDate).getTime() ||
                            // !showmovie.releaseDate ? (
                            <div
                              onClick={() => {
                                setSelected(showmovie);
                                if (
                                  !location.includes("Movies%20revenues") &&
                                  !location.includes("Detailed%20revenues")
                                ) {
                                  setOpen(!open);
                                }
                              }}
                              key={showmovie._id}
                              className={`   min-w-[78px] cursor-pointer object-contain`}
                            >
                              <MovieCard
                                showMovie={{ movie: showmovie }}
                                text="text-xs mt-1   font-base"
                                height={`${
                                  selected?.includes(showmovie._id)
                                    ? " border border-[5px]   border-red-500"
                                    : ""
                                } h-[216px] translate-x-2 w-[146px] `}
                                opacity={`   opacity-100 w-[146px] `}
                              />
                            </div>
                          ))}
                      </div>
                    </div>{" "}
                    <div className="flex justify-center">
                      <button
                        onClick={() => {
                          setOpen(!open);
                        }}
                        className="mt-10 h-[55px]  w-[190px]  rounded-2xl border-2 border-[#3B5DAD] bg-white  px-6 py-1 text-[#3B5DAD] shadow-2xl  hover:bg-[#3B5DAD] hover:text-white "
                      >
                        Done
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
