import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Signin from "./pages/auth/Signin";
import Users from "./pages/users/UsersList";
import UserOperations from "./pages/users/UserPage";
import DistributorsList from "./pages/distributors/DistributorsList";
import DistributorPage from "./pages/distributors/DistributorPage";
import ShowtimesList from "./pages/showtimes/ShowtimesList";
import MoviesList from "./pages/movies/MoviesList";
import MoviePage from "./pages/movies/MoviePage";
import ShowtimePage from "./pages/showtimes/ShowtimePage";
import ScreenList from "./pages/screens/ScreenList";
import ScreenPage from "./pages/screens/ScreenPage";
import ReservationPage from "./pages/reservation/ReservationPage";
import SeatsFormation from "./pages/screens/SeatsFormation";
import CustomLayout from "./components/layout/CustomLayout";
import ShowMoviesList from "./pages/showMovies/ShowMoviesList";
import ShowMoviePage from "./pages/showMovies/ShowMoviePage";
import MoviesPage from "./pages/reservation/MoviesPage";
import ShowMovieResPage from "./pages/reservation/ShowMovieResPage";
import ReportsList from "./pages/Reports/ReportsList";
import ReportsPage from "./pages/Reports/ReportsPage";
import MoviesRevenues from "./components/Reports/Reports table/ViewReports";
import ProtectedRoutes from "./utiles/ProtectedRoutes";
import PublicRoutes from "./utiles/PublicRoutes";
import RolesRoutes from "./utiles/RolesRoutes";
import ScanPage from "./pages/scan/ScanPage";
import Setting from "./pages/setting/Setting";
import TaxsPage from "./pages/Taxs/TaxsPage";
import AgentsPage from "./pages/agents/agentsPage";
import SelfMoviesPage from "./pages/selfServices/SelfMoviesPage";
import SelfShowMovieResPage from "./pages/selfServices/SelfShowMovieResPage";

// test
function App() {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route element={<Layout />}>
          <Route
            element={
              <RolesRoutes
                allowedRoles={["Super Admin", "Admin", "Supervisor"]}
              />
            }
          >
            <Route path="/" element={<Navigate to="/users" replace={true} />} />
            <Route path="/users" element={<Users />} />
            <Route path="/distributors" element={<DistributorsList />} />
            {/* <Route path="/edit-dist/:id" element={<DistributorPage />} /> */}
            <Route path="/screens" element={<ScreenList />} />
            <Route path="/showtimes" element={<ShowtimesList />} />
            <Route path="/showmovies" element={<ShowMoviesList />} />
            <Route path="/movies" element={<MoviesList />} />{" "}
            <Route path="/settings" element={<Setting />} />
            <Route path="/taxs" element={<TaxsPage />} />
          </Route>
          <Route
            element={
              <RolesRoutes allowedRoles={["Super Admin", "Admin", "Taxes"]} />
            }
          >
            <Route path="/reports" element={<ReportsList />} />
            <Route path="/viewReport/:path" element={<MoviesRevenues />} />
            <Route path="/reports/:path" element={<ReportsPage />} />
            <Route path="/add-user" element={<UserOperations />} />
            <Route path="/edit-user/:id" element={<UserOperations />} />
            {/* <Route path="/add-dist" element={<DistributorPage />} /> */}
            <Route path="/add-screen" element={<ScreenPage />} />
            <Route path="/edit-screen/:id" element={<ScreenPage />} />
            <Route
              path="/add-formation/:screenId"
              element={<SeatsFormation />}
            />
            <Route path="/edit-showtime/:id" element={<ShowtimePage />} />
            <Route path="/add-showmovie" element={<ShowMoviePage />} />
            <Route path="/edit-showmovie/:id" element={<ShowMoviePage />} />
            <Route path="/add-showtime" element={<ShowtimePage />} />
            <Route path="/add-movie" element={<MoviePage />} />
            <Route path="/edit-movie/:id" element={<MoviePage />} />
          </Route>
          <Route
            element={
              <RolesRoutes
                allowedRoles={[
                  "Super Admin",
                  "Admin",
                  "Supervisor",
                  "Reservation",
                  "Kiosk",
                  "Finance",
                  "Distributor",
                ]}
              />
            }
          >
            <Route path="/reservation" element={<MoviesPage />} />
            <Route path="/reservation/:id" element={<ShowMovieResPage />} />
            <Route path="/selfServices" element={<SelfMoviesPage />} />
            <Route path="/selfServices/:id" element={<SelfShowMovieResPage />} />
          </Route>
        </Route>
        <Route
          element={
            <RolesRoutes
              allowedRoles={[
                "Super Admin",
                "Admin",
                "Supervisor",
                "Reservation",
                "Kiosk",
              ]}
            />
          }
        >
          <Route element={<CustomLayout />}>
            <Route path="/reserve" element={<ReservationPage />} />
            <Route path="/selfReserve" element={<ReservationPage />} />
          </Route>
        </Route>
        <Route
          element={
            <RolesRoutes
              allowedRoles={[
                "Super Admin",
                "Admin",
                "Supervisor",
                // "Reservation",
                "Scanner",
              ]}
            />
          }
        >
          <Route element={<CustomLayout />}>
            <Route path="/scan" element={<ScanPage />} />
          </Route>
        </Route>
      </Route>

      <Route element={<RolesRoutes allowedRoles={["Super Admin", "Admin"]} />}>
        <Route element={<Layout />}>
          <Route path="/agents" element={<AgentsPage />} />
        </Route>
      </Route>

      <Route element={<PublicRoutes />}>
        <Route path="/signin" element={<Signin />} />
      </Route>
    </Routes>
  );
}

export default App;
