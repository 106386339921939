const Input = ({
  label,
  register,
  placeholder,
  registerName,
  defaultValue,
  type,
  onChange,
}) => {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var dateDefault = curr.toISOString().substring(0, 10);

  return (
    <div className="w-full">
      <label
        htmlFor={registerName}
        className="text-left text-sm font-semibold text-[#3B5CAD]"
      >
        {label}
      </label>
      <input
        {...(register && register(registerName))}
        defaultValue={defaultValue}
        min={type === "date" && dateDefault}
        type={type ? type : "text"}
        onChange={onChange}
        name={registerName}
        id={registerName}
        className=" mt-1 h-10 w-full  rounded-xl border-gray-300 shadow-lg focus:border-primary focus:ring-primary sm:text-sm"
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
