import React from "react";

import moment from "moment";

function TotalRevenuesByDayTable({ data, movieReportData }) {
  // filter array of object to get only the _id from movieReportData And not to repeat it

  const distributors = movieReportData?.map(
    (movieReportData) => movieReportData._id
  );
  const distributorsUnique = [...new Set(distributors)];

  return (
    <table className=" w-full text-center">
      <tr className="w-full border border-black  ">
        {data.map((item, index) => (
          <th className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1">
            {item}
          </th>
        ))}
      </tr>
      <br />
      {distributorsUnique.map((distributor, index) => {
        let total = {
          number: 0,
          total: 0,
          tax: 0,
          addedValueTax: 0,
          net: 0,
        };
        return (
          <>
            <div className="  mb-2 w-full text-center">
              <p className="border-b-2 border-b-black">
                {moment(distributor).format("DD/MM/YYYY")}
              </p>
            </div>
            <>
              {movieReportData.map((item) => {
                if (item._id === distributor) {
                  item.data.forEach((d) => {
                    total.number += d["عدد التذاكر"];
                    total.total += d["الاجمالى"];
                    total.tax += d["الضريبة"];
                    total.addedValueTax += d["القيمه المضافة"];
                    total.net += d["الصافى"];
                  });
                }
                return item._id === distributor ? (
                  <tr className="w-full border  border-black  ">
                    {data.map((item1, index) => (
                      <td className=" h-[50px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                        {item1 === "عدد التذاكر"
                          ? item.data[0][item1]
                          : item1 === "اونلاين"
                          ? item.data[0][item1]
                          : typeof item.data[0][item1] === "number"
                          ? item1 === "الاجمالى"
                            ? item.data[0][item1]
                            : item.data[0][item1].toFixed(2)
                          : item1 === "تاريخ الحفله" ||
                            item1 === "توقيت الحذف" ||
                            item1 === "توقيت البيع" ||
                            item1 === "وقت اعادة الطباعه" ||
                            item1 === "التوقيت"
                          ? moment(item.data[0][item1]).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )
                          : item1 === "المشرف"
                          ? item.data[0][item1].name
                          : item.data[0][item1]}
                      </td>
                    ))}
                  </tr>
                ) : null;
              })}
              <tr className="w-full border  border-black  ">
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total.net.toFixed(2)}
                </td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total.addedValueTax.toFixed(2)}
                </td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total.tax.toFixed(2)}
                </td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total.total}
                </td>{" "}
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  {total.number}
                </td>
                <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1"></td>
                <th className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
                  الاجمالي
                </th>
              </tr>
              <br />
            </>
          </>
        );
      })}
    </table>
  );
}

// {movieReportData?.map((item, index) => {
//     return (
//       <tr className="w-full border  border-black  ">
//         {data.map((item1, index) => (
//           <td className=" h-[50px] w-24 max-w-[100px] items-center justify-center border border-black px-1">
//             {typeof item[item1] === "number"
//               ? item[item1].toFixed(2)
//               : item1 === "تاريخ الحفله" ||
//                 item1 === "توقيت الحذف" ||
//                 item1 === "توقيت البيع" ||
//                 item1 === "وقت اعادة الطباعه" ||
//                 item1 === "التوقيت"
//               ? moment(item[item1]).format("DD-MM-YYYY HH:mm:ss")
//               : item1 === "المشرف"
//               ? item[item1].name
//               : item[item1]}
//           </td>
//         ))}
//       </tr>
//     );
//   })}
export default TotalRevenuesByDayTable;
