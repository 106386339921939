import { useEffect, useState } from "react";
import Choices from "./Choices";
import { useLocation } from "react-router-dom";

function ChooseList({ setChoices, data, lastChoices, type }) {
  let Location = useLocation().pathname;
  const sletectAllHandler = () => {
    data?.length === lastChoices?.length
      ? setChoices([], "all")
      : setChoices(
          data?.map((i) => i._id),
          "all"
        );
  };

  useEffect(() => {
    if (
      Location.includes("/Screens%20revenues") ||
      Location.includes("/Distributors%20revenues")
    ) {
      setTimeout(() => {
        sletectAllHandler();
      }, 100);
    }
  }, [data]);

  return (
    <div className="relative w-full max-w-[394px]">
      <>
        <button
          onClick={() => sletectAllHandler()}
          className="absolute top-[-110px] mt-10 h-[55px] w-[190px] rounded-2xl border-2 border-[#3B5DAD] bg-[#3B5DAD] px-6 py-1 text-white hover:bg-white hover:text-[#3B5DAD] "
        >
          Select All
        </button>
      </>

      <h1 className=" mb-3 text-base font-medium text-[#3B5DAD]">{type}</h1>

      <div className=" mt-1 h-[258px] w-full overflow-scroll rounded-2xl border border-[#00C18B]">
        {data &&
          data.map((item) => (
            <div
              key={item._id}
              onClick={() => {
                setChoices(item._id);
              }}
            >
              <Choices item={item} Choices={lastChoices} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ChooseList;
