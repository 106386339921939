import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SigninForm from "../../components/auth/SigninForm";
import { useLoginMutation } from "../../store/authUserApi";
import CustomToast from "../../utiles/CustomToast";
import { Helmet } from "react-helmet";

export default function Signin() {
   const [loginUser, { isSuccess, isError, error, data }] = useLoginMutation();
   const location = useLocation();
   const search = location?.state?.from?.search || "";
   const pathname = location?.state?.from?.pathname || "/";
   const navigator = useNavigate();
   const loginHandler = (data) => {
      loginUser({ email: data.email.toLowerCase(), password: data.password });
   };

   useEffect(() => {
      if (isSuccess && data) {
         navigator(
            data.role === "Reservation" ? "/reservation" : data.role === "Kiosk" ? "/selfServices" : pathname + search,
            { replace: true }
         );
      }
   }, [data, isSuccess, navigator, pathname, search]);

   console.log("🚀 ~ file: Signin.js ~ line 33 ~ Signin ~ data", isError);
   return (
      <>
         <Helmet>
            <title>Sign In</title>
         </Helmet>
         {(isError || isSuccess) && (
            <CustomToast
               IsError={isError}
               error={error}
               isSuccess={isSuccess}
               path="/"
            />
         )}
         <SigninForm onSubmit={loginHandler} />
      </>
   );
}
