import React from "react";
import { useParams } from "react-router-dom";
import ReportsDate from "../../components/Reports/ReportsCalendar";
import { useMoviesQuery } from "../../store/moviesApi";
import axios from "axios";
import { BASE_URL } from "../../config/dataService/dataService";
import { utils, writeFile } from "xlsx";
import { useScreensQuery } from "../../store/screensApi";
import { useUsersQuery } from "../../store/usersApi";
import { useDistributorsQuery } from "../../store/distributorsApi";
import { Helmet } from "react-helmet";

const ReportsPage = () => {
  const { path } = useParams();
  const { data: movies } = useMoviesQuery(null, {
    skip: path !== "Movies revenues",
  });

  const { data: screens } = useScreensQuery(null, {
    skip: path !== "Screens revenues",
  });

  const { data: distributors } = useDistributorsQuery(null, {
    skip: path !== "Distributors revenues",
  });

  const { data: users } = useUsersQuery(null, {
    skip: path !== "Detailed sales",
  });

  const exportExcel = async (link, body) => {
    const response = await axios.post(`${BASE_URL}/reports${link}`, body, {
      withCredentials: true,
    });
    const workBook = utils.book_new();
    const WorkSheet = utils.json_to_sheet(response.data);
    utils.book_append_sheet(workBook, WorkSheet, "Sheet");
    writeFile(workBook, "NewExcel.xlsx");
  };

  const submitHandler = ({ link, ...body }) => {
    exportExcel(link, body);
  };

  return (
    <>
      <Helmet>
        <title>Reports Page</title>
      </Helmet>
      <ReportsDate
        path={path}
        data={
          path === "Movies revenues"
            ? movies
            : path === "Screens revenues"
            ? screens
            : path === "Detailed sales"
            ? users
            : path === "Distributors revenues"
            ? distributors
            : []
        }
        onGenerate={submitHandler}
      />
    </>
  );
};

export default ReportsPage;
