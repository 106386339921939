import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import MovieCard from "../../components/reservation/MovieCard";
import MoviesReservation from "../../components/reservation/MoviesReservation";
import { useShowMoviesQuery } from "../../store/showMovieApi";
import { useShowTimesQuery } from "../../store/showTimesApi";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
import { reservationActions } from "../../store/reservation/reservationSlice";
import { Helmet } from "react-helmet";

const ShowMovieResPage = () => {
  const dispatch = useDispatch();
  const reservationDate = useSelector(
    (state) => state.reservation.ReservationDate
  );
  const { id } = useParams();
  const {
    data: showMovies,
    isLoading,
    refetch,
  } = useShowMoviesQuery(`reservation=yes&date=${reservationDate}`);
  const { data: showTimes } = useShowTimesQuery();
  const selectedShowMovie = showMovies?.find((i) => i._id === id);

  useEffect(() => {
    refetch();
  }, [reservationDate, refetch]);

  useEffect(() => {
    dispatch(
      reservationActions.setReservationDate(
        new Date().getHours() < 7
          ? new Date(
              new Date().setDate(new Date().getDate() - 1)
            ).toLocaleDateString()
          : new Date().toLocaleDateString()
      )
    );
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>ShowMovieRes Page</title>
      </Helmet>
      <ScrollContainer>
        <div className="flex  items-start  gap-6    pt-5">
          {!isLoading &&
            showMovies &&
            showMovies?.map((showmovie) => (
              <div
                key={showmovie._id}
                className=" w-[78px] min-w-[78px] cursor-grab object-contain"
              >
                <Link to={`/reservation/${showmovie._id}`}>
                  <MovieCard
                    showMovie={showmovie}
                    text="text-xs mt-1   font-base"
                    height="h-[116px] "
                    opacity={
                      showmovie._id === id ? "opacity-100" : "opacity-40"
                    }
                  />
                </Link>
              </div>
            ))}
        </div>
      </ScrollContainer>
      {!isLoading && (
        <MoviesReservation
          showMovie={selectedShowMovie}
          showTimes={showTimes}
        />
      )}
    </div>
  );
};

export default ShowMovieResPage;
