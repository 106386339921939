import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../config/dataService/dataService";
export const authUserApi = createApi({
  reducerPath: "authUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/auth`,
    credentials: "include",
  }),
  tagTypes: ["User"],
  endpoints: ({ query, mutation }) => ({
    authUser: query({
      query: () => "/validate",
      providesTags: ["User"],
    }),
    login: mutation({
      query: (data) => ({
        url: "/login",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    logout: mutation({
      query: (i) => `/logout/${i}`,
      invalidatesTags: ["User"],
    }),
  }),
});

export const { useAuthUserQuery, useLoginMutation, useLogoutMutation } =
  authUserApi;
