import React from "react";

function ReportBar({ type }) {
  return (
    <div className=" relative z-0 mt-4 flex h-[52px] w-full cursor-pointer items-center justify-between rounded-xl border-0 text-sm font-medium shadow-md">
      <h1 className="w-full p-[16px] text-end font-semibold text-[#3B5DAD]">
        {type}
      </h1>
    </div>
  );
}

export default ReportBar;
