export const sortShowTime = (showTime) => {
  const showTimesTest =
    showTime &&
    [...showTime]?.sort(
      (a, b) =>
        new Date(`8/10/2022, ${a.startTime}`).getTime() -
        new Date(`8/10/2022, ${b.startTime}`).getTime()
    );
  const breakingPoing = showTimesTest?.findIndex(
    (i) =>
      new Date(`8/10/2022, ${i.startTime}`).getTime() >=
      new Date(`8/10/2022, 07:00 AM`).getTime()
  );
  const lastTimes = showTimesTest?.splice(0, breakingPoing);
  return showTimesTest && lastTimes && [...showTimesTest, ...lastTimes];
};
