import axios from "axios";
import { BASE_URL } from "../config/dataService/dataService";

export const uploadFileS3 = async (files, setIsLoading, folder) => {
  try {
    const uploadPromis = files.map((item) =>
      axios.get(
        `${BASE_URL}/upload?contentType=${item.type}&filename=${item.name}&folder=${folder}`,
        {
          withCredentials: true,
        }
      )
    );
    const uploadurls = await Promise.all(uploadPromis);
    const filesPromise = uploadurls.map(({ data }, i) =>
      axios.put(data, files[i], {
        headers: {
          "Content-Type": files[i].type,
        },
        onUploadProgress: (e) => {
          setIsLoading({
            loading: true,
            progress: Math.trunc((e.loaded / e.total) * 100),
          });
        },
      })
    );
    await Promise.all(filesPromise);
    setIsLoading({
      loading: false,
      progress: 0,
    });
    return uploadurls.map(({ data }) => data.split("?")[0]);
  } catch (error) {
    setIsLoading({
      loading: false,
      progress: 0,
    });
    throw error;
  }
};
