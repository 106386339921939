export const BASE_URL = "https://cinema-api.codpark.com/v1";

export const SOCKET_URL = "https://cinema-api.codpark.com/";

export const reservationMovieCard = "rotate-180"; //Qatamya

export const urlInPrint = "www.scene-cinema.com"; //Qatamya

export const cinemaNameInReport = 'سين سينما'; //Qatamya

// PRODUCTION
// export const BASE_URL = "https://apicfc.scene-cinema.com/v1";
// export const SOCKET_URL = "https://apicfc.scene-cinema.com/";

// LOACAL URLS.

// export const BASE_URL = "http://localhost:5000/v1";
// export const SOCKET_URL = "http://localhost:5080/";


// export const logo = "" //Qatamya

// export const cinemaEnv = "QAT"; //Qatamya
