import React from "react";
import TotalRevenuesTable from "./TotalRevenuesTable";
function TotalRevenues({ movieReportData, data, startDate, endDate, Choices }) {
  const daysBetween = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000;

    const [day, month, year] = startDate.split("/");
    const firstDate = new Date(`${year}-${month}-${day}`);
    const [day2, month2, year2] = endDate.split("/");
    const secondDate = new Date(`${year2}-${month2}-${day2}`);

    return Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
  };

  return (
    <div className="flex w-full flex-col items-center p-5 ">
      <div className=" my-6 flex w-full flex-row justify-between ">
        <div>
          <img alt="logo" className=" w-36" src="/logo.png"></img>
        </div>

        <div className=" text-xl">
          <p>مديرية الضرائب العقارية</p>
          <p>ادارة ضرائب الملاهي</p>
        </div>
      </div>
      <div className=" my-6 flex w-full flex-row items-center  self-center ">
        <div className=" w-full text-center">
          <p>حافظه توريد اجمالي</p>
          <p>
            من مستغلي الحفلات التي قيمت ب تاريخ {startDate} الي {endDate}
          </p>
        </div>
      </div>
      <div className=" w-full  text-end ">
        <p>اسم المستغل و عنوانه</p>
        <p>اسم المحل و عنوانه </p>
        <p>
          عدد الحفلات ووقت اقامتها : عدد الحفلات 6 حفلات يوميا 10.30 -1.00- 3.30
          - 6.30 - 9.30 - 12.30
        </p>
      </div>
      <div className="my-2 w-full  text-start ">
        <p>
          {`${
            Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
          } ${Choices?.movieType || "ALL"}`}
        </p>
      </div>
      <div className=" flex w-full flex-col"></div>
      <div className=" flex w-full flex-col items-center">
        {movieReportData?.length === 0 ? (
          <h1 className="text-xl font-extrabold text-red-600">
            لا يوجد بيانات للعرض
          </h1>
        ) : (
          <>
            <TotalRevenuesTable
              movieReportData={movieReportData}
              data={data}
              daysBetween={daysBetween(startDate, endDate)}
            />
          </>
        )}
      </div>{" "}
      <div className="flex w-full flex-col text-lg">
        <div className=" mt-5 flex w-full flex-row-reverse justify-between px-10">
          <p>
            الجمله العمومية وا قدرها
            ........................................................................................................و..................................................
            قرشا
          </p>
        </div>
        <div className=" mt-5 flex w-full flex-row-reverse justify-between px-10">
          <p>اقر بأن البيانات مطابقة للحقيقة</p>
          <p>
            {" "}
            ................................................ : امضاء المستغل
          </p>
        </div>
        <div className=" mt-5 flex w-1/2 flex-row-reverse justify-between self-end px-10">
          <p>
            تحرير في ............. سنه <span className="mr-5">20</span>
            {"  "}{" "}
          </p>
          <p>
            {" "}
            سنه <span className="mr-5">20</span>
          </p>
        </div>
        <div className=" mt-5 flex w-full flex-row-reverse justify-between px-10">
          <p>
            تسلمت المبلغ و توريد باليوميه صفحة ............... وحرر عنه ايصال
            رقم ............ في تاريخ
          </p>
        </div>
        <div className=" mt-5 flex w-full flex-row-reverse justify-between  px-10">
          <p>
            تحرير في ............. سنه <span className="mr-5">20</span>
          </p>
          <p>
            {" "}
            سنه <span className="mr-5">20</span>
          </p>
          <p>................................................ : المحصل</p>
        </div>
        <div className=" mt-5 flex w-full flex-row-reverse justify-between px-10">
          <p>
            ................ : روجعت البيانات المدرجة في بهذا الحافظة و قيدت
            بالجريدة تحت رقم
          </p>
        </div>
        <div className=" mt-5 flex w-full flex-row-reverse justify-between  px-10">
          <p>
            تحرير في ............. سنه <span className="mr-5">20</span>
            {"  "}{" "}
          </p>
          <p>
            {" "}
            سنه <span className="mr-5">20</span>
          </p>
          <p>................................................ : المراجع</p>
        </div>
      </div>
    </div>
  );
}

export default TotalRevenues;
