import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { useShowTimesQuery } from "../../../store/showTimesApi";
import Table from "./Table";
import { cinemaNameInReport } from "../../../config/dataService/dataService";

function OnlineDetailedRevenues({
  movieReportData,
  data,
  startDate,
  endDate,
  Choices,
  date,
}) {
  const { data: ShowTimes } = useShowTimesQuery();
  
  const total = movieReportData?.reduce(
    (acc, curr) => {
      return {
        total: acc.total + curr["الاجمالى"],
        totalTickets: acc.totalTickets + curr["عدد التذاكر"],
      };
    },
    {
      total: 0,
      totalTickets: 0,
    }
  );

  return (
    <div className="flex w-full flex-col items-center p-5 ">
      <div className="self-start">
        <img alt="logo" className=" w-36" src="/logo.png"></img>
      </div>
      <div className="mb-6 flex w-[530px] flex-row-reverse items-end justify-between self-end">
        <div className="">
          <p>التاريخ - {dayjs(date).format("DD/MM/YYYY")}</p>
        </div>
        <div className="flex flex-col  items-center  ">
          <p>عرض اجمالي ارادات بالاونلاين</p>
          <p>{cinemaNameInReport}</p>
        </div>
      </div>
      <div className="flex w-full flex-wrap items-center">
        {Choices?.showTimes && Choices?.showTimes?.length > 0 ? (
          <p>
            From -{" "}
            {
              ShowTimes?.filter((showTime) =>
                Choices?.showTimes.includes(showTime._id)
              )[0].startTime
            }{" "}
            - To -{" "}
            {
              ShowTimes?.filter((showTime) =>
                Choices?.showTimes.includes(showTime._id)
              ).slice(-1)[0].startTime
            }
          </p>
        ) : (
          <p>
            {`${
              Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
            } ${Choices?.movieType || "ALL"}`}
          </p>
        )}
      </div>
      <div className=" flex w-full flex-col items-center">
        <div className="mt-5 w-full">
          {movieReportData?.length === 0 ? (
            <h1 className="text-xl font-extrabold text-red-600">
              لا يوجد بيانات للعرض
            </h1>
          ) : (
            <table className=" w-full text-center">
              <thead>
                <tr className=" h-[50px] w-full items-center justify-center  border border-black px-1">
                  {data.map((header) => (
                    <th
                      key={header}
                      className=" h-[50px] max-w-[150px] items-center justify-center  border border-black px-1"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {movieReportData?.map((row) => (
                  <tr className="w-full border  border-black " key={row._id}>
                    {data.map((header) => (
                      <td
                        key={`${header}:${row}`}
                        className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center"
                      >
                        {row[header]}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr className="w-full border  border-black ">
                  <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center">
                    {total.total}
                  </td>
                  <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center">
                    {total.totalTickets}
                  </td>
                  <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center"></td>
                  <td className=" h-[40px] w-24 max-w-[100px] items-center justify-center border border-black px-1 text-center">
                    الإجمالي
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default OnlineDetailedRevenues;
