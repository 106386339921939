import React from "react";
import { NavLink } from "react-router-dom";
import {
  FilmIcon,
  DocumentReportIcon,
  ClockIcon,
  PresentationChartBarIcon,
  UsersIcon,
  XIcon,
  LibraryIcon,
  CogIcon,
  CreditCardIcon,
  DatabaseIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const navigation = [
  {
    name: "Users",
    href: "/users",
    icon: UsersIcon,
    current: false,
    roles: ["Super Admin", "Admin", "Supervisor"],
  },
  {
    name: "Screens",
    href: "/screens",
    icon: PresentationChartBarIcon,
    current: false,
    roles: ["Super Admin", "Admin", "Supervisor"],
  },
  {
    name: "Showtimes",
    href: "/showtimes",
    icon: ClockIcon,
    current: false,
    roles: ["Super Admin", "Admin", "Supervisor"],
  },
  {
    name: "Showmovies",
    href: "/showmovies",
    icon: FilmIcon,
    current: false,
    roles: ["Super Admin", "Admin", "Supervisor"],
  },
  {
    name: "Distributors",
    href: "/distributors",
    icon: LibraryIcon,
    current: false,
    roles: ["Super Admin", "Admin", "Supervisor"],
  },
  {
    name: "Movies",
    href: "/movies",
    icon: FilmIcon,
    current: false,
    roles: ["Super Admin", "Admin", "Supervisor"],
  },
  {
    name: "Reports",
    href: "/reports",
    icon: DocumentReportIcon,
    current: false,
    roles: ["Super Admin", "Admin", "Taxes"],
  },
  {
    name: "Reservation",
    href: "/reservation",
    icon: CreditCardIcon,
    current: false,
    roles: [
      "Super Admin",
      "Admin",
      "Supervisor",
      "Reservation",
      "Finance",
      "Distributor",
    ],
  },
  {
    name: "Agents",
    href: "/agents",
    icon: UserGroupIcon,
    current: false,
    roles: ["Super Admin", "Admin"],
  },
  {
    name: "Settings",
    href: "/settings",
    icon: CogIcon,
    current: false,
    roles: ["Super Admin", "Admin"],
  },
  {
    name: "Taxs",
    href: "/taxs",
    icon: DatabaseIcon,
    current: false,
    roles: ["Super Admin", "Admin"],
  },
];

const MainNavigation = ({ sidebarOpen, setSidebarOpen, authUser }) => {
  // console.log(authUser);
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col overflow-hidden bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">
                    {navigation.map(
                      (item) =>
                        item.roles.includes(authUser?.role) && (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            className={({ isActive }) =>
                              isActive
                                ? "group flex items-center rounded-md bg-[#3B5CAD] px-2 py-2 text-base font-medium text-white"
                                : "group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-600 hover:bg-[#3B5CAD] hover:text-white"
                            }
                          >
                            <item.icon
                              className={({ isActive }) =>
                                isActive
                                  ? "mr-4 h-6 w-6 flex-shrink-0 text-gray-500"
                                  : "mr-4 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              }
                              aria-hidden="true"
                            />
                            {item.name}
                          </NavLink>
                        )
                    )}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden  md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col ">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col  overflow-y-auto bg-[#F1F4FA] pt-5">
          <div className="flex flex-shrink-0 items-center justify-center px-4">
            <img className=" mt-8 w-44" src="/logo.png" alt="logo" />
          </div>
          <div className="mt-10 flex flex-grow flex-col px-6">
            <nav className="flex-1 space-y-1 px-2 pb-4">
              {navigation.map(
                (item) =>
                  item.roles.includes(authUser?.role) && (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      // className={classNames(
                      //   item.current
                      //     ? "bg-[#3B5CAD] text-white"
                      //     : "text-gray-600 hover:bg-[#3B5CAD] hover:text-white",
                      //   "group flex items-center rounded-full px-2 py-4 text-sm font-medium"
                      // )}
                      className={({ isActive }) =>
                        isActive
                          ? "group flex items-center rounded-full bg-[#3B5CAD] px-2 py-4 text-sm font-medium text-white [&>*]:text-white"
                          : "group flex items-center rounded-full px-2 py-4 text-sm font-medium text-gray-600 hover:bg-[#3B5CAD] hover:text-white"
                      }
                    >
                      <item.icon
                        className="mx-3 h-6 w-6 flex-shrink-0 text-gray-400 "
                        // className={classNames(
                        //   item.current
                        //     ? "text-white mx-3 h-6 w-6 flex-shrink-0"
                        //     : "text-gray-400 group-hover:text-white mx-3 h-6 w-6 flex-shrink-0",
                        //   ""
                        // )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLink>
                  )
              )}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNavigation;
