import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../ui-assets/Input";
import Toggle from "../ui-assets/Toggle";
import { useDistributorsQuery } from "../../store/distributorsApi";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { ToastContainer, toast } from "react-toastify";
import DropDownList from "../ui-assets/DropDownList";

const ExperienceOptions = [
   { name: "3D", available: true },
   { name: "Standard", available: true },
   { name: "IMAX", available: true },
   { name: "D-Box", available: true },
];

const re =
   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const schema = yup
   .object({
      name: yup.string().required("Name is required"),
      code: yup.string().required("Code is required"),
      duration: yup
         .number()
         .typeError("duration must be a Number")
         .required("Duration is required"),
      price: yup
         .number()
         .typeError("price must be a Number")
         .required("Price is required"),
      description: yup.string(),
      link: yup.string().matches(re, "URL is not valid"),
      cast: yup.string().required("Cast is required"),
      type: yup.string().required("Type is required"),
      startDate: yup.date().required("Start date is required"),
      minAge: yup.string().typeError("Minimum Age must be a Number"),
   })
   .required();

const MovieForm = ({ onSubmit, movie, imageloading }) => {
   const avatarInput = useRef();
   const [imgPreview, setImgPreview] = useState();
   const [enabled, setEnabled] = useState(movie?.onlineReservation || false);
   const [egyptian, setEgyptian] = useState(movie?.egyptian || false);
   const [deleteMovie, setDeleteMovie] = useState(movie?.locked || false);
   const [dropdownState, setDropdownStste] = useState(false);
   const [distributor, setDistributor] = useState("");
   const { data: distributors } = useDistributorsQuery();
   const {
      register,
      handleSubmit,
      reset,

      formState: { errors },
   } = useForm({
      resolver: yupResolver(schema),
      mode: "all",
   });

   const [experienceType, setexperienceType] = useState([]);

   useEffect(() => {
      if (movie) {
         setexperienceType(movie.experience);
         setDeleteMovie(movie.locked);
         setEgyptian(movie.egyptian);
         setEnabled(movie.onlineReservation);
         reset({
            ...movie,
            startDate: new Date(movie.startDate).toISOString().substring(0, 10),
         });
         setDistributor(
            distributors?.filter((i) => i._id === movie.distributor)[0]
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [movie]);

   let curr = movie
      ? new Date(movie?.startDate)
      : new Date(
           new Date(
              new Date().getHours() < 7
                 ? new Date(new Date().setDate(new Date().getDate() - 1))
                 : new Date()
           )
        );
   curr.setDate(curr.getDate());
   let dateDefault = curr.toISOString().substring(0, 10);

   const randomCode =
      Math.random().toString(36).substring(2, 7).toUpperCase() +
      Math.random().toString(36).substring(2, 7).toUpperCase();

   const notify = (i, ii) =>
      ii === "error" ? toast.error(i) : toast.success(i);

   return (
      <>
         <ToastContainer />
         <Link
            to={"/movies"}
            className=" flex w-auto max-w-[600px] items-center gap-2 text-xl font-bold text-primary"
         >
            <ArrowLeftIcon className="h-5 w-5 text-primary" />
            <span className="text-primary">Back</span>
         </Link>
         <form
            onSubmit={handleSubmit(
               onSubmit.bind(this, {
                  file: imgPreview,
                  experience: experienceType,

                  egyptian: egyptian,
                  locked: deleteMovie,
                  onlineReservation: enabled,
               })
            )}
            className=" mt-6 "
         >
            <div className=" flex w-full flex-wrap gap-4 lg:flex-nowrap">
               {/* left section */}
               <div className="basis-2/5 space-y-4 md:flex-auto">
                  <Input
                     label="Movie name"
                     defaultValue={movie?.name}
                     registerName="name"
                     placeholder="Enter movie name"
                     register={register}
                  />
                  {errors.name && (
                     <span className="text-xs text-red-500">
                        {errors.name?.message}
                     </span>
                  )}

                  <>
                     <h1 className="  font-semibold text-primary">
                        Distributor
                     </h1>
                     <div className="mt-3 w-full">
                        <div className="relative z-10 flex h-11 w-full items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                           <p className="p-4">{distributor?.name}</p>
                           <button
                              onClick={() => setDropdownStste(true)}
                              type="button"
                              className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                           >
                              <i className="fa-solid fa-bars"></i>
                           </button>
                        </div>
                     </div>
                  </>
                  {/* <div className="relative  z-50 flex h-10 w-full items-center justify-between rounded-xl border border-gray-300 text-sm font-medium ">
              <input
                placeholder="Enter distributor"
                onFocus={() => setDropdownStste(true)}
                className="w-full truncate pl-3 pr-6 outline-none "
                value={distributor?.name}
              />

              <div
                className={classNames(
                  " absolute top-10 mt-4 w-full overflow-y-scroll rounded-xl bg-white shadow-md transition-all",
                  dropdownState ? " h-auto max-h-96" : "h-0"
                )}
              >
                {distributors &&
                  distributors.map((item) => (
                    <div
                      key={item._id}
                      className=" h-10 w-full cursor-pointer truncate  border-b-2 bg-white py-2 pl-2"
                      onClick={() => {
                        setDistributor(item);
                        setDropdownStste(false);
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
              </div>
            </div> */}
                  {dropdownState && (
                     <DropDownList
                        data={distributors}
                        setSelected={(i) => {
                           setDistributor(i);
                        }}
                        type="Distributors"
                        setDropdownState={(i) => setDropdownStste(false)}
                        selected={distributor}
                     />
                  )}
                  {/* Experience select */}
                  <div>
                     <span className="text-left text-sm font-semibold text-[#3B5CAD]">
                        Experience
                     </span>
                     <div className="mt-2 flex flex-row flex-wrap gap-4 ">
                        {ExperienceOptions.map((option) => (
                           <div
                              key={option.name}
                              className={`flex cursor-pointer items-center justify-center rounded-2xl border
                     ${
                        experienceType?.includes(option.name)
                           ? "bg-primary !text-white"
                           : ""
                     }
                     border-primary  px-2  py-2 text-sm font-medium text-primary   sm:flex-1`}
                              onClick={() =>
                                 experienceType?.includes(option.name)
                                    ? setexperienceType(
                                         experienceType.filter(
                                            (i) => i !== option.name
                                         )
                                      )
                                    : setexperienceType([
                                         ...experienceType,
                                         option.name,
                                      ])
                              }
                           >
                              <span>{option.name}</span>
                           </div>
                        ))}
                     </div>
                     {experienceType.length === 0 && (
                        <span className="mt-1 text-xs text-red-500">
                           Please select Experience Type
                        </span>
                     )}
                  </div>

                  {/* Code && Price */}
                  <div className="flex gap-4">
                     <div className="flex flex-col">
                        <div className="w-full">
                           <div className="w-full">
                              <label
                                 htmlFor="code"
                                 className="text-left text-sm font-semibold text-[#3B5CAD]"
                              >
                                 Code
                              </label>
                              <input
                                 value={movie?.code ? movie?.code : randomCode}
                                 {...(register && register("code"))}
                                 name={"code"}
                                 id={"code"}
                                 type="text"
                                 className=" mt-1 h-10 w-full  rounded-xl border-gray-300 shadow-lg focus:border-primary focus:ring-primary sm:text-sm"
                                 placeholder="Enter movie code"
                              />
                           </div>
                           {/* <Input
                    register={register}
                    registerName="code"
                    defaultValue={movie?.code}
                    label="Code"
                    placeholder="Enter movie code"
                  /> */}
                        </div>
                        {errors.code && (
                           <span className="mt-1 text-xs text-red-500">
                              {errors.code?.message}
                           </span>
                        )}
                     </div>
                     <div>
                        <div className="flex flex-col">
                           <p className="text-left text-sm font-semibold text-[#3B5CAD]">
                              price
                           </p>
                           <div className="relative mt-1  ">
                              <input
                                 {...register("price")}
                                 defaultValue={movie?.price}
                                 type="text"
                                 name="price"
                                 id="price"
                                 className=" h-10  rounded-xl border-gray-300 pr-12 focus:border-primary focus:ring-primary sm:text-sm"
                                 placeholder="0.00"
                                 aria-describedby="price-currency"
                              />

                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                 <span
                                    className="border-l pl-4 text-gray-500 sm:text-sm"
                                    id="price-currency"
                                 >
                                    EGP
                                 </span>
                              </div>
                           </div>
                           {errors.price && (
                              <span className="mt-1 text-xs text-red-500">
                                 {errors.price?.message}
                              </span>
                           )}
                        </div>
                     </div>
                  </div>
                  {/* Duration && Start Date */}
                  <div className="flex  gap-4">
                     <div className="w-2/3 flex-row">
                        <div className="relative flex w-1/2 flex-col rounded-md">
                           <div>
                              <p className="text-left  text-sm font-semibold text-[#3B5CAD]">
                                 minutes
                              </p>
                              <div className="flex flex-row items-center">
                                 <div className="pointer-events-none absolute top-[16px] left-0 mt-1 flex items-center pl-3">
                                    <span className="mt-4 border-r border-[#CFD8DC] pr-2 text-xs text-gray-500">
                                       Minutes
                                    </span>
                                 </div>
                                 <input
                                    {...register("duration")}
                                    defaultValue={movie?.duration}
                                    type="text"
                                    name="duration"
                                    id="duration"
                                    maxLength={3}
                                    className=" mt-1  h-10 rounded-xl border-gray-300 pl-20  focus:border-primary focus:ring-primary sm:text-sm"
                                    placeholder="000"
                                 />
                              </div>{" "}
                              {errors.duration && (
                                 <span className="mt-1 text-xs text-red-500">
                                    {errors.duration?.message}
                                 </span>
                              )}
                           </div>
                        </div>
                        <div className="relative mt-4 flex w-1/2 flex-col rounded-md">
                           <div>
                              <p className="text-left  text-sm font-semibold text-[#3B5CAD]">
                                 MinimumAge
                              </p>
                              <div className="flex flex-row  items-center">
                                 <div className="pointer-events-none absolute top-[16px] left-0 mt-1 flex items-center pl-3">
                                    <span className="mt-4 border-r border-[#CFD8DC] pr-2 text-xs text-gray-500">
                                       minimumAge
                                    </span>
                                 </div>
                                 <input
                                    {...register("minAge")}
                                    defaultValue={movie?.minAge}
                                    type="text"
                                    name="minAge"
                                    id="minAge"
                                    maxLength={3}
                                    className=" mt-1 h-10 rounded-xl border-gray-300 pl-28  focus:border-primary focus:ring-primary sm:text-sm"
                                    placeholder="+18"
                                 />
                              </div>
                              {errors.minAge && (
                                 <span className="mt-1 text-xs text-red-500">
                                    {errors.minAge?.message}
                                 </span>
                              )}
                           </div>
                        </div>
                     </div>
                     <div>
                        <label
                           htmlFor="description"
                           className="text-left text-sm font-semibold text-[#3B5CAD]"
                        >
                           Start Date
                        </label>
                        <div className="">
                           <input
                              {...register("startDate")}
                              defaultValue={dateDefault}
                              name="startDate"
                              id="startDate"
                              min={dateDefault}
                              type="date"
                              className="block h-10 w-full rounded-xl border border-gray-300 px-4 shadow-sm outline-none focus:border-primary focus:ring-primary sm:text-sm"
                           />
                           {errors.startDate && (
                              <span className="mt-1 text-xs text-red-500">
                                 {errors.startDate?.message}
                              </span>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               {/* mid sec */}
               <div className="flex-auto basis-2/5 space-y-4">
                  <div>
                     <label
                        htmlFor="description"
                        className="text-left text-sm font-semibold text-[#3B5CAD]"
                     >
                        Movie description
                     </label>
                     <div className="mt-1">
                        <textarea
                           {...register("description")}
                           defaultValue={movie?.description}
                           rows={4}
                           name="description"
                           id="description"
                           className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                        />
                     </div>
                  </div>
                  <Input
                     label="Trailer link"
                     register={register}
                     defaultValue={movie?.link}
                     registerName="link"
                     placeholder="e.g. www.youtube.com/jdhdke87362"
                  />
                  {errors.link && (
                     <span className="mt-1 text-xs text-red-500">
                        {errors.link?.message}
                     </span>
                  )}
                  <div>
                     <Input
                        label="Cast"
                        register={register}
                        defaultValue={movie?.cast}
                        registerName="cast"
                        placeholder="e.g. Johny Depp, Tom Cruise, Jennifer Aniston"
                     />
                     <span className="text-xs text-[#00C18B]">
                        Seprate names using comma
                     </span>
                  </div>
                  {errors.cast && (
                     <span className="mt-1 text-xs text-red-500">
                        {errors.cast?.message}
                     </span>
                  )}
                  <div>
                     <Input
                        label="Type"
                        register={register}
                        registerName="type"
                        defaultValue={movie?.type}
                        placeholder="e.g, Action, Fantasy, Sci-Fi"
                     />
                     <span className="text-xs text-[#00C18B]">
                        Seprate names using comma
                     </span>
                  </div>
                  {errors.type && (
                     <span className="mt-1 text-xs text-red-500">
                        {errors.type?.message}
                     </span>
                  )}
               </div>
               {/* right sec */}
               <div className="flex-auto basis-1/6">
                  {/* poster */}
                  <div>
                     <div className="flex h-[304px] flex-col items-center justify-center rounded-2xl bg-[#F1F4FA] object-cover">
                        <img
                           className=" h-full  place-self-stretch rounded-2xl object-cover"
                           src={
                              imgPreview
                                 ? URL.createObjectURL(imgPreview)
                                 : movie?.poster || "/poster.JPG"
                           }
                           alt="as"
                        />
                        <input
                           className=" ml-5 hidden rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                           type="file"
                           ref={avatarInput}
                           onChange={(e) => {
                              if (e.target.files[0].size >= 1024000)
                                 return notify("File size is too big", "error");
                              setImgPreview(
                                 (prev) => e.target.files[0] || prev
                              );
                           }}
                        />
                     </div>
                     <button
                        type="button"
                        className=" relative mt-8 w-full rounded-2xl border-2 border-primary px-6 py-2 text-lg font-medium text-[#3B5CAD]"
                        onClick={() => avatarInput.current.click()}
                     >
                        upload
                     </button>
                  </div>
                  <div className="flex w-44 items-center justify-between">
                     <div className="mt-8 flex flex-col items-center">
                        <Toggle enabled={enabled} setEnabled={setEnabled} />
                        <span className="mt-1 text-center text-xs font-semibold">
                           Online reservation
                        </span>
                     </div>
                     <div className="mt-8 flex flex-col items-center">
                        <Toggle enabled={egyptian} setEnabled={setEgyptian} />
                        <span className="mt-1 text-center text-xs font-semibold">
                           egyptian Movie
                        </span>
                     </div>
                     <div className="mt-8 flex flex-col items-center">
                        <Toggle
                           enabled={deleteMovie}
                           setEnabled={setDeleteMovie}
                        />
                        <span className="mt-1 text-center text-xs font-semibold">
                           Lock Movie
                        </span>
                     </div>
                  </div>
               </div>
            </div>
            <div className="w-full text-center">
               <button
                  type="submit"
                  // onClick={
                  //   !Experience && notify("Please select Experience Type", "error")
                  // }
                  disabled={imageloading.loading}
                  className=" mt-4 rounded-2xl bg-primary px-14  py-4 text-white"
               >
                  {imageloading.loading
                     ? `loading ${imageloading.progress}%`
                     : movie
                     ? "Edit Movie"
                     : "Add Movie"}
               </button>
            </div>
         </form>
      </>
   );
};

export default MovieForm;
