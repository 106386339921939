import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../config/dataService/dataService";

export const seatsApi = createApi({
  reducerPath: "seatsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/seats`,
    credentials: "include",
  }),
  tagTypes: ["Seats"],
  endpoints: ({ query, mutation }) => ({
    seats: query({
      query: ({ screen, showTime, date }) =>
        `?screen=${screen}&showTime=${showTime}&date=${date}`,
      providesTags: ["Seats"],
    }),
    seatsRows: query({
      query: (id) => `/screen-rows/${id}`,
      providesTags: ["Seats"],
    }),
    seat: query({
      query: (id) => `/${id}`,
      providesTags: ["Seats"],
    }),
    createSeatRow: mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Seats"],
    }),
    updateSeat: mutation({
      query: ({ _id, data }) => ({
        url: `/${_id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Seats"],
    }),
    updateSeats: mutation({
      query: (data) => ({
        url: `/update-seats`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Seats"],
    }),
    deleteSeat: mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Seats"],
    }),
  }),
});

export const {
  useSeatsQuery,
  useSeatQuery,
  useSeatsRowsQuery,
  useCreateSeatRowMutation,
  useUpdateSeatMutation,
  useDeleteSeatMutation,
  useUpdateSeatsMutation,
} = seatsApi;
