import { Loader } from "@mantine/core";
import React from "react";

const Spinner = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <Loader color="red" size="xl" variant="dots" />
    </div>
  );
};

export default Spinner;
