import { PencilAltIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import DeleteButton from "../DeleteButton";

const TableRow = ({ data, onDelete, column, editUrl }) => {
   const { _id: id } = data;
   return (
      <div className="mt-4 flex flex-col items-center justify-between rounded-2xl  border-2 border-slate-300 p-4 md:flex-row md:rounded-2xl">
         {column.map((item, i) =>
            item.component ? (
               item.value(data)
            ) : (
               <div className={item.classes} key={i}>
                  <h5 className="text-xs text-gray-400">{item.title}</h5>
                  <p className="text-sm">{item.value(data)}</p>
               </div>
            )
         )}
         <div className="flex h-full basis-auto flex-row items-center justify-end gap-2">
            {editUrl && (
               <Link
                  to={`${editUrl}/${id}`}
                  className="flex h-8 w-8 items-center justify-center rounded-lg bg-white p-1 text-[#3B5DAD] shadow-md hover:text-[#3B5DAD] focus:outline-none "
               >
                  <span className="sr-only">Edit</span>
                  <PencilAltIcon className="h-4 w-4" aria-hidden="true" />
               </Link>
            )}
            {onDelete && (
               <DeleteButton onDelete={() => onDelete(id)} item={data} />
            )}
         </div>
      </div>
   );
};

export default TableRow;
