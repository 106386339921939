import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../config/dataService/dataService";

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/reports/`,
    credentials: "include",
  }),
  tagTypes: ["Report"],
  endpoints: (builder) => ({
    reports: builder.query({
      query: ({ link, ...data }) => ({
        url: link,
        method: "POST",
        body: data,
      }),
      providesTags: ["Report"],
    }),
    report: builder.query({
      query: (id) => `movies/${id}`,
      providesTags: ["Report"],
    }),
    ticketsTaxs: builder.query({
      query: (date) => ({
        url: "ticket-taxes?page=1&limit=1000000",
        method: "POST",
        body: date,
      }),
      providesTags: ["Report"],
    }),
    glassesTaxs: builder.query({
      query: (date) => ({
        url: "glasses-taxes?page=1&limit=1000000",
        method: "POST",
        body: date,
      }),
      providesTags: ["Report"],
    }),

    agentReport: builder.query({
      query: (data) => ({
        url: `/agents-tab`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Report"],
    }),

    agentReportDetails: builder.query({
      query: (data) => ({
        url: `/agents-details-tab`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Report"],
    }),
  }),
});

export const {
  useReportsQuery,
  useReportQuery,
  useTicketsTaxsQuery,
  useGlassesTaxsQuery,
  useAgentReportQuery,
  useAgentReportDetailsQuery,
} = reportsApi;
