import React from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
   useBuyGlassesMutation,
   useGetpriceSettingsQuery,
   useRefundGlassesMutation,
} from "../../store/reservation/reservationApi";
import { uiActions } from "../../store/uiSlice";
import CustomToast from "../../utiles/CustomToast";

function classNames(...classes) {
   return classes.filter(Boolean).join(" ");
}
const paymentOptions = [
   { name: "Cash", available: true },
   { name: "Bank Card", available: true },
];

export default function RefundGlasses({ className }) {
   const dispatch = useDispatch();

   const [glasses, setglasses] = useState(0);
   const [payment, setpayment] = useState(paymentOptions[0].name);
   const [
      refundGlasses,
      {
         isSuccess: isRefundGlassesSuccess,
         isError: isRefundGlassesError,
         error,
      },
   ] = useRefundGlassesMutation();
   const { data: priceSettings } = useGetpriceSettingsQuery();
   const reservationDate = useSelector(
      (state) => state.reservation.ReservationDate
   );
   const adminToken = useSelector((state) => state.socket.adminToken);
   const buyGlassesHandler = () => {
      if (glasses > 0) {
         refundGlasses({
            refund: glasses,
            payment: payment,
            supervisor: adminToken,
         });
         setglasses(0);
         dispatch(uiActions.setRefundModal(false));
      }
   };

   const isOpen = useSelector((state) => state.ui.refundModal);

   console.log(error, isRefundGlassesSuccess, isRefundGlassesError);

   const setIsOpen = (isOpen) => {
      dispatch(uiActions.setRefundModal(isOpen));
   };

   return (
      <>
         {(isRefundGlassesError || isRefundGlassesSuccess) && (
            <CustomToast
               IsError={isRefundGlassesError}
               error={error}
               isSuccess={isRefundGlassesSuccess}
            />
         )}
         <div className={` flex items-center justify-center ${className}`}>
            <button
               type="button"
               className="h-12 w-12 rounded-2xl bg-red-500  text-2xl text-white"
               onClick={() => {
                  dispatch(uiActions.setLoginPopup(true));
                  dispatch(uiActions.setContextMenu("refundGlasses"));
               }}
            >
               -
            </button>
         </div>
         <Transition appear show={isOpen} as={Fragment}>
            <Dialog
               as="div"
               className="relative z-10"
               onClose={() => setIsOpen(false)}
            >
               <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
               >
                  <div className="fixed inset-0 bg-black bg-opacity-40" />
               </Transition.Child>

               <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                     <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                     >
                        <Dialog.Panel className="w-full max-w-[400px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                           {/* left side */}
                           {
                              <>
                                 <div className="flex">
                                    {/* right side */}
                                    <div className="  flex w-full flex-col items-center justify-center gap-2">
                                       {/*  */}

                                       {/* glasses */}
                                       <div className=" mt-4 flex w-full items-center justify-around gap-4">
                                          <h3 className="mr-4 text-sm font-semibold text-primary">
                                             3D Glasses
                                          </h3>
                                          <div className="relative flex items-center justify-center">
                                             <button
                                                onClick={() =>
                                                   setglasses((prev) =>
                                                      prev > 0 ? prev - 1 : prev
                                                   )
                                                }
                                                className="z-20 -mr-4"
                                             >
                                                <MinusCircleIcon className=" h-8 w-8 text-primary" />
                                             </button>
                                             <p className=" rounded-2xl border px-10">
                                                {glasses}
                                             </p>
                                             <button
                                                onClick={() =>
                                                   setglasses(
                                                      (prev) => prev + 1
                                                   )
                                                }
                                                className="z-20 -ml-4"
                                             >
                                                <PlusCircleIcon className=" h-8 w-8 text-[#00C18B]" />
                                             </button>
                                          </div>
                                       </div>
                                       {/*  */}
                                       <div className="flex w-full flex-col text-center">
                                          <span className=" text-xs text-[#607D8B]">
                                             Price per Glasses
                                          </span>
                                          <p className="text-base font-semibold">
                                             {glasses *
                                                priceSettings?.glassesPrice}{" "}
                                             EGP
                                          </p>
                                       </div>
                                       <div>
                                          <h3 className="mt-4 text-sm font-semibold text-primary">
                                             Payment method
                                          </h3>

                                          <div className="flex flex-row items-center gap-14">
                                             <RadioGroup
                                                value={
                                                   payment
                                                      ? payment
                                                      : paymentOptions[0].name
                                                }
                                                onChange={setpayment}
                                                className="mt-2   text-left"
                                             >
                                                <div className="flex flex-row flex-wrap gap-2">
                                                   {paymentOptions.map(
                                                      (option) => (
                                                         <RadioGroup.Option
                                                            key={option.name}
                                                            value={option.name}
                                                            className={({
                                                               active,
                                                               checked,
                                                            }) =>
                                                               classNames(
                                                                  active
                                                                     ? "bg-primary text-white"
                                                                     : "text-primary",
                                                                  checked
                                                                     ? "border-transparent bg-primary !text-white hover:bg-primary"
                                                                     : "border-gray-200 bg-white   ",
                                                                  "font-base flex  h-10 w-[126px] cursor-pointer items-center justify-center rounded-2xl border border-primary px-6 py-2 text-sm "
                                                               )
                                                            }
                                                         >
                                                            <RadioGroup.Label as="span">
                                                               {option.name}
                                                            </RadioGroup.Label>
                                                         </RadioGroup.Option>
                                                      )
                                                   )}
                                                </div>
                                             </RadioGroup>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mt-8 flex-auto pb-8">
                                    <button
                                       type="button"
                                       className={`absolute inset-x-0 bottom-0 h-14 w-full justify-center rounded-md border border-transparent  bg-primary px-4 py-2 text-sm font-medium text-white`}
                                       onClick={buyGlassesHandler}
                                    >
                                       Confirm Refund
                                    </button>
                                 </div>
                              </>
                           }
                        </Dialog.Panel>
                     </Transition.Child>
                  </div>
               </div>
            </Dialog>
         </Transition>
      </>
   );
}
