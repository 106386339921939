import React from "react";
import { Link } from "react-router-dom";
import { useDeleteShowTimeMutation } from "../../store/showTimesApi";
import CustomToast from "../../utiles/CustomToast";
import DeleteButton from "../ui-assets/DeleteButton";

const ShowtimeCard = ({ showTime }) => {
  const checked = showTime.status !== "Available";
  const [deletedShowTime, { isError, isSuccess, error }] =
    useDeleteShowTimeMutation();
  const handleDelete = (id) => {
    deletedShowTime(id);
  };

  return (
    <>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/showtimes"
        />
      )}
      <div
        className={`${
          checked ? " cursor-not-allowed bg-[#CFD8DC]" : "bg-white"
        }  flex h-[108px] w-[199px] flex-row justify-between rounded-2xl border border-[#CFD8DC] p-4`}
      >
        <div className="flex h-full flex-col justify-between">
          <h3 className="text-2xl font-bold">{showTime.startTime}</h3>
          <p className=" font-medium text-[#00C18B]">{showTime.price} EGP</p>
        </div>
        <div className=" flex h-full flex-col justify-between ">
          <Link
            type="button"
            to={`/edit-showtime/${showTime._id}`}
            disabled={checked}
            className="flex h-8 w-8 items-center justify-center rounded-lg bg-white p-1 text-[#3B5DAD] shadow-md hover:text-[#3B5DAD] focus:outline-none "
          >
            <span className="sr-only">Edit</span>
            <img src="/edit.svg" alt="edit" />
          </Link>
          <DeleteButton onDelete={() => handleDelete(showTime._id)} item="" />
        </div>
      </div>
    </>
  );
};

export default ShowtimeCard;
