import { Link } from "react-router-dom";
import ReportBar from "../../components/ui-assets/ReportBar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useAuthUserQuery } from "../../store/authUserApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const reportsData = [
  {
    reportsType: "Revenues",
    reportsInfo: [
      {
        reportType: "Total revenues",
        reportAction: "View",
        title: "حافظه توريد اجمالي",
        roles: ["Super Admin", "Admin", "Taxes"],
      },

      {
        reportType: "Detailed revenues",
        reportAction: "View",
        title: "عرض اجمالي ارادات باليوم ",
        roles: ["Super Admin", "Admin"],
      },
      {
        reportType: "Detailed revenues 2",
        reportAction: "View",
        title: "عرض اجمالي ارادات باليوم 2",
        roles: ["Super Admin", "Admin"],
      },
      {
        reportType: "online Detailed revenues",
        reportAction: "View",
        title: "عرض اجمالي ارادات بالاونلاين ",
        roles: ["Super Admin", "Admin"],
      },
      {
        reportType: "Movies revenues",
        reportAction: "View",
        title: "بوردروه ",
        roles: ["Super Admin", "Admin"],
      },
      {
        reportType: "Total Revenues By Day",
        reportAction: "View",
        title: "كشف البردروهات بالإجمالي باليوم",
        roles: ["Super Admin", "Admin"],
      },
      {
        reportType: "Screens revenues",
        reportAction: "View",
        title: "حافظه توريد تفصيلي بالقاعات",
        roles: ["Super Admin", "Admin", "Taxes"],
      },
      {
        reportType: "Distributors revenues",
        reportAction: "View",
        title: "عرض تفصيلي ارادات بالموزع",
        roles: ["Super Admin", "Admin"],
      },
    ],
  },
];

const ReportsList = () => {
  const [reportsAllData, setReportsAllData] = useState(reportsData);
  const { data: user, isLoading } = useAuthUserQuery();
  const userRole = user?.role;

  useEffect(() => {
    if (userRole !== "Taxes") {
      setReportsAllData(
        reportsAllData.concat([
          {
            reportsType: "Tickets",
            reportsInfo: [
              {
                reportType: "Reprinted tickets",
                reportAction: "View",
                title: "التذاكر المعاد طباعتها",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Cancelled tickets",
                reportAction: "View",
                title: "كشف بما تم الغائه",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Invitation",
                reportAction: "View",
                title: "كشف بالدعوات",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Exchange Report",
                reportAction: "View",
                title: "كشف بالتبديل",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "online",
                reportAction: "View",
                title: "كشف حساب اونلاين",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "online for bank",
                reportAction: "View",
                title: "كشف حساب اونلاين للبنك",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: 'online paymob for bank',
                reportAction: 'View',
                title: 'كشف حساب اونلاين بايموب للبنك',
                roles: ['Super Admin', 'Admin'],
              },
            ],
          },
          {
            reportsType: "Agents",
            reportsInfo: [
              {
                reportType: "Total sales",
                reportAction: "View",
                title: "كشف حساب الشباك اجمالي",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Total sales2",
                reportAction: "View",
                title: "2 كشف حساب الشباك اجمالي",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Total sales inadvance",
                reportAction: "View",
                title: "كشف حساب الشباك اجمالي مقدم",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Total sales inprevious",
                reportAction: "View",
                title: "كشف حساب الشباك اجمالي مؤخر",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Total sales inadvance2",
                reportAction: "View",
                title: "2 كشف حساب الشباك اجمالي مقدم",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Total sales inprevious2",
                reportAction: "View",
                title: "2 كشف حساب الشباك اجمالي مؤخر",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Detailed sales",
                reportAction: "View",
                title: "2 كشف حساب العاملين تفصيلي",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "Detailed sales 3",
                reportAction: "View",
                title: "كشف حساب العاملين تفصيلي",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "glasses-report",
                reportAction: "View",
                title: "كشف حساب نظارات",
                roles: ["Super Admin", "Admin"],
              },
              {
                reportType: "refund-glasses-report",
                reportAction: "View",
                title: "كشف بما تم الغائه من  النظارات",
                roles: ["Super Admin", "Admin"],
              },
            ],
          },
        ])
      );
    }
  }, [userRole]);

  return (
    <>
      <>
        <div className="flex w-full flex-col items-center">
          <Helmet>
            <title>Reports List</title>
          </Helmet>
          <div className=" flex w-full flex-row justify-around pt-[50px]">
            {reportsAllData.map((report, i) => (
              <div
                key={i}
                className={classNames(
                  i === 2 ? "" : "border-r-[3px] pr-[31px]",
                  "  w-[30%] "
                )}
              >
                <h1 className="w-full text-center font-bold">
                  {report.reportsType}
                </h1>
                {report.reportsInfo
                  .filter((data) => data.roles.includes(userRole))
                  .map((data, i) => (
                    <React.Fragment key={i}>
                      {data.reportAction === "View" ? (
                        <Link
                          key={i}
                          to={`/reports/${data.reportType}`}
                          state={data.reportType}
                        >
                          <ReportBar key={i + "5546REP"} type={data.title} />
                        </Link>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            ))}
          </div>
          <h1 className="mt-[40px] text-xl font-bold">{`${moment().format(
            "h:mm A, dddd "
          )} , ${moment().format("DD MMM, YYYY")}`}</h1>
        </div>
      </>
    </>
  );
};

export default ReportsList;
