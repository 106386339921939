import React from "react";
import Table from "./Table";
import { cinemaNameInReport } from "../../../config/dataService/dataService";
import { useLocation } from "react-router-dom";

function TotalSales({ movieReportData, data, startDate, endDate, Choices }) {
  const location = useLocation().pathname;
  const total = movieReportData?.reduce(
    (acc, curr) => {
      return {
        TotalTickets: acc.TotalTickets + curr["جمله التذاكر المباعه"],
        Total: acc.Total + curr["عدد التذاكر"],
        cash: acc.cash + curr["نقدي"],
        totalCash: acc.totalCash + curr["اجمالي النقدي"],
        card: acc.card + curr["بطاقات مصرفية"],
        totalCard: acc.totalCard + curr["اجمالي البطاقات المصرفية"],
      };
    },
    {
      TotalTickets: 0,
      Total: 0,
      totalCash: 0,
      cash: 0,
      card: 0,
      totalCard: 0,
    }
  );
  return (
    <div className="flex w-full flex-col items-center p-5 ">
      <div className="self-start">
        <img alt="logo" className=" w-36" src="/logo.png"></img>
      </div>
      <div className="mb-6 flex w-[530px] flex-row-reverse items-end justify-between self-end">
        <div className="">
          <p>
            التاريخ من {startDate} الي {endDate}
          </p>
        </div>
        <div className="flex flex-col  items-center border-b-8 ">
          <p>{cinemaNameInReport}</p>
          <p>
            كشف حساب الشباك اجمالي{" "}
            {location.includes("Total%20sales2") && <span>2</span>}
            {location.includes("Total%20sales%20inadvance") && (
              <span>مقدم</span>
            )}
            {location.includes("Total%20sales%20inadvance2") && <span>2</span>}
            {location.includes("Total%20sales%20inprevious") && (
              <span>مؤخر</span>
            )}
            {location.includes("Total%20sales%20inprevious2") && <span>2</span>}
          </p>
        </div>
      </div>
      <div className="my-2 w-full  text-start ">
        <p>
          {`${
            Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
          } ${Choices?.movieType || "ALL"}`}
        </p>
      </div>
      <div className=" flex w-full flex-col items-center">
        <div className="mt-5 w-full">
          {movieReportData?.length === 0 ? (
            <h1 className="text-xl  font-extrabold text-red-600">
              لا يوجد بيانات للعرض
            </h1>
          ) : (
            <>
              <Table
                movieReportData={movieReportData}
                data={data}
                total={total}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TotalSales;
