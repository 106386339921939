import React from "react";
import { useLocation } from "react-router-dom";
import Table from "./Table";
import { cinemaNameInReport } from "../../../config/dataService/dataService";

function CancelTickets({ data, movieReportData, startDate, endDate, Choices }) {
  const location = useLocation();
  return (
    <div className="flex w-full flex-col items-center p-5 ">
      <div className=" my-2 self-start">
        <img alt="logo" className=" w-36" src="/logo.png"></img>
      </div>
      <div className=" my-2 text-center ">
        <p>{cinemaNameInReport}</p>
        {location.pathname === "/viewReport/Cancelled%20tickets" ? (
          <p>كشف ب ما تم الغاءه</p>
        ) : (
          <p>كشف بالدعوات</p>
        )}{" "}
      </div>
      <div className=" my-2 self-end">
        <p>
          التاريخ {startDate} الي {endDate}
        </p>
      </div>
      <div className="my-2 w-full  text-start ">
        <p>
          {`${
            Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
          } ${Choices?.movieType || "ALL"}`}
        </p>
      </div>
      <div className=" my-2 flex w-full flex-col">
        {movieReportData?.length === 0 ? (
          <h1 className="text-xl font-extrabold text-red-600">
            لا يوجد بيانات للعرض
          </h1>
        ) : (
          <Table movieReportData={movieReportData} data={data} />
        )}
      </div>
    </div>
  );
}

export default CancelTickets;
