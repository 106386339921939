import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../config/dataService/dataService";

export const screensApi = createApi({
  reducerPath: "screensApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: "include",
  }),
  tagTypes: ["Screens"],
  endpoints: ({ query, mutation }) => ({
    screens: query({
      query: () => "screens",
      providesTags: ["Screens"],
    }),
    screen: query({
      query: (id) => `screens/${id}`,
      providesTags: ["Screens"],
    }),
    createScreen: mutation({
      query: (data) => ({
        url: "screens",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Screens"],
    }),
    updateScreen: mutation({
      query: ({ id, ...data }) => ({
        url: `screens/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Screens"],
    }),
    deleteScreen: mutation({
      query: (id) => ({
        url: `screens/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Screens"],
    }),
  }),
});

export const {
  useScreensQuery,
  useScreenQuery,
  useCreateScreenMutation,
  useUpdateScreenMutation,
  useDeleteScreenMutation,
} = screensApi;
