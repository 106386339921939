import React, { useState } from "react";
import { useDistributorsQuery } from "../../store/distributorsApi";
import { useMoviesQuery } from "../../store/moviesApi";
import { useScreensQuery } from "../../store/screensApi";
import { useShowTimesQuery } from "../../store/showTimesApi";
import DropDownList from "../ui-assets/DropDownList";
import { DatePicker } from "@mantine/dates";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReportMovieModal from "../ui-assets/ReportMovieModal";
import { useUsersQuery } from "../../store/usersApi";

function MoviesRevenuesChoose({ setChoices }) {
  const { path } = useParams();
  const [filterField, setfilterField] = useState("Active Movies");

  const [searchValue, setSearchValue] = useState("");

  const { data: users, isLoading } = useUsersQuery({
    search: "",
    role: "",
  });
  const { data: screens } = useScreensQuery();
  const { data: ShowTimes } = useShowTimesQuery();
  const { data: movies, refetch } = useMoviesQuery(
    `${
      filterField === "Active Movies" ? "" : "locked=true"
    }&search=${searchValue}`
  );
  const { data: distributors } = useDistributorsQuery();

  const [data, setData] = useState({
    seatsType: ["Standard", "VIP", "IMAX"],
    movieType: undefined,
    group: "Day",
  });

  const [showTimes, setShowTimes] = useState();
  const [value, setValue] = useState(new Date());
  const [valueOne, setValueOne] = useState(
    new Date(
      new Date().getHours() < 7
        ? new Date(new Date().setDate(new Date().getDate() - 1))
        : new Date()
    )
  );
  const [valueTwo, setValueTwo] = useState(
    new Date(
      new Date().getHours() < 7
        ? new Date(new Date().setDate(new Date().getDate() - 1))
        : new Date()
    )
  );

  useEffect(() => {
    setValue([
      new Date(new Date(valueOne).setHours(7, 0, 0, 0)),

      new Date(
        new Date(
          new Date(valueTwo).setDate(new Date(valueTwo).getDate() + 1)
        ).setHours(7, 0, 0, 0)
      ),
    ]);
  }, [valueOne, valueTwo]);

  useEffect(() => {
    if (ShowTimes) {
      const showTimesTest =
        ShowTimes &&
        [...ShowTimes]?.sort((a, b) => {
          return (
            new Date(`8/10/2022, ${a.startTime}`).getTime() -
            new Date(`8/10/2022, ${b.startTime}`).getTime()
          );
        });
      const breakingPoing = showTimesTest?.findIndex(
        (i) =>
          new Date(`8/10/2022, ${i.startTime}`).getTime() >=
          new Date(`8/10/2022, 07:00 AM`).getTime()
      );
      const lastTimes = showTimesTest?.splice(0, breakingPoing);
      setShowTimes([...showTimesTest, ...lastTimes]);
    }
  }, [ShowTimes]);

  const [timeFromTo, setTimeFromTo] = useState();

  useEffect(() => {
    setTimeFromTo(
      showTimes && {
        timeFrom: showTimes[0]._id,
        timeTo: showTimes[showTimes.length - 1]._id,
      }
    );
  }, [showTimes]);

  useEffect(() => {
    setChoices(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    [
      "Total Revenues By Day",
      "Detailed revenues",
      "Detailed revenues 2",
      "online Detailed revenues",
    ].includes(path) && setData({ ...data, date: value });
    [
      "Total revenues",
      "Reprinted tickets",
      "Cancelled tickets",
      "Invitation",
      "Exchange Report",
      "refund-glasses-report",
    ].includes(path) && setData({ ...data, data: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    showTimes?.filter(
      (_, index) =>
        index >= showTimes?.findIndex((i) => i._id === timeFromTo?.timeFrom) &&
        index <= showTimes?.findIndex((i) => i._id === timeFromTo?.timeTo)
    )?.length !== ShowTimes?.length &&
      setData({
        ...data,
        showTimes: showTimes
          ?.filter(
            (_, index) =>
              index >=
                showTimes?.findIndex((i) => i._id === timeFromTo?.timeFrom) &&
              index <= showTimes?.findIndex((i) => i._id === timeFromTo?.timeTo)
          )
          .map((i) => i._id),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFromTo]);

  const [popUpsState, setPopUpsState] = useState({
    movies: false,
    screens: false,
    disrupter: false,
    timeFrom: false,
    timeTo: false,
    users: false,
  });
  useEffect(() => {
    refetch();
  }, [filterField, refetch, searchValue]);

  return (
    <>
      <div className="flex w-full flex-col items-start">
        <div className="mt-3 flex h-auto w-full flex-row gap-10 self-center  rounded-xl ">
          <div className=" flex h-full w-full flex-wrap  justify-around   gap-5   px-4">
            {/* movies */}
            {![
              "Total sales",
              "Total sales2",
              "glasses-report",
              "Total sales",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 3",

              "Total revenues",
              "Total Revenues By Day",
              "Screens revenues",
              "Distributors revenues",

              "Reprinted tickets",
              "Cancelled tickets",
              "Invitation",
              "Exchange Report",
              "online",
              "online for bank",
              "online paymob for bank",

              "Total sales",
              "Total sales2",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 203",
              "glasses-report",
              "refund-glasses-report",
            ].includes(path) && (
              <div className="flex min-h-[40px] w-[48%] flex-col">
                <div className="flex w-full items-center justify-between">
                  <h1 className="  font-semibold  text-primary">Movie</h1>
                  {data.movies && (
                    <i
                      onClick={() => setData({ ...data, movies: undefined })}
                      className="fa-solid fa-xmark mr-3 cursor-pointer text-gray-500"
                    ></i>
                  )}
                </div>
                <div className="mt-3 w-full">
                  <div className="min-h-11 relative z-10 flex w-full items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                    <p className="p-4">
                      {movies
                        ?.filter(
                          (i) => data.movies && data.movies.includes(i._id)
                        )
                        .map((i) => i.name)
                        .join(" , ")}
                    </p>

                    <button
                      onClick={() =>
                        setPopUpsState({ ...popUpsState, movies: true })
                      }
                      className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                    >
                      <i className="fa-solid fa-bars"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* Screens */}
            {![
              "Total Revenues By Day",
              "Total sales",
              "Total sales2",
              "glasses-report",
              "Total sales",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 3",

              "Total revenues",
              "Total Revenues By Day",
              "Screens revenues",
              "Distributors revenues",

              "Reprinted tickets",
              "Cancelled tickets",
              "Invitation",
              "Exchange Report",
              "online",
              "online for bank",
              "online paymob for bank",

              "Total sales",
              "Total sales2",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 203",
              "glasses-report",
              "refund-glasses-report",
            ].includes(path) && (
              <div className="flex min-h-[40px] w-[48%] flex-col">
                <div className="flex w-full items-center justify-between">
                  <h1 className="  font-semibold  text-primary">Screens</h1>
                  {data.screens && (
                    <i
                      onClick={() => setData({ ...data, screens: undefined })}
                      className="fa-solid fa-xmark mr-3 cursor-pointer text-gray-500"
                    ></i>
                  )}
                </div>
                <div className="mt-3 w-full">
                  <div className="min-h-11 relative z-10 flex w-full items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                    <p className="p-4">
                      {screens
                        ?.filter(
                          (i) => data.screens && data.screens?.includes(i._id)
                        )
                        .map((i) => i.name)
                        .join(" , ")}
                    </p>
                    <button
                      onClick={() =>
                        setPopUpsState({ ...popUpsState, screens: true })
                      }
                      className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                    >
                      <i className="fa-solid fa-bars"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* distributors */}
            {![
              "Total Revenues By Day",
              "Total sales",
              "Total sales2",
              "glasses-report",
              "Total sales",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 3",

              "Total revenues",
              "Total Revenues By Day",
              "Screens revenues",
              "Distributors revenues",

              "Reprinted tickets",
              "Cancelled tickets",
              "Invitation",
              "Exchange Report",
              "online",
              "online for bank",
              "online paymob for bank",

              "Total sales",
              "Total sales2",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 203",
              "glasses-report",
              "refund-glasses-report",
            ].includes(path) && (
              <div className="flex min-h-[40px] w-[48%] flex-col">
                <div className="flex w-full items-center justify-between">
                  <h1 className="  font-semibold  text-primary">
                    distributors
                  </h1>
                  {data.distributors && (
                    <i
                      onClick={() =>
                        setData({ ...data, distributors: undefined })
                      }
                      className="fa-solid fa-xmark mr-3 cursor-pointer text-gray-500"
                    ></i>
                  )}
                </div>
                <div className="mt-3 w-full">
                  <div className="relative z-10 flex min-h-[40px] w-full items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                    <p className="p-4">
                      {distributors
                        ?.filter(
                          (i) =>
                            data.distributors &&
                            data.distributors?.includes(i._id)
                        )
                        .map((i) => i.name)
                        .join(" , ")}
                    </p>
                    {!data.movies && (
                      <button
                        onClick={() =>
                          setPopUpsState({
                            ...popUpsState,
                            distributors: true,
                          })
                        }
                        className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                      >
                        <i className="fa-solid fa-bars"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* Users */}
            {[
              "Total sales",
              "Total sales2",
              "glasses-report",
              "Total sales",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 3",
            ].includes(path) && (
              <div
                className={`flex min-h-[40px] ${
                  [
                    "Total sales",
                    "Total sales2",
                    "glasses-report",
                    "Total sales",
                    "Total sales inadvance",
                    "Total sales inadvance2",
                    "Total sales inprevious",
                    "Total sales inprevious2",
                    "Detailed sales",
                    "Detailed sales 3",
                  ].includes(path)
                    ? "w-[100%]"
                    : "w-[48%]"
                } flex-col`}
              >
                <div className="flex w-full items-center justify-between">
                  <h1 className="  font-semibold  text-primary">Users</h1>
                  {data.users && (
                    <i
                      onClick={() => setData({ ...data, users: undefined })}
                      className="fa-solid fa-xmark mr-3 cursor-pointer text-gray-500"
                    ></i>
                  )}
                </div>
                <div className="mt-3 w-full">
                  <div className="relative z-10 flex min-h-[40px] w-full items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                    <p className="p-4">
                      {users
                        ?.filter(
                          (i) => data.users && data.users?.includes(i._id)
                        )
                        .map((i) => i.name)
                        .join(" , ")}
                    </p>
                    {!data.movies && (
                      <button
                        onClick={() =>
                          setPopUpsState({
                            ...popUpsState,
                            users: true,
                          })
                        }
                        className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                      >
                        <i className="fa-solid fa-bars"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* Time From To */}
            {!["Total Revenues By Day",
              "glasses-report",
              "Total revenues",
              "Total Revenues By Day",
              "Screens revenues",
              "Distributors revenues",

              "Reprinted tickets",
              "Cancelled tickets",
              "Invitation",
              "Exchange Report",
              "online",
              "online for bank",
              "online paymob for bank",

              "Total sales",
              "Total sales2",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 203",
              "glasses-report",
              "refund-glasses-report",].includes(path) && (
              <div
                className={`flex min-h-[40px] ${
                  [
                    "Total sales",
                    "Total sales2",
                    "Total sales inadvance",
                    "Total sales inadvance2",
                    "Total sales inprevious",
                    "Total sales inprevious2",
                    "Detailed sales",
                    "Detailed sales 3",
                  ].includes(path)
                    ? "w-[100%]"
                    : "w-[48%]"
                } flex-col`}
              >
                <h1 className="  font-semibold  text-primary">Time From To</h1>
                <div className="mt-3 w-full">
                  <div className="relative z-10 flex max-h-[40px] w-full items-center justify-between rounded-xl border-0 text-sm font-medium shadow-lg">
                    <>
                      <p className="p-4">
                        {ShowTimes?.filter(
                          (i) => timeFromTo?.timeFrom === i._id
                        )
                          .map((i) => i.startTime)
                          .join(" , ")}
                      </p>
                      <button
                        onClick={() =>
                          setPopUpsState({ ...popUpsState, timeFrom: true })
                        }
                        className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                      >
                        <i className="fa-solid fa-bars"></i>
                      </button>
                    </>
                    <h1 className="  font-semibold  text-primary">To</h1>
                    <>
                      <p className="p-4">
                        {ShowTimes?.filter((i) => timeFromTo?.timeTo === i._id)
                          .map((i) => i.startTime)
                          .join(" , ")}
                      </p>
                      <button
                        onClick={() =>
                          setPopUpsState({ ...popUpsState, timeTo: true })
                        }
                        className="rounded-lg   p-[4px] pr-4 text-sm text-gray-600 hover:text-gray-900"
                      >
                        <i className="fa-solid fa-bars"></i>
                      </button>
                    </>
                  </div>
                </div>
              </div>
            )}
            {/* Seats Type */}
              <div className="flex min-h-[40px] w-[48%] flex-col items-center gap-3">
                <h1 className="  font-semibold  text-primary">Seats Type</h1>
                <div className="flex w-40 flex-row items-center justify-around text-primary gap-1">
                
                  <div
                    onClick={() =>
                      setData({
                        ...data,
                        seatsType: data.seatsType.includes("IMAX")
                          ? [...data.seatsType.filter((i) => i !== "IMAX")]
                          : [...data.seatsType, "IMAX"],
                      })
                    }
                    className={`${
                      data.seatsType?.includes("IMAX") && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    IMAX
                  </div>
                  <div
                    onClick={() =>
                      setData({
                        ...data,
                        seatsType: data.seatsType.includes("VIP")
                          ? [...data.seatsType.filter((i) => i !== "VIP")]
                          : [...data.seatsType, "VIP"],
                      })
                    }
                    className={`${
                      data.seatsType?.includes("VIP") && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    VIP
                  </div>
                  <div
                    onClick={() =>
                      setData({
                        ...data,
                        seatsType: data.seatsType.includes("Standard")
                          ? [...data.seatsType.filter((i) => i !== "Standard")]
                          : [...data.seatsType, "Standard"],
                      })
                    }
                    className={`${
                      data.seatsType?.includes("Standard") &&
                      "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    Standard
                  </div>
                </div>
              </div>
            {/* Movies Type */}
              <div className="flex min-h-[40px] w-[48%] flex-col items-center gap-3">
                <h1 className="  font-semibold  text-primary">Movies Type</h1>
                <div className="flex flex-wrap items-center justify-between  gap-2  text-primary ">
                  <div
                    onClick={() =>
                      setData({
                        ...data,
                        movieType: "Egyptian",
                      })
                    }
                    className={`${
                      data.movieType === "Egyptian" && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    Egyptian
                  </div>{" "}
                  :{" "}
                  <div
                    onClick={() =>
                      setData({
                        ...data,
                        movieType: "Foreign",
                      })
                    }
                    className={`${
                      data.movieType === "Foreign" && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    Foreign
                  </div>{" "}
                  :{" "}
                  <div
                    onClick={() =>
                      setData({
                        ...data,
                        movieType: undefined,
                      })
                    }
                    className={`${
                      data.movieType === undefined && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    All
                  </div>
                </div>
              </div>
            {/* Group By */}
            {![
              "Total Revenues By Day",
              "Total sales",
              "Total sales2",
              "glasses-report",
              "Total sales",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 3",

              "Total revenues",
              "Total Revenues By Day",
              "Screens revenues",
              "Distributors revenues",

              "Reprinted tickets",
              "Cancelled tickets",
              "Invitation",
              "Exchange Report",
              "online",
              "online for bank",
              "online paymob for bank",

              "Total sales",
              "Total sales2",
              "Total sales inadvance",
              "Total sales inadvance2",
              "Total sales inprevious",
              "Total sales inprevious2",
              "Detailed sales",
              "Detailed sales 203",
              "glasses-report",
              "refund-glasses-report",
            ].includes(path) && (
              <div
                className={`flex min-h-[40px] ${
                  [
                    "Total sales",
                    "Total sales2",
                    "Total sales inadvance",
                    "Total sales inadvance2",
                    "Total sales inprevious",
                    "Total sales inprevious2",
                  ].includes(path)
                    ? "w-[100%]"
                    : "w-[48%]"
                } flex-col items-center gap-3`}
              >
                <h1 className="  font-semibold  text-primary">Group by</h1>
                <div className="flex flex-wrap items-center justify-between  gap-2  text-primary ">
                  <div
                    onClick={() =>
                      setData({
                        ...data,
                        group: "Day",
                      })
                    }
                    className={`${
                      data.group === "Day" && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    Day
                  </div>{" "}
                  :{" "}
                  <div
                    onClick={() =>
                      setData({
                        ...data,
                        group: "Week",
                      })
                    }
                    className={`${
                      data.group === "Week" && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    Week
                  </div>{" "}
                  :{" "}
                  <div
                    onClick={() =>
                      setData({
                        ...data,
                        group: "Month",
                      })
                    }
                    className={`${
                      data.group === "Month" && "bg-primary text-white"
                    } cursor-pointer rounded-xl border border-primary  px-3`}
                  >
                    Month
                  </div>
                  {["Detailed revenues", "Detailed revenues 2"].includes(path) && (
                    <>
                      <div> : </div>
                      <div
                        onClick={() =>
                          setData({
                            ...data,
                            group: "None",
                          })
                        }
                        className={`${
                          data.group === "None" && "bg-primary text-white"
                        } cursor-pointer rounded-xl border border-primary  px-3`}
                      >
                        None
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {/* start date & end date */}
            {["Detailed revenues", "Detailed revenues 2", "online Detailed revenues"].includes(path) && (
              <>
                <div>
                  <h1 className="  font-semibold  text-primary">Start date</h1>
                  <DatePicker
                    value={valueOne}
                    onChange={setValueOne}
                    withAsterisk
                    onClear={() => setValueOne(new Date())}
                    inputFormat="DD MMM, YYYY"
                  />
                </div>
                <div>
                  <h1 className="  font-semibold  text-primary">End date</h1>
                  <DatePicker
                    value={valueTwo}
                    onChange={setValueTwo}
                    withAsterisk
                    // clearable={false}
                    onClear={() => setValueTwo(new Date())}
                    inputFormat="DD MMM, YYYY"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {popUpsState.movies && (
        <ReportMovieModal
          data={movies}
          setSelected={(i) =>
            setData({
              ...data,
              movies: !data?.movies
                ? [i._id]
                : data?.movies?.includes(i._id)
                ? data?.movies?.filter((j) => j !== i._id)
                : [...data?.movies, i._id],
            })
          }
          type="Movies"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, movies: false })
          }
          selected={data?.movies}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          setfilterField={setfilterField}
        />
      )}
      {popUpsState.screens && (
        <DropDownList
          data={screens}
          setSelected={(i) =>
            setData({
              ...data,
              screens: !data?.screens
                ? [i._id]
                : data?.screens?.includes(i._id)
                ? data?.screens?.filter((j) => j !== i._id)
                : [...data?.screens, i._id],
            })
          }
          type="Screens"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, screens: false })
          }
          selected={data?.screens}
        />
      )}
      {popUpsState.distributors && (
        <DropDownList
          data={distributors}
          setSelected={(i) =>
            setData({
              ...data,
              distributors: !data?.distributors
                ? [i._id]
                : data?.distributors?.includes(i._id)
                ? data?.distributors?.filter((j) => j !== i._id)
                : [...data?.distributors, i._id],
            })
          }
          type="Distributors"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, distributors: false })
          }
          selected={data?.distributors}
        />
      )}
      {popUpsState.users && (
        <DropDownList
          data={users}
          setSelected={(i) =>
            setData({
              ...data,
              users: !data?.users
                ? [i._id]
                : data?.users?.includes(i._id)
                ? data?.users?.filter((j) => j !== i._id)
                : [...data?.users, i._id],
            })
          }
          type="Users"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, users: false })
          }
          selected={data?.users}
        />
      )}
      {popUpsState.timeFrom && (
        <DropDownList
          data={showTimes}
          setSelected={(i) =>
            setTimeFromTo({
              ...timeFromTo,
              timeFrom: i._id,
            })
          }
          type="Time Show"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, timeFrom: false })
          }
          selected={timeFromTo.timeFrom}
        />
      )}
      {popUpsState.timeTo && (
        <DropDownList
          data={showTimes}
          setSelected={(i) =>
            setTimeFromTo({
              ...timeFromTo,
              timeTo: i._id,
            })
          }
          type="Time Show"
          setDropdownState={(i) =>
            setPopUpsState({ ...popUpsState, timeTo: false })
          }
          selected={timeFromTo.timeTo}
        />
      )}
    </>
  );
}

export default MoviesRevenuesChoose;
