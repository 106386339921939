import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Spinner from "../components/ui-assets/Spinner";
import { useAuthUserQuery } from "../store/authUserApi";

const RolesRoutes = ({ allowedRoles }) => {
  const { data: user, isLoading } = useAuthUserQuery();
  const userRole = user?.role;
  const location = useLocation();

  return isLoading ? (
    <Spinner />
  ) : allowedRoles.includes(userRole) ? (
    <Outlet />
  ) : (
    <Navigate
      to={
        userRole === "Scanner"
          ? "/scan"
          : userRole === "Taxes"
          ? "/reports"
          : userRole === "Kiosk"
          ? "/selfServices"
          : "/reservation"
      }
      state={{ from: location }}
      replace
    />
  );
};

export default RolesRoutes;
