import MovieForm from "../../components/movies/MovieForm";
import { useParams } from "react-router-dom";
import {
  useCreateMovieMutation,
  useMovieQuery,
  useUpdateMovieMutation,
} from "../../store/moviesApi";
import { uploadFileS3 } from "../../utiles/uploadFileS3";
import { useState } from "react";
import CustomToast from "../../utiles/CustomToast";
import { Helmet } from "react-helmet";

const MoviePage = () => {
  const { id } = useParams();
  const [imageloading, setImageLoading] = useState({
    loading: false,
    progress: 0,
  });
  const { data: movie } = useMovieQuery(id, { skip: !!!id });
  const [createMovie, { isSuccess, isError, error }] = useCreateMovieMutation();
  const [
    updateMovie,
    { isSuccess: updateIsSuccess, isError: updateIsError, error: updateError },
  ] = useUpdateMovieMutation();
  const submitHandler = async (
    { file, experience, onlineReservation, distributor, locked, egyptian },
    data
  ) => {
    data.poster =
      file && (await uploadFileS3([file], setImageLoading, "movies"))[0];
    createMovie({
      ...data,
      experience,
      onlineReservation,
      distributor,
      egyptian,
      locked,
    });
  };

  const updateHandler = async (
    { file, experience, egyptian, onlineReservation, distributor, locked },
    data
  ) => {
    data.poster =
      file && (await uploadFileS3([file], setImageLoading, "users"))[0];
    updateMovie({
      ...data,
      id: data._id,
      experience,
      egyptian,
      distributor,
      onlineReservation,
      locked,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Movie Page</title>
      </Helmet>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/movies"
        />
      )}
      {(updateIsError || updateIsSuccess) && (
        <CustomToast
          IsError={updateIsError}
          error={updateError}
          isSuccess={updateIsSuccess}
          path="/movies"
        />
      )}
      <MovieForm
        imageloading={imageloading}
        onSubmit={id && movie ? updateHandler : submitHandler}
        movie={id && movie}
      />
    </div>
  );
};

export default MoviePage;
