import React from "react";
import DistributorsRevenuesTable from "./DistributorsRevenuesTable";

function DistributorsRevenues({
  movieReportData,
  data,
  startDate,
  endDate,
  Choices,
}) {
  return (
    <div className="flex w-full flex-col items-center p-5">
      <div className=" my-6 flex w-full flex-row justify-center ">
        <div className=" text-center">
          <p className="rounded-xl border border-solid border-black p-3">
            عرض تفصيلي ارادات بالموزع{" "}
          </p>
        </div>
      </div>
      <div className="mb-4 w-full text-end ">
        <p>
          التاريخ من {startDate} الي {endDate}
        </p>
      </div>
      <div className="my-2 w-full text-start ">
        <p>
          {`${
            Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
          } ${Choices?.movieType || "ALL"}`}
        </p>
      </div>
      <div className=" flex w-full flex-col">
        {movieReportData?.length === 0 ? (
          <h1 className="text-xl font-extrabold text-red-600">
            لا يوجد بيانات للعرض
          </h1>
        ) : (
          <>
            <DistributorsRevenuesTable
              movieReportData={movieReportData}
              data={data}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default DistributorsRevenues;
