import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../config/dataService/dataService";

export const distributorsApi = createApi({
   reducerPath: "distributorsApi",
   baseQuery: fetchBaseQuery({
      baseUrl: BASE_URL,
      credentials: "include",
   }),
   tagTypes: ["Distributors"],
   endpoints: ({ query, mutation }) => ({
      distributors: query({
         query: (search) => `distributors${search ? `?search=${search}` : ""}`,
         providesTags: ["Distributors"],
      }),

      distributor: query({
         query: (id) => `distributors/${id}`,
         providesTags: ["Distributors"],
      }),
      createDistributor: mutation({
         query: (data) => ({
            url: "distributors",
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["Distributors"],
      }),
      updateDistributor: mutation({
         query: ({ _id, ...data }) => ({
            url: `distributors/${_id}`,
            method: "PATCH",
            body: data,
         }),
         invalidatesTags: ["Distributors"],
      }),
      deleteDistributor: mutation({
         query: (id) => ({
            url: `distributors/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["Distributors"],
      }),
      SyncDistributors: mutation({
         query: (id) => ({
            url: `distributors/sync`,
            method: "GET",
         }),
         invalidatesTags: ["Distributors"],
      }),
   }),
});

export const {
   useDistributorsQuery,
   useDistributorQuery,
   useCreateDistributorMutation,
   useUpdateDistributorMutation,
   useDeleteDistributorMutation,
   useSyncDistributorsMutation,
} = distributorsApi;
