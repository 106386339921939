import { createSlice } from "@reduxjs/toolkit";
import io from "socket.io-client";
import { SOCKET_URL } from "../config/dataService/dataService";
const socket = io(SOCKET_URL);

const initialState = {
  socket,
  exchangeData: {
    oldSeats: JSON.parse(localStorage.getItem("ExchangesRes")),
  },
  adminToken: null,
  chosenSeats: [],
  exOrCancelRes: [],
};

const socketSlice = createSlice({
  name: "socketSlice",
  initialState,
  reducers: {
    setReservation(state, { payload }) {
      socket.emit("Seat-Reservation", payload);
    },
    seatStateHandler(state, { payload: { seat, data, authUser, screen } }) {
      if (
        seat.selectionStatus &&
        seat.selectionStatus.agent === authUser?._id
      ) {
        socket.emit("unCheck-seat", {
          seatStatus: seat.selectionStatus._id,
          seat: seat._id,
          screen,
          data,
        });
      }

      if (!seat.selectionStatus && !seat.reservationStatus) {
        socket.emit("Seat-Status", { data, seat, screen });
      }

      if (!seat.selectionStatus && seat.reservationStatus) {
        if (state.exOrCancelRes.some((i) => i._id === seat._id))
          state.exOrCancelRes = state.exOrCancelRes.filter(
            (i) => i._id !== seat._id
          );
        else state.exOrCancelRes = [...state.exOrCancelRes, seat];
        state.adminToken = null;
      }
    },

    cancelReservation(state, { payload }) {
      const cancelData =
        payload.type === "Cancel"
          ? state.exOrCancelRes
          : state.exchangeData.oldSeats;
      // const fullDate = new Date(payload.date);
      // const newDate =
      //   fullDate.getHours() < 7
      //     ? new Date(fullDate.setDate(fullDate.getDate() + 1))
      //     : fullDate;
      // if (
      //   new Date(newDate.setMinutes(newDate.getMinutes() + 30)).getTime() <=
      //   Date.now()
      // ) {
      //   toast.warning("you Cant Cancel This the Show Started +30 min", {
      //     autoClose: 1500,
      //     toastId: "54d65sae",
      //   });
      //   state.exOrCancelRes = [];
      //   state.adminToken = null;
      //   return state;
      // }
      if (state.adminToken) {
        socket.emit("Cancel-Reservation", {
          exOrCancelRes: cancelData,
          ...state.adminToken,
          data: payload.data,
          exchange: payload.type !== "Cancel",
        });
        state.adminToken = null;
      }
    },

    reprintReservation(state, { payload }) {
      socket.emit("Reprint-Reservation", {
        ...payload,
        ...state.adminToken,
      });
      state.adminToken = null;
    },

    getAdminToken(state, { payload }) {
      state.adminToken = payload;
    },

    cancelAdminToken(state) {
      state.adminToken = null;
    },

    setExchangeData(state, { payload }) {
      // const fullDate = new Date(payload.date);
      // const newDate =
      //   fullDate.getHours() < 7
      //     ? new Date(fullDate.setDate(fullDate.getDate() + 1))
      //     : fullDate;
      // if (
      //   new Date(newDate.setMinutes(newDate.getMinutes() + 30)).getTime() <=
      //   Date.now()
      // ) {
      //   toast.warning("you Cant Cancel This the Show Started +30 min", {
      //     autoClose: 1500,
      //     toastId: "54d65sae",
      //   });
      //   state.exOrCancelRes = [];
      //   state.adminToken = null;
      //   return state;
      // }
      localStorage.setItem("ExchangesRes", JSON.stringify(state.exOrCancelRes));
      state.exchangeData.oldSeats = state.exOrCancelRes;
      state.exOrCancelRes = [];
    },

    resetExchangeData(state, action) {
      localStorage.removeItem("ExchangesRes");
      state.exchangeData.oldSeats = [];
    },

    resetSelections(state, { payload }) {
      socket.emit("Reset-selections", payload);
      state.chosenSeats = [];
    },

    setChosenSeats(state, { payload }) {
      state.chosenSeats = state.chosenSeats.some((i) => i._id === payload._id)
        ? state.chosenSeats
        : [...state.chosenSeats, payload];
    },

    unsetChosenSeats(state, { payload }) {
      state.chosenSeats = state.chosenSeats.filter((i) => i._id !== payload);
    },
    setExOrCancelRes(state, { payload }) {
      if (state.exOrCancelRes.some((i) => i._id === payload._id))
        state.exOrCancelRes = state.exOrCancelRes.filter(
          (i) => i._id !== payload._id
        );
      else state.exOrCancelRes = [...state.exOrCancelRes, payload];
    },
    resetExOrCancelRes(state) {
      state.exOrCancelRes = [];
    },
  },
});

export const socketActions = socketSlice.actions;
export default socketSlice.reducer;
