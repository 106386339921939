import dayjs from "dayjs";
import React from "react";
import { useShowTimesQuery } from "../../../store/showTimesApi";
import Table from "./Table";
import { cinemaNameInReport } from "../../../config/dataService/dataService";

function DetailedRevenues2({
  movieReportData,
  data,
  startDate,
  endDate,
  Choices,
  date,
}) {
  const { data: ShowTimes } = useShowTimesQuery();

  console.log(Choices);

  const total = movieReportData?.reduce(
    (acc, curr) => {
      return {
        totalTickets: acc.totalTickets + curr["عدد التذاكر"],
        online: acc.online + curr["اونلاين"],
        totalOnline: acc.totalOnline + curr["اجمالي الاونلاين"],
        cash: acc.cash + curr["نقدي"],
        totalCash: acc.totalCash + curr["اجمالي النقدي"],
        card: acc.card + curr["بطاقات مصرفية"],
        totalCard: acc.totalCard + curr["اجمالي البطاقات"],
        total: acc.total + curr["الاجمالى"],
        net: acc.net + curr["الصافى"],
        tax: acc.tax + curr["الضريبة"],
        ValueAdded: acc.ValueAdded + curr["القيمه المضافة"],
        standard: acc.standard + curr["عادي"],
        deluxe: acc.deluxe + curr["ديلوكس"],
        premium: acc.premium + curr["بريميوم"],
      };
    },
    {
      totalTickets: 0,
      online: 0,
      totalOnline: 0,
      cash: 0,
      totalCash: 0,
      card: 0,
      totalCard: 0,
      total: 0,
      net: 0,
      tax: 0,
      ValueAdded: 0,
      standard: 0,
      deluxe: 0,
      premium: 0,
    }
  );

  return (
    <div className="flex w-full flex-col items-center p-5 ">
      <div className="self-start">
        <img alt="logo" className=" w-36" src="/logo.png"></img>
      </div>
      <div className="mb-6 flex w-[530px] flex-row-reverse items-end justify-between self-end">
        <div className="">
          {Choices.group === "None" ? (
            <p>
              التاريخ من {dayjs(Choices.date[0]).format("DD/MM/YYYY")} الي{" "}
              {dayjs(Choices.date[1]).subtract(1, "d").format("DD/MM/YYYY")}
            </p>
          ) : (
            <p>التاريخ - {dayjs(date).format("DD/MM/YYYY")}</p>
          )}
        </div>
        <div className="flex flex-col  items-center  ">
          <p>عرض اجمالي ارادات باليوم 2</p>
          <p>{cinemaNameInReport}</p>
        </div>
      </div>
      <div className="flex w-full flex-wrap items-center">
        {Choices?.showTimes && Choices?.showTimes?.length > 0 ? (
          <p>
            From -{" "}
            {
              ShowTimes?.filter((showTime) =>
                Choices?.showTimes.includes(showTime._id)
              )[0].startTime
            }{" "}
            - To -{" "}
            {
              ShowTimes?.filter((showTime) =>
                Choices?.showTimes.includes(showTime._id)
              ).slice(-1)[0].startTime
            }
          </p>
        ) : (
          <p>
            {`${
              Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
            } ${Choices?.movieType || "ALL"}`}
          </p>
        )}
      </div>
      <div className=" flex w-full flex-col items-center">
        <div className="mt-5 w-full">
          {movieReportData?.length === 0 ? (
            <h1 className="text-xl font-extrabold text-red-600">
              لا يوجد بيانات للعرض
            </h1>
          ) : (
            <Table
              movieReportData={movieReportData}
              data={data}
              total={total}
              Choices={Choices}
            />
          )}
          {/* <table className="w-full border border-black text-center">
            <tr className="w-full border border-black">
              <td colSpan="1" className=" w-24 border border-black">
                {(
                  +total?.net.toFixed(2) +
                  +(
                    daysBetween(Choices.date[0], Choices.date[1]) * 0.1
                  ).toFixed(2)
                ).toFixed(2)}
              </td>
              <td colSpan="1" className=" w-24 border border-black">
                {total?.ValueAdded.toFixed(2)}
              </td>
              <td colSpan="1" className=" w-24 border border-black">
                {total?.tax.toFixed(2)}
              </td>
              <td colSpan="1" className=" w-24 border border-black">
                {total?.total.toFixed(2)}
              </td>
              <td colSpan="1" className=" w-24 border border-black">
                {total?.totalTickets}
              </td>
              <td colSpan="1" className=" w-24 border border-black"></td>
              <td colSpan="1" className=" w-24 border border-black"></td>
            </tr>
          </table> */}
        </div>
      </div>
    </div>
  );
}

export default DetailedRevenues2;
