import React from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../components/ui-assets/SearchBar";
import { useShowTimesQuery } from "../../store/showTimesApi";
import ShowtimeCard from "../../components/showtimes/ShowtimeCard";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Showtimes = () => {
  const { data, isLoading } = useShowTimesQuery();
  const [showTimes, setShowTimes] = useState();

  useEffect(() => {
    if (data) {
      const showTimesTest = [...data]?.sort((a, b) => {
        return (
          new Date(`8/10/2022, ${a.startTime}`).getTime() -
          new Date(`8/10/2022, ${b.startTime}`).getTime()
        );
      });
      const breakingPoing = showTimesTest.findIndex(
        (i) =>
          new Date(`8/10/2022, ${i.startTime}`).getTime() >=
          new Date(`8/10/2022, 07:00 AM`).getTime()
      );
      const lastTimes = showTimesTest.splice(0, breakingPoing);
      setShowTimes([...showTimesTest, ...lastTimes]);
    }
  }, [data]);

  return (
    <div className="container relative py-10">
      <Helmet>
        <title>Showtimes List</title>
      </Helmet>
      <div className="sticky top-0 flex flex-col items-center justify-between gap-4 bg-white py-4 md:flex-row">
        <SearchBar />
        <Link
          className="rounded-2xl bg-primary px-14 py-4 text-white"
          to="/add-showtime"
        >
          Add Showtime
        </Link>
      </div>
      <div className="flex items-center justify-center">
        <div className=" grid grid-cols-1 content-center gap-x-4 gap-y-4 py-4  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 ">
          {!isLoading &&
            data &&
            showTimes?.map((item) => (
              <ShowtimeCard showTime={item} key={item._id} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Showtimes;
