import React, { useState } from "react";
import ReservationBtn from "./ReservationBtn";
import MovieCard from "./MovieCard";
import ReserveModal from "./ReserveModal";
import DropDown from "../ui-assets/DropDown";
import Signinform from "../ui-assets/Signinform";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useShowMoviesQuery } from "../../store/showMovieApi";
import {
   ArrowLeftIcon,
   KeyIcon,
   LockClosedIcon,
   LockOpenIcon,
} from "@heroicons/react/solid";
import EditStatus from "../ui-assets/EditStatus";
import { useEffect } from "react";
import Checkpoint from "../ui-assets/Checkpoint";
import { useDispatch, useSelector } from "react-redux";
import { socketActions } from "../../store/socketSlice";
import PopUp from "./PopUp";
import AdditionsToReservationModal from "./AdditionsToReservationModal";
import { uiActions } from "../../store/uiSlice";
import RefundGlasses from "./refundGlassesModal";
import DetailsModal from "../ui-assets/DetailsModal";
import { reservationMovieCard } from "../../config/dataService/dataService";
import Modal from "../ui-assets/Modal";

function classNames(...classes) {
   return classes.filter(Boolean).join(" ");
}

const seatsStatus = [
   {
      id: "Available",
      title: "Available",
      color: "#FFFFFF",
      borderColor: "#000",
   },
   {
      id: "Reserved",
      title: "Reserved",
      // color: "#FF6335",
      // borderColor: "#FF6335",
      color: "#0000FF",
      borderColor: "#0000FF",
   },
   {
      id: "Chosen",
      title: "Chosen",
      color: "#00C18B",
      borderColor: "#00C18B",
   },
   {
      id: "Emergency",
      title: "Emergency",
      color: "#EF9A9A",
      borderColor: "#EF9A9A",
   },
   {
      id: "Invitation",
      title: "Invitation",
      color: "#A5D6A7",
      borderColor: "#A5D6A7",
   },
   {
      id: "Waiting",
      title: "Waiting",
      color: "#CE93D8",
      borderColor: "#CE93D8",
   },
   {
      id: "Repair",
      title: "Repair",
      color: "#FFCC80",
      borderColor: "#FFCC80",
   },
   {
      id: "online pending",
      title: "Online Pending",
      color: "#635a10",
      borderColor: "#635a10",
   },
   // {
   //   id: "Online reservation",
   //   title: "Online reservation",
   //   color: "#FFFFFF",
   //   borderColor: "#FFFFFF",
   // },
];
const seatsStatusSelfServices = [
   {
      id: "Available",
      title: "Available",
      color: "#FFFFFF",
      borderColor: "#000",
   },
   {
      id: "Reserved",
      title: "Reserved",
      // color: "#FF6335",
      // borderColor: "#FF6335",
      color: "#0000FF",
      borderColor: "#0000FF",
   },
   {
      id: "Chosen",
      title: "Chosen",
      color: "#00C18B",
      borderColor: "#00C18B",
   },
   {
      id: "Deluxe",
      title: "Deluxe",
      color: "#faeb96",
      borderColor: "#faeb96",
   },
];

function Reservation({
   screen,
   seats,
   isLoading,
   showTime,
   showMovie,
   authUser,
   reservationDate,
   setContextType,
}) {
   const dispatch = useDispatch();
   const exchangesRes = useSelector(
      (state) => state.socket.exchangeData.oldSeats
   );
   const chosenSeats = useSelector((state) => state.socket.chosenSeats);
   const [showMenuStat, setShowMenuState] = useState();
   const [searchParams, setSearchParam] = useSearchParams();
   const { data: showMovies } = useShowMoviesQuery();
   const queryParams = Object.fromEntries([...searchParams?.entries()]);
   const faMenuStat = (f) => {
      setShowMenuState(f);
   };

   const location = useLocation().pathname;
   const [isSelfServices, setIsSelfServices] = useState(false);
   const [isOpen, setIsOpen] = useState(true);
   const adminToken = useSelector((state) => state.socket.adminToken);

   useEffect(() => {
      console.log("sess", sessionStorage.getItem("changeDate"));
      if (
         sessionStorage.getItem("changeDate") &&
         sessionStorage.getItem("changeDate") === "true"
      ) {
         setIsOpen(false);
      }
   }, []);

   useEffect(() => {
      if (location.includes("/selfReserve")) {
         setIsSelfServices(true);
      } else {
         setIsSelfServices(false);
      }
   }, [location, isSelfServices]);

   const [isEditStatusOpen, setIsEditStatusOpen] = useState(false);
   const [checkpoint, setCheckpoint] = useState(false);
   const popupState = useSelector((state) => state.ui.exChangePopup);
   const loginPopup = useSelector((state) => state.ui.loginPopup);
   const detailsPopup = useSelector((state) => state.ui.detailsModal);
   const contextMenu = useSelector((state) => state.ui.contextMenu);

   useEffect(() => {
      dispatch(
         socketActions.resetSelections({
            agent: authUser?._id,
            data: {
               showMovie: queryParams.showmovie,
               showTime: queryParams.showtime,
               screen: queryParams.screen,
            },
         })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [showTime, showMovie, dispatch, screen, reservationDate, authUser]);

   useEffect(() => {
      if (showMovie) {
         const screenCheck = showMovie?.screens?.some(
            (i) => i.screen._id === queryParams.screen
         );
         const showTimeCheck = showMovie?.screens
            .find((i) => i.screen._id === queryParams.screen)
            ?.showTimes?.some((i) => i._id === queryParams.showtime);
         if (!screenCheck || !showTimeCheck)
            setSearchParam({
               ...queryParams,
               screen: "undefined",
               showtime: "undefined",
            });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reservationDate, showMovie]);

   const stateHandler = (seat, data) => {
      dispatch(
         socketActions.seatStateHandler({
            seat,
            data,
            authUser,
            screen: screen._id,
         })
      );
   };

   const emergencyReservationHandler = (seat) => {
      dispatch(uiActions.setLoginPopup(true));
      dispatch(
         uiActions.setContextMenu({ type: "EmergencyReservation", seat })
      );
   };

   const rows = seats?.map((i) => i.row);
   const rows2 = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "AA",
      "BB",
      "CC",
      "DD",
      "EE",
      "FF",
      "GG",
      "HH",
      "II",
      "JJ",
   ];

   return (
      <>
         <div
            className={`relative flex rotate-180 flex-row ${
               isSelfServices ? "px-10" : ""
            } justify-between  py-5 `}
         >
            {!location.includes("/selfReserve") && (
               <>
                  <div className=" flex flex-col items-center text-center ">
                     <p className=" pl-1 text-xs text-[#607D8B]">
                        Chosen seats
                     </p>
                     <h1 className="  truncate text-lg font-extrabold">
                        {chosenSeats?.length} seat
                     </h1>
                  </div>
                  <div className=" flex flex-col items-center text-center ">
                     <p className=" pl-1 text-xs text-[#607D8B]">Online</p>
                     <h1 className="  truncate text-lg font-extrabold">
                        {
                           seats?.filter(
                              (i) =>
                                 i.status === "Online reservation" &&
                                 !i.reservationStatus
                           ).length
                        }{" "}
                        seat
                     </h1>
                  </div>
                  <div className=" flex flex-col items-center text-center ">
                     <p className=" pl-1 text-xs text-[#607D8B]">Waiting</p>
                     <h1 className="  truncate text-lg font-extrabold">
                        {
                           seats?.filter(
                              (i) =>
                                 i.status === "Waiting" && !i.reservationStatus
                           ).length
                        }{" "}
                        seat
                     </h1>
                  </div>
                  <div className=" flex flex-col items-center text-center ">
                     <p className=" pl-1 text-xs text-[#607D8B]">Invitation</p>
                     <h1 className="  truncate text-lg font-extrabold">
                        {
                           seats?.filter(
                              (i) => i.reservationStatus?.invitation === true
                           ).length
                        }{" "}
                        seat
                     </h1>
                  </div>
               </>
            )}
            <div className=" flex flex-col items-center text-center ">
               <p className=" pl-1 text-xs text-[#607D8B]">public</p>
               <h1 className="  truncate text-lg font-extrabold">
                  {
                     seats?.filter(
                        (i) =>
                           i.reservationStatus &&
                           !i.reservationStatus?.invitation
                     ).length
                  }{" "}
                  seat
               </h1>
            </div>

            {exchangesRes?.length > 0 ? (
               <div className=" col-auto col-start-3 flex flex-col items-center text-center ">
                  <p className=" pl-1 text-xs text-[#607D8B]">Exchange seats</p>
                  <h1 className=" text-center text-lg  font-extrabold">
                     {exchangesRes.length} seat
                  </h1>
               </div>
            ) : null}

            <div className="relative z-10">
               <ReserveModal
                  className=""
                  screen={screen}
                  showTime={showTime}
                  showMovie={showMovie}
                  chosenSeats={chosenSeats}
                  isSelfServices={isSelfServices}
               />
            </div>
            {!isSelfServices ? (
               <>
                  <div className="relative z-10">
                     <AdditionsToReservationModal className="" />
                  </div>
                  <div className="relative z-10">
                     <RefundGlasses className="" />
                  </div>
                  <button
                     onClick={() => setCheckpoint(!checkpoint)}
                     className=" h-[44px] w-[105px] rounded-2xl border-2 border-[#FF6335] py-3 text-sm text-[#FF6335]  hover:bg-[#FF6335] hover:text-white"
                  >
                     Checkpoint
                  </button>
               </>
            ) : null}
            {/* <div className="cursor-pointer">
            <LockClosedIcon className="h-10 w-10 text-primary" />
            {/* <LockOpenIcon className="h-10 w-10 text-primary" /> */}
            {/* <KeyIcon className="h-10 w-10 text-primary" /> */}
            {/* </div> */}
            {exchangesRes?.length > 0 && (
               <button
                  onClick={() => {
                     dispatch(socketActions.resetExchangeData());
                  }}
                  className="h-[44px] rounded-2xl border-2 border-green-500 px-3 py-3 text-sm text-green-500  hover:bg-green-500 hover:text-white"
               >
                  Reset Exchange
               </button>
            )}

            <div className=" flex flex-col items-center justify-center ">
               <p className=" pl-1 text-xs text-[#607D8B]">Available Seats</p>
               <h1 className=" text-lg font-extrabold">
                  {
                     seats?.filter(
                        (i) =>
                           i.bookingStatus === "Available" &&
                           !i.reservationStatus
                     ).length
                  }
                  Seat
               </h1>
            </div>
         </div>
         <div className="w-full rotate-180">
            <div className="h-full w-auto">
               <Link
                  to={`${isSelfServices ? "/selfServices" : "/reservation"}`}
                  className="  flex w-auto max-w-[100px] items-center gap-2 text-xl font-bold text-primary"
               >
                  <ArrowLeftIcon className="h-5 w-5 text-primary" />
                  <span className="text-primary">Back</span>
               </Link>
            </div>
         </div>
         <div
            onClick={() => setShowMenuState("l")}
            className={`special_screen flex h-full w-full flex-col gap-10`}
         >
            <div
               className={`mt-[10px] flex h-full w-full ${
                  isSelfServices
                     ? "flex-col gap-10 pt-36"
                     : "flex-row items-start"
               }`}
            >
               <div className="h-full w-full ">
                  {queryParams.screen !== "undefined" &&
                  queryParams.showtime !== "undefined" ? (
                     <>
                        <div className="flex h-full w-full flex-row justify-between">
                           <div className="items-start">
                              <p className=" pl-1 text-xs text-[#607D8B]">
                                 Screen
                              </p>
                              <h1 className=" text-lg font-extrabold">
                                 {screen?.name}{" "}
                                 {screen?.type !== "Standard" && (
                                    <span className="text-red-600">
                                       {screen?.type}
                                    </span>
                                 )}
                              </h1>
                           </div>
                           <div className=" ">
                              <img
                                 alt="screen"
                                 src="/screen10.png"
                                 className=" relative top-[-20px] h-[50px] w-[850px]"
                              />
                           </div>
                           <div className="items-start ">
                              <p className=" pl-1 text-xs text-[#607D8B]">
                                 Show time
                              </p>
                              <h1 className=" text-lg font-extrabold">
                                 {showTime?.startTime}
                              </h1>
                           </div>
                        </div>
                        <div className="flex flex-row">
                           <div className=" mr-4 flex  w-auto flex-col justify-around ">
                              {screen?.template === "CFC-Screen-04"
                                 ? rows2?.map((i) => <div key={i}>{i}</div>)
                                 : null}
                           </div>
                           <div
                              className={`${screen?.template} grid h-full min-h-[415px] w-full rotate-180 gap-1`}
                              //  className="grid-cols-21 mt-[42px] grid   w-full "
                           >
                              {!isLoading &&
                                 seats &&
                                 seats.map((seatsData, i) => (
                                    <div
                                       style={{
                                          gridArea:
                                             seatsData.title.split("-")[1],
                                       }}
                                       key={seatsData._id}
                                       className={`${
                                          seatsData.title.split("-")[0] ===
                                             "S15" &&
                                          ["N14", "M14", "L14"].includes(
                                             seatsData.title.split("-")[1]
                                          ) &&
                                          "ml-4"
                                       }`}
                                    >
                                       <ReservationBtn
                                          seat={seatsData}
                                          onClick={() => {
                                             if (
                                                seatsData.status ===
                                                   "Emergency" &&
                                                !seatsData.reservationStatus
                                             ) {
                                                emergencyReservationHandler(
                                                   seatsData
                                                );
                                             } else {
                                                stateHandler(seatsData, {
                                                   seat: seatsData._id,
                                                   date: reservationDate,
                                                   agent: authUser?._id,
                                                   showMovie: showMovie._id,
                                                   showTime: showTime._id,
                                                });
                                             }

                                             // seatsData.status === "Emergency" &&
                                             // !seatsData.reservationStatus
                                             //   ? emergencyReservationHandler(seatsData)
                                          }}
                                          menuStat={
                                             showMenuStat === i ? true : false
                                          }
                                          faMenuStat={faMenuStat}
                                          Index={i}
                                          signin={(seat, type) => {
                                             setContextType(type);
                                          }}
                                          editstatus={(seat) =>
                                             setIsEditStatusOpen(
                                                (prev) => !prev
                                             )
                                          }
                                          agent={authUser?._id}
                                          addRow={
                                             screen?.template ===
                                             "CFC-Screen-04"
                                                ? false
                                                : true
                                          }
                                       />
                                    </div>
                                 ))}
                           </div>
                           <div className=" ml-4 flex  w-auto flex-col justify-around ">
                              {screen?.template === "CFC-Screen-04"
                                 ? rows2?.map((i) => <div key={i}>{i}</div>)
                                 : null}
                           </div>
                        </div>
                        <div
                           className={`my-4  flex h-5 ${
                              isSelfServices
                                 ? "items-center justify-center gap-14"
                                 : "w-[945px]  justify-evenly"
                           }  `}
                        >
                           {!isSelfServices
                              ? seatsStatus.map((seatstatus) => (
                                   <div
                                      key={seatstatus.id}
                                      className={classNames(
                                         `text-[${seatstatus.color}] flex items-center`
                                      )}
                                      style={{
                                         color: `${
                                            seatstatus.title !== "Available"
                                               ? seatstatus.color
                                               : "black"
                                         }`,
                                      }}
                                   >
                                      <span
                                         className={classNames(
                                            `bg-[${seatstatus.color}] border-[${seatstatus.borderColor}]  h-4 w-4 rounded-full border bg-red-200`
                                         )}
                                         style={{
                                            backgroundColor: `${seatstatus.color}`,
                                            borderColor: `${
                                               seatstatus.borderColor
                                                  ? seatstatus.borderColor
                                                  : ""
                                            }`,
                                         }}
                                      ></span>
                                      <label
                                         htmlFor={seatstatus.id}
                                         className=" ml-1 block text-xs"
                                      >
                                         {seatstatus.title}
                                      </label>
                                   </div>
                                ))
                              : seatsStatusSelfServices.map((seatstatus) => (
                                   <div
                                      key={seatstatus.id}
                                      className={`${classNames(
                                         `text-[${seatstatus.color}] flex items-center`
                                      )} ${isSelfServices && "rotate-180"}`}
                                      style={{
                                         color: `${
                                            seatstatus.title !== "Available"
                                               ? seatstatus.color
                                               : "black"
                                         }`,
                                      }}
                                   >
                                      <span
                                         className={classNames(
                                            `bg-[${seatstatus.color}] border-[${seatstatus.borderColor}]  h-4 w-4 rounded-full border bg-red-200`
                                         )}
                                         style={{
                                            backgroundColor: `${seatstatus.color}`,
                                            borderColor: `${
                                               seatstatus.borderColor
                                                  ? seatstatus.borderColor
                                                  : ""
                                            }`,
                                         }}
                                      ></span>
                                      <label
                                         htmlFor={seatstatus.id}
                                         className=" ml-1 block text-xs"
                                      >
                                         {seatstatus.title}
                                      </label>
                                   </div>
                                ))}
                        </div>
                     </>
                  ) : (
                     <div className="flex h-[50vh] w-full rotate-180 flex-col items-center justify-center ">
                        <p className="text-2xl font-bold text-primary">
                           Please select a screen and showtime
                        </p>
                        <p className="text-2xl font-bold text-red-600"> or</p>
                        <Link
                           to={`${
                              isSelfServices ? "/selfServices" : "/reservation"
                           }`}
                           className="rounded-2xl bg-primary px-14 py-3 text-white"
                        >
                           Go to reservation
                        </Link>
                     </div>
                  )}
               </div>
               <div className="ml-6 h-full w-[4px] rounded-full bg-slate-400 "></div>
               <div
                  className={`flex ${
                     isSelfServices ? "w-full" : "w-80"
                  } flex-col items-center gap-6`}
               >
                  <div
                     className={`flex w-full ${
                        isSelfServices
                           ? "justify-evenly gap-52 px-20"
                           : "flex-col justify-around gap-3"
                     } `}
                  >
                     {showMovie && (
                        <DropDown
                           type="ShowMovie"
                           data={showMovie}
                           isSelfServices={isSelfServices}
                           setSelected={(showtime) => {
                              setSearchParam({
                                 ...queryParams,
                                 ...showtime,
                              });
                           }}
                           selected={
                              queryParams.screen !== "undefined" &&
                              queryParams.showtime !== "undefined"
                                 ? `${screen?.name} - ${showTime?.startTime}`
                                 : ""
                           }
                        />
                     )}

                     {showMovies && (
                        <DropDown
                           type="Movies"
                           data={showMovies}
                           setSelected={(showMovie) => {
                              setSearchParam({
                                 showmovie: showMovie._id,
                                 showtime:
                                    showMovie?.screens[0]?.showTimes[0]?._id,
                                 screen: showMovie?.screens[0]?.screen?._id,
                                 startTime:
                                    showMovie?.screens[0]?.showTimes[0]
                                       ?.startTime,
                              });
                           }}
                           selected={showMovie}
                        />
                     )}
                  </div>
                  {showMovie && (
                     <div
                        className={`${reservationMovieCard} relative top-16 z-10`}
                     >
                        <MovieCard
                           showMovie={showMovie}
                           text="font-semibold mt-2"
                           height={`w-full ${
                              isSelfServices ? "h-full" : "max-h-[320px]"
                           }`}
                           opacity=""
                           isSelfServices={isSelfServices}
                        />
                     </div>
                  )}
                  {showMovie?.movie?.banner && isSelfServices && (
                     <div className={`${reservationMovieCard}`}>
                        <div
                           className={`flex h-[450px] w-[1100px] flex-col items-center justify-center`}
                        >
                           <div className={`h-[450px] w-[1100px] rounded-lg`}>
                              <img
                                 src={showMovie?.movie?.banner}
                                 alt="movie"
                                 className={`h-full w-full object-cover`}
                              />
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
         <>
            {showMovie && (
               <EditStatus
                  On_Of={isEditStatusOpen}
                  faeditstatus={() => setIsEditStatusOpen((prev) => !prev)}
                  showMovie={showMovie}
               />
            )}
            <Signinform
               onof={loginPopup}
               screen={screen}
               showTime={showTime}
               showMovie={showMovie}
            />

            {detailsPopup.show && <DetailsModal />}

            {popupState && (
               <div className=" rotate-180">
                  <PopUp
                     text="are you sure you want to delete this seat ?"
                     type="Delete"
                  />
               </div>
            )}

            {checkpoint && (
               <Checkpoint
                  On_Of={checkpoint}
                  faeditstatus={() => setCheckpoint((prev) => !prev)}
                  showMovie={showMovie}
               />
            )}
            {isSelfServices && (
               <Modal
                  action="Confirm"
                  name="Showtime"
                  // onClick={onDelete}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  datepicker={true}
                  isSelfServices={isSelfServices}
               />
            )}
         </>
      </>
   );
}

export default Reservation;
