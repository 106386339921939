import React from "react";
import Table from "./Table";
import { cinemaNameInReport } from "../../../config/dataService/dataService";

function GlassesTable({ movieReportData, data, startDate, endDate, titel, Choices }) {
  const total = movieReportData?.reduce(
    (acc, curr) => {
      return {
        total: acc.total + curr["السعر"],
        count: acc.count + curr["العدد"],
        cash: acc.cash + curr["نقدي"],
        card: acc.card + curr["بطاقات مصرفية"],
        totalCash: acc.totalCash + curr["اجمالي النقدي"],
        totalCard: acc.totalCard + curr["اجمالي البطاقات"],
      };
    },
    {
      total: 0,
      count: 0,
      cash: 0,
      card: 0,
      totalCash: 0,
      totalCard: 0,
    }
  );

  return (
    <div className="flex w-full flex-col items-center p-5 ">
      <div className="self-start">
        <img alt="logo" className=" w-36" src="/logo.png"></img>
      </div>
      <div className="mb-6 flex w-[530px] flex-row-reverse items-end justify-between self-end">
        <div className="">
          <p>
            التاريخ من {startDate} الي {endDate}
          </p>
        </div>
        <div className="flex flex-col  items-center border-b-8 ">
          <p>{cinemaNameInReport}</p>
          <p>{titel ? titel : " كشف حساب النظارات"}</p>
        </div>
      </div>
      <div className="my-2 w-full  text-start ">
        <p>
          {`${
            Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
          } ${Choices?.movieType || "ALL"}`}
        </p>
      </div>
      <div className=" flex w-full flex-col items-center">
        <div className="mt-5 w-full">
          {movieReportData?.length === 0 ? (
            <h1 className="text-xl  font-extrabold text-red-600">
              لا يوجد بيانات للعرض
            </h1>
          ) : (
            <>
              <Table
                movieReportData={movieReportData}
                data={data}
                total={total}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default GlassesTable;
