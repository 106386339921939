import React from "react";
import { useState } from "react";
import Modal from "../ui-assets/Modal";

function OnlineModal({ showtime, onToggleOnline }) {
   const [ShowOnlineModal, setShowOnlineModal] = useState(false);
   return showtime.online ? (
      <>
         <Modal
            onClick={() => {
               onToggleOnline(showtime.showTimeStatus);
               setShowOnlineModal(false);
            }}
            isOpen={ShowOnlineModal}
            // item={item}
            setIsOpen={setShowOnlineModal}
            action=" change "
            name={"Showtime Status"}
            ok="OK"
         />
         <i
            onClick={() => setShowOnlineModal(true)}
            class="fa-solid fa-circle cursor-pointer text-green-500"
         ></i>
      </>
   ) : (
      <>
         <Modal
            onClick={() => {
               setShowOnlineModal(false);
               onToggleOnline(showtime.showTimeStatus);
            }}
            ok="OK"
            isOpen={ShowOnlineModal}
            // item={item}
            setIsOpen={setShowOnlineModal}
            action=" change "
            name={"Showtime Status"}
         />
         <i
            onClick={() => setShowOnlineModal(true)}
            class="fa-solid fa-circle cursor-pointer text-red-500"
         ></i>
      </>
   );
}

export default OnlineModal;
