import React from "react";
import dayjs from "dayjs";

function ExchangeReport({ startDate, movieReportData, Choices }) {
  let sortedReportData = movieReportData ? [...movieReportData] : [];
  sortedReportData?.sort((a, b) =>
    dayjs(`1/1/1999 ${a.oldShowTime}`).diff(dayjs(`1/1/1999 ${b.oldShowTime}`))
  );

  return (
    <>
      <div className="mt-16 mb-8 flex w-full flex-col items-center justify-center">
        <div className="rounded-md border-2 border-black px-4 py-2 ">
          كشف بتعديل اماكن الحجز{" "}
        </div>
        <div className="rounded-md px-4 py-2 ">{startDate}</div>
        <div className="w-full text-start ">
          <p>
            {`${
              Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
            } ${Choices?.movieType || "ALL"}`}
          </p>
        </div>
      </div>
      <table className=" w-full text-center">
        <tr>
          <th className="  border border-black " colSpan="1">
            المشرف{" "}
          </th>{" "}
          <th className="  border border-black " colSpan="1">
            المبدل{" "}
          </th>{" "}
          <th className="  border border-black " colSpan="1">
            المبدل منه{" "}
          </th>{" "}
          <th className="  border border-black " colSpan="1">
            الاماكن{" "}
          </th>
          <th className=" border border-black " colSpan="1">
            الحفلة
          </th>
          <th className="  border border-black " colSpan="1">
            القاعة
          </th>
          <th className="  border border-black " colSpan="1">
            اسم الفيلم{" "}
          </th>
          <th className="  border border-black " colSpan="1"></th>
          <th className=" border border-black " colSpan="1">
            السعر
          </th>
          <th className="  border border-black " colSpan="1">
            عدد الاماكن
          </th>
          <th className="  border border-black " colSpan="1">
            وقت تعديل الاماكن{" "}
          </th>
        </tr>

        {sortedReportData?.map((i) => (
          <>
            <tr>
              <td className=" border border-black " rowSpan="2" colSpan="1">
                {i.superVisor}
              </td>
              <td className=" border border-black " rowSpan="2" colSpan="1">
                {i.agent}
              </td>
              <td className=" border border-black " rowSpan="2" colSpan="1">
                {i.replacedFrom}
              </td>
              <td className=" border border-black " colSpan="1">
                {i.oldSeat.map((i) => (
                  <>{i.split("-")[1] + " "}</>
                ))}
              </td>
              <td className=" border border-black " colSpan="1">
                {i.oldShowTime}
              </td>
              <td className=" border border-black " colSpan="1">
                {i.oldScreen?.split(" ")[1]}
              </td>
              <td className=" border border-black " colSpan="1">
                {i.oldMovie}
              </td>
              <td className=" w-[130px] border border-black " colSpan="1">
                بيانات ب الحجز القديم{" "}
              </td>
              <td className=" border border-black " rowSpan="2" colSpan="1">
                {i.price}
              </td>
              <td className=" border border-black " rowSpan="2" colSpan="1">
                {i.count}
              </td>
              {/* TODO: FIX THIS (3 HOURS PLUS) */}
              <td className=" border border-black " rowSpan="2" colSpan="1">
                {dayjs(i?._id?.updatedAtFormatted).add(3, "h").format("h:mm A")}
              </td>
            </tr>
            <tr>
              <td className=" border border-black " colSpan="1">
                {i.newSeat.map((i) => (
                  <>{i.split("-")[1] + " "}</>
                ))}
              </td>
              <td className=" border border-black " colSpan="1">
                {i.newShowTime}
              </td>
              <td className=" border border-black " colSpan="1">
                {i.newScreen?.split(" ")[1]}
              </td>
              <td className=" border border-black " colSpan="1">
                {i.newMovie}
              </td>
              <td className=" border border-black " colSpan="1">
                {" "}
                بيانات ب الحجز الجديد{" "}
              </td>
            </tr>
          </>
        ))}
      </table>
    </>
  );
}

export default ExchangeReport;
