import React, { useRef } from 'react';
import Reservation from '../../components/reservation/Reservation';
import { useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { useSearchParams } from 'react-router-dom';
import { useScreenQuery } from '../../store/screensApi';
import { useSeatsQuery } from '../../store/seatsApi';
import { useState } from 'react';
import { useShowTimeQuery } from '../../store/showTimesApi';
import { useShowMovieReservationQuery } from '../../store/showMovieApi';
import { ComponentToPrint } from '../../utiles/ComponentToPrint';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthUserQuery } from '../../store/authUserApi';
import { socketActions } from '../../store/socketSlice';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

const ReservationPage = () => {
  const socket = useSelector((state) => state.socket.socket);
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { data: authUser } = useAuthUserQuery();
  const reservationDate = useSelector(
    (state) => state.reservation.ReservationDate
  );
  const [reservationData, setReservationData] = useState();

  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams?.entries()]);
  const { data: showMovie, refetch: refetchShowNovie } =
    useShowMovieReservationQuery({
      id: queryParams.showmovie,
      date: reservationDate,
    });
  const { data: screen } = useScreenQuery(queryParams.screen);

  const {
    data: backSeats,
    isLoading,
    refetch,
  } = useSeatsQuery({
    screen: queryParams.screen,
    showTime: queryParams.showtime,
    date: reservationDate,
  });

  const { data: showTime } = useShowTimeQuery(queryParams.showtime);

  useEffect(() => {
    refetchShowNovie();
  }, [reservationDate, refetchShowNovie]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on('Seat-Status', (data) => {
      if (
        data.screen === queryParams.screen &&
        data.showtime === queryParams.showtime &&
        data.showmovie === queryParams.showmovie
      )
        refetch();
    });

    socket.on('Cancel-Reservation', () => {
      dispatch(socketActions.resetExOrCancelRes());
    });

    socket.on('Error', (error) => {
      toast.warning(error, {
        autoClose: 1500,
        toastId: '54d65sae564',
      });
    });

    socket.on('unCheck-seat', (seat) => {
      return dispatch(socketActions.unsetChosenSeats(seat));
    });

    socket.on('Seat-Selection', (seat) => {
      dispatch(socketActions.setChosenSeats(seat));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, dispatch, socket, searchParams]);

  useEffect(() => {
    dispatch(socketActions.resetExOrCancelRes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backSeats]);

  const printHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    socket.on('Seat-Reservation', (payload) => {
      setReservationData(payload);
      console.log(payload);
      setTimeout(() => printHandler(), 1000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    socket.on('Reprint-Reservation', (payload) => {
      setReservationData(payload);
      setTimeout(() => printHandler(), 100);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <>
      <Helmet>
        <title>Reservation Page</title>
      </Helmet>
      <Reservation
        seats={backSeats}
        showMovie={showMovie}
        screen={screen}
        authUser={authUser}
        isLoading={isLoading}
        showTime={showTime}
        socket={socket}
        reservationDate={reservationDate}
      />
      <div style={{ display: 'none' }}>
        <ComponentToPrint reservations={reservationData} ref={componentRef} />
      </div>
    </>
  );
};

export default ReservationPage;
