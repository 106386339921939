/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import ReservationBtn from "../reservation/ReservationBtn";
import { useUpdateSeatsMutation } from "../../store/seatsApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const seatsStatus = [
  {
    id: "Available",
    title: "Available",
    color: "#fff",
    borderColor: "#CFD8DC",
  },
  { id: "Booked", title: "Booked", color: "#90CAF9" },
  { id: "Holding", title: "Holding", color: "#9FA8DA" },
  { id: "Invitation", title: "Invitation", color: "#A5D6A7" },
  { id: "Waiting", title: "Waiting", color: "#CE93D8" },
  { id: "Repair", title: "Repair", color: "#FFCC80" },
  { id: "Emergency", title: "Emergency", color: "#EF9A9A" },
  {
    id: "Online reservation",
    title: "Online reservation",
    color: "#FFFFFF",
    borderColor: "#80CBC4",
  },
];

export default function EditSeatSuits({ open, setOpen, screen, seatsBack }) {
  const [updateSeats, { isSuccess }] = useUpdateSeatsMutation();
  const [seats, setSeats] = useState(
    Array.from({ length: seatsBack.length }, (item, i) => ({
      _id: seatsBack[i]._id,
      title: seatsBack[i].title,
      screen: screen._id,
      status: seatsBack[i].status,
      onlineReservation: seatsBack[i].onlineReservation,
      type: seatsBack[i].type,
      column: seatsBack[i].column,
      row: seatsBack[i].row,
      price: seatsBack[i].price,
      index: i,
    }))
  );
  const [selectedSeat, setSelectedSeat] = useState(seats[0]);
  const updateHandler = (data) => {
    if (data?.length > 0) updateSeats(data);
  };

  useEffect(() => {
    if (isSuccess) setOpen(false);
  }, [isSuccess, setOpen]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[990px] transform overflow-hidden rounded-2xl bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all ">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className=" rounded-full bg-[#F44336] text-white "
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0  sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-bold leading-6 text-primary"
                    >
                      Edit Seat Suits
                    </Dialog.Title>
                  </div>
                </div>

                {/* seats row */}

                <div className="w-full ">
                  <div className="mt-8 flex flex-auto items-center justify-center">
                    <div className={`${screen?.template} grid  w-full`}>
                      {seats &&
                        seats.map((seat, i) => (
                          <div
                            key={seat.title}
                            style={{ gridArea: seat.title.split("-")[1] }}
                          >
                            <ReservationBtn
                              seat={seat}
                              key={seat.title}
                              index={i}
                              selectC={seat.title === selectedSeat.title}
                              onClick={() => {
                                setSelectedSeat(seat);
                              }}
                              setSelectedSeatToEdit={selectedSeat}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* seat status indicator */}
                  <div className="mt-4 flex justify-between gap-2">
                    {seatsStatus.map((seatstatus) => (
                      <div key={seatstatus.id} className="flex items-center">
                        <span
                          className="h-4 w-4 rounded-full border bg-red-200"
                          style={{
                            backgroundColor: `${seatstatus.color}`,
                            borderColor: `${
                              seatstatus.borderColor
                                ? seatstatus.borderColor
                                : ""
                            }`,
                          }}
                        ></span>
                        <label
                          htmlFor={seatstatus.id}
                          className=" ml-1 block text-xs text-gray-700"
                        >
                          {seatstatus.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-3 w-full">
                  <label
                    htmlFor="price"
                    className="text-left text-sm font-semibold text-[#3B5CAD]"
                  >
                    Price
                  </label>
                  <input
                    type="number"
                    className="mt-4 w-full rounded-md border border-gray-300 py-2 px-3 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="Price"
                    value={selectedSeat.price}
                    min={0}
                    onChange={(e) => {
                      setSeats((prev) => {
                        prev[selectedSeat.index] = {
                          ...prev[selectedSeat.index],

                          price: +e.target.value,
                        };
                        return prev;
                      });
                      setSelectedSeat((prev) => ({
                        ...prev,

                        price: +e.target.value,
                      }));
                    }}
                  />
                </div>

                <>
                  <div className="mt-8">
                    <p className="mb-4 text-xs font-semibold text-primary">
                      Seat status
                    </p>
                    <RadioGroup
                      value={selectedSeat.status}
                      onChange={(e) => {
                        setSeats((prev) => {
                          prev[selectedSeat.index] = {
                            ...prev[selectedSeat.index],
                            status: e,
                          };
                          return prev;
                        });
                        setSelectedSeat((prev) => ({
                          ...prev,
                          status: e,
                        }));
                      }}
                      className="mt-2   text-left"
                    >
                      <div className="flex flex-row flex-wrap gap-2">
                        {seatsStatus.slice(0, -1).map((option) => (
                          <RadioGroup.Option
                            key={option.title}
                            value={option.title}
                            className={({ active, checked }) =>
                              classNames(
                                active
                                  ? "bg-primary text-white"
                                  : "text-primary",
                                checked
                                  ? "border-transparent bg-primary !text-white hover:bg-primary"
                                  : "border-gray-200 bg-white   ",
                                "font-base flex h-10 w-[126px] cursor-pointer items-center justify-center rounded-2xl border border-primary px-6 py-2 text-sm "
                              )
                            }
                          >
                            <RadioGroup.Label as="span">
                              {option.title}
                            </RadioGroup.Label>
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                </>
                <div className="mt-5 flex w-full justify-center">
                  <button
                    type="button "
                    className="mt-8  rounded-2xl  border bg-primary px-8 py-3 text-base  text-white sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() =>
                      updateHandler(
                        seats.filter(
                          (seat, i) =>
                            seat.status !== seatsBack[i]?.status ||
                            seat.price !== seatsBack[i]?.price
                        )
                      )
                    }
                  >
                    Save Edits
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
