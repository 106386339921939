import React from "react";
import SettingCom from "../../components/setting/Setting";
import { Helmet } from "react-helmet";

function Setting() {
  return (
    <div>
      <Helmet>
        <title>Setting</title>
      </Helmet>
      <SettingCom />
    </div>
  );
}

export default Setting;
