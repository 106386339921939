import { useSelector } from 'react-redux';
import { buttonColors } from '../../utiles/buttonsColors';
import ContextMenu from '../ui-assets/ContextMenu';
import { useLocation } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ReservationBtn = ({
  faMenuStat,
  seat,
  onClick,
  menuStat,
  Index,
  editstatus,
  type,
  setSelectedSeatToEdit,
  agent,
  addRow = true,
}) => {
  const exOrCancelRes = useSelector((state) => state.socket.exOrCancelRes);
  const { _id, status, reservationStatus, selectionStatus } = seat;

  const location = useLocation().pathname;

  return (
    <div className="relative h-full w-full">
      <button
        type="button"
        onClick={onClick}
        onContextMenu={(e) => {
          e.preventDefault();
          faMenuStat(Index);
        }}
        disabled={
          !location.includes('/add-formation') &&
          !(status === 'Available' || status === 'Emergency')
        }
        className={classNames(
          'h-full w-full min-w-[25px]  rounded-md border text-[12px] font-bold  shadow-md',
          classNames(
            reservationStatus?.invitation
              ? '!bg-[#A5D6A7]'
              : reservationStatus && !reservationStatus.paid
              ? '!bg-[#635a10] !text-white'
              : reservationStatus
              ? '!bg-[#0000FF] !text-white'
              : !selectionStatus
              ? ''
              : selectionStatus.agent === agent
              ? '!bg-[#00C18B]'
              : 'bg-[#CFD8DC]'
          ),
          classNames(
            selectionStatus || exOrCancelRes?.some((i) => i._id === _id)
              ? reservationStatus
                ? '!bg-[#00C18B]'
                : 'border-[#00C18B]'
              : 'border-black',
            buttonColors(
              status === 'Emergency' && reservationStatus?.invitation
                ? 'Invitation'
                : location.includes('/selfReserve') &&
                  (status === 'Repair' || status === 'online pending')
                ? 'Reserved'
                : status
            )
          ),
          classNames(
            setSelectedSeatToEdit?.title === seat?.title && ' !border-[#00C18B]'
          ),
          classNames(seat?.price > 0 && 'innerShadow'),
          classNames(
            seat?.price > 0 &&
              location.includes('/selfReserve') &&
              'bg-[#faeb96] !text-black'
          )
        )}
      >
        {addRow ? seat.row : ''}
        {seat.column}
        {status === 'Emergency' &&
          !reservationStatus &&
          !reservationStatus?.invitation && (
            <div className="absolute ml-[6px] -mt-1 h-2 w-2 rounded-full bg-white" />
          )}
      </button>
      {location.includes('/add-formation') ? (
        menuStat && <></>
      ) : (
        <>
          {menuStat && seat.reservationStatus && exOrCancelRes.length !== 0 && (
            <ContextMenu
              editstatus={editstatus}
              seat={seat}
              Index={Index}
              type={type}
              seatNum={seat.column}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ReservationBtn;
