import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import MovieCard from "../reservation/MovieCard";
import { Link, useLocation } from "react-router-dom";
import ChangeShowTime from "../showMovies/changeShowTime";
import { sortShowTime } from "../../utiles/sortTime";
import ScrollContainer from "react-indiana-drag-scroll";
import { useSelector } from "react-redux";

export default function DropDownList({
  data,
  setSelected,
  type,
  setDropdownState,
  selected,
  overWriteHandler,
  newMovie,
  isSelfServices,
}) {
  const [changeShowTimeState, setChangeShowTimeState] = useState(false);
  const [timesSelected, setTimesSelected] = useState();
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  const reservationDate = useSelector(
    (state) => state.reservation.ReservationDate
  );

  const handleAvailableShowTime = (ts) => {
    if (!isSelfServices) return true;

    let date = new Date(reservationDate);
    let timeString = ts.startTime;
    let timeParts = timeString.split(":");
    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1].split(" ")[0]);
    let isPM = timeParts[1].split(" ")[1] === "PM";

    if (isPM && hours !== 12) {
      hours += 12;
    } else if (!isPM && hours === 12) {
      hours = 0;
    }
    if (!isPM && (hours >= 12 || hours < 6)) {
      date.setDate(date.getDate() + 1);
    }
    date.setHours(hours, minutes);
    let now = new Date();
    date.setTime(date.getTime() + 30 * 60 * 1000);
    if (date.getTime() > now.getTime()) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    !open && setDropdownState(false);
  }, [open, setDropdownState]);

  const location = useLocation().pathname;

  const OpenHandler = (e) => {
    if (
      !location.includes("add-showmovie") &&
      !location.includes("edit-showmovie") &&
      ((!location.includes("Movies%20revenues") && type === "Movies") ||
        type === "Time Show")
    ) {
      setOpen(!open);
    }
  };

  const handleSelectedShowTime = (showtimeId, showtimeStartTime, screenId) => {
    setSelected({
      showtime: showtimeId,
      startTime: showtimeStartTime,
      screen: screenId,
    });
    setOpen(!open);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full ">
          <div className="flex min-h-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0  translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100  translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative ${
                  isSelfServices ? "w-[90%] py-32 px-8" : "w-4/5 p-4"
                } transform  rounded-lg  bg-white text-left shadow-xl transition-all`}
              >
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className={`mt-6 flex flex-row ${
                      isSelfServices && "text-lg"
                    } items-center justify-between px-5 font-bold text-primary`}
                  >
                    <p>{type}</p>
                    <div
                      onClick={() => setOpen((prev) => !prev)}
                      className=" cursor-pointer text-red-500"
                    >
                      <i className="fa-solid fa-circle-xmark"></i>
                    </div>
                  </Dialog.Title>

                  <div className="mt-2">
                    <p className="px-3 text-sm text-gray-500">
                      {Array.isArray(selected) &&
                        data
                          ?.filter((i) => selected.includes(i._id))
                          .map((i) => i.name)
                          .join(" , ")}
                      {type === "Movies"
                        ? location.includes("add-showmovie")
                          ? selected?.name
                          : selected?.movie?.name
                        : type === "Screens"
                        ? selected?.name
                        : selected?.startTime}
                    </p>
                  </div>
                </div>
                {type === "Movies" ? (
                  <div className="  h-[65vh] overflow-scroll px-4 py-2">
                    <div className="   flex w-full  flex-wrap items-start justify-around  gap-4    pt-10">
                      {data &&
                        data.map((showmovie) => (
                          // Date.now() >=
                          //   new Date(showmovie.releaseDate).getTime() ||
                          // !showmovie.releaseDate ? (
                          <div
                            onClick={() => {
                              setSelected(showmovie);
                              if (
                                !location.includes("Movies%20revenues") &&
                                !location.includes("Detailed%20revenues")
                              ) {
                                setOpen(!open);
                              }
                            }}
                            key={showmovie._id}
                            className={`  min-w-[78px] cursor-pointer object-contain`}
                          >
                            <MovieCard
                              showMovie={
                                location.includes("add-showmovie") ||
                                location.includes("edit-showmovie") ||
                                location.includes("Movies%20revenues") ||
                                location.includes("Detailed%20revenues")
                                  ? { movie: showmovie }
                                  : showmovie
                              }
                              text="text-xs mt-1   font-base"
                              height="h-[216px] w-[146px] "
                              opacity={"opacity-100"}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                ) : type === "Time Show" ? (
                  <div className="  h-full w-full items-center  bg-white p-4   text-primary transition-all ">
                    <div className="flex h-full  w-full flex-wrap justify-around gap-2 ">
                      {data &&
                        sortShowTime(data)?.map((item) => (
                          <button
                            key={item.startTime}
                            type="button"
                            className={`${
                              location.includes("add-showmovie") ||
                              location.includes("edit-showmovie")
                                ? selected.includes(item) &&
                                  `bg-primary text-white`
                                : ``
                            }
                              ${
                                item.showTimeStatus
                                  ? "border-0 !bg-red-500 text-white hover:bg-red-500"
                                  : "hover:bg-primary"
                              }
                                drop-down-list-showTime font-base relative flex w-[84px] items-center justify-center rounded-2xl border border-primary px-2 py-2 text-sm outline-none  hover:text-white `}
                            onClick={() => {
                              !item.showTimeStatus && setSelected(item);
                              OpenHandler();
                              item.showTimeStatus && setTimesSelected(item);
                              item.showTimeStatus &&
                                setChangeShowTimeState(true);
                            }}
                          >
                            {type === "Movies"
                              ? item.movie?.name
                              : type === "Screen"
                              ? item.name
                              : item.startTime}
                            {item.showTimeStatus && (
                              <div className="popup-showTim absolute left-24 top-0 z-30 h-auto w-52 cursor-default rounded-lg bg-white shadow-lg">
                                <div className="flex w-full flex-col gap-2 rounded-lg pb-3  ">
                                  <div className="w-full  rounded-lg">
                                    <img
                                      className="h-44 w-full  rounded-lg object-cover p-2"
                                      src={item.showTimeStatus.movie?.poster}
                                      alt=""
                                    />
                                  </div>
                                  <div className="flex flex-col justify-start gap-4 text-xs text-black ">
                                    <p className="font-semibold">
                                      {item.showTimeStatus.movie?.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </button>
                        ))}
                    </div>
                  </div>
                ) : type === "Screens" ? (
                  <div className=" h-96 w-full items-center   overflow-scroll rounded-xl bg-white p-4  text-primary transition-all ">
                    <div className="flex h-full w-full flex-wrap justify-around gap-2 ">
                      {data &&
                        data?.map((item) => (
                          <button
                            type="button"
                            key={item._id}
                            className={`
                            ${
                              selected === item &&
                              `bg-primary !fill-white text-white`
                            }

                             font-base flex h-[98px] w-[154px] cursor-pointer items-center justify-center rounded-2xl border border-primary fill-primary px-2 py-2 text-center text-sm outline-none `}
                            onClick={() => {
                              setSelected(item);
                              if (
                                !location.includes("Movies%20revenues") &&
                                !location.includes("Detailed%20revenues")
                              ) {
                                setOpen(!open);
                              }
                            }}
                          >
                            {type === "Movies"
                              ? item.movie?.name
                              : type === "Screens"
                              ? item.name
                              : item.startTime}

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 48 48"
                              className="svgStyle mx-auto h-8 w-8  "
                            >
                              <path d="M16.75 44v-3h4.3v-5h-14q-1.2 0-2.1-.9-.9-.9-.9-2.1V9q0-1.2.9-2.1.9-.9 2.1-.9h34q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9h-14v5h4.3v3Zm-9.7-11h34V9h-34v24Zm0 0V9v24Z" />
                            </svg>
                          </button>
                        ))}
                    </div>
                  </div>
                ) : type === "Distributors" || type === "Users" ? (
                  <div
                    className={
                      " max-h-96 w-full items-center  overflow-scroll rounded-xl bg-white p-4  text-black transition-all"
                    }
                  >
                    {data &&
                      data.map((item) => (
                        <div
                          key={item._id}
                          className=" h-10 w-full cursor-pointer truncate  border-b-2 bg-white py-2 pl-2"
                          onClick={() => {
                            setSelected(item);
                            if (
                              !location.includes("Movies%20revenues") ||
                              location.includes("Detailed%20revenues")
                            ) {
                              setOpen(!open);
                            }
                          }}
                        >
                          {item.name}
                        </div>
                      ))}
                  </div>
                ) : type === "ShowMovie" ? (
                  <div className="  grid h-auto  w-full auto-rows-auto grid-cols-12 gap-x-5 gap-y-2  rounded-2xl p-4">
                    <div
                      className={`col-start-1  ${
                        isSelfServices ? "col-end-4" : "col-end-3"
                      } row-start-2  h-full w-full`}
                    >
                      <MovieCard
                        text="hidden"
                        height="w-full h-full"
                        opacity=""
                        showMovie={data}
                        movieSelf={isSelfServices ? true : false}
                      />
                    </div>
                    <div className=" col-start-3 col-end-10  flex flex-row items-center justify-end">
                      <h1 className=" truncate text-base font-bold">
                        Release Date:{" "}
                        {new Date(data?.releaseDate).toLocaleDateString() ||
                          "NA"}
                      </h1>
                      <div className=" flex w-20  justify-around  "></div>
                    </div>
                    <div
                      className={`${
                        isSelfServices ? "col-start-4" : "col-start-3"
                      } col-end-13 row-start-2 mt-2 mb-4 flex w-full flex-row justify-start`}
                    >
                      <ScrollContainer
                        horizontal={isSelfServices ? true : false}
                        hideScrollbars={isSelfServices ? false : true}
                        className={`${
                          isSelfServices ? "my-scroll-container" : ""
                        }`}
                      >
                        <div className="col-start-3 col-end-13 row-start-2 mt-2 mb-4 flex w-full flex-row justify-start">
                          {[...data.screens]
                            ?.sort(
                              (a, b) =>
                                +a.screen.name.split(" ")[1] -
                                +b.screen.name.split(" ")[1]
                            )
                            ?.map(({ screen, showTimes }, i) => (
                              <>
                                <div
                                  key={i}
                                  className="col-span-1 mr-2   flex basis-1/3 flex-col"
                                >
                                  <span
                                    className={`${
                                      isSelfServices ? "text-xl" : ""
                                    } text-red-500`}
                                  >
                                    {screen.type}
                                  </span>
                                  <span
                                    className={`font-base mb-4 flex h-[50px] w-[154px]  items-center  rounded-2xl  bg-white fill-primary px-2 py-2 ${
                                      isSelfServices ? "text-xl" : "text-sm"
                                    } text-primary`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 48 48"
                                      className="svgStyle mx-2 h-8 w-8"
                                    >
                                      <path d="M16.75 44v-3h4.3v-5h-14q-1.2 0-2.1-.9-.9-.9-.9-2.1V9q0-1.2.9-2.1.9-.9 2.1-.9h34q1.2 0 2.1.9.9.9.9 2.1v24q0 1.2-.9 2.1-.9.9-2.1.9h-14v5h4.3v3Zm-9.7-11h34V9h-34v24Zm0 0V9v24Z" />
                                    </svg>
                                    {screen.name}
                                  </span>

                                  <div className="relative">
                                    <div
                                      className={`relative flex ${
                                        isSelfServices
                                          ? ""
                                          : "max-h-[240px] overflow-scroll scroll-smooth"
                                      } flex-col justify-between gap-2`}
                                    >
                                      {sortShowTime(showTimes)?.map(
                                        (showtime, i) => (
                                          <div
                                            key={i}
                                            className={`${
                                              handleAvailableShowTime(showtime)
                                                ? "cursor-pointer border-primary text-primary hover:bg-primary hover:text-white"
                                                : "cursor-auto border-[#aaa] text-[#aaa] line-through"
                                            }  ${
                                              isSelfServices
                                                ? "text-base"
                                                : "text-sm"
                                            } font-base flex max-h-[98px] w-[154px] items-center justify-center rounded-2xl border px-2 py-2 text-center`}
                                            onClick={(e) => {
                                              handleAvailableShowTime(showtime)
                                                ? handleSelectedShowTime(
                                                    showtime._id,
                                                    showtime.startTime,
                                                    screen?._id
                                                  )
                                                : e.preventDefault();
                                            }}
                                          >
                                            {showtime.startTime}
                                          </div>
                                        )
                                      )}
                                    </div>{" "}
                                    {!isSelfServices && showTimes.length > 5 && (
                                      <span className="absolute -bottom-3 mx-16 h-4 w-4 animate-bounce text-primary">
                                        <i className="fa-solid fa-circle-arrow-down"></i>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </ScrollContainer>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {changeShowTimeState && (
                  <ChangeShowTime
                    onClick={() => {
                      overWriteHandler(timesSelected);
                    }}
                    changeShowTimeState={changeShowTimeState}
                    changeState={() =>
                      setChangeShowTimeState(!changeShowTimeState)
                    }
                    showTime={timesSelected}
                    movie={newMovie}
                  />
                )}
                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      setOpen(!open);
                    }}
                    className="mt-10 h-[55px]  w-[190px]  rounded-2xl border-2 border-[#3B5DAD] bg-white  px-6 py-1 text-[#3B5DAD] shadow-2xl  hover:bg-[#3B5DAD] hover:text-white "
                  >
                    Done
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
