import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useUpdatepriceSettingsMutation } from "../../../store/reservation/reservationApi";

const validationSchema = yup.object().shape({
  glassesPrice: yup
    .number()
    .typeError("Please enter a valid number")
    .required("Please enter a valid number")
    .positive("Please enter a valid number")
    .integer("Please enter a valid number"),
});

const defaultValues = {
  glassesPrice: "5",
};

function Glasses({ priceSettings }) {
  const [updatePrices, { isSuccess }] = useUpdatepriceSettingsMutation();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (priceSettings) {
      reset(priceSettings);
    }
  }, [priceSettings]);

  const onSubmit = (data) => {
    updatePrices(data);
  };

  return (
    <>
      <form
        className="flex w-full flex-col items-center justify-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex  w-1/2 flex-col">
          <label className="py-2 text-sm font-semibold text-gray-600">
            Glasses Price
          </label>
          <input
            {...register("glassesPrice")}
            type="text"
            name="glassesPrice"
            className="rounded-lg border-2 border-gray-200 px-2 py-2 outline-none focus:border-primary"
          />
        </div>
        {errors?.glassesPrice && (
          <p className="mt-2 text-sm text-red-500">
            {errors.glassesPrice.message}
          </p>
        )}
        <div className="flex w-full justify-center">
          <button
            type="submit"
            className="mt-4 rounded-lg bg-primary px-4 py-2 text-white"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}

export default Glasses;
