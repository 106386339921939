import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../config/dataService/dataService";

export const showMovieApi = createApi({
   reducerPath: "showMovieApi",
   baseQuery: fetchBaseQuery({
      baseUrl: `${BASE_URL}/show-movies`,
      credentials: "include",
   }),
   tagTypes: ["ShowMovies"],
   endpoints: ({ query, mutation }) => ({
      showMovies: query({
         query: (query) => `?${query || ""}`,
         providesTags: ["ShowMovies"],
      }),
      showMovie: query({
         query: ({ id }) => `/${id}`,
         providesTags: ["ShowMovies"],
      }),
      showMovieReservation: query({
         query: ({ id, date }) =>
            `/reservation/${id}${date ? "?date=" + date : ""}`,
         providesTags: ["ShowMovies"],
      }),
      createShowMovie: mutation({
         query: (data) => ({
            url: "/",
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["ShowMovies"],
      }),
      updateShowMovie: mutation({
         query: ({ id, ...data }) => ({
            url: `/${id}`,
            method: "PATCH",
            body: data,
         }),
         invalidatesTags: ["ShowMovies"],
      }),

      recoverShowMovie: mutation({
         query: (id) => ({
            url: `/recover/${id}`,
            method: "PATCH",
         }),
         invalidatesTags: ["ShowMovies"],
      }),

      overwriteShowTime: mutation({
         query: (data) => ({
            url: `/overwrite-showtime`,
            method: "PATCH",
            body: data,
         }),
         invalidatesTags: ["ShowMovies"],
      }),

      deleteShowtimeStatus: mutation({
         query: (body) => ({
            url: `/remove-showtime`,
            method: "DELETE",
            body,
         }),
         invalidatesTags: ["ShowMovies"],
      }),

      deleteShowMovie: mutation({
         query: (id) => ({
            url: `/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["ShowMovies"],
      }),
      toggleLockShowMovie: mutation({
         query: (id) => ({
            url: `/toggle-lock/${id}`,
            method: "PATCH",
         }),
         invalidatesTags: ["ShowMovies"],
      }),
      toggleOnlineShowMovie: mutation({
         query: (id) => ({
            url: `/toggle-online/${id}`,
            method: "PATCH",
         }),
         invalidatesTags: ["ShowMovies"],
      }),
      editShowTimeDate: mutation({
         query: ({id, data}) => ({
            url: `/show-time-status/${id}`,
            method: "PATCH",
            body: data
         }),
         invalidatesTags: ["ShowMovies"],
      }),
   }),
});

export const {
   useShowMoviesQuery,
   useShowMovieQuery,
   useShowMovieReservationQuery,
   useCreateShowMovieMutation,
   useUpdateShowMovieMutation,
   useDeleteShowMovieMutation,
   useRecoverShowMovieMutation,
   useOverwriteShowTimeMutation,
   useDeleteShowtimeStatusMutation,
   useToggleLockShowMovieMutation,
   useToggleOnlineShowMovieMutation,
   useEditShowTimeDateMutation,
} = showMovieApi;
