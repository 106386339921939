import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useAuthUserQuery } from '../../store/authUserApi';
import { toast } from 'react-toastify';
import { uiActions } from '../../store/uiSlice';
import Exchange from './Exchange';
import { socketActions } from '../../store/socketSlice';
import { useSearchParams } from 'react-router-dom';
import { useGetpriceSettingsQuery } from '../../store/reservation/reservationApi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const paymentOptions = [
  { name: 'Cash', available: true },
  { name: 'Bank Card', available: true },
];
const paymentOptionsSelfServices = [{ name: 'Bank Card', available: true }];

export default function ReserveModal({
  screen,
  className,
  chosenSeats,
  showTime,
  setIsOpenProp = undefined,
  isOpenProp = false,
  showMovie,
  isSelfServices,
  isEmergency = false,
  showButton = true,
}) {
  const reservationDate = useSelector(
    (state) => state.reservation.ReservationDate
  );
  const exchangesRes = useSelector(
    (state) => state.socket.exchangeData.oldSeats
  );
  const { data: priceSettings } = useGetpriceSettingsQuery();
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams.entries()]);
  const [isChecked, setisChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(isOpenProp);
  const [glasses, setglasses] = useState(0);
  const [payment, setpayment] = useState(paymentOptions[0].name);
  const [paymentSelf, setpaymentSelf] = useState(
    paymentOptionsSelfServices[0].name
  );
  const { data: userAgent } = useAuthUserQuery();

  const seatPrice =
    screen?.type === 'VIP' || screen?.type === 'IMAX'
      ? screen?.price
      : screen?.price + showTime?.price || 0;
  // const seatPrice = screen?.price + showTime?.price || 0;
  const dispatch = useDispatch();
  const ContextMenu = useSelector((state) => state.ui.contextMenu);
  const oldContextMenu = useSelector((state) => state.ui.oldContextMenu);

  const adminToken = useSelector((state) => state.socket.adminToken);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object({
    name: yup.string().required(),
    mobileNumber: yup
      .string()
      .required('Phone number is a required field')
      .typeError('Please enter a number only')
      .matches(phoneRegExp, 'Phone number is not valid')
      .max(11)
      .min(11),
  });

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const reserveHandler = (type) => {
    console.log(
      'hiii',
      chosenSeats.map((item, i) => ({
        seat: item._id,
        oldTicket:
          type === 'Exchange'
            ? exchangesRes?.[i]?.reservationStatus?._id
            : undefined,
        showTime: showTime?._id,
        movie: showMovie?.movie?._id,
        distributor: showMovie?.movie?.distributor,
        showMovie: showMovie?._id,
        screen: screen?._id,
        egyptian: showMovie?.movie?.egyptian,
        exchanged: exchangesRes?.length > 0,
        glasses: i === 0 ? glasses : 0,
        agent: userAgent?._id,
        price: Number(seatPrice) + Number(item.price),
        date: new Date(
          `${reservationDate}, ${showTime?.startTime}`
        ).toLocaleString(),
        payment: !isSelfServices
          ? payment || 'Cash'
          : paymentSelf || 'Bank Card',
        invitation: isChecked,
        AdminToken: adminToken,
        online:
          exchangesRes?.length > 0
            ? exchangesRes?.[i]?.reservationStatus?.online
            : false,
        onlineId:
          exchangesRes?.length > 0 &&
            exchangesRes?.[i]?.reservationStatus?.online
            ? exchangesRes?.[i]?.reservationStatus?.onlineId
            : undefined,
        onlineCustomerName:
          exchangesRes?.length > 0 &&
            exchangesRes?.[i]?.reservationStatus?.online
            ? exchangesRes?.[i]?.reservationStatus?.onlineCustomerName
            : isSelfServices
              ? getValues('name')
              : undefined,
        onlineCustomerMobile:
          exchangesRes?.length > 0 &&
            exchangesRes?.[i]?.reservationStatus?.online
            ? exchangesRes?.[i]?.reservationStatus?.onlineCustomerMobile
            : isSelfServices
              ? getValues('mobileNumber')
              : undefined,
        kiosk: isSelfServices ? true : false,
      }))
    );
    dispatch(
      socketActions.setReservation(
        chosenSeats.map((item, i) => ({
          seat: item._id,
          oldTicket:
            type === 'Exchange'
              ? exchangesRes?.[i]?.reservationStatus?._id
              : undefined,
          showTime: showTime?._id,
          movie: showMovie?.movie?._id,
          distributor: showMovie?.movie?.distributor,
          showMovie: showMovie?._id,
          screen: screen?._id,
          egyptian: showMovie?.movie?.egyptian,
          exchanged: exchangesRes?.length > 0,
          glasses: i === 0 ? glasses : 0,
          emergency: isEmergency,
          agent: userAgent?._id,
          price: Number(seatPrice) + Number(item.price),
          date: new Date(
            `${reservationDate}, ${showTime?.startTime}`
          ).toLocaleString(),
          payment: !isSelfServices
            ? payment || 'Cash'
            : paymentSelf || 'Bank Card',
          invitation: isChecked,
          AdminToken: adminToken,
          online:
            exchangesRes?.length > 0
              ? exchangesRes?.[i]?.reservationStatus?.online
              : false,
          onlineId:
            exchangesRes?.length > 0 &&
              exchangesRes?.[i]?.reservationStatus?.online
              ? exchangesRes?.[i]?.reservationStatus?.onlineId
              : undefined,
          onlineCustomerName:
            exchangesRes?.length > 0 &&
              exchangesRes?.[i]?.reservationStatus?.online
              ? exchangesRes?.[i]?.reservationStatus?.onlineCustomerName
              : isSelfServices
                ? getValues('name')
                : undefined,
          onlineCustomerMobile:
            exchangesRes?.length > 0 &&
              exchangesRes?.[i]?.reservationStatus?.online
              ? exchangesRes?.[i]?.reservationStatus?.onlineCustomerMobile
              : isSelfServices
                ? getValues('mobileNumber')
                : undefined,
        }))
      )
    );
    dispatch(
      socketActions.resetSelections({
        agent: userAgent?._id,
        data: {
          showMovie: queryParams.showmovie,
          showTime: queryParams.showtime,
          screen: queryParams.screen,
        },
      })
    );

    if (type === 'Exchange') {
      dispatch(
        socketActions.cancelReservation({
          type: 'Exchange',
          date: `${reservationDate}, ${showTime?.startTime}`,
          data: {
            agent: userAgent?._id,
            showMovie: queryParams.showmovie,
            showTime: queryParams.showtime,
            screen: queryParams.screen,
          },
        })
      );
      dispatch(socketActions.resetExchangeData());
    }
    setglasses(0);
    setpayment('');
    setpaymentSelf('');
    setisChecked(false);
    if (setIsOpenProp) setIsOpenProp(false);
    else setIsOpen(false);
    dispatch(uiActions.setContextMenu('invitation'));
  };

  useEffect(() => {
    console.log('i started zz');
  }, []);

  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  // const [mounted, setMounted] = useState(false);

  // useEffect(() => {
  //   if (!mounted) {
  //     dispatch(uiActions.setLoginPopup(false));
  //     setMounted(true);
  //   }
  // }, [dispatch, mounted]);

  useEffect(() => {
    if (
      isOpen &&
      ContextMenu !== 'invitationTrue' &&
      ContextMenu !== 'invitation' &&
      oldContextMenu?.type !== 'EmergencyReservation' &&
      !isEmergency
    )
      setisChecked(false);
    else if (
      ContextMenu === 'invitationTrue' &&
      oldContextMenu?.type === 'EmergencyReservation' &&
      isEmergency
    )
      setisChecked(true);
  }, [isOpen, ContextMenu, oldContextMenu?.type]);

  useEffect(() => {
    if (
      ContextMenu === 'invitationTrue' &&
      oldContextMenu?.type !== 'EmergencyReservation'
    ) {
      setIsOpen(true);
    }
  }, [ContextMenu, oldContextMenu?.type]);

  useEffect(() => {
    if (
      ContextMenu === 'invitationTrue' &&
      oldContextMenu?.type === 'EmergencyReservation'
    ) {
      if (setIsOpenProp) {
        setIsOpenProp(true);
      }
    }
  }, [ContextMenu, oldContextMenu?.type, setIsOpenProp]);

  const onSubmit = (data) => {
    console.log(data);
    reserveHandler();
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (isOpen) {
      console.log('ch', chosenSeats);
      console.log('seatPrice', seatPrice);
    }
  }, [isOpen]);

  const largestPrice = (data) => {
    const largestObject = data?.reduce((acc, curr) => {
      const objectPrice = curr?.price + 150;
      if (!acc || objectPrice > acc?.price) {
        return { ...curr, price: objectPrice };
      }
      return acc;
    }, null);

    return largestObject?.price;
  };

  return (
    <>
      <div
        className={classNames(` flex items-center justify-center ${className}`)}
      >
        {showButton && (
          <button
            type="button"
            onClick={() => {
              if (chosenSeats.length === 0)
                return alert('Please Select a Seat!');
              const fullDate = new Date(
                `${reservationDate}, ${showTime?.startTime}`
              );

              if (userAgent?.role !== 'Super Admin') {
                const newDate =
                  fullDate.getHours() < 7
                    ? new Date(fullDate.setDate(fullDate.getDate() + 1))
                    : fullDate;

                if (
                  new Date(
                    newDate.setMinutes(newDate.getMinutes() + 30)
                  ).getTime() <= Date.now()
                )
                  return toast.warning(
                    "You Can't Reserve after Show Started By +30 min",
                    {
                      autoClose: 1500,
                      toastId: '54sd654fdsf',
                    }
                  );
              }

              if (
                new Date(fullDate).getTime() >
                new Date().setDate(new Date().getDate() + 7)
              )
                return toast.warning(
                  "You Can't Reserve more than One Week from now",
                  {
                    autoClose: 1500,
                    toastId: '54sd654fdsf',
                  }
                );
              setIsOpen(true);
            }}
            className={`rounded-2xl bg-primary py-3 text-white ${isSelfServices ? 'px-36 text-2xl' : 'px-14'
              }`}
          >
            Reserve
          </button>
        )}
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            if (setIsOpenProp) setIsOpenProp(false);
            else setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full ${isSelfServices ? 'max-w-3xl' : 'max-w-2xl'
                    }  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
                >
                  {/* left side */}
                  {exchangesRes?.length > 0 ? (
                    <Exchange
                      onExchange={(i) => reserveHandler(i)}
                      newReservation={chosenSeats.map((i) => ({
                        movie: showMovie?.movie,
                        price: seatPrice + i.price,
                        screen: screen,
                        showTime: showTime,
                        seat: i,
                      }))}
                      setIsOpen={() => {
                        setIsOpen(false)
                        if (setIsOpenProp) setIsOpenProp(false);
                      }}
                    />
                  ) : (
                    <>
                      <div className="flex">
                        <div
                          className={`flex ${isSelfServices
                              ? 'h-[530px] max-h-[600px]  w-[300px] max-w-[300px]'
                              : 'h-[319px] w-[180px]  max-w-[180px]'
                            } `}
                        >
                          {showMovie && (
                            <img
                              src={showMovie.movie.poster}
                              alt="poster"
                              className="h-full place-self-stretch rounded-2xl object-cover"
                            />
                          )}
                        </div>
                        {/* right side */}
                        <div className="ml-6  flex-auto">
                          {isSelfServices && (
                            <form
                              className=" my-4 space-y-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div>
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium text-[#3B5CAD]"
                                >
                                  Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    {...register('name')}
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                                  />
                                  {errors.name && (
                                    <span className="text-xs text-red-500">
                                      {errors.name?.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="mobile"
                                  className="block text-sm font-medium text-[#3B5CAD]"
                                >
                                  mobile number
                                </label>
                                <div className="mt-1">
                                  <input
                                    {...register('mobileNumber')}
                                    id="mobile"
                                    name="mobileNumber"
                                    type="text"
                                    autoComplete="mobile"
                                    className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                                  />
                                  {errors.mobileNumber && (
                                    <span className="text-xs text-red-500">
                                      {errors.mobileNumber?.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </form>
                          )}
                          <span className=" text-xs text-[#607D8B]">Movie</span>
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold"
                          >
                            {showMovie?.movie?.name}
                          </Dialog.Title>
                          {/*  */}
                          <div className="mt-4 flex justify-center">
                            <div className="flex basis-1/3  flex-col">
                              <span className=" text-xs text-[#607D8B]">
                                Seats
                              </span>
                              <p className="w-[120px] truncate text-base font-semibold">
                                {chosenSeats
                                  .map((i) => i.title.split('-')[1])
                                  .join(',')}
                              </p>
                            </div>

                            <div className="flex basis-1/3  flex-col">
                              <span className=" text-xs text-[#607D8B]">
                                Screen
                              </span>
                              <p className="text-base font-semibold">
                                {screen?.name}
                              </p>
                            </div>

                            <div className="flex basis-1/3  flex-col">
                              <span className=" text-xs text-[#607D8B]">
                                Showtime
                              </span>
                              <p className="text-base font-semibold">
                                {showTime?.startTime}
                              </p>
                            </div>
                          </div>
                          {/* glasses */}
                          <div className=" mt-4 flex w-full items-center justify-around gap-4">
                            {!isSelfServices && (
                              <>
                                <h3 className="mr-4 text-sm font-semibold text-primary">
                                  3d glasses
                                </h3>
                                <div className="relative flex items-center justify-center">
                                  <button
                                    onClick={() =>
                                      setglasses((prev) =>
                                        prev > 0 ? prev - 1 : prev
                                      )
                                    }
                                    className="z-20 -mr-4"
                                  >
                                    <MinusCircleIcon className=" h-8 w-8 text-primary" />
                                  </button>
                                  <p className=" rounded-2xl border px-10">
                                    {glasses}
                                  </p>
                                  <button
                                    onClick={() =>
                                      setglasses((prev) => prev + 1)
                                    }
                                    className="z-20 -ml-4"
                                  >
                                    <PlusCircleIcon className=" h-8 w-8 text-[#00C18B]" />
                                  </button>
                                </div>
                              </>
                            )}
                            <div
                              className={`flex ${isSelfServices ? 'basis-full' : 'basis-1/3'
                                }  flex-col`}
                            >
                              <span className=" text-xs text-[#607D8B]">
                                Selected Seats
                              </span>
                              <p className="text-base font-semibold">
                                {chosenSeats?.length}
                              </p>
                            </div>
                          </div>
                          {/*  */}
                          <div className="mt-4 flex justify-center">
                            <div className="flex basis-1/3 flex-col">
                              <span className=" text-xs text-[#607D8B]">
                                Price per Seat
                              </span>
                              <p className="text-base font-semibold">
                                {isSelfServices && chosenSeats
                                  ? largestPrice(chosenSeats)
                                  : seatPrice}{' '}
                                EGP
                              </p>
                            </div>

                            <div className="flex basis-1/3  flex-col">
                              <span className=" text-xs text-[#607D8B]">
                                Price per Glasses
                              </span>
                              <p className="text-base font-semibold">
                                {glasses * (priceSettings?.glassesPrice || 20)}{' '}
                                EGP
                              </p>
                            </div>

                            <div className="flex basis-1/3  flex-col">
                              <span className=" text-xs text-[#607D8B]">
                                Total Price
                              </span>
                              <p className="text-base font-semibold">
                                {Number(chosenSeats?.length * seatPrice) +
                                  Number(
                                    chosenSeats?.reduce(
                                      (partialSum, item) =>
                                        partialSum + Number(item?.price),
                                      0
                                    ) || 0
                                  ) +
                                  Number(
                                    (glasses || 0) *
                                    (priceSettings?.glassesPrice || 20)
                                  )}{' '}
                                EGP
                              </p>
                            </div>
                          </div>
                          <div>
                            <h3 className="mt-4 text-sm font-semibold text-primary">
                              Payment method
                            </h3>

                            <div className="flex flex-row items-center gap-14">
                              {!isSelfServices ? (
                                <RadioGroup
                                  value={
                                    payment ? payment : paymentOptions[0].name
                                  }
                                  onChange={setpayment}
                                  className="mt-2 text-left"
                                >
                                  <div className="flex flex-row flex-wrap gap-2">
                                    {paymentOptions.map((option) => (
                                      <RadioGroup.Option
                                        key={option.name}
                                        value={option.name}
                                        className={({ active, checked }) =>
                                          classNames(
                                            active
                                              ? 'bg-primary text-white'
                                              : 'text-primary',
                                            checked
                                              ? 'border-transparent bg-primary !text-white hover:bg-primary'
                                              : 'border-gray-200 bg-white   ',
                                            'font-base flex  h-10 w-[126px] cursor-pointer items-center justify-center rounded-2xl border border-primary px-6 py-2 text-sm '
                                          )
                                        }
                                      >
                                        <RadioGroup.Label as="span">
                                          {option.name}
                                        </RadioGroup.Label>
                                      </RadioGroup.Option>
                                    ))}
                                  </div>
                                </RadioGroup>
                              ) : (
                                <RadioGroup
                                  value={
                                    paymentSelf
                                      ? paymentSelf
                                      : paymentOptionsSelfServices[0].name
                                  }
                                  onChange={setpaymentSelf}
                                  className="mt-2 text-left"
                                >
                                  <div className="flex flex-row flex-wrap gap-2">
                                    {paymentOptionsSelfServices.map(
                                      (option) => (
                                        <RadioGroup.Option
                                          key={option.name}
                                          value={option.name}
                                          className={({ active, checked }) =>
                                            classNames(
                                              active
                                                ? 'bg-primary text-white'
                                                : 'text-primary',
                                              checked
                                                ? 'border-transparent bg-primary !text-white hover:bg-primary'
                                                : 'border-gray-200 bg-white   ',
                                              'font-base flex  h-10 w-[126px] cursor-pointer items-center justify-center rounded-2xl border border-primary px-6 py-2 text-sm '
                                            )
                                          }
                                        >
                                          <RadioGroup.Label as="span">
                                            {option.name}
                                          </RadioGroup.Label>
                                        </RadioGroup.Option>
                                      )
                                    )}
                                  </div>
                                </RadioGroup>
                              )}
                              {/* add inv checkbox in emergencyModal later */}
                              {!isSelfServices && (
                                <div>
                                  <div className="flex justify-center">
                                    <div className="form-check form-switch flex items-center gap-1">
                                      <input
                                        className="form-check-input float-left -ml-10 h-5 w-9 cursor-pointer appearance-none rounded-full bg-white  bg-contain bg-no-repeat align-top shadow-sm focus:outline-none"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckDefault"
                                        onChange={() =>
                                          setisChecked(!isChecked)
                                        }
                                        checked={isChecked}
                                        onClick={() => {
                                          if (!isChecked) {
                                            dispatch(
                                              uiActions.setLoginPopup(true)
                                            );
                                            dispatch(
                                              uiActions.setContextMenu(
                                                'invitation'
                                              )
                                            );
                                            setIsOpen(false);
                                            if (setIsOpenProp) setIsOpenProp(false);
                                          } else {
                                            dispatch(
                                              uiActions.setLoginPopup(false)
                                            );
                                          }
                                        }}
                                      />
                                      <label
                                        className="form-check-label inline-block text-gray-800"
                                        htmlFor="flexSwitchCheckDefault"
                                      >
                                        invitation
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-8 flex-auto pb-8">
                        <button
                          type="button"
                          className={`absolute inset-x-0 bottom-0 h-14 w-full justify-center rounded-md border border-transparent ${exchangesRes?.length > 0
                              ? 'bg-red-500'
                              : 'bg-primary'
                            }  px-4 py-2 text-sm font-medium  text-white`}
                          onClick={() => {
                            if (chosenSeats.length === 0) {
                              return alert('Please Select a Seat!');
                            } else if (isSelfServices) {
                              handleButtonClick();
                            } else {
                              reserveHandler();
                              setIsOpen(false);
                              if (setIsOpenProp) setIsOpenProp(false);
                            }
                          }}
                        >
                          {exchangesRes?.length > 0
                            ? 'Confirm Exchange'
                            : 'Confirm reservation'}
                        </button>
                      </div>
                    </>
                  )}
                  { }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
