import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MoviesRevenuesTable from './MoviesRevenuesTable';
import ReprintedTickets from './ReprintedTickets';
import ScreenRevenue from './ScreenRevenue';
import CancelTickets from './CancelTickets';
import { useReportsQuery } from '../../../store/reportsApi';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import TotalRevenues from './TotalRevenues';
import DetailedRevenues from './DetailedRevenues';
import DistributorsRevenues from './DistributorsRevenues';
import DetailedSales from './DetailedSales';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TotalSales from './TotalSales';
import { sortReportsShowtime } from '../../../utiles/sortReportsShowtime';
import ExchangeReport from './ExchangeReport';
import TotalRevenuesByDay from './TotalRevenuesByDay';
import GlassesTable from './GlassesTable';
import OnlineTable from './onlineTable';
import RemovedGlasses from './removedGlasses';
import OnlineDetailedRevenues from './onlineDetailedRevenues';
import DetailedRevenues2 from './DetailedRevenues2';
import ExportExcel from '../../../utiles/Excelexport';
import { FaFilePdf } from 'react-icons/fa';

function MoviesRevenues() {
  const location = useLocation().pathname;
  const { state } = useLocation();
  const componentRef = useRef();

  const reportsData = useSelector((state) => state.reports.reportsData);

  const MoviesRevenues = [
    'ضريبه قيمه مضافه',
    'رسم الدرن',
    'رسم شرطه',
    'رسم دعم السينيما',
    'رسم البر',
    'خدمات اخرى',
    'جملة ضريبه الملاهى المستحقة',
    'صافى الايراد',
    'مقدار ضريبه الملاهى المحتسبه',
    'جملة صافى مقابل الدخول',
    'جمله ايراد التذاكر المباعه',
    'عدد التذاكر',
    'النوع',
    'الحفله',
  ];

  const ReprintedTicketsCol = [
    'المستخدم',
    'الحفلة',
    'نوع القاعه',
    'القاعه',
    'الفيلم',
    'رقم التذكرة',
    'وقت اعادة الطباعه',
  ];

  const ScreenRevenueCol = [
    'الاجمالى',
    'دمغة',
    'عدد التذاكر',
    'اونلاين',
    'اعانه درن',
    'اعانه سينيما',
    'اعانه بر',
    'خدمات واخرى',
    'رسم شرطه',
    'الضريبة',
    'القاعه',
  ];
  const CancelTicketsCol = [
    'المشرف',
    'المستخدم',
    'اللاغي',
    'السعر',
    'مقعد',
    'تاريخ الحفله',
    'توقيت الحذف',
    'توقيت البيع',
    'الفيلم',
    'القاعه',
  ];
  const invitationTickets = [
    'المستخدم',
    'رقم',
    'الصف',
    'تاريخ الحفله',
    'توقيت البيع',
    'الفيلم',
    'القاعه',
    'المشرف',
  ];
  const TotalRevenuesCol = [
    'الضريبة',
    'رسم شرطه',
    'خدمات واخرى',
    'اعانه بر',
    'اعانه سينيما',
    'اعانه درن',
    'دمغة',
    'الاجمالى',
  ];
  const DetailedRevenuesCol = [
    'الصافى',
    'القيمه المضافة',
    'الضريبة',
    'الاجمالى',
    'عدد التذاكر',
    'الموزع',
    'اسم العمل',
  ];
  const DetailedRevenues2Col = [
    'الصافى',
    'القيمه المضافة',
    'الضريبة',
    'عادي',
    'ديلوكس',
    'بريميوم',
    'اجمالي الاونلاين',
    'اونلاين',
    'اجمالي البطاقات',
    'بطاقات مصرفية',
    'اجمالي النقدي',
    'نقدي',
    'عدد التذاكر',
    'الموزع',
    'اسم العمل',
  ];
  const onlineDetailedRevenuesCol = [
    'الاجمالى',
    'عدد التذاكر',
    'الموزع',
    'اسم العمل',
  ];
  const TotalRevenuesByDayData = [
    'الصافى',
    'القيمه المضافة',
    'الضريبة',
    'الاجمالى',
    'عدد التذاكر',
    'الموزع',
    'اسم العمل',
  ];
  const DistributorsRevenuesCol = [
    'الصافى',
    'القيمه المضافة',
    'الضريبة',
    'الاجمالى',
    'عدد التذاكر',
    'اونلاين',
    'الفيلم',
  ];
  const DetailedSalesCol = [
    'الحاله',
    'طريقة الدفع',
    'السعر',
    'رقم',
    'الصف',
    'الفيلم',
    'الحفله',
    'التاريخ',
    'القاعه',
  ];
  const DetailedSalesCol3 = [
    'الحاله',
    'المبلغ',
    'اجمالي البطاقات المصرفية',
    'بطاقات مصرفية',
    'اجمالي النقدي',
    'نقدي',
    'العدد',
    'الفيلم',
    'الحفله',
    'التاريخ',
    'السعر',
  ];

  const TotalSalesCol = [
    'جمله التذاكر المباعه',
    'اجمالي البطاقات المصرفية',
    'بطاقات مصرفية',
    'اجمالي النقدي',
    'نقدي',
    'عدد التذاكر',
    'الوظيفه',
    'العامل',
  ];
  const TotalSalesInadvanceV2Col = [
    'طريقة الدفع',
    'السعر',
    'رقم',
    'الصف',
    'الفيلم',
    'الحفله',
    'التاريخ',
    'القاعه',
  ];
  const glassesCol = [
    'تاريخ',
    'اجمالي البطاقات',
    'بطاقات مصرفية',
    'اجمالي النقدي',
    'نقدي',
    'السعر',
    'العدد',
    'المستخدم',
  ];
  const onlineCol = [
    'القاعه',
    'الفيلم',
    'توقيت البيع',
    'الحفلة',
    'مقعد',
    'السعر',
    'رقم العميل',
    'اسم العميل',
    'الطلب',
  ];
  const removedGlassesCol = [
    'طريقة الدفع',
    'السعر',
    'الكمية',
    'المستخدم',
    'المشرف',
    'توقيت الحذف',
  ];
  const { data: reports, isLoading } = useReportsQuery(reportsData, {
    refetchOnMountOrArgChange: true,
  });
  const printHandler = useReactToPrint({
    content: () => componentRef.current,
  });
  let navigate = useNavigate();


  const moviesUnique =
    location.includes('/viewReport/Movies%20revenues') &&
    reports?.map((movieReportData) => {
      return {
        movie: movieReportData['الفيلم'],
        screen: movieReportData['القاعه'],
        distributor: movieReportData['الموزع'],
      };
    });

  const dataUnique =
    location.includes('/viewReport/Movies%20revenues') &&
    moviesUnique?.filter(
      (moviesUnique, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.screen === moviesUnique.screen && t.movie === moviesUnique.movie
        )
    );

  return (
    <>
      <div
        className="flex w-auto max-w-[600px] cursor-pointer items-center gap-2 text-xl font-bold text-primary"
        onClick={() => navigate(-1)}
      >
        <ArrowLeftIcon className="h-5 w-5 text-primary" />
        <span className="text-primary">Back</span>
      </div>
      <div ref={componentRef}>
        {location.includes('/viewReport/Movies%20revenues') && !isLoading ? (
          <div className="flex flex-col gap-4">
            {dataUnique &&
              [...dataUnique]
                ?.sort(
                  (a, b) => +a.screen.split(' ')[1] - +b.screen.split(' ')[1]
                )
                ?.map((i) => (
                  <div key={i} className="printLandscape test text-sm">
                    <style type="text/css" media="print">
                      {
                        '\
                        @page { size: A4 landscape; }\
                      '
                      }
                    </style>
                    <MoviesRevenuesTable
                      singleDate={state.startDate}
                      data={MoviesRevenues}
                      movieReportData={sortReportsShowtime(
                        reports?.filter(
                          (movie) =>
                            movie?.['الفيلم'] === i.movie &&
                            movie?.['القاعه'] === i.screen
                        ),
                        'الحفله'
                      )}
                    />
                  </div>
                ))}
          </div>
        ) : location.includes('/viewReport/Reprinted%20tickets') ? (
          <div className="print ">
            <ReprintedTickets
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={ReprintedTicketsCol}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Screens%20revenues') ? (
          <div className="print">
            <ScreenRevenue
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={ScreenRevenueCol}
              movieReportData={reports}
              Choices={state.dataChoices}
            />
          </div>
        ) : location.includes('/viewReport/Cancelled%20tickets') ? (
          <div className="print">
            <CancelTickets
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={CancelTicketsCol}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Total%20revenues') ? (
          <div className="print">
            <TotalRevenues
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={TotalRevenuesCol}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Detailed%20revenues%202') ? (
          <div className="print">
            {reports?.map((i) => (
              <DetailedRevenues2
                startDate={moment(state?.startDate).format('DD/MM/YYYY')}
                endDate={moment(state?.endDate).format('DD/MM/YYYY')}
                data={DetailedRevenues2Col}
                movieReportData={
                  i.data &&
                  [...i.data].sort(
                    (a, b) => b['عدد التذاكر'] - a['عدد التذاكر']
                  )
                }
                date={i.date}
                Choices={state.data}
              />
            ))}
          </div>
        ) : location.includes('/viewReport/Detailed%20revenues') ? (
          <div className="print">
            {reports?.map((i) => (
              <DetailedRevenues
                startDate={moment(state?.startDate).format('DD/MM/YYYY')}
                endDate={moment(state?.endDate).format('DD/MM/YYYY')}
                data={DetailedRevenuesCol}
                movieReportData={
                  i.data &&
                  [...i.data].sort(
                    (a, b) => b['عدد التذاكر'] - a['عدد التذاكر']
                  )
                }
                date={i.date}
                Choices={state.data}
              />
            ))}
          </div>
        ) : location.includes('/viewReport/online%20Detailed%20revenues') ? (
          <div className="print">
            {reports?.map((i) => (
              <OnlineDetailedRevenues
                startDate={moment(state?.startDate).format('DD/MM/YYYY')}
                endDate={moment(state?.endDate).format('DD/MM/YYYY')}
                data={onlineDetailedRevenuesCol}
                movieReportData={
                  i.data &&
                  [...i.data].sort(
                    (a, b) => b['عدد التذاكر'] - a['عدد التذاكر']
                  )
                }
                date={i.date}
                Choices={state.data}
              />
            ))}
          </div>
        ) : location.includes('/viewReport/Distributors%20revenues') ? (
          <div className="print ">
            <DistributorsRevenues
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={DistributorsRevenuesCol}
              movieReportData={reports}
              Choices={state.dataChoices}
            />
          </div>
        ) : location.includes('/viewReport/Detailed%20sales%203') ? (
          <div className="print">
            <DetailedSales
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={DetailedSalesCol}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Total%20sales%20inadvance2') ? (
          <div className="print">
            <DetailedSales
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={TotalSalesInadvanceV2Col}
              movieReportData={reports}
              title={"2 كشف حساب الشباك اجمالي مقدم"}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Total%20sales%20inprevious2') ? (
          <div className="print">
            <DetailedSales
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={TotalSalesInadvanceV2Col}
              movieReportData={reports}
              title={"2 كشف حساب الشباك اجمالي مؤخر"}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Total%20sales') ? (
          <div className="print">
            <TotalSales
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={TotalSalesCol}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Total%20sales%20inadvance') ? (
          <div className="print">
            <TotalSales
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={TotalSalesCol}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Total%20sales%20inprevious') ? (
          <div className="print">
            <TotalSales
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={TotalSalesCol}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Detailed%20sales') ? (
          <div className="print">
            <DetailedSales
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={DetailedSalesCol3}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('/viewReport/Invitation') ? (
          <div className="print">
            <CancelTickets
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={invitationTickets}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('viewReport/Exchange%20Report') ? (
          <div className="print">
            <ExchangeReport
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('viewReport/Total%20Revenues%20By%20Day') ? (
          <div className="print">
            <TotalRevenuesByDay
              startDate={moment(state?.data?.date[0]).format('DD/MM/YYYY')}
              endDate={moment(state?.data?.date[1]).format('DD/MM/YYYY')}
              data={TotalRevenuesByDayData}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('viewReport/glasses-report') ? (
          <div className="print">
            <GlassesTable
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={glassesCol}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : location.includes('viewReport/online') ? (
          <div className="print">
            <OnlineTable
              startDate={moment(state?.data?.date[0]).format('DD/MM/YYYY')}
              endDate={moment(state?.data?.date[1]).format('DD/MM/YYYY')}
              data={onlineCol}
              movieReportData={reports}
              titel={'كشف الاونلاين '}
              Choices={state.data}
            />
          </div>
        ) : location.includes('viewReport/refund-glasses-report') ? (
          <div className="print">
            <RemovedGlasses
              startDate={moment(state?.startDate).format('DD/MM/YYYY')}
              endDate={moment(state?.endDate).format('DD/MM/YYYY')}
              data={removedGlassesCol}
              movieReportData={reports}
              Choices={state.data}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="fixed right-14 top-20 z-50 ">
        <div className="flex flex-wrap flex-col items-center justify-center">
          <button
            onClick={printHandler}
            className=" rounded-lg border-2 border-[#3B5DAD] bg-white  p-5 text-[#3B5DAD] shadow-2xl  hover:bg-[#3B5DAD] hover:text-white "
          >
          <FaFilePdf className="scale-150" />
          </button>
          {/* <button
            onClick={() => exportToXLSX()}
            className="mt-10 h-[55px] w-[190px]  rounded-2xl border-2 border-[#3B5DAD] bg-white  px-6 py-1 text-[#3B5DAD] shadow-2xl  hover:bg-[#3B5DAD] hover:text-white "
          >
            Download As Excell
          </button> */}
          <ExportExcel
            inputData={
              reports && reports?.[0]?.data
                ? reports?.map((ele) => ele.data).flat()
                : reports
            }
            fileName={'Excel Export'}
          />
        </div>
      </div>
    </>
  );
}

export default MoviesRevenues;
