import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  exChangePopup: false,
  loginPopup: false,
  refundModal: false,
  detailsModal: { show: false, seat: undefined },
  contextMenu: '',
  oldContextMenu: '',
  contextMenuSet: {},
};

const uiSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setExChangePopup(state, { payload }) {
      state.exChangePopup = payload;
    },
    setLoginPopup(state, { payload }) {
      state.loginPopup = payload;
    },
    setContextMenu(state, { payload }) {
      if (
        payload !== 'invitationTrue' &&
        state.contextMenu !== 'invitationTrue'
      )
        state.oldContextMenu = state.contextMenu;

      state.contextMenu = payload;
    },
    setContextMenuSet(state, { payload }) {
      state.contextMenuSet = payload;
    },
    setRefundModal(state, { payload }) {
      state.refundModal = payload;
    },
    setDetailsModal(state, { payload }) {
      state.detailsModal = payload;
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
