import React from "react";
import { Link } from "react-router-dom";
import ScreenCard from "../../components/screens/ScreenCard";
import SearchBar from "../../components/ui-assets/SearchBar";
import { useScreensQuery } from "../../store/screensApi";
import { Helmet } from "react-helmet";

const ScreenList = () => {
  const { data: screens, isLoading } = useScreensQuery();

  return (
    <div className="container relative py-10">
      <Helmet>
        <title>Screen List</title>
      </Helmet>
      <div className="sticky top-0 flex flex-col items-center justify-between gap-4 bg-white py-4 md:flex-row">
        <SearchBar />
        <Link
          className="rounded-2xl bg-primary px-14 py-4 text-white"
          to="/add-screen"
        >
          Add screen
        </Link>
      </div>
      <div className="flex items-center justify-center">
        <div className=" grid grid-cols-1 content-center gap-x-4 gap-y-4 py-4  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 ">
          {!isLoading &&
            screens &&
            [...screens]
              ?.sort(
                (a, b) => +a.template.split("-")[1] - +b.template.split("-")[1]
              )
              ?.map((screen) => (
                <ScreenCard key={screen._id} screen={screen} />
              ))}
        </div>
      </div>
    </div>
  );
};

export default ScreenList;
