import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../components/ui-assets/SearchBar";
import { useShowMoviesQuery } from "../../store/showMovieApi";
import ShowMoviesCard from "../../components/showMovies/ShowMovieCard";
import Filters from "../../components/ui-assets/Filters";
import { Helmet } from "react-helmet";

const filterData = [
  {
    id: 1,
    name: "Active Show Movies",
    value: "Active Show Movies",
  },
  {
    id: 2,
    name: "Deleted Show Movies",
    value: "Deleted Show Movies",
  },
];

const ShowMoviesList = () => {
  const [filterField, setfilterField] = useState(filterData[0].name);
  const {
    data: showMovies,
    isLoading,
    isSuccess,
    refetch,
  } = useShowMoviesQuery(
    `${filterField === "Active Show Movies" ? "" : "removed=true"}`
    );
    console.log(showMovies)

  return (
    <>
      <div className="container relative overflow-scroll py-10">
        <Helmet>
          <title>ShowMovies List</title>
        </Helmet>
        <div className="sticky top-0 flex flex-col items-center justify-between gap-4 bg-white py-4 md:flex-row">
          <SearchBar />
          <>
            <Filters setfilterData={setfilterField} filterData={filterData} />
          </>
          <Link
            className="rounded-2xl bg-primary px-14 py-4 text-white"
            to="/add-showmovie"
          >
            Add ShowMovie
          </Link>
        </div>

        <div className=" flex w-full flex-col  content-center gap-2 overflow-scroll py-4  ">
          <>
            {filterField === "Deleted Show Movies" ? (
              <>
                {!isLoading &&
                  showMovies &&
                  showMovies.map(
                    (item) =>
                      item?.removed && (
                        <ShowMoviesCard
                          key={item._id}
                          showMovie={item}
                          removed={item?.removed}
                          reFetch={() => {
                            refetch();
                          }}
                        />
                      )
                  )}
              </>
            ) : (
              <>
                {!isLoading &&
                  showMovies &&
                  isSuccess &&
                  showMovies.map(
                    (item) =>
                      !item?.removed && (
                        <ShowMoviesCard
                          key={item._id}
                          showMovie={item}
                          removed={item?.removed}
                          reFetch={() => {
                            refetch();
                          }}
                        />
                      )
                  )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default ShowMoviesList;
