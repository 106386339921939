import { useDispatch, useSelector } from "react-redux";
import { socketActions } from "../../store/socketSlice";
import { uiActions } from "../../store/uiSlice";
import { useAuthUserQuery } from "../../store/authUserApi";
import { useLocation } from "react-router-dom";

const ContextMenu = ({ seat }) => {
  const dispatch = useDispatch();
  const adminToken = useSelector((state) => state.socket.adminToken);
  const location = useLocation().pathname;

  const { data: user } = useAuthUserQuery();

  const showDel =
    !seat.reservationStatus.exchanged ||
    (seat.reservationStatus.exchanged &&
      seat.reservationStatus.agent === user._id);

  return (
    <>
      <div className=" absolute bottom-[-82px] right-[-140px] z-10 flex w-36  flex-col rounded-lg bg-gray-900 ">
        {showDel && seat?.reservationStatus?.paid && !seat?.reservationStatus?.online && !location.includes("/selfReserve") && (
          <div
            onClick={() => {
              dispatch(uiActions.setContextMenu("Delete"));
              !adminToken ||
              (seat.status === "Emergency" && !seat.reservationStatus)
                ? dispatch(uiActions.setLoginPopup(true))
                : dispatch(uiActions.setExChangePopup(true));
            }}
            className="flex h-8 cursor-pointer items-center border-b-2 border-zinc-600 pl-2 text-[10px] text-white"
          >
            <i className="fa-solid fa-trash-can mr-2"></i>
            Delete
          </div>
        )}

        {seat?.reservationStatus?.paid && !location.includes("/selfReserve") && (
          <div
            onClick={() => {
              dispatch(uiActions.setContextMenu("Reprint"));
              dispatch(uiActions.setContextMenuSet(seat));
              !adminToken
                ? dispatch(uiActions.setLoginPopup(true))
                : dispatch(socketActions.reprintReservation(seat));
            }}
            className="flex h-8 cursor-pointer items-center border-b-2 border-zinc-600 pl-2 text-[10px] text-white"
          >
            <i className="fa-solid fa-repeat mr-2"></i>
            Reprint
          </div>
        )}
        {!seat?.reservationStatus?.invitation && seat?.reservationStatus?.paid && !location.includes("/selfReserve") && (
          <div
            onClick={() => {
              dispatch(uiActions.setContextMenu("Exchange"));
              !adminToken
                ? dispatch(uiActions.setLoginPopup(true))
                : dispatch(socketActions.setExchangeData());
            }}
            className="flex h-8 cursor-pointer items-center border-b-2 border-zinc-600 pl-2 text-[10px] text-white"
          >
            <i className="fa-solid fa-repeat mr-2"></i>
            Exchange
          </div>
        )}
        {seat?.reservationStatus?.paid && !location.includes("/selfReserve") && (
          <div
            onClick={() => {
              dispatch(uiActions.setContextMenu("Details"));
              dispatch(uiActions.setDetailsModal({ show: true, seat }));
            }}
            className="flex h-8 cursor-pointer items-center  pl-2 text-[10px] text-white"
          >
            <i className="fa-info fa-solid mr-2"></i>
            Details
          </div>
        )}
      </div>
    </>
  );
};

export default ContextMenu;
