export const sortReportsShowtime = (showTime, x) => {
  const showTimesTest =
    showTime &&
    [...showTime]?.sort(
      (a, b) =>
        new Date(`8/10/2022, ${a[`${x}`]}`).getTime() -
        new Date(`8/10/2022, ${b[`${x}`]}`).getTime()
    );
  const breakingPoing = showTimesTest?.findIndex(
    (i) =>
      new Date(`8/10/2022, ${i[`${x}`]}`).getTime() >=
      new Date(`8/10/2022, 07:00 AM`).getTime()
  );
  const lastTimes = showTimesTest?.splice(0, breakingPoing);
  return showTimesTest && lastTimes && [...showTimesTest, ...lastTimes];
};
