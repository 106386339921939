import { DocumentAddIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";

const ScreenCard = ({ screen }) => {
  return (
    <div className=" flex h-[170px] w-[199px] flex-col justify-center rounded-2xl border border-[#CFD8DC] p-4">
      <div className="flex  flex-col items-center justify-center">
        <div className="flex items-center gap-2">
          <img src="/screen.svg" alt="screen" />
          <span className="text-2xl font-bold">{screen.name}</span>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="">
          <p className="py-3 text-left font-medium text-[#00C18B]">
            Type: <span className="text-red-500">{screen.type}</span>
          </p>
          <p className="py-3 text-left font-medium text-[#00C18B]">
            Price: {screen.price} EGP
          </p>
        </div>
        <div className="flex flex-col justify-center gap-4 ">
          <Link
            title="Edit"
            to={`/edit-screen/${screen._id}`}
            className="flex h-8 w-8 items-center justify-center rounded-lg bg-white  text-[#3B5DAD] shadow-md hover:text-[#3B5DAD] focus:outline-none "
          >
            <span className="sr-only">Edit</span>
            <img src="/edit.svg" alt="edit" />
          </Link>
          <Link
            title="add formation"
            to={`/add-formation/${screen._id}`}
            className="flex h-8 w-8 items-center justify-center rounded-lg bg-white  text-[#3B5DAD] shadow-md hover:text-[#3B5DAD] focus:outline-none "
            state={screen.name}
          >
            <span className="sr-only">add formation</span>
            <DocumentAddIcon className="h-6 w-6" />
          </Link>
        </div>
      </div>
      {/* <div className="flex flex-wrap justify-between gap-2">
        {screen.showTimes.map((showtime) => (
          <span className="rounded-lg bg-[#00C18B] px-2 py-1 text-xs text-white">
            {showtime.startTime}
          </span>
        ))}
      </div> */}
    </div>
  );
};

export default ScreenCard;
