import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Spinner from "../components/ui-assets/Spinner";
import { useAuthUserQuery } from "../store/authUserApi";

const PublicRoutes = () => {
   const { data: user } = useAuthUserQuery();
   return user ? <Navigate to="/users" /> : <Outlet />;
};

export default PublicRoutes;
