import { ArrowLeftIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import MovieCard from "../../components/reservation/MovieCard";
import { useShowMoviesQuery } from "../../store/showMovieApi";
import { useAuthUserQuery } from "../../store/authUserApi";
import { Helmet } from "react-helmet";

const MoviesPage = () => {
  const { data: showMovies, isLoading } = useShowMoviesQuery("reservation=yes");
  const { data: user } = useAuthUserQuery();

  return (
    <div className="container relative py-10">
      <Helmet>
        <title>Movies Page</title>
      </Helmet>
      {user.role !== "Reservation" && (
        <Link
          to={"/users"}
          className=" flex w-auto max-w-[600px] items-center gap-2 text-xl font-bold text-primary"
        >
          <ArrowLeftIcon className="h-5 w-5 text-primary" />
          <span className="text-primary">Back</span>
        </Link>
      )}
      <div className="mt-4 flex items-center justify-center">
        <div className=" grid  grid-cols-1 items-start gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 lg:gap-8 xl:grid-cols-6">
          {!isLoading &&
            showMovies &&
            showMovies.map((showmovie, i) => (
              <Link key={i} to={`/reservation/${showmovie._id}`}>
                <MovieCard
                  height="h-[270px] w-[182px]"
                  showMovie={showmovie}
                  key={showmovie._id}
                  text="text-sm font-medium mt-4"
                  opacity=""
                />
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MoviesPage;
