import React from "react";
import { useParams } from "react-router-dom";
import ShowMovieForm from "../../components/showMovies/ShowMovieForm";

import { useMoviesQuery } from "../../store/moviesApi";
import { useScreensQuery } from "../../store/screensApi";
import {
  useCreateShowMovieMutation,
  useShowMovieQuery,
  useUpdateShowMovieMutation,
} from "../../store/showMovieApi";
import { useShowTimesQuery } from "../../store/showTimesApi";
import CustomToast from "../../utiles/CustomToast";
import { Helmet } from "react-helmet";

const ShowMoviePage = () => {
  const { id } = useParams();
  const { data: Movies } = useMoviesQuery();
  const { data: screens } = useScreensQuery();
  const { data: showTimes } = useShowTimesQuery();
  const [createShowMovie, { isSuccess, isError, error }] =
    useCreateShowMovieMutation();
  const [
    updateShowMovie,
    { isSuccess: updateIsSuccess, updateIsError, updateError },
  ] = useUpdateShowMovieMutation();

  const { data: showMovie } = useShowMovieQuery({ id }, { skip: !!!id });

  const submitHandler = (data) => {
    createShowMovie(data);
  };
  const updateHandler = (date) => {
    updateShowMovie({ id, ...date });
  };

  return (
    <>
      <Helmet>
        <title>ShowMovie Page</title>
      </Helmet>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/showmovies"
        />
      )}
      {(updateIsError || updateIsSuccess) && (
        <CustomToast
          IsError={updateIsError}
          error={updateError}
          isSuccess={updateIsSuccess}
          path="/showmovies"
        />
      )}
      <div className="flex flex-col">
        {((!id && !showMovie) || (showMovie && id)) && (
          <ShowMovieForm
            movies={Movies}
            screens={screens}
            showTimes={showTimes}
            onSubmit={id && showMovie ? updateHandler : submitHandler}
            showMovie={id && showMovie}
          />
        )}
      </div>
    </>
  );
};

export default ShowMoviePage;
