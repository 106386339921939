import React, { useState } from "react";
import Filters from "../../components/ui-assets/Filters";
import Table from "../../components/Reports/Reports table/Table";
import {
  useGlassesTaxsQuery,
  useTicketsTaxsQuery,
} from "../../store/reportsApi";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Loader } from "@mantine/core";

const TaxsPage = () => {
  const filterData = [
    {
      id: 1,
      name: "Tickets",
      value: "Tickets",
    },
    {
      id: 2,
      name: "3d Glassess",
      value: "3d Glassess",
    },
  ];
  const [filterField, setfilterField] = useState(filterData[0].name);

  const dataTickets = [
    "اسم الفيلم",
    "الاجمالي",
    "الموزع",
    "الصافي",
    "التاريخ والوقت",
    "القيمه المضافة",
  ];
  const dataGlasses = ["الصنف", "العدد", "المبلغ"];

  const date = {
    startDate: `${moment(new Date()).format()}`,
    endDate: `${moment(
      new Date(new Date().setDate(new Date().getDate() + 1))
    ).format()}`,
  };

  const { data: ticketsData = [], isLoading: ticketsLoading } =
    useTicketsTaxsQuery(date);
  const { data: glassesData = [], isLoading: glassesLoading } =
    useGlassesTaxsQuery(date);

  return (
    <div>
      <Helmet>
        <title>Taxs Page</title>
      </Helmet>
      <div className="mb-3 flex items-center justify-center">
        <Filters setfilterData={setfilterField} filterData={filterData} />
      </div>
      {filterField === "Tickets" ? (
        <div className="mt-5 w-full pb-7">
          {ticketsLoading ? (
            <div className="flex h-[calc(100vh-300px)] items-center justify-center">
              <Loader color="red" size="xl" variant="dots" />
            </div>
          ) : !ticketsLoading && ticketsData?.length === 0 ? (
            <h1 className="text-center text-xl font-extrabold text-red-600">
              لا يوجد بيانات للعرض
            </h1>
          ) : (
            <>
              <div className="text-right">
                <button
                  className="mb-5 w-[240px] whitespace-nowrap rounded-2xl bg-primary px-4 py-2 text-white md:w-fit"
                  onClick={() => console.log("hi")}
                >
                  Send to Taxs
                </button>
              </div>
              <Table
                movieReportData={ticketsData[0]}
                data={dataTickets}
                tax="tax"
              />
            </>
          )}
        </div>
      ) : (
        <div className="mt-5 w-full pb-7">
          {glassesLoading ? (
            <div className="flex h-[calc(100vh-300px)] items-center justify-center">
              <Loader color="red" size="xl" variant="dots" />
            </div>
          ) : !glassesLoading && glassesData?.length === 0 ? (
            <h1 className="text-center text-xl font-extrabold text-red-600">
              لا يوجد بيانات للعرض
            </h1>
          ) : (
            <>
              <div className="text-right">
                <button
                  className="mb-5 w-[240px] whitespace-nowrap rounded-2xl bg-primary px-4 py-2 text-white md:w-fit"
                  onClick={() => console.log("hi")}
                >
                  Send to Taxs
                </button>
              </div>
              <Table
                movieReportData={glassesData[0]}
                data={dataGlasses}
                tax="tax"
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TaxsPage;
