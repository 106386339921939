import React, { useState } from "react";
import Table from "./Table";
import { cinemaNameInReport } from "../../../config/dataService/dataService";
import moment from "moment";
import SearchBar from "../../ui-assets/SearchBar";

function OnlineTable({
  movieReportData,
  data,
  startDate,
  endDate,
  titel,
  Choices,
}) {
  const [nameFilter, setNameFilter] = useState("");
  const [numberFilter, setNumberFilter] = useState("");

  const filteredData = movieReportData?.filter(
    (item) =>
      item["اسم العميل"]?.toLowerCase().startsWith(nameFilter.toLowerCase()) &&
      item["رقم العميل"]?.toString().startsWith(numberFilter)
  );
  const total = filteredData?.reduce(
    (acc, curr) => {
      return {
        total: acc.total + curr["السعر"],
      };
    },
    {
      total: 0,
    }
  );
  return (
    <div className="flex w-full flex-col items-center p-5 ">
      <div className="self-start">
        <img alt="logo" className=" w-36" src="/logo.png"></img>
      </div>
      <div className="mb-6 flex w-[530px] flex-row-reverse items-end justify-between self-end">
        <div className="">
          <p>
            التاريخ من {startDate} الي{" "}
            {moment(endDate, "DD/MM/YYYY")
              .subtract(1, "days")
              .format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="flex flex-col  items-center border-b-8 ">
          <p>{cinemaNameInReport}</p>
          <p>{titel ? titel : " كشف حساب النظارات"}</p>
        </div>
      </div>
      <div className="my-2 flex  w-full items-center justify-center gap-10 ">
        <SearchBar
          onChange={setNameFilter}
          value={nameFilter}
          placeHolder="Search Name"
        />
        <SearchBar
          onChange={setNumberFilter}
          value={numberFilter}
          placeHolder="Search Mobile"
        />
      </div>
      <div className="my-2 w-full  text-start ">
        <p>
          {`${
            Choices?.seatsType?.length > 1 ? "" : `${Choices?.seatsType[0]} ,`
          } ${Choices?.movieType || "ALL"}`}
        </p>
      </div>
      <div className=" flex w-full flex-col items-center">
        <div className="mt-5 w-full">
          {movieReportData?.length === 0 ? (
            <h1 className="text-xl  font-extrabold text-red-600">
              لا يوجد بيانات للعرض
            </h1>
          ) : (
            <>
              <Table movieReportData={filteredData} data={data} total={total} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default OnlineTable;
