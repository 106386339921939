import { RadioGroup } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Roles({ setSelected, selected, settings }) {
  return (
    <>
      <div className="space-y-2">
        <RadioGroup value={selected} onChange={setSelected}>
          <div className="mt-1 flex flex-wrap gap-2 -space-y-px rounded-md bg-white text-center shadow-sm">
            {settings.map((setting, settingIdx) => (
              <RadioGroup.Option
                key={setting.name}
                value={setting.name}
                className={({ checked }) =>
                  classNames(
                    checked
                      ? "z-10 border-sky-200 bg-[#3B5DAD] "
                      : "border-[#3B5DAD]",
                    "text-xm  relative flex  cursor-pointer  items-center justify-center rounded-2xl border py-3  px-2 font-medium shadow-sm focus:outline-none  "
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span className="">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? "text-white" : "text-[#3B5DAD]",
                          "block text-center text-xs font-medium "
                        )}
                      >
                        {setting.name}
                      </RadioGroup.Label>
                    </span>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
}
