import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { Fragment, useState } from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { reservationActions } from "../../store/reservation/reservationSlice";

export default function Modal({
  action,
  onClick,
  isOpen,
  setIsOpen,
  name,
  item,
  ok,
  button,
  datepicker,
  isSelfServices,
  changeDate,
}) {
  const [valueStart, setChangeStart] = useState(new Date());
  const handleDatevalue = (e) => {
    setDateValue(e);
  };
  const dispatch = useDispatch();
  const reservationDate = useSelector(
    (state) => state.reservation.ReservationDate
  );
  const [datevalue, setDateValue] = useState(new Date(reservationDate));
  const calenderHandler = (e) => {
    dispatch(
      reservationActions.setReservationDate(new Date(e).toLocaleDateString())
    );
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => (isSelfServices ? setIsOpen(true) : setIsOpen(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all ${
                  isSelfServices && "scale-125"
                }`}
              >
                {datepicker && (
                  <>
                    <h1 className="mb-3 text-base font-medium leading-6 text-gray-900">
                      Please Select date
                    </h1>
                    <div className="mb-10 flex items-center justify-center">
                      {isSelfServices ? (
                        <Calendar
                          defaultValue={
                            new Date(reservationDate)
                          }
                          onChange={handleDatevalue}
                          value={datevalue}
                          minDate={
                            new Date().getHours() < 7
                              ? new Date(
                                  new Date().setDate(new Date().getDate() - 1)
                                )
                              : new Date()
                          }
                          maxDate={
                            new Date(
                              new Date().getFullYear(),
                              new Date().getMonth(),
                              new Date().getDate() + 6
                            )
                          }
                          locale={"en"}
                        />
                      ) : (
                        <Calendar
                          defaultValue={new Date()}
                          onChange={setChangeStart}
                          value={valueStart}
                          locale={"en"}
                        />
                      )}
                    </div>
                  </>
                )}
                {!isSelfServices && (
                  <Dialog.Title
                    as="h3"
                    className="text-base font-medium leading-6 text-gray-900"
                  >
                    Are you sure you want to {action} this {name} ?
                  </Dialog.Title>
                )}
                <div className="mt-6 flex justify-center text-left">
                  {item?.name}
                </div>

                <div className={`${datepicker ? "mt-1" : "mt-6"}`}>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-2xl border border-transparent bg-[#3B5CAD] px-14 py-4 text-sm font-medium text-white hover:bg-[#3B5CAD]/90 focus:outline-none focus-visible:ring-2 focus-visible:ring-[#3B5CAD] focus-visible:ring-offset-2"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`inline-flex justify-center rounded-2xl border border-transparent px-14 py-4 text-sm font-medium ${
                      isSelfServices
                        ? " ml-4 bg-[#3B5CAD] text-white hover:bg-[#3B5CAD]/90 focus:outline-none focus-visible:ring-2 focus-visible:ring-[#3B5CAD] focus-visible:ring-offset-2"
                        : "text-[#FF6335]"
                    } `}
                    onClick={() => {
                      if (onClick) {
                        onClick(
                          datepicker && moment(valueStart).add(3, "hours")
                        );
                      }
                      if (isSelfServices) {
                        if (changeDate) {
                          sessionStorage.setItem("changeDate", true)
                        }
                        calenderHandler(datevalue);
                      }
                      setIsOpen(false);
                    }}
                  >
                    {ok || action}
                  </button>
                  {button}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
