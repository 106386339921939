import { useState } from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { Outlet, useLocation } from "react-router-dom";
import moment from "moment";
import Profile from "../ui-assets/Profile";
import { useAuthUserQuery } from "../../store/authUserApi";
import MainNavigation from "./MainNavigation";

export default function Layout() {
   const { data: authUser, isLoading } = useAuthUserQuery();
   const [sidebarOpen, setSidebarOpen] = useState(false);
   const location = useLocation().pathname;
   return (
      <>
         <div className="mx-auto h-screen max-h-screen max-w-[1366px]">
            {/* Static sidebar for desktop */}
            {!location.includes("/reservation") && !location.includes("/selfServices") && (
               <MainNavigation
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                  authUser={authUser}
               />
            )}
            <div
               className={`flex h-full flex-1 flex-col bg-[#F1F4FA] px-8 py-6 md:pl-64 ${
                  location.includes("/reservation") || location.includes("/selfServices") ? "md:pl-0" : "md:pl-64"
               }`}
            >
               <div className="z-10 flex h-16 flex-shrink-0 rounded-t-3xl bg-white px-6">
                  <button
                     type="button"
                     className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3B5CAD] md:hidden"
                     onClick={() => setSidebarOpen(true)}
                  >
                     <span className="sr-only">Open sidebar</span>
                     <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div className="flex flex-1 items-center justify-end px-4 lg:justify-between">
                     <div className="hidden flex-1 lg:flex ">
                        <p>{`${moment().format(
                           "h:mm A, dddd "
                        )} , ${moment().format("DD MMM, YYYY")}`}</p>
                     </div>
                     <div className="ml-4 flex items-center md:ml-6">
                        {!isLoading && authUser && !location.includes("/selfServices") && <Profile user={authUser} />}
                     </div>
                  </div>
               </div>
               <main className={`h-full flex-1 overflow-scroll ${location.includes("/selfServices") ? "movies-scroll" : ""} rounded-b-2xl`}>
                  <div className="min-h-full rounded-b-2xl  bg-white">
                     <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 ">
                        <div>
                           <Outlet />
                        </div>
                     </div>
                  </div>
               </main>
            </div>
         </div>
      </>
   );
}
