import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../components/ui-assets/SearchBar";
import Filters from "../../components/ui-assets/Filters";
import { useDeleteUserMutation, useUsersQuery } from "../../store/usersApi";
import TableRow from "../../components/ui-assets/table/TableRow";
import CustomToast from "../../utiles/CustomToast";
import avatar from "../../Assets/profileaAvatar.svg";
import { Helmet } from "react-helmet";

const data = [
  {
    component: true,
    value: (item) => (
      <div key={item._id} className="md:basis-1/12">
        <div className="mr-3 flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#3B5CAD] focus:ring-offset-2">
          <span className="sr-only"> user image</span>
          <img
            className="h-8 w-8 rounded-full"
            src={item?.avatar}
            alt="profile img"
          />
        </div>
      </div>
    ),
  },
  {
    title: "Name",
    value: (item) => item.name,
    classes: "text-xs text-gray-600 md:basis-2/6",
  },
  {
    title: "Email",
    value: (item) => item.email,
    classes: "flex basis-3/6 flex-col md:basis-2/6",
  },
  {
    title: "Role",
    value: (item) => item.role,
    classes: "flex basis-3/6 flex-col md:basis-1/6",
  },
  {
    title: "Last Log",
    value: (item) => item.updatedAt,
    classes: "flex basis-2/6  flex-col",
  },
];

const filterData = [
  {
    id: 1,
    name: "All",
    value: "",
  },
  {
    id: 2,
    name: "Super Admin",
    value: "Super Admin",
  },
  {
    id: 27,
    name: "Admin",
    value: "Admin",
  },
  {
    id: 3,
    name: "Supervisor",
    value: "Supervisor",
  },
  {
    id: 4,
    name: "Reservation",
    value: "Reservation",
  },
];

const UsersList = () => {
  const [searchValue, setSearchValue] = useState("");
  const [filterField, setfilterField] = useState(filterData[0].value);
  const { data: users, isLoading } = useUsersQuery({
    search: searchValue,
    role: filterField,
  });
  const [deleteUser, { isSuccess, isError, error }] = useDeleteUserMutation();

  return (
    <>
      <Helmet>
        <title>User List</title>
      </Helmet>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/users"
        />
      )}
      <div className="container py-10">
        <div className="flex flex-col items-center justify-between gap-4 lg:flex-row">
          <SearchBar onChange={setSearchValue} value={searchValue} />
          <div>
            <Filters setfilterData={setfilterField} filterData={filterData} />
          </div>
          <Link
            className="rounded-2xl bg-primary px-4 py-4 text-white  xl:px-10"
            to="/add-user"
          >
            Add User
          </Link>
        </div>
        <div className="py-4">
          {!isLoading &&
            users?.map((user) => (
              <TableRow
                data={{
                  ...user,
                  avatar:
                    user.avatar === "/uploads/Default-avatar.jpg"
                      ? avatar
                      : user.avatar,
                }}
                column={data}
                key={user._id}
                onDelete={(id) => deleteUser(id)}
                editUrl="/edit-user"
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default UsersList;
