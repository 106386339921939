import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import Calendar from "react-calendar";

export default function ModalDate({
  action,
  name,
  isOpen,
  setIsOpen,
  onClick,
  datepicker,
  releaseDate,
  startDate,
  expDate
}) {
  const [valueStart, setChangeStart] = useState(new Date(startDate));
  const [valueEnd, setChangeEnd] = useState(new Date(expDate));

  const handleDate = (value) => {
    const selectedDate = value;
    const isEndOfMonth = selectedDate.getDate() === 31;
    if (isEndOfMonth) {
      selectedDate.setHours(7, 0, 0, 0); // Set the time to 07:00 AM
      selectedDate.setDate(1); // Set the date to the 1st of the next month
      selectedDate.setMonth(selectedDate.getMonth() + 1); // Set the month to the next month
    }
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  };

  const handleSubmit = () => {
    onClick({valueStart: handleDate(valueStart), valueEnd: handleDate(valueEnd)});
    setIsOpen(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all`}
              >
                {datepicker && (
                  <>
                    <h1 className="mb-3 text-base font-medium leading-6 text-gray-900">
                      Please Select date
                    </h1>
                    <div className="mb-10 flex items-center justify-center gap-10">
                      <div className="flex flex-col gap-4">
                        <h2>Start Date</h2>
                        <Calendar
                          defaultValue={new Date(startDate)}
                          onChange={setChangeStart}
                          value={valueStart}
                          locale={"en"}
                          minDate={
                            new Date(releaseDate).getHours() < 7
                              ? new Date(
                                  new Date(releaseDate).setDate(new Date(releaseDate).getDate() - 1)
                                )
                              : new Date(releaseDate)
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-4">
                        <h2>End Date</h2>
                        <Calendar
                          defaultValue={new Date(expDate)}
                          onChange={setChangeEnd}
                          value={valueEnd}
                          locale={"en"}
                          minDate={
                            new Date(releaseDate).getHours() < 7
                              ? new Date(
                                  new Date(releaseDate).setDate(new Date(releaseDate).getDate() - 1)
                                )
                              : new Date(releaseDate)
                          }
                          // maxDate={new Date(expDate)}
                        />
                      </div>
                    </div>
                  </>
                )}
                <Dialog.Title
                  as="h3"
                  className="text-base font-medium leading-6 text-gray-900"
                >
                  Are you sure you want to {action} this {name} ?
                </Dialog.Title>

                <div className={`${datepicker ? "mt-1" : "mt-6"}`}>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-2xl border border-transparent bg-[#3B5CAD] px-14 py-4 text-sm font-medium text-white hover:bg-[#3B5CAD]/90 focus:outline-none focus-visible:ring-2 focus-visible:ring-[#3B5CAD] focus-visible:ring-offset-2"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`focus-visible:ring-offset-2" ml-4 inline-flex justify-center rounded-2xl border border-transparent bg-[#3B5CAD] px-14 py-4 text-sm font-medium text-white hover:bg-[#3B5CAD]/90 focus:outline-none focus-visible:ring-2 focus-visible:ring-[#3B5CAD]
                    `}
                    onClick={handleSubmit}
                  >
                    {action}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
