import UserForm from "../../components/users/UserForm";
import { useParams } from "react-router-dom";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserQuery,
} from "../../store/usersApi";
import { uploadFileS3 } from "../../utiles/uploadFileS3";
import { useState } from "react";
import CustomToast from "../../utiles/CustomToast";
import { Helmet } from "react-helmet";

export default function UserPage() {
  const { id } = useParams();
  const [imageloading, setImageLoading] = useState({
    loading: false,
    progress: 0,
  });
  const { data: user } = useUserQuery({ id }, { skip: !!!id });
  const [createUser, { isSuccess, isError, error }] = useCreateUserMutation();
  const [
    updateUser,
    { isSuccess: updateIsSuccess, updateIsError, updateError },
  ] = useUpdateUserMutation();

  const submitHandler = async ({ file, role }, data) => {
    data.avatar =
      file && (await uploadFileS3([file], setImageLoading, "users"))[0];
    data.role = role;
    createUser({
      ...data,
      distributor: role === "Distributor" ? data.distributor : undefined,
      password: data.password,
      email: data.email.toLowerCase(),
    });
  };
  const updateHandler = async ({ file, role }, data) => {
    const avatar =
      file && (await uploadFileS3([file], setImageLoading, "users"))[0];
    const newData = {
      id,
      name: data.name,
      email: data.email.toLowerCase(),
      password: data.password,
      avatar: avatar || data.avatar,
      role: role,
      distributor: role === "Distributor" ? data.distributor : undefined,
    };
    updateUser(newData);
  };
  return (
    <>
      <Helmet>
        <title>User Page</title>
      </Helmet>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/users"
        />
      )}
      {(updateIsError || updateIsSuccess) && (
        <CustomToast
          IsError={updateIsError}
          error={updateError}
          isSuccess={updateIsSuccess}
          path="/users"
        />
      )}
      <UserForm
        imageloading={imageloading}
        onSubmit={id && user ? updateHandler : submitHandler}
        user={id && user}
      />
    </>
  );
}
