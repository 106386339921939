import React, { useState } from 'react';
import Filters from '../../components/ui-assets/Filters';
import SearchBar from '../../components/ui-assets/SearchBar';
import { Link } from 'react-router-dom';
import { useMoviesQuery, useSyncMovieMutation } from '../../store/moviesApi';
import TableRow from '../../components/ui-assets/table/TableRow';
import { useEffect } from 'react';
import CustomToast from '../../utiles/CustomToast';
import { Helmet } from 'react-helmet';

const columns = [
  {
    component: true,
    value: (item) => (
      <div className="basis-2/6 ">
        <div className="mr-6 flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#3B5CAD] focus:ring-offset-2">
          <img
            className="h-[36px] w-[36px] rounded-lg object-cover"
            src={item.poster}
            alt="movie img"
          />
          <div className="ml-4">
            <p className="text-xs text-gray-500">Name</p>
            <h6 className="font-medium">{item.name}</h6>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: 'Distributor',
    value: (item) => item?.distributor?.name || 'NA',
    classes: 'flex basis-2/6 flex-col md:basis-1/6',
  },
  {
    title: 'Type',
    value: (item) => item.experience,
    classes: 'flex basis-1/6 flex-col md:basis-1/6',
  },
];

const filterData = [
  {
    id: 2,
    name: 'Active Movies',
    value: 'Active Movies',
  },
  {
    id: 3,
    name: 'Locked Movies',
    value: 'Locked Movies',
  },
];

const Movies = () => {
  const [filterField, setfilterField] = useState(filterData[0].name);
  const [searchValue, setSearchValue] = useState('');
  const {
    data: movies,
    isLoading,
    refetch,
  } = useMoviesQuery(
    `${
      filterField === 'Active Movies' ? '' : 'locked=true'
    }&search=${searchValue}`
  );

  const [SyncMovie, { isError, isSuccess, error }] = useSyncMovieMutation();
  useEffect(() => {
    refetch();
  }, [filterField, refetch, searchValue, isSuccess]);
  return (
    <>
      <Helmet>
        <title>Movies List</title>
      </Helmet>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/movies"
        />
      )}
      <div className="container relative py-10">
        <div className=" flex flex-col flex-wrap items-center justify-between gap-4 bg-white lg:flex-row">
          <SearchBar onChange={setSearchValue} value={searchValue} />
          <div>
            <Filters setfilterData={setfilterField} filterData={filterData} />
          </div>
          <div className="w-100 flex flex-1 flex-wrap justify-center gap-2 text-[14px] md:flex-nowrap">
            <button
              className="w-[240px] whitespace-nowrap rounded-2xl bg-primary px-4 py-2 text-white md:w-fit"
              onClick={() => SyncMovie()}
            >
              Sync Movies
            </button>
            <button
              className="w-[240px] whitespace-nowrap rounded-2xl bg-primary px-4 py-2 text-white md:w-fit"
              onClick={() => SyncMovie()}
            >
              Update Existing Movies
            </button>
          </div>
        </div>
        <div className="py-4">
          {!isLoading &&
            movies &&
            movies.map((movie) => <TableRow data={movie} column={columns} />)}
        </div>
      </div>
    </>
  );
};

export default Movies;
