import React from "react";
import MovieCard from "../reservation/MovieCard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function EditStatus({ On_Of, faeditstatus, showMovie }) {
  const { movie } = showMovie;

  return (
    <div
      className={classNames(
        On_Of ? "fixed" : "hidden",
        " top-0 right-0 z-50 flex h-[100vh] w-full items-center justify-center bg-[#00000048] "
      )}
    >
      <div className=" relative flex h-[443px] w-[600px] rotate-180 flex-col items-center rounded-xl bg-white ">
        <div className="flex w-full justify-between p-[32px]">
          <h1 className=" text-lg font-extrabold text-[#3B5CAD] ">
            Edit Seat Status
          </h1>
          <div
            onClick={() => {
              faeditstatus();
            }}
            className=" cursor-pointer text-rose-700"
          >
            <i className="fa-solid fa-circle-xmark"></i>
          </div>
        </div>
        <div className="flex w-full  flex-row px-[32px] ">
          <MovieCard
            text=" hidden"
            height="h-[319px]"
            opacity=""
            showMovie={showMovie}
          />
          <div className=" w-[331px] pl-6">
            <div className="">
              <p className="text-xs font-thin text-[#607D8B]">Movie</p>
              <h1 className="font-bold">{movie.name}</h1>
            </div>
            <div className=" flex w-full flex-row justify-between pt-4">
              <div className=" ">
                <p className="text-xs font-thin text-[#607D8B]">Seats</p>
                <h1 className=" text-sm font-bold">H5</h1>
              </div>
              <div className="">
                <p className="text-xs font-thin text-[#607D8B]">Screen</p>
                <h1 className="font-bold">Screen 10</h1>
              </div>
              <div className="">
                <p className="text-xs font-thin text-[#607D8B]">Showtime</p>
                <h1 className="font-bold">10:45 AM</h1>
              </div>
            </div>
            <div className="mt-4 flex w-full flex-col">
              <p className=" text-xs font-thin text-[#607D8B]">Seats Status</p>
              <div className="flex w-full flex-row justify-between pt-4">
                <button className=" place-self-center rounded-2xl border border-primary bg-primary px-[20px] py-3 text-xs text-white hover:bg-white hover:text-primary">
                  Available
                </button>
                <button className=" place-self-center rounded-2xl border border-primary bg-primary px-[20px] py-3 text-xs text-white hover:bg-white hover:text-primary">
                  Waiting
                </button>
                <button className=" place-self-center rounded-2xl border border-primary bg-primary px-[20px] py-3 text-xs text-white hover:bg-white hover:text-primary">
                  Emergency
                </button>
              </div>
            </div>
            <div className=" flex justify-center">
              <button className="mt-[55px] place-self-center rounded-2xl border border-primary bg-primary px-14 py-4 text-white hover:bg-white hover:text-primary">
                Save Edits
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditStatus;
