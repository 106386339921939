import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CustomToast = ({ isSuccess, IsError, error, path }) => {
   const navigator = useNavigate();
   useEffect(() => {
      if (IsError) {
         toast.error(error?.data?.message, {
            toastId: "errorkshdfkjhe",
            autoClose: 1500,
         });
      }
      if (isSuccess) {
         toast.success("Success!", {
            toastId: "kshdfkjhe",
            autoClose: 1500,
         });
         path && navigator(path);
      }
   }, [IsError, isSuccess, navigator, error?.data?.message, path]);

   return <></>;
};

export default CustomToast;
