import React from "react";

function ChangeShowTime({ changeState, showTime, onClick, movie }) {
  return (
    <div className="fixed top-0 left-0 z-50 h-[100vh] w-full ">
      <div className=" relative mx-auto  flex  w-[400px] flex-col items-center justify-between rounded-xl bg-white pb-6 ">
        <h1 className=" mt-6 font-bold text-primary">Change Show Time</h1>
        <div className="pl- mt-3 flex h-[134px]  flex-row items-center  p-2">
          <div className="h-[134px]  w-[150px]">
            <img
              alt="movie"
              className="h-[134px] w-[76px] rounded-2xl"
              src={showTime?.showTimeStatus?.movie?.poster}
            />
          </div>
          <div className="ml-5 flex  w-full flex-col ">
            <div>
              <p className=" text-xs text-[#607D8B]">ShowTime</p>
              <h1 className=" font-bold ">{showTime?.startTime}</h1>
            </div>
          </div>
        </div>
        <p className="text-lg font-semibold">
          To - <i className="fa-solid fa-arrow-down text-green-500"></i>
        </p>
        <div className="pl- mt-3 mb-5 flex  h-[134px] flex-row items-center  p-2">
          <div className="h-[134px]  w-[150px]">
            <img
              alt="movie"
              className="h-[134px] w-[76px] rounded-2xl"
              src={movie?.poster}
            />
          </div>
          <div className="ml-5 flex  w-full flex-col ">
            <div>
              <p className=" text-xs text-[#607D8B]">ShowTime</p>
              <h1 className=" font-bold ">{showTime?.startTime}</h1>
            </div>
          </div>
        </div>
        <div className="flex w-[50%] flex-row justify-between">
          <button
            onClick={() => changeState()}
            className=" place-self-center rounded-2xl border border-2 border-red-600 bg-white px-6 py-2 text-red-600 hover:bg-red-600 hover:text-white"
          >
            No
          </button>
          <button
            onClick={() => {
              onClick(showTime);
              changeState();
            }}
            className=" place-self-center rounded-2xl border border-2 border-green-500 bg-white px-6 py-2 text-green-500 hover:bg-green-500 hover:text-white"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangeShowTime;
