import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Calendar from "react-calendar";
import ChooseList from "./ChooseList";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { reportsActions } from "../../store/reportsSlice";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import MoviesRevenuesChoose from "./RevenuesChoose.js";
import TotalRevenuesByDayChooses from "./TotalRevenuesByDayChooses";

function ReportsDate({ path, data, onGenerate }) {
  const [valueStart, setChangeStart] = useState(
    new Date(
      new Date().getHours() < 7
        ? new Date(new Date().setDate(new Date().getDate() - 1))
        : new Date()
    )
  );

  const [valueEnd, setChangeEnd] = useState(
    new Date(
      new Date().getHours() < 7
        ? new Date(new Date().setDate(new Date().getDate() - 1))
        : new Date()
    )
  );

  // eslint-disable-next-line no-unused-vars
  const [singleDate, setSingleDate] = useState(
    new Date(new Date().setHours(0, 0, 0))
  );

  const [Choices, setChoices] = useState([]);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const type = location.state;

  const [dataChoices, setDataChoices] = useState({
    seatsType: ["Standard", "VIP", "IMAX"],
    movieType: undefined,
    group: "Day",
  });

  const link = () => {
    switch (path) {
      case "Movies revenues":
        return `movies`;
      case "Screens revenues":
        return "screens";
      case "Cancelled tickets":
        return "canceled-tickets";
      case "Reprinted tickets":
        return "reprinted-tickets";
      case "Detailed sales":
        return `agents/${Choices[0]}`;
      case "Total sales":
        return `agents`;
      case "Total sales2":
        return `agents-v2`;
      case "Total sales inadvance":
        return `agents-inadvance`;
      case "Total sales inprevious":
        return `agents-inprevious`;
      case "Total sales inadvance2":
        return `agents-inadvance-v2`;
      case "Total sales inprevious2":
        return `agents-inprevious-v2`;
      case "Detailed revenues":
        return `total-revenues`;
      case "Detailed revenues 2":
        return `total-revenues`;
      case "Total revenues":
        return `/screens?total=true`;
      case "Distributors revenues":
        return `distributors/${Choices[0]}`;
      case "Detailed sales 3":
        return `/agents-detailed`;
      case "Invitation":
        return "canceled-tickets?invitation=true";
      case "Exchange Report":
        return "/exchanged-tickets";
      case "Total Revenues By Day":
        return "/total-revenues-by-day";
      case "glasses-report":
        return "/glasses";
      case "online":
        return "/online-revenues";
      case "online for bank":
        return "/online-bank";
      case "online paymob for bank":
        return "/online-paymob-bank";
      case "refund-glasses-report":
        return "/removed-glasses";
      case "online Detailed revenues":
        return "/online-total-revenues";
      default:
        break;
    }
  };

  return (
    <>
      <Link
        to={"/reports"}
        className=" flex w-full max-w-[600px] items-center gap-2 text-xl font-bold text-primary"
      >
        <ArrowLeftIcon className="h-5 w-5 text-primary" />
        <span className="text-primary">Back</span>
      </Link>
      <div className="flex w-full flex-col items-center">
        <h1 className=" w-full py-8 text-center text-xl font-extrabold">
          {type}
        </h1>
        <div className="mt-8 flex w-full flex-row justify-around gap-4 ">
          {["Screens revenues", "Distributors revenues"].includes(path) ? (
            <ChooseList
              data={data}
              type={
                ["Screens revenues"].includes(path)
                  ? "Screens"
                  : ["Distributors revenues"].includes(path) && "Distributors"
              }
              setChoices={(i, type) => {
                type === "all"
                  ? setChoices(i)
                  : setChoices(
                      Choices?.filter((x) => x === i).length !== 0
                        ? Choices?.filter((x) => x !== i)
                        : [...Choices, i]
                    );
              }}
              lastChoices={Choices}
            />
          ) : (
            <></>
          )}
          {[
            "Movies revenues",
            "Detailed revenues",
            "Detailed revenues 2",
            "online Detailed revenues",
            "Total sales",
            "Total sales2",
            "Total sales inadvance",
            "Total sales inprevious",
            "Total sales inadvance2",
            "Total sales inprevious2",
            "glasses-report",
            "Detailed sales",
            "Detailed sales 3",
          ].includes(path) ? (
            <>
              {["Movies revenues"].includes(path) && (
                <div className="flex max-w-[300px] flex-col">
                  <h1 className=" mb-3 text-base font-medium text-[#3B5DAD]">
                    Start date
                  </h1>

                  <Calendar
                    defaultValue={
                      new Date(
                        new Date().getHours() < 7
                          ? new Date(
                              new Date().setDate(new Date().getDate() - 1)
                            )
                          : new Date()
                      )
                    }
                    onChange={setChangeStart}
                    value={valueStart}
                    locale={"en"}
                  />

                  <h1 className=" mt-7 text-base font-bold ">
                    {moment(valueStart).format("ddd, DD MMM YYYY")}
                  </h1>
                </div>
              )}
              <div className="w-full">
                <MoviesRevenuesChoose setChoices={(i) => setChoices(i)} />
              </div>
            </>
          ) : (
            <></>
          )}
          {[
            "Total Revenues By Day",
            "online",
            "online for bank",
            "online paymob for bank",
          ].includes(path) ? (
            <>
              <div className="w-full">
                <TotalRevenuesByDayChooses setChoices={(i) => setChoices(i)} />
              </div>
            </>
          ) : (
            <></>
          )}

          {["Exchange Report"].includes(path) && (
            <div className="flex max-w-[300px] flex-col">
              <h1 className=" mb-3 text-base font-medium text-[#3B5DAD]">
                Start date
              </h1>

              <Calendar
                defaultValue={
                  new Date().getHours() < 7
                    ? new Date(
                        new Date().setDate(new Date().getDate() - 1)
                      ).toLocaleDateString()
                    : new Date().toLocaleDateString()
                }
                onChange={setChangeStart}
                value={valueStart}
                locale={"en"}
              />

              <h1 className=" mt-7 text-base font-bold ">
                {moment(valueStart).format("ddd, DD MMM YYYY")}
              </h1>
            </div>
          )}

          {[
            "Movies revenues",
            "Detailed revenues",
            "Detailed revenues 2",
            "online Detailed revenues",
            "Exchange Report",
            "Total Revenues By Day",
            "online",
            "online for bank",
            "online paymob for bank",
          ].includes(path) ? (
            <></>
          ) : (
            <div
              className={`flex w-full ${"max-w-[633px]"} flex-row justify-between gap-8`}
            >
              {/* {["Detailed revenues"].includes(path) && (
                <div div className="w-full">
                  <DetailedRevenues setChoices={(i) => setChoices(i)} />
                </div>
              )} */}
              <div className="flex w-full max-w-[300px] flex-col">
                <h1 className=" mb-3 text-base font-medium text-[#3B5DAD]">
                  Start date
                </h1>
                <Calendar
                  defaultValue={
                    new Date().getHours() < 7
                      ? new Date(
                          new Date().setDate(new Date().getDate() - 1)
                        ).toLocaleDateString()
                      : new Date().toLocaleDateString()
                  }
                  onChange={setChangeStart}
                  value={valueStart}
                  locale={"en"}
                />
                <h1 className=" mt-7 text-base font-bold ">
                  {moment(valueStart).format("ddd, DD MMM YYYY")}
                </h1>
              </div>
              <div className="flex w-full max-w-[300px] flex-col">
                <h1 className=" mb-3 text-base font-medium text-[#3B5DAD]">
                  End date
                </h1>
                <Calendar
                  defaultValue={
                    new Date().getHours() < 7
                      ? new Date(
                          new Date().setDate(new Date().getDate() - 1)
                        ).toLocaleDateString()
                      : new Date().toLocaleDateString()
                  }
                  onChange={setChangeEnd}
                  value={valueEnd}
                  locale={"en"}
                />
                <h1 className=" mt-7 text-base font-bold ">
                  {moment(valueEnd).format("ddd, DD MMM YYYY")}
                </h1>
              </div>
            </div>
          )}
        </div>
        {["Screens revenues", "Distributors revenues"].includes(path) && (
          <>
            {/* Seats Type */}
            <div className="flex min-h-[40px] w-[48%] flex-col items-center gap-3">
              <h1 className="  font-semibold  text-primary">Seats Type</h1>
              <div className="flex w-40 flex-row items-center justify-around text-primary gap-1">
              <div
                  onClick={() =>
                    setDataChoices({
                      ...dataChoices,
                      seatsType: dataChoices.seatsType.includes("IMAX")
                        ? [...dataChoices.seatsType.filter((i) => i !== "IMAX")]
                        : [...dataChoices.seatsType, "IMAX"],
                    })
                  }
                  className={`${
                    dataChoices.seatsType?.includes("IMAX") &&
                    "bg-primary text-white"
                  } cursor-pointer rounded-xl border border-primary  px-3`}
                >
                  IMAX
                </div>
                <div
                  onClick={() =>
                    setDataChoices({
                      ...dataChoices,
                      seatsType: dataChoices.seatsType.includes("VIP")
                        ? [...dataChoices.seatsType.filter((i) => i !== "VIP")]
                        : [...dataChoices.seatsType, "VIP"],
                    })
                  }
                  className={`${
                    dataChoices.seatsType?.includes("VIP") &&
                    "bg-primary text-white"
                  } cursor-pointer rounded-xl border border-primary  px-3`}
                >
                  VIP
                </div>
                <div
                  onClick={() =>
                    setDataChoices({
                      ...dataChoices,
                      seatsType: dataChoices.seatsType.includes("Standard")
                        ? [
                            ...dataChoices.seatsType.filter(
                              (i) => i !== "Standard"
                            ),
                          ]
                        : [...dataChoices.seatsType, "Standard"],
                    })
                  }
                  className={`${
                    dataChoices.seatsType?.includes("Standard") &&
                    "bg-primary text-white"
                  } cursor-pointer rounded-xl border border-primary  px-3`}
                >
                  Standard
                </div>
              </div>
            </div>
            {/* Movies Type */}
            <div className="flex min-h-[40px] w-[48%] flex-col items-center gap-3">
              <h1 className="  font-semibold  text-primary">Movies Type</h1>
              <div className="flex flex-wrap items-center justify-between  gap-2  text-primary ">
                <div
                  onClick={() =>
                    setDataChoices({
                      ...dataChoices,
                      movieType: "Egyptian",
                    })
                  }
                  className={`${
                    dataChoices.movieType === "Egyptian" &&
                    "bg-primary text-white"
                  } cursor-pointer rounded-xl border border-primary  px-3`}
                >
                  Egyptian
                </div>{" "}
                :{" "}
                <div
                  onClick={() =>
                    setDataChoices({
                      ...dataChoices,
                      movieType: "Foreign",
                    })
                  }
                  className={`${
                    dataChoices.movieType === "Foreign" &&
                    "bg-primary text-white"
                  } cursor-pointer rounded-xl border border-primary  px-3`}
                >
                  Foreign
                </div>{" "}
                :{" "}
                <div
                  onClick={() =>
                    setDataChoices({
                      ...dataChoices,
                      movieType: undefined,
                    })
                  }
                  className={`${
                    dataChoices.movieType === undefined &&
                    "bg-primary text-white"
                  } cursor-pointer rounded-xl border border-primary  px-3`}
                >
                  All
                </div>
              </div>
            </div>
          </>
        )}
        {![
          "Movies revenues",
          "Detailed revenues",
          "Detailed revenues 2",
          "online Detailed revenues",
          "Total sales",
          "Total sales2",
          "Total sales inadvance",
          "Total sales inadvance2",
          "Total sales inprevious",
          "Total sales inprevious2",
          "glasses-report",
          "Detailed sales",
          "Detailed sales 3",
          "Total Revenues By Day",
          "online",
          "online for bank",
          "online paymob for bank",
          "Screens revenues",
          "Distributors revenues",
        ].includes(path) && (
          <div className="w-full">
            <MoviesRevenuesChoose setChoices={(i) => setChoices(i)} />
          </div>
        )}
        <div className="mt-10 flex w-[410px] justify-between">
          <button
            onClick={() =>
              onGenerate({
                startDate: valueStart,
                endDate: valueEnd,
                link: link(),
              })
            }
            className=" h-[55px]  w-[190px] rounded-2xl border-2 border-[#3B5DAD] bg-[#3B5DAD] px-6 py-1 text-white hover:bg-white hover:text-[#3B5DAD] "
          >
            Print
          </button>
          <button
            onClick={() => {
              if (!Choices.movies && ["Total Revenues By Day"].includes(path)) {
                alert("movie is required");
              } else {
                {
                  ["Screens revenues", "Distributors revenues"].includes(path)
                    ? dispatch(
                        reportsActions.setReportsData({
                          startDate: new Date(
                            new Date(valueStart).setHours(7, 0, 0, 0)
                          ).toISOString(),
                          endDate: new Date(
                            new Date(
                              new Date(valueEnd).setDate(
                                new Date(valueEnd).getDate() + 1
                              )
                            ).setHours(7, 0, 0, 0)
                          ).toISOString(),
                          data: Choices,
                          ...dataChoices,
                          link: link(),
                        })
                      )
                    : ![
                        "Total revenues",
                        "Screens revenues",
                        "Distributors revenues",
                        "Reprinted tickets",
                        "Cancelled tickets",
                        "Invitation",
                        "Exchange Report",
                        "refund-glasses-report",
                      ].includes(path)
                    ? dispatch(
                        reportsActions.setReportsData({
                          startDate: new Date(
                            new Date(valueStart).setHours(7, 0, 0, 0)
                          ).toISOString(),
                          endDate: new Date(
                            new Date(
                              new Date(valueEnd).setDate(
                                new Date(valueEnd).getDate() + 1
                              )
                            ).setHours(7, 0, 0, 0)
                          ).toISOString(),
                          data: Choices,
                          link: link(),
                        })
                      )
                    : dispatch(
                        reportsActions.setReportsData({
                          startDate: new Date(
                            new Date(valueStart).setHours(7, 0, 0, 0)
                          ).toISOString(),
                          endDate: new Date(
                            new Date(
                              new Date(valueEnd).setDate(
                                new Date(valueEnd).getDate() + 1
                              )
                            ).setHours(7, 0, 0, 0)
                          ).toISOString(),
                          ...Choices,
                          link: link(),
                        })
                      );
                }
                {
                  ["Screens revenues", "Distributors revenues"].includes(path)
                    ? navigator(`/viewReport/${type}`, {
                        state: {
                          startDate: valueStart,
                          endDate: valueEnd,
                          singleDate: singleDate,
                          data: Choices,
                          dataChoices: dataChoices,
                        },
                      })
                    : navigator(`/viewReport/${type}`, {
                        state: {
                          startDate: valueStart,
                          endDate: valueEnd,
                          singleDate: singleDate,
                          data: Choices,
                        },
                      });
                }
              }
            }}
            className=" h-[55px]  w-[190px] rounded-2xl border-2 border-[#3B5DAD]  bg-white px-6 py-1 text-[#3B5DAD]  hover:bg-[#3B5DAD] hover:text-white "
          >
            View report
          </button>
        </div>
      </div>
    </>
  );
}

export default ReportsDate;
