import React from "react";

function ExchangeForm({ reservations }) {
  return (
    <div className="flex h-[134px] w-full flex-row">
      <div className="h-[134px]  w-[96px]">
        <img
          alt="movie"
          className="h-[134px] w-[76px] rounded-2xl"
          src={reservations?.[0]?.movie?.poster}
        />
      </div>
      <div className="ml-5 flex h-1/2 w-full flex-col ">
        <div>
          <p className=" text-xs text-[#607D8B]">Movie</p>
          <h1 className=" font-bold ">{reservations?.[0]?.movie?.name}</h1>
        </div>
        <div className="mt-4 flex flex-row justify-between">
          <div>
            <p className=" text-xs text-[#607D8B]">Seats</p>
            <h1 className=" w-[50px] truncate font-bold ">
              {reservations?.map((i) => i.seat.title.split("-")[1]).join(",")}
            </h1>
          </div>
          <div className="text-center">
            <p className=" text-xs text-[#607D8B]">Selected Seats</p>
            <h1 className=" w-full  font-bold ">{reservations?.length}</h1>
          </div>
          <div>
            <p className=" text-xs text-[#607D8B]">Screen</p>
            <h1 className=" font-bold ">{reservations?.[0]?.screen?.name}</h1>
          </div>
          <div>
            <p className=" text-xs text-[#607D8B]">price</p>
            <h1 className=" font-bold ">
              {
                reservations?.reduce(
                  (a, b) => ({ price: Number(a.price) + Number(b.price) }),
                  {
                    price: 0,
                  }
                ).price
              }
              LE
            </h1>
          </div>
          <div>
            <p className=" text-xs text-[#607D8B]">Showtime</p>
            <h1 className=" font-bold ">
              {reservations?.[0]?.showTime?.startTime}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExchangeForm;
