import { TrashIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import Modal from "./Modal";

const DeleteButton = ({ onDelete, item }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        className="flex h-8 w-8 items-center justify-center rounded-lg bg-white p-1 text-[#F44336] shadow-md hover:text-[#F44336] focus:outline-none "
      >
        <span className="sr-only">Delete</span>
        <TrashIcon className="h-4 w-4" aria-hidden="true" />
      </button>
      <Modal
        action="Delete"
        name="movie"
        onClick={onDelete}
        isOpen={isOpen}
        item={item}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default DeleteButton;
