import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { authUserApi, useLogoutMutation } from "../../store/authUserApi";
import Modal from "./Modal";
import avatar from "../../Assets/profileaAvatar.svg";

const Profile = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  const logoutHandler = (i) => {
    logout(i);
    setTimeout(() => dispatch(authUserApi.util.resetApiState()), 200);
  };
  return (
    <>
      <div className="mr-6 flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#3B5CAD] focus:ring-offset-2">
        <span className="sr-only"> user image</span>
        <img
          className="h-8 w-8 rounded-full"
          src={
            user.avatar === "/uploads/Default-avatar.jpg" ? avatar : user.avatar
          }
          alt="profile img"
        />
        <div className="ml-4">
          <h6 className="font-medium">{user.name}</h6>
          <p className="text-xs text-gray-500">{user.role}</p>
        </div>
      </div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        className="rounded-lg bg-white p-1 text-[#F44336] shadow-md hover:text-[#F44336] focus:outline-none "
      >
        <span className="sr-only">logout</span>
        <ArrowCircleRightIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <Modal
        action="Logout"
        name="user"
        onClick={() => logoutHandler(false)}
        isOpen={isOpen}
        Profile={<Profile />}
        setIsOpen={setIsOpen}
        button={
          <button
            type="button"
            className="mt-6 inline-flex justify-center rounded-2xl border border-red-600 border-transparent px-14 py-4 text-sm font-medium text-[#FF6335]  "
            onClick={() => {
              logoutHandler(true);
              setIsOpen(false);
            }}
          >
            logout and reset checkpoint
          </button>
        }
      />
    </>
  );
};

export default Profile;
