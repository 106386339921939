import React, { useRef } from "react";
import { useState } from "react";
import { Loader } from "@mantine/core";
import ScanForm from "../../components/scan/ScanForm";
import { useTicketMutation } from "../../store/reservation/reservationApi";
import { date } from "yup";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";

const ScanPage = () => {
   const inputRef = useRef();
   const [getTicket, { data, isLoading }] = useTicketMutation();
   const [code, setCode] = useState("");
   const submitHandler = (e) => {
      e.preventDefault();
      const inputCode = inputRef.current.value;
      setCode(inputCode);
      getTicket(inputCode);
      inputRef.current.value = "";
   };
   return (
      <div className=" w-full">
         <Helmet>
            <title>Scan Page</title>
         </Helmet>
         <ScanForm onSubmit={submitHandler} inputRef={inputRef} />
         <div className="mt-12 flex w-full flex-row justify-start gap-8 px-10 py-3">
            <div className=" h-5 w-1/3 ">
               <img
                  src={data?.movie?.poster}
                  alt=""
                  className="m-auto w-8/12 rounded-3xl"
               />
            </div>
            <div className="mt-10 h-5 w-1/3 ">
               <div className="flex flex-row ">
                  <p className="font-semibold text-gray-600">Name: </p>{" "}
                  <p className=" font-medium">{data?.movie?.name}</p>
               </div>
               <div className="mt-7 flex flex-row ">
                  <p className="font-semibold text-gray-600">
                     Price :{" "}
                     <span className="text-black">{data?.price || 0} EG</span>{" "}
                  </p>
               </div>
               <div className="mt-7 flex flex-row ">
                  <p className="font-semibold text-gray-600">Screen : </p>
                  <p className=" font-medium"> {data?.screen?.name}</p>
               </div>
               <div className="mt-7  flex flex-row ">
                  <p className="font-semibold text-gray-600">Show Time : </p>
                  <p className=" font-medium">{data?.showTime?.startTime}</p>
               </div>
               <div
                  className={`mt-8 flex h-12 justify-center rounded-lg ${
                     isLoading
                        ? "bg-gray-500"
                        : data?.valid
                        ? "bg-green-500"
                        : "bg-red-500"
                  }  p-3 text-center text-white`}
               >
                  {isLoading ? (
                     <Loader color="red" size="xl" variant="dots" />
                  ) : data?.valid ? (
                     "Valid"
                  ) : (
                     "Not Valid"
                  )}
               </div>
               {data?.errors?.length > 0 && <div className="mt-8 flex h-12 justify-center rounded-lg bg-red-500 p-3 text-center text-white">{data.errors.join(",")}</div>}

               {/* table  */}
               <table className="scan_table mt-10 flex w-[460px] max-h-[260px] flex-col overflow-scroll">
                  <tr className="grid grid-cols-4 gap-5 border-b-2  text-start">
                     <th className="text-start  font-bold text-[18px] ">اسم الفيلم</th>
                     <th className="text-start font-bold text-[18px]">تاريخ الحجز</th>
                     <th className="text-start font-bold text-[18px]">وقت الدخول</th>
                     <th className="text-start font-bold">showTime</th>
                  </tr>
                  {data?.allReservations?.tickets.map((item, index) => (
                     <tr
                        key={index}
                        className=" grid grid-cols-4 gap-5 border-b-2"
                     >
                        <td className="font-bold scan_td_wrap">{item.movie}</td>
                        <td className="font-bold scan_td_wrap">
                           {dayjs(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                        <td className="font-bold scan_td_wrap">
                           {dayjs(item.scanDate).format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                        <td className="font-bold scan_td_wrap">
                           {item.showTime}
                        </td>
                     </tr>
                  ))}
               </table>
            </div>
            <div className="flex w-1/3 flex-row justify-around">
               <div className="text-center">
                  <p className="font-bold">Screen Seats</p>
                  <p className="font-[500]">
                     Seat {data?.allReservations?.totalSeats}
                  </p>
               </div>
               <div className="text-center">
                  <p className="font-bold">Registered Tickets</p>
                  <p className="font-[500]">
                     Ticket {data?.allReservations?.registeredTickets}
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ScanPage;
