import React, { useState } from "react";
import EditRow from "../../components/screens/EditRow";
import { Link, useParams } from "react-router-dom";
import { useScreenQuery } from "../../store/screensApi";
import { useSeatsRowsQuery } from "../../store/seatsApi";
import ReservationBtn from "../../components/reservation/ReservationBtn";
import EditSeatSuits from "../../components/screens/EditSeatSuits";
import { Helmet } from "react-helmet";

const seatsStatus = [
  {
    id: "Available",
    title: "Available",
    color: "#fff",
    borderColor: "#CFD8DC",
  },
  { id: "Booked", title: "Booked", color: "#90CAF9" },
  { id: "Holding", title: "Holding", color: "#9FA8DA" },
  { id: "Invitation", title: "Invitation", color: "#A5D6A7" },
  { id: "Waiting", title: "Waiting", color: "#CE93D8" },
  { id: "Repair", title: "Repair", color: "#FFCC80" },
  { id: "Emergency", title: "Emergency", color: "#EF9A9A" },
  {
    id: "Online reservation",
    title: "Online reservation",
    color: "#FFFFFF",
    borderColor: "#80CBC4",
  },
];

const SeatsFormation = () => {
  const { screenId } = useParams();
  const { data: screen } = useScreenQuery(screenId);
  const { data: seatsBack, isLoading } = useSeatsRowsQuery(screenId);
  const [open, setOpen] = useState(false);
  // const [rows, setRows] = useState();

  return (
    <div className="flex h-full flex-col items-center justify-between gap-10">
      <Helmet>
        <title>Seats Formation</title>
      </Helmet>
      <div className="h-full w-full pt-6">
        <div className=" flex flex-wrap items-center justify-between px-4 align-baseline">
          <div className="flex  items-center justify-between ">
            <img src="/screen.svg" alt="screen" className="w-10" />
            <span className="ml-2 text-2xl font-semibold">{screen?.name}</span>
          </div>
          <button
            className="h-[55px] w-[190px]  rounded-2xl border-2 border-[#3B5DAD] px-6 py-1 text-[#3B5DAD]  hover:bg-[#3B5DAD] hover:text-white"
            onClick={() => {
              setOpen(true);
            }}
          >
            Edit Seat Suits
          </button>
        </div>
        <div className={`${screen?.template} mt-[42px] grid h-full w-full `}>
          {!isLoading &&
            seatsBack &&
            seatsBack.map((seatsData, i) => (
              <React.Fragment key={i}>
                <div style={{ gridArea: seatsData.title.split("-")[1] }}>
                  <ReservationBtn
                    key={seatsData._id}
                    seat={seatsData}
                    // onClick={() => stateHandler(seatsData)}
                    index={Math.ceil((i + 1) / 20)}
                    selectC=""
                  />
                </div>
              </React.Fragment>
            ))}
        </div>
        {open && (
          <EditSeatSuits
            open={open}
            setOpen={setOpen}
            action="Save Edits"
            screen={screen}
            seatsBack={seatsBack}
          />
        )}
      </div>
      <div className="mt-4 flex justify-between gap-4">
        {seatsStatus.map((seatstatus) => (
          <div key={seatstatus.id} className="flex items-center">
            <span
              className="h-4 w-4 rounded-full border bg-red-200"
              style={{
                backgroundColor: `${seatstatus.color}`,
                borderColor: `${
                  seatstatus.borderColor ? seatstatus.borderColor : ""
                }`,
              }}
            ></span>
            <label
              htmlFor={seatstatus.id}
              className=" ml-1 block text-xs text-gray-700"
            >
              {seatstatus.title}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeatsFormation;
