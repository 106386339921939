import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/uiSlice";
import { useEffect, useState } from "react";
import { useGetResDetailsQuery } from "../../store/reservation/reservationApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DetailsModal = () => {
  const [resToken, setResToken] = useState();
  const dispatch = useDispatch();
  const detailsPopup = useSelector((state) => state.ui.detailsModal);

  const {
    data: details,
    isLoading,
    isError,
    error,
  } = useGetResDetailsQuery(resToken, { skip: resToken === undefined });

  useEffect(() => {
    setResToken(detailsPopup.seat.reservationStatus?.reservationToken);
  }, []);

  return (
    <div
      className={classNames(
        " fixed top-0 right-0 z-50 flex h-[100vh] w-full items-center justify-center bg-[#00000048] "
      )}
    >
      <div className=" relative flex h-fit w-[476px] rotate-180 flex-col items-center rounded-xl bg-white py-14 ">
        <div
          onClick={() => dispatch(uiActions.setDetailsModal(false))}
          className="absolute right-8 top-5 cursor-pointer text-rose-700"
        >
          <i className="fa-solid fa-circle-xmark"></i>
        </div>
        {!detailsPopup.seat.reservationStatus?.reservationToken ? (
          <div className="flex h-full w-full items-center justify-center">
            This is an old reservation
          </div>
        ) : (
          <div className="flex h-full w-full flex-col justify-center px-5">
            <div className="flex items-center">
              <p className="text-lg font-bold">Agent Name :</p>
              <p className="ml-1">{details?.[0]?.agent?.name}</p>
            </div>
            <div className="flex items-center">
              <p className="text-lg font-bold">seats :</p>
              {details?.map((seat, index) => (
                <div
                  className="flex"
                  key={`${seat.seat.row}${seat.seat.column}`}
                >
                  <p className="mx-1">
                    {seat.seat.row}
                    {seat.seat.column}
                  </p>
                  {index !== details.length - 1 && <span>-</span>}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsModal;
