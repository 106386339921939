import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MovieCard from "../reservation/MovieCard";
import ScrollContainer from "react-indiana-drag-scroll";
import DeleteButton from "../ui-assets/DeleteButton";
import {
  useDeleteShowMovieMutation,
  useRecoverShowMovieMutation,
  useToggleLockShowMovieMutation,
  useToggleOnlineShowMovieMutation,
} from "../../store/showMovieApi";
import Recovery from "../ui-assets/Back";
import CustomToast from "../../utiles/CustomToast";
import { sortShowTime } from "../../utiles/sortTime";
import Modal from "../ui-assets/Modal";
import OnlineModal from "./onlineModal";
import LockModal from "./LockShowMovie";
import dayjs from "dayjs";

const ShowMoviesCard = ({ showMovie, removed, reFetch }) => {
  const { _id, movie, screens } = showMovie;
  const [deleteShowMovie, { isSuccess, isError, error }] =
    useDeleteShowMovieMutation();
  const [showMovieRecovery, { isSuccess: recIsSuccess, recIsError, recError }] =
    useRecoverShowMovieMutation();
  const onDelete = (id) => {
    deleteShowMovie(id);
  };
  const onRecovery = (id) => {
    showMovieRecovery(id);
  };

  const [
    ToggleLockShowMovie,
    { isSuccess: toggleIsSuccess, isError: toggleIsError, error: toggleError },
  ] = useToggleLockShowMovieMutation();
  const [
    ToggleOnlineShowMovie,
    {
      isSuccess: toggleOnlineIsSuccess,
      isError: toggleOnlineIsError,
      error: toggleOnlineError,
    },
  ] = useToggleOnlineShowMovieMutation();

  const [ShowLockModal, setShowLockModal] = useState({
    show: false,
    index: 0,
  });

  useEffect(() => {
    if (toggleIsSuccess || toggleIsError) {
      reFetch();
    }
  }, [toggleIsSuccess, toggleIsError]);

  useEffect(() => {
    if (toggleOnlineIsSuccess || toggleOnlineIsError) {
      reFetch();
    }
  }, [toggleOnlineIsSuccess, toggleOnlineIsError]);

  return (
    <>
      {(isError || isSuccess) && (
        <CustomToast
          IsError={isError}
          error={error}
          isSuccess={isSuccess}
          path="/showmovies"
        />
      )}
      {(recIsError || recIsSuccess) && (
        <CustomToast
          IsError={recIsError}
          error={recError}
          isSuccess={recIsSuccess}
          path="/showmovies"
        />
      )}
      <div className="  grid h-auto  w-full auto-rows-auto grid-cols-12 gap-x-5 gap-y-2  rounded-2xl border border-[#CFD8DC] p-4">
        <div className="col-start-1 col-end-3 row-start-2  h-full w-full">
          <MovieCard
            text="hidden"
            height="w-full h-full"
            opacity=""
            showMovie={showMovie}
          />
        </div>
        <div className=" col-start-3 col-end-13 flex flex-row items-center justify-between">
          <h1 className=" truncate text-base font-bold">{movie?.name}</h1>
          <h1 className=" truncate text-base font-bold">
            Release Date:{" "}
            {new Date(showMovie?.releaseDate).toLocaleDateString() || "NA"}
          </h1>
          <div className=" flex w-20  justify-around  ">
            {!removed && (
              <Link
                title="Edit"
                to={`/edit-showmovie/${_id}`}
                state={{ releaseDate: showMovie?.releaseDate }}
                className="flex h-8 w-8 items-center justify-center rounded-lg bg-white  text-[#3B5DAD] shadow-md hover:text-[#3B5DAD] focus:outline-none "
              >
                <span className="sr-only">Edit</span>
                <img src="/edit.svg" alt="edit" />
              </Link>
            )}

            {removed ? (
              // <div
              //   title="Back"
              //   className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg  bg-white  shadow-md "
              // >
              //   <span className="sr-only">Back</span>
              //   <i className="fa-solid fa-reply text-green-500"></i>
              // </div>
              <Recovery onDelete={() => onRecovery(_id)} item="" />
            ) : (
              <DeleteButton onDelete={() => onDelete(_id)} item="" />
            )}
          </div>
        </div>
        <div className="col-start-3 col-end-13 row-start-2 mt-2 mb-4 flex w-full flex-row justify-start  ">
          <ScrollContainer
            horizontal={true}
            hideScrollbars={false}
            className={`my-scroll-container !mb-0`}
          >
            <div className="col-start-3 col-end-13 row-start-2 mt-2 mb-4 flex w-full flex-row justify-start">
              {screens &&
                [...screens]
                  .sort(
                    (a, b) =>
                      +a.screen.name.split(" ")[1] -
                      +b.screen.name.split(" ")[1]
                  )
                  ?.map(({ screen, showTimes }, i) => (
                    <div
                      key={i}
                      className="col-span-1 mr-2  flex basis-1/3 flex-col"
                    >
                      <div className="place-self-center">
                        <div className="flex gap-2">
                          <img className="w-4" src="/screen.svg" alt="screen" />
                          <span className="h-[15px] font-bold text-red-600">
                            {screen?.type === "VIP" && "VIP"}
                          </span>
                        </div>
                        <span className="text-xs font-bold">
                          {screen?.name.split(" ")[1]}
                        </span>
                      </div>
                      <div className="flex flex-col justify-between gap-2">
                        {sortShowTime(showTimes)?.map((showtime, i) => (
                          <span className="flex  gap-2">
                            <span
                              key={i}
                              className=" h-5 min-w-[80px] rounded-lg bg-[#00C18B] px-2 py-1 text-center text-xs text-white"
                            >
                              {showtime.startTime}
                            </span>
                            <div className="flex w-[40px] justify-between ">
                              <LockModal
                                showtime={showtime}
                                onToggleLock={ToggleLockShowMovie}
                              />
                              <OnlineModal
                                showtime={showtime}
                                onToggleOnline={ToggleOnlineShowMovie}
                              />
                            </div>
                            <div className="mr-2 ml-1 truncate text-sm font-bold">
                            {
                              showtime?.date <= showtime?.expDate ? 
                              `${dayjs(showtime?.date)?.format("D / M")} - 
                              ${dayjs(showtime?.expDate)?.format("D / M")}` :
                              `replaced`
                            }
                            </div>
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
            </div>
          </ScrollContainer>
        </div>
      </div>
    </>
  );
};

export default ShowMoviesCard;
