import React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useAuthUserQuery } from "../../store/authUserApi";
import {
   useAgentCheckPointsQuery,
   useCheckPointQuery,
   useCreateCheckPointMutation,
} from "../../store/checkPointApi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { useGetpriceSettingsQuery } from "../../store/reservation/reservationApi";

function classNames(...classes) {
   return classes.filter(Boolean).join(" ");
}
const schema = yup
   .object({
      email: yup.string().email().required("email is required"),
      superVisorCash: yup
         .number("cash must be a number")
         .required("cash is required"),
      password: yup
         .string()
         .required("Please enter your password.")
         .min(8, "Your password is too short."),
      transactionType: yup.string().required("Transaction is required"),
      paymentMethod: yup.string().required("PaymentMethod amount is required"),
   })
   .required();

function Checkpoint({ On_Of, faeditstatus, showMovie }) {
   const cancelButtonRef = useRef(null);
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
   });

   const { data: authUser } = useAuthUserQuery();
   const { data: checkPointData, refetch } = useCheckPointQuery(authUser?._id);
   const { data: agentCheckPoints, refetch: checkPointRefetch } =
      useAgentCheckPointsQuery(authUser?._id);
   // eslint-disable-next-line no-unused-vars
   const [CreateCheckPoint, { data, isSuccess, isError, error }] =
      useCreateCheckPointMutation();

   const checkPointHandler = (e) => {
      CreateCheckPoint({
         ...e,
         agent: authUser?._id,
         superVisor: authUser?._id,
      });
      refetch();
   };

   const currencyFormat = Intl.NumberFormat("en-US");

   useEffect(() => {
      if (On_Of) {
         refetch();
         checkPointRefetch();
      }
   }, [On_Of, refetch, checkPointRefetch]);

   const { data: priceSettings } = useGetpriceSettingsQuery();

   console.log(checkPointData);

   const notify = (i, ii) =>
      ii === "error" ? toast.error(i) : toast.success(i);

   useEffect(() => {
      if (isSuccess) {
         notify("Success!", "success");
      }
      if (isError) {
         notify(error?.data?.message, "error");
      }
   }, [isSuccess, isError, error?.data?.message]);

   return (
      <>
         <Transition.Root show={On_Of} as={Fragment}>
            <Dialog
               as="div"
               className="relative z-10"
               initialFocus={cancelButtonRef}
               onClose={faeditstatus}
            >
               <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
               >
                  <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
               </Transition.Child>

               <div className="fixed inset-0 z-10 w-full ">
                  <div className="flex min-h-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                     <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0  translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100  translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                     >
                        <Dialog.Panel className="relative w-[700px]  transform rounded-2xl  bg-white text-left shadow-xl transition-all ">
                           <form onSubmit={handleSubmit(checkPointHandler)}>
                              <div className="flex w-full justify-between p-[32px]">
                                 <h1 className=" text-lg font-extrabold text-[#3B5CAD] ">
                                    Check Point
                                 </h1>
                                 <div
                                    onClick={() => {
                                       faeditstatus();
                                    }}
                                    className=" cursor-pointer text-rose-700"
                                 >
                                    <i className="fa-solid fa-circle-xmark"></i>
                                 </div>
                              </div>
                              <div className="mt-4 flex w-full items-start gap-4 px-8">
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Shift start
                                    </span>
                                    <p className="text-base font-semibold">
                                       {new Date(
                                          authUser?.loginDate
                                       ).toLocaleTimeString()}
                                    </p>
                                 </div>
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Sold Tickets
                                    </span>
                                    <p className="text-base font-semibold">
                                       {checkPointData?.cash?.ticketCount +
                                          checkPointData?.credit?.ticketCount ||
                                          0}
                                    </p>
                                 </div>
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Cash Tickets
                                    </span>
                                    <p className="text-base font-semibold">
                                       {checkPointData?.cash?.ticketCount || 0}
                                    </p>
                                 </div>
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Credit Tickets
                                    </span>
                                    <p className="text-base font-semibold">
                                       {checkPointData?.credit?.ticketCount ||
                                          0}
                                    </p>
                                 </div>
                              </div>
                              <div className="mt-4 flex w-full items-start gap-4  border-b-2 border-slate-200 px-8 pb-6">
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Sold Glasses
                                    </span>
                                    <p className="text-base font-semibold">
                                       {checkPointData?.cash?.soldGlasses +
                                          checkPointData?.credit?.soldGlasses ||
                                          0}
                                    </p>
                                 </div>
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Cash Glasses
                                    </span>
                                    <p className="text-base font-semibold">
                                       {checkPointData?.cash?.soldGlasses}
                                    </p>
                                 </div>
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Credit Glasses
                                    </span>
                                    <p className="text-base font-semibold">
                                       {checkPointData?.credit?.soldGlasses}
                                    </p>
                                 </div>
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Checkpoint
                                    </span>
                                    <p className="text-base font-semibold">
                                       {new Date().toLocaleTimeString()}
                                    </p>
                                 </div>
                              </div>
                              <div className="mt-4 flex w-full items-center justify-between  gap-4 px-8 pb-6">
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Total sales
                                    </span>
                                    <p className="text-base font-semibold">
                                       {currencyFormat.format(
                                          (checkPointData?.cash?.ticketsPrice ||
                                             0) +
                                             (checkPointData?.credit
                                                ?.ticketsPrice || 0)
                                       )}{" "}
                                       EGP
                                    </p>
                                 </div>
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Total Cash
                                    </span>
                                    <p className="text-base font-semibold">
                                       {currencyFormat.format(
                                          (checkPointData?.cash?.ticketsPrice ||
                                             0) -
                                             (agentCheckPoints?.superVisorCashOut ||
                                                0) +
                                             (agentCheckPoints?.superVisorCashIn ||
                                                0)
                                       )}{" "}
                                       EGP
                                    </p>
                                 </div>
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Total Credit
                                    </span>
                                    <p className="text-base font-semibold">
                                       {currencyFormat.format(
                                          (checkPointData?.credit
                                             ?.ticketsPrice || 0) -
                                             (agentCheckPoints?.superVisorCreditOut ||
                                                0)
                                       )}{" "}
                                       EGP
                                    </p>
                                 </div>
                                 <div className="flex basis-1/3  flex-col">
                                    <span className=" text-xs text-[#607D8B]">
                                       Total Glasses
                                    </span>
                                    <p className="text-base font-semibold">
                                       {priceSettings?.glassesPrice *
                                          (checkPointData?.cash?.soldGlasses +
                                             checkPointData?.credit
                                                ?.soldGlasses)}{" "}
                                       EGP
                                    </p>
                                 </div>
                              </div>

                              <div className="w-full px-8">
                                 <div>
                                    <h1 className="  text-xs font-extrabold text-[#3B5CAD] ">
                                       Cash Delivered to Supervisor
                                    </h1>
                                    <div className="mt-2 flex w-full items-center justify-between">
                                       <div className="flex h-[41px] w-1/2 flex-row items-center justify-between rounded-xl border border-[#CFD8DC]">
                                          <input
                                             name="superVisorCash"
                                             {...register("superVisorCash")}
                                             className=" h-4/5 w-[70%]  border-r-2 px-4 outline-none"
                                          />
                                          <p className=" w-[30%] text-center font-semibold">
                                             EGP
                                          </p>
                                       </div>

                                       <div className="flex   flex-col">
                                          <span className=" text-xs text-[#607D8B]">
                                             Total Credit
                                          </span>
                                          <p className="text-base font-semibold text-[#00C18B]">
                                             {currencyFormat.format(
                                                (agentCheckPoints?.superVisorCashOut ||
                                                   0) +
                                                   (agentCheckPoints?.superVisorCreditOut ||
                                                      0)
                                             )}{" "}
                                             EGP
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mt-4 flex w-full flex-row items-center justify-around px-3">
                                    <div className="flex w-1/2 flex-row  items-start justify-around ">
                                       <div className=" mb-1 flex items-center">
                                          <input
                                             {...register("paymentMethod")}
                                             type="radio"
                                             name="paymentMethod"
                                             value="Credit"
                                             id="paymentMethod1"
                                             className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                                          />
                                          <label
                                             htmlFor="paymentMethod1"
                                             className="ml-2 select-none text-sm font-medium text-gray-900 "
                                          >
                                             Credit
                                          </label>
                                       </div>
                                       <div className="flex items-center">
                                          <input
                                             {...register("paymentMethod")}
                                             type="radio"
                                             name="paymentMethod"
                                             value="Cash"
                                             id="paymentMethod2"
                                             className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                                          />
                                          <label
                                             htmlFor="paymentMethod2"
                                             className="ml-2 select-none text-sm font-medium text-gray-900 "
                                          >
                                             Cash
                                          </label>
                                       </div>
                                    </div>
                                    <span className="h-5 w-1 rounded-lg bg-slate-500"></span>
                                    <div className="flex w-1/2  flex-row items-start justify-around ">
                                       <div className=" mb-1 flex items-center">
                                          <input
                                             {...register("transactionType")}
                                             type="radio"
                                             name="transactionType"
                                             value="Withdrawal"
                                             id="transactionType1"
                                             className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                                          />
                                          <label
                                             htmlFor="transactionType1"
                                             className="ml-2 select-none text-sm font-medium text-gray-900 "
                                          >
                                             Withdrawal
                                          </label>
                                       </div>
                                       <div className="flex items-center">
                                          <input
                                             {...register("transactionType")}
                                             type="radio"
                                             name="transactionType"
                                             value="Deposit"
                                             id="transactionType2"
                                             className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                                          />
                                          <label
                                             htmlFor="transactionType2"
                                             className="ml-2 select-none text-sm font-medium text-gray-900 "
                                          >
                                             Deposit
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="mt-6 flex w-4/5 flex-row justify-between self-start px-8">
                                 <div>
                                    <label
                                       htmlFor="email"
                                       className="block text-sm font-medium text-[#3B5CAD]"
                                    >
                                       Email address
                                    </label>
                                    <div className="mt-1">
                                       <input
                                          {...register("email")}
                                          id="email"
                                          name="email"
                                          type="email"
                                          autoComplete="email"
                                          className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                                       />
                                       {errors.email && (
                                          <span className="text-xs text-red-500">
                                             {errors.email?.message}
                                          </span>
                                       )}
                                    </div>
                                 </div>

                                 <div>
                                    <label
                                       htmlFor="password"
                                       className="block text-sm font-medium text-[#3B5CAD]"
                                    >
                                       Password
                                    </label>
                                    <div className="mt-1">
                                       <input
                                          {...register("password")}
                                          id="password"
                                          name="password"
                                          type="password"
                                          autoComplete="current-password"
                                          className="block w-full appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#3B5CAD] focus:outline-none focus:ring-[#3B5CAD] sm:text-sm"
                                       />
                                    </div>
                                    <div className="h-8">
                                       {errors.password && (
                                          <span className="text-xs text-red-500 ">
                                             {errors.password?.message}
                                          </span>
                                       )}
                                    </div>
                                 </div>
                              </div>
                              <button
                                 // onClick={() =>
                                 //   !data &&
                                 //   notify(
                                 //     "The account email or password that you have entered is incorrect.",
                                 //     "error"
                                 //   )
                                 // }
                                 className=" w-full  rounded-b-xl bg-primary py-4 text-white"
                              >
                                 Check Point
                              </button>
                           </form>
                        </Dialog.Panel>
                     </Transition.Child>
                  </div>
               </div>
            </Dialog>
         </Transition.Root>
      </>
   );
}

export default Checkpoint;
